import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, PlusCircleOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Table, Avatar, Tag, Divider, Button, Select, Row, Tooltip, Popover, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { cleanObject, getFirstLetterOfWords, isValidDate, stripHtml } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';
import BaseSelect from '../../components/Elements/BaseSelect';
import DentalPatientWaitingForm from './DentalPatientWaitingForm'
// actions
import { getListPatientWaiting } from '../../redux/actions/DentalPatientCasesActions';
import { getUser } from '../../redux/actions/UserActions';
import NumberFormat from 'react-number-format';
import TextTruncate from 'react-text-truncate';
import HtmlParser from 'react-html-parser';
import moment from 'moment';

const { Paragraph } = Typography

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/dental-patient-waiting',
        breadcrumbName: 'Danh sách bệnh nhân đang chờ',
    },
]

class DentalPatientWaitingList extends Component {
    state = {
        isLoading: false,
        isOpenForm: false,
        listUser: [],
        selectedRowKeys: []
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListPatientWaiting(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListPatientWaiting(query).then(() => {
                this.setState({
                    isLoading: false
                })
            });
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query.page = 1;
        query = cleanObject(query); // remove empty fields

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));

        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order == 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });

    }

    toggleOpenForm(isOpenForm) {
        const { selectedRowKeys } = this.state;
        const listUserPromises = selectedRowKeys.map(user_id => {
            return this.props.getUser(user_id);
        });
        Promise.all(listUserPromises)
            .then(responses => {
                const listUser = responses.map(res => res);
                this.setState({ isOpenForm: isOpenForm, listUser: listUser });
            })
            .catch(error => {
                console.error("Error fetching users:", error);
            });
    }

    handleCancel() {
        this.toggleOpenForm(false);
        this.setState({ selectedRowKeys: [] })
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    render() {
        var { list_patient_waiting, pagination, config } = this.props;
        var { isLoading, isOpenForm, listUser, selectedRowKeys } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const columns = [
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '250px',
                render: (text, record) => {
                    if (record.full_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;{record.full_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Bác sĩ, phụ tá',
                dataIndex: 'appointment_schedule',
                key: 'appointment_schedule',
                width: '150px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {moment(item.time).format('DD/MM/YYYY HH:mm')}
                                </div>
                            )
                        })
                        let first_item = text[0]?.time;
                        return (
                            <div className='item-action-btn' onClick={() => this.openAppointmentScheduleDetail(record.id)}>
                                <Popover placement="right" content={content}>
                                    {moment(first_item).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'dental_patient_cases_note',
                key: 'dental_patient_cases_note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày nhận',
                dataIndex: 'assign_date',
                key: 'assign_date',
                width: '130px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, true)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle
                    title="Danh sách bác sĩ, phụ tá"
                    routes={routes}
                />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            checkPermission('doctor_assistant_status', 'assign') ? (
                                <Button className="table-button" onClick={() => this.toggleOpenForm(true)} type='primary' icon={<PlusCircleOutlined />} disabled={!selectedRowKeys.length ? true : false}>
                                    Giao bệnh nhân
                                </Button>
                            ) : null
                        }

                        <BaseSelect
                            placeholder="Lọc theo trạng thái"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter("user_status", value)}
                            options={[
                                { label: "Trạng thái", value: "" },
                                { label: "Đang sẵn sàng", value: 'available' },
                                { label: "Đang bận", value: 'busy' },
                            ]}
                            className="table-button"
                            defaultValue={query.user_status || null}
                        />
                        <Select
                            showSearch
                            placeholder="Lọc theo nhóm"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter("role", value)}
                            options={config.role_trees}
                            optionFilterProp="children"
                            allowClear
                            className="table-button"
                            defaultValue={parseInt(query.role) || null}
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size='small'
                    dataSource={list_patient_waiting ?? []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={{
                        ...rowSelection,
                        getCheckboxProps: (record) => ({
                            disabled: record.user_status === 'busy',
                        }),
                    }}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <DentalPatientWaitingForm
                    visible={isOpenForm}
                    listUser={listUser}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.handleCancel()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        list_patient_waiting: state.DentalPatientCasesReducer.list_patient_waiting,
        pagination: state.DentalPatientCasesReducer.pagination,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListPatientWaiting: (filter) => dispatch(getListPatientWaiting(filter)),
        getUser: (id, filter) => dispatch(getUser(id, filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DentalPatientWaitingList);
