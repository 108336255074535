import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col } from 'antd';
import { _newLine } from '../../utils/helpers';
import BaseRadios from '../../components/Elements/BaseRadios'
import { isMobile } from 'react-device-detect';
// actions
import { createTelegram, updateTelegram } from '../../redux/actions/TelegramActions';

class TelegramForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            image: []
        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateTelegram(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    await this.props.createTelegram(values);
                    this.handCancel()
                    this.props.reloadData()
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false })
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, users } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.handCancel()}>
                        Huỷ
                    </Button>,
                    <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentData ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        {/* <Form.Item label="User">
                            {getFieldDecorator("user_id", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.user_id : ""
                            })(
                                <BaseSelect
                                    options={users}
                                    defaultText="Chọn user"
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    placeholder="Chọn user"
                                    showSearch
                                />
                            )}
                        </Form.Item> */}
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên nhóm chat">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Group Id">
                                    {getFieldDecorator('chat_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.chat_id : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Route">
                                    {getFieldDecorator("route", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.route : null
                                    })(
                                        <BaseRadios
                                            options={
                                                [
                                                    // { value: 'idea', label: "Idea" },
                                                    // { value: 'design', label: "Design" },
                                                    // { value: 'content', label: "Content" },
                                                    { value: 'general', label: "General" }
                                                ]
                                            }
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator("status", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.status : 1
                            })(
                                <BaseRadios
                                    options={
                                        [
                                            { value: 1, label: "Đã kích hoạt" },
                                            { value: 0, label: "Chưa kích hoạt" }
                                        ]
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createTelegram: (data) => dispatch(createTelegram(data)),
        updateTelegram: (id, data) => dispatch(updateTelegram(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TelegramForm' })(TelegramForm));
