import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Modal, Button, Row, Col, Popover, Divider, List, Badge, Skeleton, Avatar } from 'antd';
import {
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    BellOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import qs from 'qs';
import AppDrawer from './AppDrawer';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import logo from '../assets/img/logo-white-text.png';
import logo_avatar from '../assets/img/Logo-chingo-dental.png';
import { addDeviceToken } from '../redux/actions/NotificationsActions';
import firebaseService from '../utils/FirebaseService';
import audioFile from '../assets/audio/message_received.mp3';
import HtmlParser from 'react-html-parser';
import { checkPermission } from '../utils/permission';
import { logout } from '../redux/actions/AuthActions';
import { getListNotifications, getCountNotifications, updateIsRead } from '../redux/actions/NotificationsActions';
import { getListCustomerConsultantDirect } from '../redux/actions/CustomerConsultantDirectActions';
import { getListCustomerSchedule } from '../redux/actions/CustomerScheduleActions';
import { getAllData } from '../redux/actions/ProjectBasedJobActions';
import { getListCustomer } from '../redux/actions/CustomerActions';
import { getDoctorAssistantStatus } from '../redux/actions/DoctorAssistantStatusActions';
import { getListCustomerTelesale } from '../redux/actions/CustomerTelesaleActions';

const { Header } = Layout;
const { confirm } = Modal;

class AppHeader extends Component {

    constructor(props) {
        super(props);
        this.audio = new Audio(audioFile);
        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        if (!isMobile) {
            this.fetchAndProcessData();
            // Firebase lấy token
            firebaseService.fetchToken(this.setCurrentTokenCallback);

            // Firebase Lắng nghe sự kiện push từ Service Worker
            firebaseService.onMessageListener(() => {
                this.fetchAndProcessData();
                this.playSound();
            });

            // Lắng nghe sự kiện người dùng thu nhỏ or phóng to or chuyển tab trình duyệt
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
        }
    }

    setCurrentTokenCallback = (token) => {
        console.log('Current token:', token);
        this.props.addDeviceToken({ device_token: token });
    };

    fetchAndProcessData = () => {
        console.log('fetchAndProcessData called');
        var query = qs.parse(this.props.location.search.slice(1));
        console.log(query)
        this.props.getListNotifications();
        this.props.getCountNotifications();

        // Chỉ chạy những module nào tương ứng với quyền của từng user
        const permissionsToCheck = [
            { role: 'customer_telesale', action: 'list', actionFunction: this.props.getListCustomerTelesale },
            { role: 'customer_consultant_direct', action: 'list', actionFunction: this.props.getListCustomerConsultantDirect },
            { role: 'customer_schedule', action: 'list', actionFunction: this.props.getListCustomerSchedule },
            { role: 'project_based_job', action: 'list', actionFunction: this.props.getAllData },
            { role: 'customer', action: 'list', actionFunction: this.props.getListCustomer },
            { role: 'doctor_assistant_status', action: 'list', actionFunction: this.props.getDoctorAssistantStatus }
        ];

        for (const permission of permissionsToCheck) {
            if (checkPermission(permission.role, permission.action)) {
                permission.actionFunction(query);
            }
        }
    }

    handleVisibilityChange = () => {
        if (!document.hidden) {
            this.fetchAndProcessData();
            console.log('Tab is visible, refreshing data...');
        }
    };

    playSound = () => {
        if (!this.audio.paused) {
            this.audio.pause(); // Dừng âm thanh nếu đang phát
            this.audio.currentTime = 0; // Đặt lại thời gian phát về 0
        }
        this.audio.play();
    };

    toggleDrawer(visible) {
        this.setState({ visible })
    }

    onLogout() {
        confirm({
            title: 'Xác nhận đăng xuất',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn chắc chắn muốn đăng xuất chứ?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                this.props.logout().then(() => {
                    window.location.href = '/login'
                })
            }
        })
    }

    handleNotificationScroll = () => {
        this.props.getListNotifications({ page: this.props.pagination.currentPage + 1 });
    }

    updateIsRead(notification_id) {
        this.props.updateIsRead(notification_id).then(() => {
            this.fetchAndProcessData();
        })
    }

    render() {
        var { visible } = this.state;
        var { authUser, notifications, countNotifications, pagination } = this.props;

        return (
            <Header id="custom-header" style={!isMobile ? { paddingRight: '30px' } : null}>
                <Row justify="space-between">
                    <Col>
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>
                        </div>
                    </Col>
                    <Col>
                        {
                            isMobile ? (
                                <Button
                                    type="link"
                                    icon={<MenuFoldOutlined className="header-icons" />}
                                    onClick={() => this.toggleDrawer(true)}
                                />
                            ) : (
                                <>
                                    <Popover
                                        className="header-actions"
                                        placement="bottomRight"
                                        content={
                                            <div>
                                                <Link to='/profile'>
                                                    <Button type="link" className="p-0 align-items-center">
                                                        <InfoCircleOutlined /> Thông tin tài khoản
                                                    </Button>
                                                </Link>
                                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                <Button type="link" className="p-0 align-items-center" onClick={() => this.onLogout()}>
                                                    <LogoutOutlined /> Đăng xuất
                                                </Button>
                                            </div>
                                        }
                                        title={null}
                                    >
                                        <Avatar
                                            style={{
                                                backgroundColor: '#1b4752',
                                                verticalAlign: 'middle'
                                            }}
                                            size={35}>
                                            <UserOutlined className='header-icons' />
                                        </Avatar>
                                        &nbsp;<span className="text-white">{authUser ? authUser.full_name : null}</span>
                                    </Popover>

                                    <Divider type='vertical' />
                                    <Popover
                                        className="header-actions"
                                        placement="bottomRight"
                                        trigger="click"
                                        content={
                                            <div
                                                id="scrollableDiv1"
                                                style={{
                                                    height: '88vh',
                                                    overflow: 'auto',
                                                    padding: '0 16px',
                                                    width: '320px'
                                                }}
                                            >
                                                <InfiniteScroll
                                                    dataLength={notifications.length}
                                                    next={this.handleNotificationScroll}
                                                    hasMore={notifications.length < (pagination.total - 2)}
                                                    scrollableTarget="scrollableDiv1"
                                                    loader={<Skeleton avatar paragraph={{ rows: 1, }} active />}
                                                    endMessage={<p style={{ textAlign: 'center' }}>Hết dữ liệu</p>}
                                                >
                                                    <List
                                                        header={<div><h5><BellOutlined /> Thông báo</h5></div>}
                                                        itemLayout="horizontal"
                                                        size="small"
                                                        dataSource={notifications}
                                                        renderItem={(item) => (
                                                            <List.Item key={item?.id}>
                                                                <List.Item.Meta
                                                                    avatar={<Avatar src={logo_avatar} />}
                                                                    title={
                                                                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                                                            <Link onClick={() => this.updateIsRead(item?.id)} className={item?.is_read ? "readed" : "unread"} to={item?.url || '#'}>
                                                                                {HtmlParser(item?.message)}
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                    description={<span>{moment(item?.created_at).fromNow()}</span>}
                                                                />
                                                                <div className='status-notification'>{item?.is_read ? <Badge status="default" /> : <Badge color='blue' />}</div>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </InfiniteScroll>
                                            </div>
                                        }
                                    >
                                        <Badge offset={[2, 2]} count={countNotifications?.unreadCount} size="small" overflowCount={10}>
                                            <BellOutlined onClick={this.fetchAndProcessData} style={{ fontSize: '22px', color: "#fff" }} />
                                        </Badge>
                                    </Popover>
                                </>
                            )
                        }
                    </Col>
                </Row >
                <AppDrawer visible={visible} onClose={() => this.toggleDrawer(false)} />
            </Header >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        notifications: state.NotificationsReducer.notifications,
        pagination: state.NotificationsReducer.pagination,
        countNotifications: state.NotificationsReducer.countNotifications,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        getListNotifications: (filter) => dispatch(getListNotifications(filter)),
        getCountNotifications: () => dispatch(getCountNotifications()),
        updateIsRead: (id) => dispatch(updateIsRead(id)),
        addDeviceToken: (data) => dispatch(addDeviceToken(data)),
        getListCustomerTelesale: (filter) => dispatch(getListCustomerTelesale(filter)),
        getListCustomerConsultantDirect: (filter) => dispatch(getListCustomerConsultantDirect(filter)),
        getListCustomerSchedule: (filter) => dispatch(getListCustomerSchedule(filter)),
        getAllData: (filter) => dispatch(getAllData(filter)),
        getListCustomer: (filter) => dispatch(getListCustomer(filter)),
        getDoctorAssistantStatus: (filter) => dispatch(getDoctorAssistantStatus(filter))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));