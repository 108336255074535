import api from '../../utils/api';
import {
    GET_LIST_FUNCTIONAL_AREA,
    GET_SPECIFIC_FUNCTIONAL_AREA,
    UPDATE_SPECIFIC_FUNCTIONAL_AREA,
    CREATE_NEW_FUNCTIONAL_AREA,
    REMOVE_FUNCTIONAL_AREA,
    GET_FUNCTIONAL_AREA_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListFunctionalArea = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/functional-area', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_FUNCTIONAL_AREA, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificFunctionalArea = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/functional-area/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_FUNCTIONAL_AREA, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getFunctionalAreaActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/functional-area/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_FUNCTIONAL_AREA_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createFunctionalArea = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/functional-area', data).then(res => {
            dispatch({ type: CREATE_NEW_FUNCTIONAL_AREA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateFunctionalArea = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/functional-area/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_FUNCTIONAL_AREA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeFunctionalArea = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/functional-area`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_FUNCTIONAL_AREA, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
