
export const messages_example = [
    {
        "id": 17219,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:11:18",
        "message_id": "m_kR0mkO0A2GfpLKlwPiNI5JSSOUngIEDUje8L3n0zYIfAdGEiWp7uk0AHfZA_eQW7jEh-Dpb2jB7kEDgt0-LK8g",
        "message": "Xin chào, cảm ơn bạn đã liên hệ với chúng tôi. Chúng tôi đã nhận được tin nhắn của bạn và sẽ sớm trả lời.",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17220,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:11:55",
        "message_id": "m_YHHb-C_-ZoetIVsYzKtd2ZSSOUngIEDUje8L3n0zYIdFo8OnXWiIdAfvEHSY3FbeciJG4ZThsrk0QmUQf7k-nQ",
        "message": "Răng vàng bọc sú bao hiểu một cái vậy",
        "captions": null,
        "from_name": "Trinh Đàm",
        "from_id": "24145795508400776",
        "group_from_id": null,
        "from_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF2K_TqTuaB7OGZGVcunqskVQIuJS1NBNa88p8af0-KQqXV5WNM_6FFdgw57Lm6j7O8THABNoDh&psid=24145795508400776&height=50&width=50&ext=1704852717&hash=AfoE6DGvu5BkxuUr9_F5ZoGAUYh4ig6PHDYi-QsXJuDl9g",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 0,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "customer",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17221,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:18:26",
        "message_id": "m_ctCRZvx0baU5XRzhsRRcE5SSOUngIEDUje8L3n0zYIfUw43FG20_0HrIMQSTRu49EZxfdqDbCwU247rQIIQqmQ",
        "message": "Em chào chị Trinh\nChúc chị ngày mới tốt lành🍀",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17222,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:18:53",
        "message_id": "m_ZwRYR1bw5tsMCqWhpEtDP5SSOUngIEDUje8L3n0zYIe7UEpIeu7l-cgzrHHfduIJOcAJq-GnQKeRxbfj9cC5tg",
        "message": "Dạ, Hiện tại nha khoa Chingo đang có chương trình ưu đãi trợ giá rất tốt từ hãng\nChỉ còn từ 799K chị đã có 1 răng TOÀN SỨ ạ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17223,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:25",
        "message_id": "m_hkKLDx7Alb9UsNcFqQqhuZSSOUngIEDUje8L3n0zYIdcPvl-FKabpveiC0hCogGM_z3ZFQMLCA0TfH45lNSejw",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/370762897_994739398267176_9074701529142999124_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=b65b05&_nc_ohc=EHYGh1Rc1TUAX9OhTsb&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdTRcaXnvkN50SV9x4kahk840AJCfsJbY_XAjNLIKbPt5Q&oe=659DFE8B\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17224,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:26",
        "message_id": "m_KJLWiJxG7Zxa9XAlUsdnZJSSOUngIEDUje8L3n0zYIddjJ9gMLDFFTDqx7RT91ZocGRIJBxWxfoXlloOjddosA",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/375635954_891636882304539_7853778310327828103_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=b65b05&_nc_ohc=rjOEayedax0AX8LtoT3&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdQ7TwBoVHrlRfOvdv3SESpyGxauwaVKFyy1oWy_rP3DYQ&oe=659DD980\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17225,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:27",
        "message_id": "m_Blz0xt_fEWdYQ21WwL77AZSSOUngIEDUje8L3n0zYIeHbQxPvCRbeLDn-M2J1CDCeUVwmXAJKhryRFcXHMtolw",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/398292762_646713314014511_8550566185518858559_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=b65b05&_nc_ohc=NLSIW4IF8uAAX-_CAOc&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdTgpxmxAVHBNxGCe8nwFJZ_jRrx_Xsd2gLGlWK8nv3aNg&oe=659DE6CB\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17226,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:27",
        "message_id": "m_NSBMXKT00wEmSgtmMVAueZSSOUngIEDUje8L3n0zYIfoBE3mY2lz4rDamxF8ZIwEHWCeHqOsIYhH0a5JfBit5g",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/394865227_322912733675456_4649455194686241025_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=b65b05&_nc_ohc=uEyz3y33cHUAX8cz-GU&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdQ5DxdYrtHr4obXKkrtEhrd8QzbArrCcfhqNuTVUI8XXA&oe=659DE3DC\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17228,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:28",
        "message_id": "m__L6BPwaWcHwFxvCiKCqS05SSOUngIEDUje8L3n0zYIfFKl_EF50tIzrcXDBrdBMdYyFC__xVFGMrXahG26LXrg",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/394689103_642768394722174_3211794248886167000_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=b65b05&_nc_ohc=s3btiGTun9oAX9DgW_D&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdTBAmC_c7HFlFwM0feFiDWTjtl2bZ1M3h7Z40gZt0Qs4w&oe=659DF9B5\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17227,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:29",
        "message_id": "m_jE6_Nciu-tJTZLVk6dJBGpSSOUngIEDUje8L3n0zYId2iiJvRA2pbg_iAQlX3mX7bXM8XbQSIfYXcRfRBZgGFw",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/370206053_929404715450575_6724472964139242716_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=b65b05&_nc_ohc=wugJh3tMhDEAX8vAmjV&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdSytdvVapYtsZqLxyZL_HmMfncWwmPXFfANL4TynulFPw&oe=659DDB19\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17229,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:29",
        "message_id": "m_eO-Gwwl7naRyruNivpTRRpSSOUngIEDUje8L3n0zYIdLfpOmtALlmhVKI5YV5n6Al7eyOgGYcDK7N70iDlZ5sg",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/399884161_838577318003380_7526269129269783942_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=b65b05&_nc_ohc=LQNLqlpICQgAX8M_Dn_&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdRFj5AyE_BjlaTGTTjBH9yweK7PGgYqSOG-pO9XakymLw&oe=659DC6D1\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17230,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:30",
        "message_id": "m_F4i7wy065szU06gp6ncEkZSSOUngIEDUje8L3n0zYIccYkKP5_SMUM8odBa3Gn3J0XBgHXgiB3Obvo9yEtTfww",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/370307622_160934223712734_3416053589654316534_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=b65b05&_nc_ohc=a0-oWZ0weeAAX--sVTP&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdSuILdZLbXnSbdHg9GtCc6XX7lw-xyCz6oIIJfJOYsxew&oe=659DFC18\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17231,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:31",
        "message_id": "m_7VcHyJibDfQIQiY389uI1ZSSOUngIEDUje8L3n0zYIe_-rOGNXzpnSlBko3ee_N-3e7Ox6PGivIPxL1G_Yu0Ng",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/396602650_362878996094089_2317199808665271407_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=b65b05&_nc_ohc=Wm5e5CeN6PgAX8j65VW&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdQ7axtCgVAlJ9IrcTbciQ2lId_X2_orfSQVMW38KBbRzg&oe=659DE67A\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17232,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:32",
        "message_id": "m_havBmkhsoegUnVwR4HVTU5SSOUngIEDUje8L3n0zYIdpIOP7L00PxEziZXwhapiqRpAmh6q_e1YyXZk_vo2-cA",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/394498046_6724280904352658_7878563241600734232_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=b65b05&_nc_ohc=qWRijjN345MAX8wAm53&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdQ-6bveaRckU2nmMrXjUS4yQ8EEI86jTLNul1fulnZdPA&oe=659DD00F\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17233,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:33",
        "message_id": "m_bcrViOKlrOccB6KskQrZpZSSOUngIEDUje8L3n0zYIe5KSKdZKZtkX2REHLxgdEZ22gQQM5esvVIof5mj3ti8g",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/396335385_839426184301394_3208961370367726417_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=b65b05&_nc_ohc=5rEmDR5Yxa4AX9mijT2&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdRQaA7B_D_8GvEUxvse1CyR3Sr0xp4yvTrHjrjPcj7J0A&oe=659DE86E\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17234,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:33",
        "message_id": "m_en5Vc7E3dvFDxVxW9uTXfpSSOUngIEDUje8L3n0zYIduPTOmIHkkoV5AGx-spvCKJwDrI3UFe1WhsK-yGIHdLA",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/400596405_328262346732732_8261370080343906250_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=b65b05&_nc_ohc=wIVWzjuzfJgAX86vHS1&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdSOlHp5txlzkRg8KMimj9YoQ8Zaxsr5N2CVWYWIQR7YGg&oe=659DFAF2\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17235,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:34",
        "message_id": "m_mTUSurwbrCMKPnAWtYHHKJSSOUngIEDUje8L3n0zYIe1E9YHTpP8Ya8enTbQ7nD7mQPe2oUte3HDxZhXnNpOsA",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/399979165_1047744133345355_8597907788019900758_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=b65b05&_nc_ohc=M9TAHg6xCNEAX8bqsu8&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdTsO7OTfeDKA5R55mF76KjNlQ6quyp7Ba3IT3clZDwTCA&oe=659DC7E1\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17236,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:35",
        "message_id": "m_KFlfduLBQUJnlYoARn2WUpSSOUngIEDUje8L3n0zYIeiftYi1UkVRcs-bjOtyl_9FHAzKG2i0uc7gs4pKZrlMg",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/395419834_1354902262095181_8777072896726518336_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=b65b05&_nc_ohc=wdLjNR0j5GsAX9db9TK&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdRvrqpqm0AkE67JFraXAuSkl88ZSsqE7MLzBRBr4P67BA&oe=659DD21E\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17237,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:36",
        "message_id": "m_toEULOEX9s01798J2jgZ7pSSOUngIEDUje8L3n0zYIeM0dRhzfEv1PbOQUW3qiBOkhYbEe3l15iGAyc8Ksos0Q",
        "message": "[\"https://scontent-ord5-2.xx.fbcdn.net/v/t1.15752-9/396266053_2663857070438375_1052782939640507524_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=b65b05&_nc_ohc=7sKuIAmK-AIAX9777tG&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-2.xx&oh=03_AdRx8m4GC-zpVbf0hI1fHlc8ykM1ga2xMhJN-VH6dlRbsg&oe=659DF08F\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17238,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:19:53",
        "message_id": "m_PeTus2lThf7JVjPIFH6gWpSSOUngIEDUje8L3n0zYIevlEUw7m6gjq7hFlqz6x2c9hKPdoOa3kBbljC1DG5NTw",
        "message": "Để nha khoa tư vấn kỹ hơn\nNếu đc, chị Trinh chụp giúp e tấm hình cận răng mình theo mẫu sau nha",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17239,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:20:01",
        "message_id": "m_ZR1I49qXLWJVI5Ffny8hqJSSOUngIEDUje8L3n0zYIe3pO0qwJ3gFZgGv7ncVqthRyzt9XoOXlyqmquInAeLZQ",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/400054179_1269820277021945_931778526590899063_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=b65b05&_nc_ohc=trU2Lt839ZgAX9kUezL&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdT18unSeH0BoeIKvvcMt3a3mrjC7ebcW_jCaKLv36M_8Q&oe=659DF7B4\"]",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17240,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:26:36",
        "message_id": "m_xeg3Yt9hyvGI06H7WifspZSSOUngIEDUje8L3n0zYIfn5Ns1GjqJ-OyLpvdVlxLlcRbjaq6YEdgklQgcYI27pw",
        "message": "[\"https://scontent-ord5-1.xx.fbcdn.net/v/t1.15752-9/375769675_1390188751872786_1961141013431513332_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=b65b05&_nc_ohc=-gML5zHk8acAX9K4o4n&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent-ord5-1.xx&oh=03_AdQnTnaDNh5RNeQwyPihnPzmySRe6heEBCG05y7DN2JXlw&oe=659DFE71\"]",
        "captions": null,
        "from_name": "Trinh Đàm",
        "from_id": "24145795508400776",
        "group_from_id": null,
        "from_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF4IWgHSGig-bYX6yAGm1RF9DMo2eWLeT45dPaNi8jl6u-TomBHTdogCKxhv-gp7amXNBm0Cbq2&psid=24145795508400776&height=50&width=50&ext=1704853599&hash=AfqsPX7J2Sybi9BSO8UGnyTysBA75W2Y5mPxXtX-p0m1Sg",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 0,
        "message_type": "attachments",
        "attachment_type": "image",
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "customer",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17241,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:27:51",
        "message_id": "m_csSiBzfydh523lWR1Y0guZSSOUngIEDUje8L3n0zYIdWQXCI-0amSkWf6iExImNM-37Qrxud-OkkpeQwQn4IHA",
        "message": "Dạ  nha khoa e nhận đc hình ảnh răng chị Trinh gửi rồi ạ\nEm xin phép gửi bác sĩ chuyên khoa chẩn đoán kỹ thêm\nÍt phút có kết quả",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17242,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:27:55",
        "message_id": "m_oVcxCCI2n8KxXRXohkvV3JSSOUngIEDUje8L3n0zYIeLZWuoycfSsR6EHpIjYWN2qjmD620UZae_Y66gBH5tlw",
        "message": "Nha khoa sẽ liên hệ lại chị tvan kỹ hơn\nchị tham khảo trước ạ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17243,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:27:57",
        "message_id": "m_sky3A8jvwLzEIGS91mnt8JSSOUngIEDUje8L3n0zYIcfXZzKFNSZ5dRXJ0Ty6HuJe1GXZVc9yA6oX0bJXM7RNA",
        "message": "Chị Trinh cho e xin lại Sđt hoặc zalo mình nha",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17244,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:28:23",
        "message_id": "m_WK0g5EpdTCQ5Zd-5Gz9N_pSSOUngIEDUje8L3n0zYIetqfaXbwsHDNfc6r-mU5Vu1TVlwOjPw-Pw0djNW3TTHA",
        "message": "Nha.  Khoa. Ở đâu vậy",
        "captions": null,
        "from_name": "Trinh Đàm",
        "from_id": "24145795508400776",
        "group_from_id": null,
        "from_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHJ9DvjMdfumTPfDqNujcbyjEDo4tcG3XE7bLLFrPZEpCW36T-YkeOKSIDyv-AZ-wlrIpj-nm25&psid=24145795508400776&height=50&width=50&ext=1704853705&hash=AfpzhWUcT5IQ8cbqAgDxPtWFtBNn7_IkgF9gXGqSSD6kjw",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 0,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "customer",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17245,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:28:52",
        "message_id": "m_TknphQvUj5aJLlEKjav6w5SSOUngIEDUje8L3n0zYIfCdpZH2eFl0rOGJ-5ImENVv0ZBN7SoMHJgWvkN4gXEFg",
        "message": "Dạ,  Địa Chỉ Nha Khoa Chingo tại số:\n💥 22 Lê Hồng Phong - Quận Hải Châu - TP Đà Nẵng\n☎️: 0916019696",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17246,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:29:15",
        "message_id": "m_Yo2-zJBTLR2XufBSkMN4w5SSOUngIEDUje8L3n0zYIdbe2gKI4uzgZ1IZEDNQSA5sEpO8hIa4r_IISvsCJrqHA",
        "message": "Nha khoa e xin phép liên hệ lại chị tư vấn kỹ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17247,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:29:20",
        "message_id": "m_Dv63RBMHZKfx0wFIkoOa8JSSOUngIEDUje8L3n0zYIcedLGVwprGdEtJjmccazCUy4Uqo5m1iWW27zHN59IQJw",
        "message": "có tiện ko ạ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17248,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 09:32:24",
        "message_id": "m_GAU8arL0s_5-w6f6ZlTF_5SSOUngIEDUje8L3n0zYIfPq14cBQa7u7JbMfPT1WYANDBoXndWxjq33tYdsuJvmQ",
        "message": "Chị Trinh ơi",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17250,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 10:18:56",
        "message_id": "m_7MsjNUzme0w2Er14sG5tHJSSOUngIEDUje8L3n0zYIdRunWCTCzTjAVWlLAX5x1HUCttEn4CfpwvVIcAR4VVcA",
        "message": "Chị à",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17251,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 10:19:06",
        "message_id": "m_Fp4plPpth5pXdxB5Yj-EAZSSOUngIEDUje8L3n0zYIf7j2KMoq8J1rFHutG2mXtPF3jGk9RzdV-QVnD803xxrQ",
        "message": "Tuần này,\nNha khoa Chingo có chương trình TẶNG  suất vệ sinh răng miệng & chụp phim thăm khám tổng quát hoàn toàn MIỄN PHÍ khi khách hàng đến trực tiếp nha khoa\n\nNếu được em xin phép gửi lời mời chị Trinh & các thành viên gia đình mình bớt chút thời gian đến thăm khám, nhận ưu đãi\n\nKhi có nhận định của Bác sĩ chuyên khoa rồi mình có kế hoạch làm sau vẫn được chị nha☘️",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17252,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 10:19:30",
        "message_id": "m_R5UKhuXQ2CNR6_8kTW36OpSSOUngIEDUje8L3n0zYIdFrAilGG400CKj0CEi_xhXA346NCiZdApfOv1JihDEIg",
        "message": "Nha khoa Chingo có xe đưa đón khách hàng hoàn toàn Miễn Phi ạ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17253,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 10:20:00",
        "message_id": "m_Se1PJLdOfRotpBlmKVnEVJSSOUngIEDUje8L3n0zYIcEHP3jIVhY5SFxCzIC8W_PbO1bl0CwusE3ymbPrXXNQA",
        "message": "Sxep công việc xong tiện ghé nha khoa\nchị Trinh nhắn trước e xíu nha",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 1,
        "is_read_time": "2023-12-11 10:20:07",
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    },
    {
        "id": 17254,
        "page_id": "108040701710179",
        "thread_id": "t_1130800564756133",
        "created_time": "2023-12-11 10:20:34",
        "message_id": "m_TTaLrAtYlOnIr6krm2FoCJSSOUngIEDUje8L3n0zYIc3RY2o8f934WjYnxkzcHIelIwofFDmq9278WV37iqpvw",
        "message": "E đặt lịch bác sĩ riêng & xe đón\nMình ko phải đợi lâu ạ",
        "captions": null,
        "from_name": "Nha khoa Chingo",
        "from_id": "108040701710179",
        "group_from_id": null,
        "from_picture": "https://scontent.fhan2-4.fna.fbcdn.net/v/t39.30808-1/258356764_108041438376772_5726725730729606747_n.png?stp=cp0_dst-png_p50x50&_nc_cat=100&ccb=1-7&_nc_sid=dbb9e7&_nc_ohc=nMYvoilxwm0AX9b2u2U&_nc_ht=scontent.fhan2-4.fna&edm=AJdBtusEAAAA&oh=00_AfB82GjvRKm7nq6OWJWQv933K16FRH-l0ZdXzHfFBlNa9g&oe=6427BD16",
        "delivery": 1,
        "is_read": 0,
        "is_read_time": null,
        "reaction": null,
        "has_read": 1,
        "message_type": "text",
        "attachment_type": null,
        "user_id": null,
        "type": "messages",
        "parent_id": null,
        "is_sender": "page",
        "is_delete": null,
        "is_hidden": null,
        "reaction_type": null
    }
];