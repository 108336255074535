import api from '../../utils/api';
import {
    GET_LIST_CASH_BOOK,
    GET_SPECIFIC_CASH_BOOK,
    UPDATE_SPECIFIC_CASH_BOOK,
    CREATE_NEW_CASH_BOOK,
    REMOVE_CASH_BOOK,
    GET_STATISTICAL_CASH_BOOK
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCashBook = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/cash-book', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CASH_BOOK, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCashBook = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/cash-book/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CASH_BOOK, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCashBook = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/cash-book', data).then(res => {
            dispatch({ type: CREATE_NEW_CASH_BOOK, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCashBook = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/cash-book/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CASH_BOOK, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCashBook = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/cash-book`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CASH_BOOK, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/cash-book/statistical/total', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: GET_STATISTICAL_CASH_BOOK,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}