//https://www.herbie.dev/blog/webauthn-basic-web-client-server/
//https://www.youtube.com/watch?v=soRQfAdJHVc
//https://simplewebauthn.dev/docs/packages/browser

import { NotificationManager } from "react-notifications";
import appConfig from '../../config';

// ArrayBuffer to URLBase64
function bufferEncode(value) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");;
}

// Base64 to ArrayBuffer
function bufferDecode(value) {
    // Chuyển đổi từ URL safe Base64 sang Base64 tiêu chuẩn
    value = value.replace(/-/g, '+').replace(/_/g, '/');

    // Nếu chiều dài của chuỗi không phải là bội số của 4, thêm dấu "=" để bổ sung
    while (value.length % 4) {
        value += '=';
    }

    return Uint8Array.from(atob(value), c => c.charCodeAt(0)).buffer;
}

export async function register(user = {}) {
    try {
        // Gửi yêu cầu GET đến endpoint /register-challenge để nhận dữ liệu thách thức
        const response = await fetch(`${appConfig.SERVER_WEBAUTHN_URL}/register-challenge`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: user?.id,
                uname: user?.user_name,
                displayName: user?.full_name,
            })
        });
        const options = await response.json();

        options.challenge = bufferDecode(options.challenge);
        options.user.id = bufferDecode(options.user.id);

        //console.log('Data received from server register-challenge:', options);

        // Tạo credential bằng WebAuthn API
        const credential = await navigator.credentials.create({
            publicKey: options
        });

        //console.log("credential: ", credential);

        let attestationObject = credential.response.attestationObject;
        let clientDataJSON = credential.response.clientDataJSON;
        let rawId = credential.rawId;

        // Gửi dữ liệu credential đến server
        const result = await fetch(`${appConfig.SERVER_WEBAUTHN_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',

            body: JSON.stringify({
                user_id: user?.id,
                username: user?.user_name,
                id: credential.id,
                rawId: bufferEncode(rawId),
                type: credential.type,
                response: {
                    attestationObject: bufferEncode(attestationObject),
                    clientDataJSON: bufferEncode(clientDataJSON),
                }
            })
        });

        const verification = await result.json();

        if (verification?.verified) {
            NotificationManager.success('Đăng ký nhận diện khuôn mặt thành công!');
        } else {
            NotificationManager.error('Đăng ký nhận diện khuôn mặt thất bại!');
            console.error('Đăng ký nhận diện khuôn mặt thất bại. Dữ liệu trả về từ server:', verification);
        }
    } catch (err) {
        console.error('Lỗi trong quá trình đăng ký credential:', err);
        NotificationManager.error('Đăng ký không thành công. Vui lòng thử lại.');
    }
}

export async function login(user = {}) {
    try {
        const response = await fetch(`${appConfig.SERVER_WEBAUTHN_URL}/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: user?.id
            })
        });
        const options = await response.json();

        if (!options || options.error) {
            NotificationManager.error(`Xác thực thất bại! ${options.error}`);
        }

        options.challenge = bufferDecode(options.challenge);
        options.allowCredentials = options.allowCredentials.map(cred => ({
            id: bufferDecode(cred.id),
            type: "public-key"
        }));

        options.userVerification = options?.userVerification;

        //console.log('Data received from server:', options);

        // Tạo assertion bằng WebAuthn API
        const assertion = await navigator.credentials.get({
            publicKey: options
        });

        //console.log('Assertion:', assertion);

        let authData = assertion.response.authenticatorData;
        let clientDataJSON = assertion.response.clientDataJSON;
        let rawId = assertion.rawId;
        let sig = assertion.response.signature;
        let userHandle = assertion.response.userHandle;

        // Gửi dữ liệu assertion đến server
        const verification = await fetch(`${appConfig.SERVER_WEBAUTHN_URL}/verify-login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                user_id: user?.id,
                id: assertion.id,
                rawId: bufferEncode(rawId),
                type: assertion.type,
                response: {
                    authenticatorData: bufferEncode(authData),
                    clientDataJSON: bufferEncode(clientDataJSON),
                    signature: bufferEncode(sig),
                    userHandle: bufferEncode(userHandle),
                }
            })
        });

        const result = await verification.json();

        //console.log('Result:', result);

        if (!result.verified) {
            NotificationManager.error(`Xác thực thất bại! ${result.error}`);
        }

        return result.verified

    } catch (err) {
        console.error('Error during authentication:', err);
    }
}