import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Tag, Button, Row, Col, Typography, Table, Spin, Divider, Alert } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { ConvertcustomerGender, ConvertPotentialProfileStatus, repeatPhoneNumber } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import CustomerPotentialForm from './CustomerPotentialForm';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import ReactHtmlParser from 'react-html-parser';

const { Paragraph } = Typography;

class CustomerPotentialDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm) {
        this.setState({ isOpenForm: isOpenForm });
    }

    render() {
        var { isOpenForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        var family_columns = [
            {
                title: "Họ và tên",
                dataIndex: "full_name",
                width: "22%"
            },
            {
                title: "Giới tính",
                dataIndex: "gender",
                width: "10%",
                render: (text, record) => {
                    return (
                        <>{ConvertcustomerGender(text)}</>
                    )
                }
            },
            {
                title: "Năm sinh",
                dataIndex: "birthday",
                width: "14%"
            },
            {
                title: "Quan hệ",
                dataIndex: "relationship",
                width: "13%"
            },
            {
                title: "Số điện thoại",
                dataIndex: "phone",
                width: "13%"
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
                width: '30%'
            }
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Hồ sơ khách hàng"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '65%'}
                    extra={
                        checkPermission('customer_potential', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tên khách hàng">
                                                {currentItem ? currentItem.full_name : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã số thẻ cũ">
                                                {
                                                    currentItem?.card_number ? (
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.card_number : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            <Tag color='#858383'>{currentItem ? currentItem.card_number : null}</Tag>
                                                        </Paragraph>
                                                    ) : null
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã số thẻ mới">
                                                {
                                                    currentItem?.card_number_new ? (
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.card_number_new : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            <Tag color='#1890ff'>{currentItem ? currentItem.card_number_new : null}</Tag>
                                                        </Paragraph>
                                                    ) : null
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Số điện thoại">
                                                {currentItem ? (
                                                    <HandlePhoneNumber
                                                        permission={checkPermission('customer_potential', 'phonenumber')}
                                                        inputPinCode={checkPermission('customer_potential', 'input_pin_code')}
                                                        customer_id={currentItem?.id}
                                                        hiddenPhoneNumber={repeatPhoneNumber(currentItem?.phone)}
                                                        fullPhoneNumber={currentItem?.phone}
                                                    />
                                                ) : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Giới tính">
                                                <span style={{ textTransform: 'capitalize' }}>{currentItem?.gender}</span>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Năm sinh">
                                                {currentItem?.birthday}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Trạng thái">
                                                {
                                                    currentItem.customer_status_color ? (
                                                        <Tag color={currentItem.customer_status_color}>{currentItem ? currentItem.customer_status : null}</Tag>
                                                    ) : (
                                                        <Tag>{currentItem ? currentItem.customer_status : null}</Tag>
                                                    )
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tỉnh/Thành phố">
                                                {currentItem?.province_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Quận/Huyện">
                                                {currentItem?.district_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Phường/Xã">
                                                {currentItem?.wards_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tổ">
                                                {currentItem?.residential_group}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Địa chỉ">
                                                {currentItem?.address}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ghi chú">
                                                {ReactHtmlParser(currentItem?.note)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Danh sách">
                                                {currentItem?.list_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Hồ sơ">
                                                {ConvertPotentialProfileStatus(currentItem?.profile)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col xs={24} md={24} className='mt-2 mb-2'>
                                        <Alert
                                            message={<span style={{ fontWeight: '600' }}>Kết quả tư vấn</span>}
                                            description={ReactHtmlParser(currentItem?.result_content)}
                                            type="info"
                                            className='fix-alert-element'
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16} className='mt-3'>
                                    <Col xs={24} md={24}>
                                        <Table
                                            className='mb-4'
                                            title={() => <strong style={{ marginLeft: '-8px' }}>Thành viên trong gia đình</strong>}
                                            size='small'
                                            rowKey='key'
                                            tableLayout="auto"
                                            columns={family_columns}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                            dataSource={currentItem ? currentItem.customer_family : []}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    ) : null}
                </Drawer>
                <CustomerPotentialForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchManagementReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerPotentialDetail' })(CustomerPotentialDetail));
