import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Space, Row, Col, Drawer } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';

// actions
import { updateCustomerConsultantDirect } from '../../redux/actions/CustomerConsultantDirectActions';

class CustomerConsultantDirectForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    console.log(values)
                    if (this.props.currentItem) {
                        await this.props.updateCustomerConsultantDirect(this.props.currentItem.id, values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.props.reloadData();
        this.props.onCancel();
        this.setState({ isLoading: false });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem, users } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Sửa nhân viên tư vấn"
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng:&nbsp;
                                    <span className='color-primary'>
                                        {(currentItem?.customer_name) + ' - ' + (currentItem?.customer_code)}
                                    </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label='Nhân viên tư vấn trực tiếp'>
                                    {getFieldDecorator("consultant_direct_staff_ids", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.consultant_direct_staff_ids : null
                                    })(
                                        <BaseSelect
                                            options={users || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            showSearch
                                            mode="multiple"
                                            placeholder="Chọn 1 hoặc nhiều nhân viên"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomerConsultantDirect: (id, data) => dispatch(updateCustomerConsultantDirect(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerConsultantDirectForm' })(CustomerConsultantDirectForm));
