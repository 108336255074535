import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
// actions
import { createNewRole, updateSpecificRole } from '../../redux/actions/RoleActions';
import { getRoleGroupActive } from '../../redux/actions/RoleGroupActions';
import { _newLine } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';

class RoleForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {
        this.props.getRoleGroupActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateSpecificRole(this.props.currentItem.id, values);
                    this.onClose();
                } else {
                    await this.props.createNewRole(values);
                    //this.onClose();
                    this.props.form.resetFields();
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        var { visible, currentItem, roleGroupActiveList } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                visible={visible}
                title={currentItem ? "Sửa vai trò" : "Thêm mới vai trò"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentItem ? "Cập nhật" : "Thêm mới"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Tên vai trò">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentItem ? currentItem.name : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Nhóm vai trò">
                            {getFieldDecorator('group_id', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentItem ? currentItem.group_id : null
                            })(<BaseSelect
                                options={roleGroupActiveList || []}
                                optionValue="id"
                                optionLabel="name"
                                showSearch
                                placeholder="Nhóm vai trò"
                                style={{ width: '100%' }}
                            />)}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Kích hoạt", value: 1 },
                                        { label: "Chưa kích hoạt", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        roleGroupActiveList: state.RoleGroupReducer.roleGroupActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewRole: (data) => dispatch(createNewRole(data)),
        updateSpecificRole: (id, data) => dispatch(updateSpecificRole(id, data)),
        getRoleGroupActive: () => dispatch(getRoleGroupActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RoleForm' })(RoleForm));
