import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Row, Col, Table, Tag } from 'antd'
import { isMobile } from 'react-device-detect';
import { isValidDate, jsonViewer } from '../../utils/helpers';

class HistoryDetail extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    handleCancel() {
        this.props.onCancel();
    }


    render() {
        var { } = this.state;
        var { visible, currentItem } = this.props;


        const columns = [
            {
                title: 'Trường',
                dataIndex: 'field_name',
                key: 'field_name',
            },
            {
                title: 'Giá trị cũ',
                dataIndex: 'old_value',
                key: 'old_value',
                render: (text) => {
                    return (
                        <>{jsonViewer(text)}</>
                    )
                }
            },
            {
                title: 'Giá trị mới',
                dataIndex: 'new_value',
                key: 'new_value',
                render: (text) => {
                    return (
                        <>{jsonViewer(text)}</>
                    )
                }
            },
        ];

        const data = currentItem?.history_values.map((item, index) => ({
            key: index,
            field_name: item.field_name,
            old_value: item.old_value,
            new_value: item.new_value,
        }));


        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Lịch sử thay đổi: ${currentItem?.record_name}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '60%'}
                >
                    {visible ? (
                        <>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Bản ghi bị thay đổi">
                                            {currentItem ? currentItem.record_name : null} - {currentItem ? currentItem.record_code : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Module">
                                            {currentItem ? currentItem.module_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhân viên thay đổi">
                                            {currentItem ? currentItem.updated_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Hành động">
                                            <Tag style={{ textTransform: 'capitalize' }} color={currentItem?.action == 'delete' ? 'red' : '#108ee9'}>{currentItem?.action}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời gian">
                                            {isValidDate(currentItem.created_at, true)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Diễn giải">
                                            {currentItem ? currentItem.note : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>

                            {
                                currentItem && Array.isArray(currentItem?.history_values) && currentItem?.history_values.length ? (
                                    <>
                                        <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                                            Các trường bị thay đổi
                                        </Divider>
                                        <Table
                                            size="small"
                                            tableLayout='auto'
                                            columns={columns}
                                            dataSource={data}
                                            pagination={false}
                                            scroll={{
                                                x: 'max-content'
                                            }}
                                        />
                                    </>
                                ) : null
                            }
                        </>
                    ) : null}
                </Drawer>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'HistoryDetail' })(HistoryDetail));
