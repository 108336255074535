import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Divider, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import VoucherForm from './VoucherForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import VoucherDetail from './VoucherDetail';
import VoucherCustomerUsedList from './VoucherCustomerUsedList';

import moment from 'moment';
import { getAllVoucher, getSpecificVoucher, removeVoucher } from '../../redux/actions/VoucherActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/voucher-list',
        breadcrumbName: 'Voucher',
    },
]

class VoucherList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        isOpenDetail: false,
        current_id: null,
        mode: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllVoucher(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllVoucher(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificVoucher(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeVoucher(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeVoucher(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id, mode = null) {
        this.setState({ current_id: id, mode: mode })
        this.props.getSpecificVoucher(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllVoucher(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { voucher_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, current_id, isOpenDetail, mode } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '70px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('voucher', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('voucher', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên voucher',
                dataIndex: 'name',
                width: '300px',
                key: 'name',
                render: (text, record) => {
                    if (record.name) {
                        if (checkPermission('voucher', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id, 'full')}>
                                        {record.name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>{record.name}</>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã voucher',
                dataIndex: 'code',
                width: '150px',
                key: 'code'
            },
            // {
            //     title: 'Chi tiết',
            //     dataIndex: 'detail',
            //     key: 'detail',
            //     width: '200px',
            //     render: (text, record) => {
            //         return (
            //             <div style={{ wordBreak: "break-all" }}>
            //                 <Tooltip title={HtmlParser(text)} placement="topLeft">
            //                     <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
            //                 </Tooltip>
            //             </div>
            //         )
            //     }
            // },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Discount',
                dataIndex: 'discount',
                key: 'discount'
            },
            {
                title: 'Ngày bắt đầu',
                dataIndex: 'start_date',
                key: 'start_date',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Ngày hết hạn',
                dataIndex: 'expire_date',
                key: 'expire_date',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Khách hàng sử dụng',
                dataIndex: 'customer_used',
                key: 'customer_used',
                render: (text, record) => {
                    if (text && text.length) {
                        return (
                            <Button type='default' size='small' onClick={() => this.onDetail(record.id, 'only_customer')}><UserOutlined />Có ({text.length}) khách hàng</Button>
                        )
                    } else {
                        return (<span style={{ color: 'red' }}>Chưa có khách hàng</span>)
                    }

                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Voucher" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('voucher', 'create')}
                        isShowDeleteButton={checkPermission('voucher', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={voucher_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <VoucherForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                {
                    mode && mode == 'only_customer' ? (
                        <VoucherCustomerUsedList
                            visible={isOpenDetail}
                            currentItem={currentItem}
                            reloadData={() => this.onDetail(current_id)}
                            onCancel={() => this.toggleOpenDetail(false)}
                        />
                    ) : (
                        <VoucherDetail
                            visible={isOpenDetail}
                            currentItem={currentItem}
                            reloadData={() => this.onDetail(current_id)}
                            onCancel={() => this.toggleOpenDetail(false)}
                        />
                    )
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        voucher_list: state.VoucherReducer.voucher_list,
        pagination: state.VoucherReducer.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllVoucher: (filter) => dispatch(getAllVoucher(filter)),
        getSpecificVoucher: (id) => dispatch(getSpecificVoucher(id)),
        removeVoucher: (ids) => dispatch(removeVoucher(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherList);
