import {
    GET_LIST_CUSTOMER,
    GET_ALL_CUSTOMERS_WITH_CONCATENATE,
    GET_SPECIFIC_CUSTOMER,
    UPDATE_SPECIFIC_CUSTOMER,
    CREATE_NEW_CUSTOMER,
    REMOVE_CUSTOMER,
    GET_LIST_CUSTOMER_ACTIVE,
    GET_STATISTICAL_CUSTOMER
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    customerList: [],
    customerActiveList: [],
    currentCustomer: null,
    statistical: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    },
    pagination_active: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER: {
            return {
                ...state,
                customerList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_LIST_CUSTOMER_ACTIVE: {
            let listCustomers = [...state.customerActiveList, ...action.payload.data];
            return {
                ...state,
                customerActiveList: listCustomers.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination_active: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_ALL_CUSTOMERS_WITH_CONCATENATE: {
            let listCustomers = [...state.customerList, ...action.payload.data];

            return {
                ...state,
                customerList: listCustomers.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_SPECIFIC_CUSTOMER: {
            return { ...state, currentCustomer: action.payload }
        }
        // case CREATE_NEW_CUSTOMER: {
        //     return {
        //         ...state,
        //         customerList: [action.payload, ...state.customerList],
        //         total: state.total + 1
        //     }
        // }
        case UPDATE_SPECIFIC_CUSTOMER: {
            let index = state.customerList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.customerList];
            temp[index] = action.payload;
            return { ...state, customerList: temp }
        }
        case REMOVE_CUSTOMER: {
            let temp = state.customerList.filter(item => {
                //console.log(action.payload)
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, customerList: temp }
        }
        case GET_STATISTICAL_CUSTOMER:
            return {
                ...state,
                statistical: action.payload
            };
        default: return { ...state };
    }
}
