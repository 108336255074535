import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Image } from 'antd';

class CurrentImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImages: props.currentImages || [],
            itemKey: props.itemKey,
            filedName: props.filedName
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentImages !== this.props.currentImages) {
            this.setState({
                currentImages: this.props.currentImages || [],
            });
        }
    }

    deleteImage = (id) => {
        this.setState(prevState => ({
            currentImages: prevState.currentImages.filter(item => item.id !== id)
        }), () => {
            this.props.getData({
                itemKey: this.state.itemKey,
                filedName: this.state.filedName,
                data: this.state.currentImages
            }); // Gọi callback để trả về currentImages mới
        });
    }

    render() {
        const { currentImages } = this.state;

        return (
            <>
                {currentImages && currentImages.map(item => (
                    <div className="list-img-thumbnail" key={item?.id}>
                        <Image
                            height={'100%'}
                            src={item?.src}
                        />
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteImage(item?.id)}
                        />
                    </div>
                ))}
            </>
        );
    }

}

export default CurrentImage;