import api from '../../utils/api';
import {
    GET_ALL_SHIFT_SCHEDULING,
    GET_SPECIFIC_SHIFT_SCHEDULING,
    UPDATE_SPECIFIC_SHIFT_SCHEDULING,
    CREATE_NEW_SHIFT_SCHEDULING,
    REMOVE_SHIFT_SCHEDULING
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllData = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/shift-scheduling', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_SHIFT_SCHEDULING, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/shift-scheduling', data).then(res => {
            dispatch({ type: CREATE_NEW_SHIFT_SCHEDULING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/shift-scheduling/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_SHIFT_SCHEDULING, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateData = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/shift-scheduling/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_SHIFT_SCHEDULING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeData = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/shift-scheduling`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_SHIFT_SCHEDULING, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}