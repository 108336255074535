import api from '../../utils/api';
import {
    GET_LIST_BLOOD_TYPE,
    GET_SPECIFIC_BLOOD_TYPE,
    UPDATE_SPECIFIC_BLOOD_TYPE,
    CREATE_NEW_BLOOD_TYPE,
    REMOVE_BLOOD_TYPE,
    GET_BLOOD_TYPE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListBloodType = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/blood-type', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_BLOOD_TYPE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificBloodType = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/blood-type/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_BLOOD_TYPE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getBloodTypeActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/blood-type/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_BLOOD_TYPE_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createBloodType = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/blood-type', data).then(res => {
            dispatch({ type: CREATE_NEW_BLOOD_TYPE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateBloodType = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/blood-type/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_BLOOD_TYPE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeBloodType = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/blood-type`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_BLOOD_TYPE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
