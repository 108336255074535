import {
    GET_ALL_PRODUCT_WAREHOUSE,
    GET_PRODUCT_WAREHOUSE,
    CREATE_PRODUCT_WAREHOUSE,
    UPDATE_PRODUCT_WAREHOUSE,
    REMOVE_PRODUCT_WAREHOUSE,
    GET_PRODUCT_WAREHOUSE_ACTIVE
} from '../actions/types';

const INIT_STATE = {
    product_warehouse_list: [],
    current_product_warehouse: null,
    product_warehouse_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_WAREHOUSE:
            return {
                ...state,
                product_warehouse_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_PRODUCT_WAREHOUSE_ACTIVE: {
            return {
                ...state,
                product_warehouse_active_list: action.payload.data,
            }
        }
        case GET_PRODUCT_WAREHOUSE:
            return {
                ...state,
                current_product_warehouse: action.payload
            };
        case CREATE_PRODUCT_WAREHOUSE:
            return {
                ...state,
                product_warehouse_list: [action.payload, ...state.product_warehouse_list],
                total: state.total + 1
            }

        case UPDATE_PRODUCT_WAREHOUSE:
            let index = state.product_warehouse_list.findIndex(response => {
                return response.id === action.payload.id
            });
            let temp = [...state.product_warehouse_list];
            temp[index] = action.payload
            return {
                ...state,
                product_warehouse_list: temp
            }
        case REMOVE_PRODUCT_WAREHOUSE:
            let temp1 = state.product_warehouse_list.filter(response => {
                return action.payload.indexOf(response.id) < 0;
            })
            return {
                ...state,
                product_warehouse_list: temp1
            }
        default:
            return { ...state }
    }
};
