import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Tooltip, Tag, DatePicker, Avatar, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, isValidDate } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
import { getListCard, getSpecificCard, removeCard } from '../../redux/actions/CardReleaseTrackingActions';
import { EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import CardReleaseTrackingForm from './CardReleaseTrackingForm';
import CardReleaseTrackingDetail from './CardReleaseTrackingDetail';
import { getCardTypeActive } from '../../redux/actions/CardTypeActions';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/card-release-tracking',
        breadcrumbName: 'Theo dõi phát hành thẻ',
    },
]

class CardReleaseTrackingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false
        }
    }

    componentDidMount() {
        this.loadData();
        this.props.getCardTypeActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCard(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }

        if (name === 'release_date') {
            if (value && value.length > 0) {
                query.release_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.release_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.release_date_start;
                delete query.release_date_end;
            }
        }
        delete query.created_at;
        delete query.release_date;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCard(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCard(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCard([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificCard(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCard(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { card_list, pagination, users, card_type_active_list } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, isOpenDetail, current_id } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => {
                    if (text) {
                        if (checkPermission('card_release_tracking', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        <EyeOutlined />
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <EyeOutlined style={{ color: '#dedede' }} />
                                </>
                            )
                        }
                    }
                }
            },
            {
                title: 'Nhân viên',
                dataIndex: 'staff_name',
                key: 'staff_name',
                sorter: true,
                render: (text, record) => {
                    if (record.staff_name) {
                        return (
                            <>
                                <Avatar
                                    style={{
                                        backgroundColor: '#31cdf5',
                                        verticalAlign: 'middle'
                                    }}
                                    size="small">
                                    {record.staff_name ? getFirstLetterOfWords(record.staff_name) : ''}
                                </Avatar>
                                &nbsp;<span className='color-primary'>{record.staff_name}</span>
                            </>
                        )
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Số lượng thẻ',
                dataIndex: 'quantity',
                key: 'quantity',
                width: '100px'
            },
            {
                title: 'Mã thẻ',
                dataIndex: 'card_number',
                key: 'card_number',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Loại thẻ',
                dataIndex: 'card_type_name',
                key: 'card_type_name',
                width: '100px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày cấp',
                dataIndex: 'release_date',
                key: 'release_date',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, false)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },

            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, true)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '180px',
                sorter: true
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Theo dõi phát hành thẻ" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('card_release_tracking', 'create')}
                        isShowDeleteButton={checkPermission('card_release_tracking', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_date_start ||
                            query.created_user_id ||
                            query.release_date_start ||
                            query.card_type_id
                        }
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày cấp', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('release_date', value)}
                                    defaultValue={[query.release_date_start ? moment(query.release_date_start, 'YYYY-MM-DD') : null, query.release_date_end ? moment(query.release_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />
                            ]
                        }
                    >
                        <BaseSelect
                            options={card_type_active_list || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Loại thẻ"
                            className="table-button"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('card_type_id', value)}
                            defaultValue={query.card_type_id ? parseInt(query.card_type_id) : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={card_list || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <CardReleaseTrackingForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CardReleaseTrackingDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => { this.onDetail(current_id); this.loadData() }}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={false}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        card_list: state.CardReleaseTrackingReducer.card_list,
        pagination: state.CardReleaseTrackingReducer.pagination,
        users: state.config.users,
        card_type_active_list: state.CardTypeReducer.card_type_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCard: (filter) => dispatch(getListCard(filter)),
        getSpecificCard: (id) => dispatch(getSpecificCard(id)),
        removeCard: (ids) => dispatch(removeCard(ids)),
        getCardTypeActive: () => dispatch(getCardTypeActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CardReleaseTrackingList' })(CardReleaseTrackingList));

