import api from '../../utils/api';
import {
    GET_ALL_CARD_TYPE,
    GET_CARD_TYPE,
    CREATE_CARD_TYPE,
    UPDATE_CARD_TYPE,
    REMOVE_CARD_TYPE,
    GET_CARD_TYPE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllCardType = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/card-type', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_CARD_TYPE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getCardTypeActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/card-type/by-active', { params: filter }).then(res => {
            dispatch({ type: GET_CARD_TYPE_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getOneCardType = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/card-type/${id}`).then(res => {
            dispatch({
                type: GET_CARD_TYPE,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createCardType = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/card-type', data).then(res => {
            dispatch({
                type: CREATE_CARD_TYPE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateCardType = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/card-type/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_CARD_TYPE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data)
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeCardType = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/card-type', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_CARD_TYPE,
                payload: ids
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
