import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, DatePicker, Select, Space, Drawer } from 'antd';
import { isMobile } from 'react-device-detect';
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
import moment from 'moment';

// actions
import { createNewVoucher, updateSpecificVoucher } from '../../redux/actions/VoucherActions';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';

const { Option } = Select;

class VoucherForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customerQuery: ''
        };
    }
    componentDidMount() {
        this.props.getListCustomerActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    values.start_date = values.start_date ? values.start_date.format("YYYY-MM-DD") : null;
                    values.expire_date = values.expire_date ? values.expire_date.format("YYYY-MM-DD") : null;
                    values.used_date = values.used_date ? values.used_date.format("YYYY-MM-DD") : null;
                    //console.log(values)
                    if (this.props.currentItem) {
                        await this.props.updateSpecificVoucher(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createNewVoucher(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false
        })
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleScroll(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem, customerActiveList } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentItem ? "Sửa voucher" : "Thêm mới voucher"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <Row justify="end">
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                {currentItem ? "Cập nhật" : "Thêm mới"}
                            </Button>
                        </Space>
                    </Row>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên voucher">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Mã voucher">
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.code : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày bắt đầu">
                                    {getFieldDecorator('start_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? moment(currentItem.start_date).isValid() ? moment(currentItem.start_date) : null : ''
                                    })(<DatePicker
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn ngày"
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày hết hạn">
                                    {getFieldDecorator('expire_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? moment(currentItem.expire_date).isValid() ? moment(currentItem.expire_date) : null : ''
                                    })(<DatePicker
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn ngày"
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Discount">
                                    {getFieldDecorator('discount', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.discount : ""
                                    })(<Input className='w-100' />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Chi tiết voucher">
                            {getFieldDecorator('detail', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.detail) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Form.Item label={<>Khách hàng: </>}>
                            {getFieldDecorator("customer_ids", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem?.customer_ids : []
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Nhập tên khách hàng, chọn 1 hoặc nhiều khách hàng"
                                    optionLabelProp="label"
                                    allowClear={true}
                                    mode='multiple'
                                    showSearch={true}
                                    onPopupScroll={(e) => this.handleScroll(e)}
                                    onSearch={(val) => this.onSearchCustomer(val)}
                                    filterOption={(input, option) =>
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {
                                        customerActiveList.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id} label={item.full_name}>{item.full_name} ({item.code})</Option>
                                            )
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Ngày khách hàng sử dụng">
                            {getFieldDecorator('used_date', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentItem ? moment(currentItem.used_date).isValid() ? moment(currentItem.used_date) : null : ''
                            })(<DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                placeholder="Chọn ngày"
                            />)}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewVoucher: (data) => dispatch(createNewVoucher(data)),
        updateSpecificVoucher: (id, data) => dispatch(updateSpecificVoucher(id, data)),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'VoucherForm' })(VoucherForm));
