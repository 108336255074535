import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { generateDots } from '../../utils/helpers';
import { format } from 'date-fns';

class PrintTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalQuantity: 0,
            totalPrice: 0,
            totalPriceColumn: 0,
            currentDate: format(new Date(), "dd 'tháng' MM 'năm' yyyy") // Format current date
        };
    }

    componentDidMount() {
        this.calculateTotal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData !== this.props.tableData) {
            this.calculateTotal();
        }
    }

    calculateTotal = () => {
        return new Promise((resolve) => {
            let totalQuantity = 0;
            let totalPrice = 0;
            let totalPriceColumn = 0;

            this.props.tableData.forEach(item => {
                totalQuantity += item.quantity;
                totalPrice += item.sale_price * item.quantity;
                totalPriceColumn += item.sale_price;
            });

            this.setState({ totalQuantity, totalPrice, totalPriceColumn }, () => {
                resolve();
            });
        });
    }

    render() {

        return (
            <div class="container">
                <div class="c0001">
                    <img src="https://chingodental.vn/logo-1024x1024-a17a4f.png" alt='' />
                    <div class="c0002">
                        <h2>NHA KHOA THẨM MỸ QUỐC TẾ CHINGO DENTAL</h2>
                        <p>
                            <strong>Hà Nội:</strong> 558 Nguyễn Văn Cừ, P. Gia Thụy, Q. Long Biên
                        </p>
                        <p>
                            <strong>Đà Nẵng:</strong> 22 Lê Hồng Phong, P. Phước Ninh, Q. Hải Châu
                        </p>
                    </div>
                </div>
                <div class="c0003">
                    <h2>PHIẾU ĐỀ XUẤT, XUẤT HÀNG</h2>
                </div>
                <div class="c0009">
                    {generateDots(20)}, ngày {this.state.currentDate}
                </div>
                <div class="c0011">
                    <div class="c0005">
                        Kính gửi: {generateDots(80)}
                    </div>
                    <div class="c0006" style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Nội dung đề xuất: {generateDots(150)}
                    </div>
                    <div class="c0006" style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Nhân viên đề xuất: {generateDots(150)}
                    </div>
                    <div class="c0006" style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Thuộc bộ phận: {generateDots(150)}
                    </div>
                    <div class="c0006" style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Hàng hóa/văn phòng phẩm/tài sản/công cụ dụng cụ đề xuất: Giá đã/chưa bao gồm VAT
                    </div>
                </div>
                <table class="tb0001">
                    <thead class="ant-table-thead">
                        <tr>
                            <th class="ant-table-cell" style={{ textAlign: "center" }}>STT</th>
                            <th class="ant-table-cell" style={{ textAlign: "left" }}>Tên hàng hóa</th>
                            <th class="ant-table-cell" style={{ textAlign: "left", minWidth: 65 }}>Đơn giá</th>
                            <th class="ant-table-cell" style={{ minWidth: '65px' }}>Số lượng</th>
                            <th class="ant-table-cell" style={{ textAlign: "left", minWidth: 80 }}>Thành tiền</th>
                            <th class="ant-table-cell" style={{ textAlign: "left", minWidth: 60 }}>Đã nhận</th>
                        </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                        {this.props.tableData.map((item, index) => (
                            <tr key={index}>
                                <td className="ant-table-cell" style={{ textAlign: "center" }}>{index + 1}</td>
                                <td className="ant-table-cell">
                                    {item.name}
                                </td>
                                <td className="ant-table-cell">{<NumberFormat value={item.sale_price} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                                <td className="ant-table-cell" style={{ textAlign: "center" }}>{item.quantity}</td>
                                <td className="ant-table-cell">{<NumberFormat value={item.sale_price * item.quantity} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                                <td className="ant-table-cell">&nbsp;</td>
                            </tr>
                        ))}
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Tổng cộng</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={this.state.totalPrice} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                            <td className="ant-table-cell">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                <div class="c0012">
                    <p style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Diễn giải:{generateDots(1000)}
                    </p>
                    <p style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        - Thời gian cần thực hiện:{generateDots(1000)}
                    </p>
                </div>

                <div class="c0010">
                    <div>
                        <strong>Người nhận</strong><br />
                        (Ký, họ tên)
                    </div>
                    <div>
                        <strong>Kế toán trưởng</strong><br />
                        (Ký, họ tên)
                    </div>
                    <div>
                        <strong>Trưởng bộ phận</strong><br />
                        (Ký, họ tên)
                    </div>
                    <div>
                        <strong>Người đề xuất</strong><br />
                        (Ký, họ tên)
                    </div>
                </div>
                <style>
                    {`
                                .container {
                                    width: 700px;
                                    margin: 0 auto;
                                    padding: 0px 20px;
                                    /**background: #f9f4f4;*/
                                }
                        
                                .c0001 img {
                                    height: 100px;
                                    display: block;
                                    margin: 0 auto;
                                }
                        
                                .c0001 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                        
                                .c0002 {
                                    font-size: 14px;
                                }
                        
                                .c0002 h2 {
                                    font-size: 16px;
                                    margin: 0 0 10px 0;
                                }
                        
                                .c0002 p {
                                    margin: 5px 0 0 0;
                                }
                        
                                .c0003 {
                                    text-align: center;
                                    position: relative;
                                    margin: 10px 0;
                                }
                        
                                .c0003 h2 {
                                    margin: 0;
                                }
                        

                                .c0004 {
                                    display: inline-block;
                                    position: absolute;
                                    right: 0;
                                    bottom: 4px;
                                }
                                .c0005 {
                                    text-align: center;
                                    margin: 10px 0 !important;
                                    font-style: italic;
                                }
                                .c0011 {
                                    margin: 0 0 20px 0;
                                    clear: both;
                                }
                        
                                .c0011 div {
                                    margin: 10px 0;
                                }
                        
                                .tb0001 {
                                    border: 1px solid #dedede;
                                    border-collapse: collapse;
                                    width: 100%;
                                    max-width: 100%;
                                    margin: 20px 0;
                                }
                        
                                .tb0001 th {
                                    border: 1px solid #929292;
                                    padding: 6px;
                                }
                        
                                .tb0001 td {
                                    border: 1px solid #929292;
                                    padding: 6px;
                                }
                        
                                .c0009 {
                                    text-align: right;
                                    margin: 15px 0;
                                }
                        
                                .c0010 {
                                    display: flex;
                                    justify-content: space-between;
                                    text-align: center;
                                    margin-bottom: 120px;
                                }
                        
                                .c0012 {
                                    margin: 0 0 10px 0;
                                }
                        
                                .c0012 p {
                                    margin: 8px 0;
                                }
                            `}
                </style>
            </div>
        );
    }
}

export default PrintTable;
