import api from '../../utils/api';
import {
    GET_LIST_DISEASES,
    GET_SPECIFIC_DISEASES,
    UPDATE_SPECIFIC_DISEASES,
    CREATE_NEW_DISEASES,
    REMOVE_DISEASES,
    GET_DISEASES_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListDiseases = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/list-diseases', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_DISEASES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificDiseases = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/list-diseases/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_DISEASES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getDiseasesActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/list-diseases/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_DISEASES_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createDiseases = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/list-diseases', data).then(res => {
            dispatch({ type: CREATE_NEW_DISEASES, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateDiseases = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/list-diseases/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_DISEASES, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeDiseases = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/list-diseases`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_DISEASES, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
