import {
    GET_LIST_PAYMENT_VOUCHER,
    REMOVE_PAYMENT_VOUCHER,
    GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    payment_voucher_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_PAYMENT_VOUCHER: {
            return {
                ...state,
                payment_voucher_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE: {
            let listPaymentVouchers = [...state.payment_voucher_list, ...action.payload.data];

            return {
                ...state,
                payment_voucher_list: listPaymentVouchers.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case REMOVE_PAYMENT_VOUCHER: {
            let temp = state.payment_voucher_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, payment_voucher_list: temp }
        }
        default: return { ...state };
    }
}
