import React, { Component } from 'react';
import { Divider, Tag, Row, Col, Descriptions, Table } from 'antd'
import ReactHtmlParser from 'react-html-parser';
import { ConvertcustomerGender } from '../../utils/helpers';

class CustomerFamilyDetail extends Component {

    render() {
        var { currentItem } = this.props;

        var family_columns = [
            {
                title: "Họ và tên",
                dataIndex: "full_name",
                width: "22%"
            },
            {
                title: "Giới tính",
                dataIndex: "gender",
                width: "10%",
                render: (text, record) => {
                    return (
                        <>{ConvertcustomerGender(text)}</>
                    )
                }
            },
            {
                title: "Năm sinh",
                dataIndex: "birthday",
                width: "14%"
            },
            {
                title: "Quan hệ",
                dataIndex: "relationship",
                width: "13%"
            },
            {
                title: "Số điện thoại",
                dataIndex: "phone",
                width: "13%"
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
                width: '30%'
            }
        ];

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-3'>
                    <Tag color='#0b2b33'>10</Tag> Thông tin nhân khẩu
                </Divider>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Tỉnh/Thành phố">
                                {currentItem ? currentItem.province_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Quận/Huyện">
                                {currentItem ? currentItem.district_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Phường/Xã">
                                {currentItem ? currentItem.wards_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Địa chỉ">
                                {currentItem ? ReactHtmlParser(currentItem.address) : null}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Table
                            bordered
                            className='mb-4'
                            title={() => <strong>Thành viên trong gia đình</strong>}
                            size='small'
                            rowKey='key'
                            tableLayout="auto"
                            columns={family_columns}
                            locale={{ emptyText: "Chưa có dữ liệu" }}
                            dataSource={currentItem ? currentItem.customer_family : []}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default (CustomerFamilyDetail);
