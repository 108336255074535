import api from '../../utils/api';
import {
    GET_ALL_JOBS,
    GET_SPECIFIC_JOBS,
    UPDATE_SPECIFIC_JOBS,
    CREATE_NEW_JOBS,
    REMOVE_JOBS
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllData = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/jobs', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_JOBS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/jobs', data).then(res => {
            dispatch({ type: CREATE_NEW_JOBS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/jobs/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_JOBS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateData = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/jobs/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_JOBS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeData = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/jobs`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_JOBS, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}