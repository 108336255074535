import axios from 'axios';
import { getCookie, removeCookie } from './cookie';
import appConfig from '../config';
import { NotificationManager } from 'react-notifications';

const api = axios.create({
    baseURL: `${appConfig.API_URL}`,
    timeout: 480000, // 8 Minute
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*'
    }
});

api.interceptors.request.use(function (config) {
    let accessToken = getCookie('chingo_dental_token');
    //console.log("accessToken: ",accessToken);
    // Do something before request is sent
    if (accessToken) {
        config.headers.common['Authorization'] = "Bearer " + accessToken
    }
    //console.log(config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

api.interceptors.response.use(
    response => response,
    error => {
        //console.log(error)
        if (error.response.status === 401) {
            removeCookie('chingo_dental_token')
            let message = "Phiên đã hết hạn. Vui lòng đăng nhập lại!";
            if (error.response.data.message) {
                message = error.response.data.message;
            }

            if (window.location.pathname != '/login' && window.location.pathname != '/reset-password') {
                NotificationManager.error(message);
                window.location.href = '/login'
            }
        } else if (error.response.status === 403) {
            window.location.href = '/error/403'
        }
        return Promise.reject(error);
    }
);

export default api;