import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Input, Button, Row, Col, TreeSelect, InputNumber, Drawer, Space, Select, Popconfirm, Table } from 'antd';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import ListCurrentImage from '../../components/ListCurrentImage';
import {
    customDataImageUpload,
    isValidDateForm, _newLine, makeTree
} from '../../utils/helpers';

import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';

// actions
import { getSpecificCustomerConsultantDirect, updateResultCustomerConsultantDirect } from '../../redux/actions/CustomerConsultantDirectActions';
import { getSpecificService } from '../../redux/actions/ServicesActions';
import NumberFormat from 'react-number-format';

const { Option } = Select;

class CustomerConsultantDirectResultForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            require_field: false,
            showService: false,
            currentImages: [],
            images: [],
            isResetUpload: true,
            items: []
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.status) {
                this.setState({ require_field: nextProps?.currentItem?.status })
            }
            if (nextProps?.currentItem?.images) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            }
            if (nextProps?.currentItem?.customer_status_id === 10) {
                this.setState({ showService: true })
            }
            if (nextProps?.currentItem?.all_service_provideds) {
                this.renderNewItem(nextProps?.currentItem?.all_service_provideds);
            }
        }
    }

    async handleUpdateResult(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.images;
            delete values.selected_service_id;
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { currentItem } = this.props;
                    const { images, currentImages, items } = this.state;

                    if (currentItem) {
                        values.services = items
                        values.images = customDataImageUpload(images, currentImages);
                        values.time_consultant = values.time_consultant ? values.time_consultant.format("YYYY-MM-DD HH:mm") : null;
                        values.selected_at = values.selected_at ? values.selected_at.format("YYYY-MM-DD") : null;
                        values.customer_id = currentItem.customer_id;
                        await this.props.updateResultCustomerConsultantDirect(currentItem.id, values);
                        this.onCancel()
                    }
                    console.log("values: ", values)
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onCancel() {
        this.props.reloadData();
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({
            isLoading: false,
            require_field: false,
            isResetUpload: true,
            images: [],
            showService: false,
            items: []
        })
    }

    ongChangeStatus(value) {
        if (!value) {
            this.props.form.setFieldsValue({
                regular_price: null,
                sale_price: null,
                selected_service_id: null,
                customer_status_id: null,
                time_telesale: null,
                result_content: null
            });
        }
        this.setState({ require_field: value })
    }

    onchangeCuatomerStatus(value) {
        if (value == 10) { // Đã chốt
            this.setState({ showService: true })
        } else {
            this.setState({ showService: false, items: [] })
        }
    }

    onChangeUpload = (data) => {
        this.setState({ isResetUpload: false, images: data });
        NotificationManager.success("Images complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                key: item.key,
                id: item.id,
                service_id: item.service_id,
                service_name: item.service_name,
                regular_price: item.regular_price,
                sale_price: item.sale_price,
                mode: item.mode,
                consultant_users_ids: item.consultant_users_ids
            }
        })
        this.setState({
            //items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.service_id === v.service_id)) === i)
            items: new_items
        }, () => {
            this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
        });
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.key != id;
        });
        this.renderNewItem(items)
    }

    async onChangeItem(name, value, key) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.key == key);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.renderNewItem(items)
    }

    onChangeService = (id) => {
        this.props.getSpecificService(id).then((item) => {
            let currentTime = new Date().getTime();
            let { currentItem } = this.props;
            let new_items = {
                key: currentTime,
                id: currentTime,
                service_id: item.id,
                service_name: item.name,
                regular_price: item.price,
                sale_price: item.price,
                mode: 'current',
                consultant_users_ids: currentItem?.consultant_direct_staff_ids
            };
            this.setState({
                // items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.service_id === v.service_id)) === i),
                items: [...this.state.items, new_items]
            }, () => {
                this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
            });
        }).catch(err => {

        });
        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    };

    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.items.forEach(item => {
            total_amount += item.sale_price * item.quantity;
        });
        this.props.form.setFieldsValue({
            total_amount: total_amount
        });
    };

    render() {
        var { currentItem, visible, service_active_list, customerStatusActiveList, users } = this.props;
        var { isLoading,
            require_field,
            showService,
            isResetUpload,
            currentImages,
            items
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);
        //console.log("currentItem: ", currentItem)
        //console.log("items: ", items)

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Dịch vụ",
                dataIndex: "service_name"
            },
            {
                title: "Giá gốc",
                dataIndex: "regular_price",
                width: "110px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Giá bán",
                dataIndex: "sale_price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="sale_price"
                            min={0}
                            className='w-100'
                            onChange={(value) => this.onChangeItem('sale_price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Nhân viên tư vấn",
                dataIndex: "consultant_users_ids",
                width: "210px",
                render: (text, record) => {
                    return (
                        <Select
                            className='w-100'
                            placeholder="Chọn 1 hoặc nhiều nhân viên"
                            optionLabelProp="label"
                            allowClear={true}
                            showSearch={true}
                            defaultValue={text}
                            mode="multiple"
                            disabled={record.mode == 'before' ? true : false}
                            onChange={(value) => this.onChangeItem('consultant_users_ids', value, record.key)}
                        >
                            {
                                users && Array.isArray(users) ? (
                                    <>
                                        {
                                            users.map((item) => {
                                                return (
                                                    <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                )
                                            })
                                        }
                                    </>
                                ) : null
                            }
                        </Select>
                    )
                }
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    if (record.mode == 'current') {
                        return (
                            <Popconfirm
                                title="Xóa bản ghi này?"
                                onConfirm={() => this.onDelete(record.key)}
                            >
                                <DeleteOutlined className='is_delete_item' />
                            </Popconfirm>
                        )
                    } else {
                        return (
                            <DeleteOutlined className='is_delete_item' style={{ color: '#dedede' }} />
                        )
                    }
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.sale_price;
        });

        return (
            <div>
                <Drawer
                    title="Cập nhật kết quả tư vấn"
                    visible={visible}
                    header={false}
                    width={isMobile ? '100%' : '50%'}
                    onClose={() => this.onCancel()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Space>
                                <Button key="back" onClick={() => this.onCancel()}>
                                    Huỷ
                                </Button>
                                <Button
                                    loading={isLoading}
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => this.handleUpdateResult(e)}
                                >
                                    Cập nhật
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{`${currentItem?.customer_name} - ${currentItem?.customer_code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : null
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { value: 1, label: 'Trả kết quả' },
                                                { value: 0, label: 'Chưa trả kết quả' }
                                            ]}
                                            placeholder="Chọn 1 bản ghi"
                                            className="w-100"
                                            onChange={(value) => this.ongChangeStatus(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            {
                                require_field ? (
                                    <>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Thời điểm tư vấn">
                                                {getFieldDecorator('time_consultant', {
                                                    rules: [
                                                        {
                                                            required: require_field,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? isValidDateForm(currentItem.time_consultant, true) : null
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label='Trạng thái khách hàng'>
                                                {getFieldDecorator("customer_status_id", {
                                                    rules: [
                                                        {
                                                            required: require_field,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.customer_status_id : null
                                                })(
                                                    <BaseSelect
                                                        options={customerStatusActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        className='w-100'
                                                        onChange={(value) => this.onchangeCuatomerStatus(value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {
                                            showService ? (
                                                <>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label='Thêm dịch vụ chốt'>
                                                            {getFieldDecorator("selected_service_id", {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <TreeSelect
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                                    treeData={service}
                                                                    treeNodeFilterProp='title'
                                                                    allowClear
                                                                    placeholder="Chọn một bản ghi"
                                                                    treeDefaultExpandAll
                                                                    onChange={this.onChangeService}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label="Ngày chốt">
                                                            {getFieldDecorator('selected_at', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Vui lòng chọn!',
                                                                    },
                                                                ],
                                                                initialValue: currentItem ? isValidDateForm(currentItem.selected_at, false) : null
                                                            })(
                                                                <DatePicker
                                                                    className='w-100'
                                                                    format="DD/MM/YYYY"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={24} className='mb-5'>
                                                        <Table
                                                            className='mt-2'
                                                            size='small'
                                                            rowKey='key'
                                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                                            tableLayout="auto"
                                                            columns={columns}
                                                            dataSource={items || []}
                                                            pagination={false}
                                                            scroll={{
                                                                x: 'max-content'
                                                            }}
                                                            footer={() => {
                                                                return (
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600' }}>
                                                                        <div style={{ marginRight: '10px' }}>Tổng giá trị:</div>
                                                                        <div>
                                                                            <NumberFormat value={total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                        />
                                                    </Col>
                                                </>
                                            ) : null
                                        }
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Kết quả tư vấn">
                                                {getFieldDecorator('result_content', {
                                                    rules: [
                                                        {
                                                            required: require_field,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? _newLine(currentItem.result_content) : null
                                                })(
                                                    <Input.TextArea rows={6} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Ảnh" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                                <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                                {getFieldDecorator('images', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <UploadImage
                                                        multiple={true}
                                                        maxCount={10}
                                                        onChangeData={this.onChangeUpload}
                                                        isResetUpload={isResetUpload}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : null
                            }
                        </Row>
                    </Form>
                </Drawer>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerConsultantList: state.CustomerConsultantDirectReducer.customerConsultantList,
        pagination: state.CustomerConsultantDirectReducer.pagination,
        users: state.config.users,
        service_active_list: state.ServiceReducer.service_active_list,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificService: (id) => dispatch(getSpecificService(id)),
        getSpecificCustomerConsultantDirect: (id) => dispatch(getSpecificCustomerConsultantDirect(id)),
        updateResultCustomerConsultantDirect: (id, data) => dispatch(updateResultCustomerConsultantDirect(id, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerConsultantDirectResultForm' })(CustomerConsultantDirectResultForm));

