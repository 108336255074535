import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Space, Typography, Image, Tag } from 'antd'
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import ProductForm from './ProductForm';
import { isValidDate } from '../../utils/helpers';
const { Text } = Typography;

class ProductDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentItem && currentItem.name ? currentItem.name : "Chi tiết sản phẩm"}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '55%'}
                    extra={
                        checkPermission('product', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="ID">
                                            <Tag>{currentItem ? currentItem.id : null}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên sản phẩm">
                                            {currentItem ? currentItem.name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã sản phẩm">
                                            {currentItem ? currentItem.code : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Kho">
                                            {currentItem ? currentItem.warehouse_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhóm sản phẩm">
                                            {currentItem ? currentItem.group_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhà cung cấp">
                                            {currentItem ? currentItem.supplier_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                {/* <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tình trạng sản phẩm">
                                            {
                                                currentItem && currentItem.condition == 1 ? <> Sản phẩm mới</> : null
                                            }
                                            {
                                                currentItem && currentItem.condition == 0 ? <> Sản phẩm cũ</> : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col> */}
                                {/* <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời gian bảo hành cung cấp">
                                            {currentItem ? currentItem.warranty_supplier : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời gian bảo hành cho khách hàng">
                                            {currentItem ? currentItem.warranty_customer : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col> */}
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Giá nhập">
                                            <NumberFormat value={currentItem ? currentItem.regular_price : null} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Giá bán">
                                            <NumberFormat value={currentItem ? currentItem.sale_price : null} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số lượng tồn">
                                            {currentItem ? currentItem.quantity : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Đơn vị tính">
                                            {currentItem ? currentItem.unit : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tình trạng">
                                            {currentItem ? currentItem.product_status_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Hạn sử dụng">
                                            {isValidDate(currentItem.expiry_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <div className='mb-1 mt-2'>
                                        <Text strong>Ảnh sản phẩm</Text>
                                    </div>
                                    <Image.PreviewGroup>
                                        <Space wrap>
                                            {
                                                currentItem?.images.map(item => {
                                                    return (
                                                        <Image
                                                            width={50}
                                                            height={50}
                                                            src={item.src}
                                                            style={{ border: '1px solid #dedede' }}
                                                        />
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentItem.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem ? currentItem.created_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>
                <ProductForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductDetail' })(ProductDetail));
