import {
    GET_ALL_VOUCHER,
    CREATE_NEW_VOUCHER,
    UPDATE_SPECIFIC_VOUCHER,
    GET_SPECIFIC_VOUCHER,
    REMOVE_VOUCHER,
    GET_ALL_VOUCHER_WITH_CONCATENATE,
    GET_ALL_VOUCHER_BY_ACTIVE
} from '../actions/types'

const INIT_STATE = {
    voucher_list: [],
    current_voucher: {},
    voucher_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_VOUCHER: {
            return {
                ...state,
                voucher_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            }
        }

        case GET_ALL_VOUCHER_BY_ACTIVE: {
            let listVoucher = [...state.voucher_active_list, ...action.payload.data];
            return {
                ...state,
                voucher_active_list: listVoucher.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            }
        }

        case GET_ALL_VOUCHER_WITH_CONCATENATE: {
            let listVoucher = [...state.voucher_list, ...action.payload.data];

            return {
                ...state,
                voucher_list: listVoucher.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case CREATE_NEW_VOUCHER: {
            return {
                ...state,
                voucher_list: [
                    action.payload,
                    ...state.voucher_list
                ],
                total: state.total + 1
            }
        }
        case GET_SPECIFIC_VOUCHER: {
            return { ...state, current_voucher: action.payload }
        }
        case UPDATE_SPECIFIC_VOUCHER: {
            let index = state.voucher_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.voucher_list];
            temp[index] = action.payload;
            return { ...state, voucher_list: temp }
        }
        case REMOVE_VOUCHER: {
            let temp = state.voucher_list.filter(role => {
                return action.payload.indexOf(role.id) < 0;
            });
            return { ...state, voucher_list: temp }
        }
        default:
            return { ...state }
    }

}
