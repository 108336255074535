import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Tag, Button, Row, Col, Table, Spin, Divider, Space, Typography, Image } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect';
import PaymentVoucherForm from './PaymentVoucherForm';
import PaymentVoucherUpdateStatusForm from './PaymentVoucherStatusForm';
import { ConvertPaymentMethod, ConvertPaymentVoucherStatus, isValidDate } from '../../utils/helpers';
import PrintButton from '../../components/PrintButton/PrintButton';
import PrintTable from './PrintTable';

const { Text } = Typography;

class PaymentVoucherDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false,
            isOpenStatusForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm) {
        this.setState({ isOpenForm: isOpenForm });
    }

    toggleOpenUpdateStatusForm(isOpenStatusForm) {
        this.setState({ isOpenStatusForm: isOpenStatusForm });
    }

    reloadData() {
        this.props.reloadData();
    }

    render() {
        var { isOpenForm, isOpenStatusForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;
        //console.log(currentItem)

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Tên",
                dataIndex: "name"
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: '80px'
            },
            {
                title: "Đơn giá",
                dataIndex: "price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            }
        ];

        const customer = {
            recipient: currentItem?.recipient,
            total_amount: currentItem?.total_amount,
            content: currentItem?.content
        }

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết phiếu chi: #${currentItem?.id}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '60%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('payment_voucher', 'update') ? (
                                    <Button type="primary" onClick={() => this.toggleOpenForm(true)} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                            {
                                checkPermission('payment_voucher', 'update_status') ? (
                                    <Button type="primary" onClick={() => this.toggleOpenUpdateStatusForm(true)} icon={<EditOutlined />}>Cập nhật trạng thái</Button>
                                ) : null
                            }
                            <PrintButton>
                                <PrintTable customer={customer} tableData={currentItem ? currentItem.items : []} />
                            </PrintButton>
                        </Space>
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tên người nhận tiền">
                                                {currentItem?.recipient}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tổng tiền">
                                                <Tag color='red'><NumberFormat value={currentItem?.total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} /></Tag>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Trạng thái">
                                                {ConvertPaymentVoucherStatus(currentItem?.status)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Hình thức chi">
                                                {ConvertPaymentMethod(currentItem?.payment_method)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày chi">
                                                {isValidDate(currentItem?.payment_at, false)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người cập nhật">
                                                {currentItem?.updated_user}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người tạo">
                                                {currentItem?.created_user}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày tạo">
                                                {isValidDate(currentItem?.created_at)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ghi chú">
                                                {ReactHtmlParser(currentItem?.note)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <div className='mb-1 mt-2'>
                                            <Text strong>Ảnh phiếu</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.images.map(item => {
                                                        return (
                                                            <Image
                                                                height={50}
                                                                src={item.src}
                                                                style={{ border: '1px solid #dedede' }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Col>
                                </Row>
                                <Row gutter={16} className='mt-3'>
                                    <Col xs={24} md={24}>
                                        <Table
                                            className='mb-4'
                                            title={() => <strong style={{ marginLeft: '-8px' }}>Nội dung chi</strong>}
                                            size='small'
                                            rowKey='key'
                                            tableLayout="auto"
                                            columns={columns}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                            dataSource={currentItem ? currentItem.items : []}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    ) : null}
                </Drawer>
                <PaymentVoucherForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <PaymentVoucherUpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenUpdateStatusForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PaymentVoucherDetail' })(PaymentVoucherDetail));
