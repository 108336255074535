import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Space, Row, Col, Skeleton } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';
import ListCurrentImage from '../../components/ListCurrentImage';
// actions
import { createCustomerProfile, getListCustomerProfile } from '../../redux/actions/CustomerProfileActions';
import { customDataImageUpload } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';

class CustomerProfileForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isLoadingData: true,
            portraitPhoto: [],
            consultationForm: [],
            medicalRecordBook: [],
            XRayImage: [],
            receipt: [],
            document: [],
            isResetUpload: true,
            currentConsultationForm: [],
            currentMedicalRecordBook: [],
            currentDocument: [],
            currentPortraitPhoto: [],
            currentReceipt: [],
            currentXRayImage: [],
            currentCustomerFamily: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentCustomer !== prevProps.currentCustomer) {
            this.loadData();
        }
    }

    updateDocumentState(data, key, stateKey) {
        const documents = data[key];
        const isArray = Array.isArray(documents);
        this.setState({ [stateKey]: isArray ? documents : [] });
    }

    loadData() {
        this.setState({ isLoadingData: true })
        this.props.getListCustomerProfile({ customer_id: this.props.currentCustomer?.id }).then(res => {
            //console.log(res);
            const data = res.data.documents;
            this.updateDocumentState(data, 'consultation_form', 'currentConsultationForm');
            this.updateDocumentState(data, 'medical_record_book', 'currentMedicalRecordBook');
            this.updateDocumentState(data, 'orther_document', 'currentDocument');
            this.updateDocumentState(data, 'portrait_photo', 'currentPortraitPhoto');
            this.updateDocumentState(data, 'receipt', 'currentReceipt');
            this.updateDocumentState(data, 'x_ray_image', 'currentXRayImage');
            this.setState({ isLoadingData: false })
        }).catch(err => {
            this.setState({ isLoadingData: false })
        });
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentCustomer) {
                        const {
                            portraitPhoto,
                            consultationForm,
                            medicalRecordBook,
                            XRayImage,
                            receipt,
                            document,
                            currentConsultationForm,
                            currentMedicalRecordBook,
                            currentDocument,
                            currentPortraitPhoto,
                            currentReceipt,
                            currentXRayImage,
                        } = this.state;

                        values.customer_id = this.props.currentCustomer?.id;
                        values.profile_portrait_photo = customDataImageUpload(portraitPhoto, currentPortraitPhoto);
                        values.profile_consultation_form = customDataImageUpload(consultationForm, currentConsultationForm);
                        values.profile_medical_record_book = customDataImageUpload(medicalRecordBook, currentMedicalRecordBook);
                        values.profile_orther_document = customDataImageUpload(document, currentDocument);
                        values.profile_receipt = customDataImageUpload(receipt, currentReceipt);
                        values.profile_x_ray_image = customDataImageUpload(XRayImage, currentXRayImage);

                        //console.log(values)
                        await this.props.createCustomerProfile(values).then(res => {
                            this.onClose();
                        }).catch(err => {
                            this.setState({ isLoading: false });
                        });

                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.props.reloadData();
        this.loadData();
        this.setState({
            isLoading: false,
            portraitPhoto: [],
            consultationForm: [],
            medicalRecordBook: [],
            XRayImage: [],
            receipt: [],
            document: [],
            isResetUpload: true,
        });
    }

    onChangeUpload = (stateKey, data, message) => {
        this.setState({ isResetUpload: false, [stateKey]: data });
        NotificationManager.success(message);
    }

    // Sử dụng hàm onChangeUpload cho từng trạng thái cụ thể
    onChangePortraitPhoto = (data) => this.onChangeUpload('portraitPhoto', data, 'Portrait photo complete.');
    onChangeConsultationForm = (data) => this.onChangeUpload('consultationForm', data, 'Consultation form complete.');
    onChangeMedicalRecordBook = (data) => this.onChangeUpload('medicalRecordBook', data, 'Medical record book complete.');
    onChangeXRayImage = (data) => this.onChangeUpload('XRayImage', data, 'X-ray image complete.');
    onChangeReceipt = (data) => this.onChangeUpload('receipt', data, 'Receipt complete.');
    onChangeDocument = (data) => this.onChangeUpload('document', data, 'Document complete.');

    getImages(stateKey, data) {
        this.setState({ [stateKey]: data })
    }

    render() {
        var {
            isResetUpload,
            isLoading,
            currentConsultationForm,
            currentMedicalRecordBook,
            currentDocument,
            currentPortraitPhoto,
            currentReceipt,
            currentXRayImage,
            isLoadingData
        } = this.state;
        var {
            visible,
            currentCustomer
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`Hồ sơ: ${currentCustomer ? currentCustomer?.full_name : ""} - ${currentCustomer?.code}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            {
                                checkPermission('customer_profile', 'create') ? (
                                    <Button
                                        loading={isLoading}
                                        key="submit"
                                        type="primary"
                                        onClick={(e) => this.submit(e)}
                                    >
                                        Cập nhật
                                    </Button>
                                ) : null
                            }
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        {
                            isLoadingData ? (
                                <Row gutter={16} align='middle'>
                                    <Col xs={24} md={24}>
                                        <Skeleton active />
                                    </Col>
                                </Row>
                            ) : (
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Ảnh chân dung" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentPortraitPhoto} getData={(data) => this.getImages('currentPortraitPhoto', data)} />
                                            {getFieldDecorator('profile_portrait_photo', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangePortraitPhoto}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Phiếu tư vấn" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentConsultationForm} getData={(data) => this.getImages('currentConsultationForm', data)} />
                                            {getFieldDecorator('profile_consultation_form', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangeConsultationForm}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Sổ khám" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentMedicalRecordBook} getData={(data) => this.getImages('currentMedicalRecordBook', data)} />
                                            {getFieldDecorator('profile_medical_record_book', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangeMedicalRecordBook}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Phim X Quang" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentXRayImage} getData={(data) => this.getImages('currentXRayImage', data)} />
                                            {getFieldDecorator('profile_x_ray_image', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangeXRayImage}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Phiếu thu" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentReceipt} getData={(data) => this.getImages('currentReceipt', data)} />
                                            {getFieldDecorator('profile_receipt', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangeReceipt}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Giấy tờ khác" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            <ListCurrentImage currentImages={currentDocument} getData={(data) => this.getImages('currentDocument', data)} />
                                            {getFieldDecorator('profile_orther_document', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={this.onChangeDocument}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }

                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerProfile: (data) => dispatch(createCustomerProfile(data)),
        getListCustomerProfile: (filter) => dispatch(getListCustomerProfile(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerProfileForm' })(CustomerProfileForm));
