import React, { Component } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { repeatPhoneNumber } from '../../../utils/helpers';
import { checkPermission } from '../../../utils/permission';
const { Option } = Select;

export default class BaseSelect extends Component {
    static propTypes = {
        defaultText: PropTypes.string,
        options: PropTypes.array.isRequired,
        attr: PropTypes.object,
        onChange: PropTypes.func,
        onScrollEnd: PropTypes.func,
    };

    static defaultProps = {
        optionValue: "value",
        optionLabel: "label",
        onScrollEnd: () => { }
    };

    handleScroll(e) {
        var element = e.target;
        // Khi element.scrollHeight == element.clientHeight + element.scrollTop thì đó là scroll tới bottom
        //if(element.scrollHeight == (element.clientHeight + element.scrollTop)) {
        this.props.onScrollEnd();
        //}
    }

    render() {
        var {
            defaultText,
            selected,
            options,
            mode,
            isloading,
            attr,
            optionValue, // name of value field
            optionLabel, // name of label field
            additionalLabel,
            additionalLabel2,
            isCustomerList,
            onChange,
            onScrollEnd,
            ...rest
        } = this.props;

        let value = selected ? selected : "";

        if (options.length) {
            let temp = options.find(option => option[optionValue] == selected);
            if (!temp) value = null;

            if (!defaultText) {
                value = options[0][optionValue];
            }
        }

        return (
            <Select
                defaultValue={value}
                mode={mode} //multiple 
                allowClear
                loading={isloading}
                {...rest}
                onChange={(value) => onChange(value)}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }

                onPopupScroll={(e) => this.handleScroll(e)}
            >
                {
                    defaultText ? (
                        <Option value={null}>{defaultText}</Option>
                    ) : null
                }
                {
                    // options.map((option, index) => {
                    //     let label = option[optionLabel];
                    //     if (additionalLabel2) {
                    //         if (isCustomerList && additionalLabel === 'phone') {
                    //             if (checkPermission('customer', 'phonenumber')) {
                    //                 label = option[optionLabel] + " (" + option[additionalLabel] + ", " + option[additionalLabel2] + ")";
                    //             } else {
                    //                 label = option[optionLabel] + " (" + repeatPhoneNumber(option[additionalLabel]) + ", " + option[additionalLabel2] + ")";
                    //             }
                    //         } else {
                    //             label = option[optionLabel] + " (" + option[additionalLabel] + ", " + option[additionalLabel2] + ")";
                    //         }
                    //     } else if (additionalLabel) {
                    //         label = option[optionLabel] + " (" + option[additionalLabel] + ")";
                    //     }
                    //     return (
                    //         <Option key={`${option[optionValue]}_${index}`} value={option[optionValue]}>{label}</Option>
                    //     )
                    // })

                    options.map((option, index) => {
                        let label = option[optionLabel];
                        let additionalInfo = '';

                        if (additionalLabel) {
                            additionalInfo += option[additionalLabel];

                            if (additionalLabel2) {
                                additionalInfo = `${option[additionalLabel2]}, ${additionalInfo}`;
                            }
                        }

                        if (isCustomerList && additionalLabel === 'phone') {
                            if (checkPermission('customer', 'phonenumber')) {
                                additionalInfo = `${option[additionalLabel2]}, ${option[additionalLabel]}`;
                            } else {
                                additionalInfo = `${option[additionalLabel2]}, ${repeatPhoneNumber(option[additionalLabel])}`;
                            }
                        }

                        label += additionalInfo ? ` (${additionalInfo})` : '';

                        return (
                            <Option key={`${option[optionValue]}_${index}`} value={option[optionValue]}>{label}</Option>
                        );
                    })

                }
            </Select>
        )
    }
}