import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Image, Card, Space, Typography, Button } from 'antd'

import { getListCustomerProfile } from '../../redux/actions/CustomerProfileActions';
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import CustomerProfileForm from './CustomerProfileForm';

const { Text } = Typography;

class CustomerProfilesDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    openForm() {
        this.setState({ currentCustomer: this.props.currentCustomer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.getListCustomerProfile({ customer_id: this.props.currentCustomer?.id }).then(res => {
            const data = res.data.profiles;
            this.setState({ currentItem: data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    render() {
        var { currentItem, isOpenForm, currentCustomer } = this.state;

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                    <Tag color='#0b2b33'>7</Tag> Hồ sơ
                </Divider>
                <Row justify="end" className='mb-1'>
                    <Button
                        disabled={!checkPermission('customer_profile', 'create')}
                        type="dashed"
                        className='color-blue'
                        size='small'
                        onClick={() => this.openForm()}
                        icon={<EditOutlined />}
                    >
                        Cập nhật
                    </Button>
                </Row>
                {
                    currentItem && currentItem?.length ? (
                        <Row gutter={16}>
                            {
                                currentItem?.map(item => {
                                    return (
                                        <Col xs={24} md={8} className='mb-2'>
                                            <Card size="small" className='border-dashed'>
                                                <Col xs={24} md={24}>
                                                    <Text strong style={{ display: 'block' }} className='mb-1'>{item.name}</Text>
                                                    {
                                                        Array.isArray(item.images) && item.images.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        item.images.map(item => {
                                                                            return (
                                                                                <Image
                                                                                    height={40}
                                                                                    src={item?.src}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Col>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }
                <CustomerProfileForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerProfile: (filter) => dispatch(getListCustomerProfile(filter))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerProfilesDetail);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerProfilesDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CustomerProfilesDetail);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerProfilesDetail {...props} ref={ref} />
));
