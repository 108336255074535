import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DeleteOutlined,
    ReloadOutlined,
    UserOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Table, Modal, Tooltip, Tag, Divider, DatePicker, Avatar, Button, Typography, Card, Tabs, Badge } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, ConvertRewardType, isValidDate, RewardType } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import moment from 'moment';
import BaseSelect from '../../components/Elements/BaseSelect';
import RewardSpinDetail from './RewardSpinDetail';

// actions
import { getListRewardHistories, getSpecificRewardHistories, removeRewardHistories, spinReward } from '../../redux/actions/RewardSpinActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';


const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/reward-histories',
        breadcrumbName: 'Lịch sử quay thưởng',
    },
]

class RewardSpinList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            districts: [],
            wards: [],
            isOpenForm: false,
            currentItem: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListRewardHistories(query).then(() => {
            this.setState({ isLoading: false });
        })

        this.props.getListProvinces();
        this.props.getListDistricts({ province_code: query.province_code }).then(res => {
            this.setState({ districts: res.data });
        })
        this.props.getListWards({ district_code: query.district_code }).then(res2 => {
            this.setState({ wards: res2.data })
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListRewardHistories(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove(tab) {
        this.props.removeRewardHistories(this.state.selectedRowKeys, tab).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.reloadData()
        })
    }
    openAlert(id, tab) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá khách hàng này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeRewardHistories([id], tab).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData()
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificRewardHistories(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListRewardHistories(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ selectedRowKeys: [] })
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.resetFields(['district_code', 'wards_code']);
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.resetFields(['wards_code']);
                this.setState({ wards: res.data })
            })
        }
    }

    onSpinReward() {
        confirm({
            title: 'Xác nhận',
            content: 'Bạn chắc chắn muốn thực hiện thao tác này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.spinReward().then(() => {
                this.reloadData();
                this.setState({ isLoading: false });
            }).catch(err => {
                this.setState({ isLoading: false });
            }),
            onCancel() { },
        })
    }

    render() {
        var { reward_histories_list, pagination, list_provinces } = this.props;
        var { isLoading, selectedRowKeys, districts, wards, isOpenDetail,
            currentItem, current_id, loadingDetail } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('reward_spin', 'remove') ? (
                                <Tooltip title="Xóa" placement="top">
                                    <DeleteOutlined className='item-action-btn color-red' onClick={() => this.openAlert(record.id, query.tab)} />
                                </Tooltip>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '200px',
                render: (text, record) => {
                    if (record.full_name) {
                        if (query?.tab == 'reward_histories') {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;{record.full_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Paragraph style={{ margin: 0 }} copyable={
                            {
                                text: record ? record.code : null,
                                tooltips: false
                            }
                        }>
                            {text}
                        </Paragraph>
                    )
                }
            },
            {
                title: 'Mã thẻ',
                dataIndex: 'card_number_new',
                key: 'card_number_new',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number_new : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Mã thẻ cũ',
                dataIndex: 'card_number',
                key: 'card_number',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Giải thưởng',
                dataIndex: 'type',
                width: '100px',
                key: 'type',
                render: (text, record) => {
                    return (
                        <>{ConvertRewardType(text)}</>
                    )
                }
            },
            {
                title: 'Ngày quay',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            },
            {
                title: 'Ngày hết hạn',
                dataIndex: 'expire_date',
                key: 'expire_date',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    var diffDate = record.expire_date ? moment(record.expire_date).diff(moment().startOf('day'), 'days') : 0;
                    var show_text;
                    var badge_color;
                    if (diffDate) {
                        if (diffDate > 0) {
                            show_text = `Còn ${diffDate} ngày`;
                            badge_color = '#02805f';
                        } else {
                            show_text = `${-1 * diffDate} ngày trước`;
                            badge_color = 'red';
                        }
                    } else {
                        show_text = "Hết hạn hôm nay";
                        badge_color = '#f50';
                    }
                    if (record.status == 1) { // Đã nhận thưởng
                        return (
                            <Badge count="Đã nhận thưởng" size='small' className='custom-badge' color='#096dd9'>
                                {isValidDate(text, false)}
                            </Badge>
                        )
                    } else {
                        return (
                            <Badge count={show_text} size='small' className='custom-badge' color={badge_color}>
                                {isValidDate(text, false)}
                            </Badge>
                        )
                    }
                }
            },
            {
                title: 'Ngày nhận thưởng',
                dataIndex: 'received_date',
                key: 'received_date',
                width: '160px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text)}</div>
                        )
                    }
                }
            },
            {
                title: 'Tỉnh/Thành phố',
                dataIndex: 'province_name',
                key: 'province_name',
                width: '140px'
            },
            {
                title: 'Quận/Huyện',
                dataIndex: 'district_name',
                key: 'district_name',
                width: '140px'
            },
            {
                title: 'Phường/Xã',
                dataIndex: 'wards_name',
                key: 'wards_name',
                width: '140px'
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            }
        ];

        const tables = <Table
            rowKey="id"
            size="small"
            tableLayout='auto'
            dataSource={reward_histories_list || []}
            columns={columns}
            loading={isLoading}
            rowSelection={rowSelection}
            pagination={{
                pageSize: pagination.perPage,
                current: pagination.currentPage,
                total: pagination.total,
                showTotal: total => `Tổng ${total} bản ghi`,
                defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100']
            }}
            onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
            scroll={{
                x: 'max-content'
            }}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Lịch sử quay thưởng" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('reward_spin', 'remove')}
                        onDelete={() => this.onRemove(query?.tab)}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_date_start ||
                            query.province_code ||
                            query.district_code ||
                            query.wards_code ||
                            query.status
                        }
                        filters={
                            [
                                <BaseSelect
                                    options={list_provinces || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Tỉnh/Thành phố"
                                    className="w-100"
                                    onChange={(value) => {
                                        this.onChangeFilter('province_code', value);
                                        this.loadDistricts(value)
                                    }}
                                    defaultValue={query.province_code ? query.province_code : null}
                                />,
                                <BaseSelect
                                    options={districts || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Quận/Huyện"
                                    className="w-100"
                                    onChange={(value) => {
                                        this.onChangeFilter('district_code', value);
                                        this.loadWards(value)
                                    }}
                                    defaultValue={query.district_code ? query.district_code : null}
                                />,
                                <BaseSelect
                                    options={wards || []}
                                    optionValue="code"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Phường/Xã"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('wards_code', value)}
                                    defaultValue={query.wards_code ? query.wards_code : null}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày quay', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />
                            ]
                        }
                    >
                        {
                            checkPermission('reward_spin', 'create') ? (
                                <Button className="table-button" icon={<SyncOutlined />} type='primary' loading={isLoading} onClick={() => this.onSpinReward()}>Quay thưởng</Button>
                            ) : null
                        }
                        <BaseSelect
                            options={[
                                { label: "Đã nhận thưởng", value: 1 },
                                { label: "Chưa nhận thưởng", value: 0 }
                            ]}
                            placeholder="Trạng thái"
                            style={{ width: '150px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('status', value)}
                            defaultValue={query.status ? parseInt(query.status) : null}
                        />
                        <BaseSelect
                            options={RewardType || []}
                            showSearch
                            placeholder="Giải thưởng"
                            style={{ width: '150px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('type', value)}
                            defaultValue={query.type ? query.type : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>

                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Giải thưởng hiện tại</span>} key="reward_current">
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch sử giải thưởng</span>} key="reward_histories">
                            {tables}
                        </TabPane>
                    </Tabs>
                </Card>

                <RewardSpinDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        reward_histories_list: state.RewardSpinReducer.reward_histories_list,
        pagination: state.RewardSpinReducer.pagination,
        list_provinces: state.RegionsReducer.list_provinces
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListRewardHistories: (filter) => dispatch(getListRewardHistories(filter)),
        getSpecificRewardHistories: (id) => dispatch(getSpecificRewardHistories(id)),
        removeRewardHistories: (ids, tab) => dispatch(removeRewardHistories(ids, tab)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        spinReward: () => dispatch(spinReward())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RewardSpinList' })(RewardSpinList));

