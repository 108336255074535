import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, DatePicker, Row, Col, Drawer, Divider, InputNumber } from 'antd';
import moment from 'moment';
import { _newLine, customDataFilesUpload } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';
import ListCurrentFiles from '../../components/ListCurrentFiles';

// actions
import { createData, updateData, getMaxValue } from '../../redux/actions/ProjectBasedJobActions';
import { getProjectActive } from '../../redux/actions/ProjectActions';

class ProjectBasedJobForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            sort_order: 0,
            attached_files: [],
            currentFiles: [],
            selectFile: false
        };
    }

    componentDidMount() {
        this.props.getProjectActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.getMaxValue({ sort_order: 'sort_order' }).then(res => {
            this.setState({ sort_order: res.data + 1 });
        })
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentFiles: nextProps?.currentData?.attached_files,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isloading: true })
                    const { currentFiles, attached_files } = this.state;
                    const params = {
                        name: values.name,
                        project_id: values.project_id,
                        sort_order: values.sort_order,
                        user_id: parseInt(values.user_id),
                        description: values.description,
                        note: values.note,
                        start_date: values.start_date ? values.start_date.format("YYYY-MM-DD") : null,
                        deadline: values.deadline ? values.deadline.format("YYYY-MM-DD") : null,
                        attached_files: customDataFilesUpload(attached_files, currentFiles)
                    }
                    if (this.props.currentData) {
                        await this.props.updateData(this.props.currentData.id, params);
                        this.handCancel()
                        this.props.reloadData()
                    } else {
                        await this.props.createData(params);
                        this.handCancel()
                        this.props.reloadData()
                    }
                    //console.log(params)
                    this.setState({ isloading: false })
                } catch (error) {
                    this.setState({ isloading: false });
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({
            isloading: false,
            attached_files: [],
            selectFile: !this.state.selectFile
        })
    }

    onChangeUpload = (data) => {
        this.setState({
            ...this.state,
            attached_files: data,
        });
    }

    getFiles(data) {
        this.setState({ currentFiles: data })
    }

    render() {
        const { isloading, currentFiles, sort_order } = this.state;
        var { visible, currentData, users, project_active_list } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa công việc" : "Thêm mới công việc"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên công việc">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.name : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Dự án">
                                    {getFieldDecorator('project_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.project_id : null
                                    })(
                                        <BaseSelect
                                            options={project_active_list || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            defaultText="Chọn dự án"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="STT">
                                    {getFieldDecorator("sort_order", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.sort_order : sort_order
                                    })(
                                        <InputNumber className='w-100' step={1} min={1} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Người thực hiện">
                                    {getFieldDecorator('user_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.user_id : null
                                    })(
                                        <BaseSelect
                                            options={users}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            defaultText="Chọn người thực hiện"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày bắt đầu">
                                    {getFieldDecorator("start_date", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? moment(currentData.start_date).isValid() ? moment(currentData.start_date) : null : ''
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Deadline">
                                    {getFieldDecorator("deadline", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? moment(currentData.deadline).isValid() ? moment(currentData.deadline) : null : ''
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Tệp đính kèm">
                            {getFieldDecorator('attached_files', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ''
                            })(
                                <DraggerFile onChangeData={this.onChangeUpload} select={this.state.selectFile} />
                            )}
                            <ListCurrentFiles currentFiles={currentFiles} getData={(data) => this.getFiles(data)} />
                        </Form.Item>
                        <Form.Item label="Mô tả công việc">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.description : null
                            })(
                                <CKEditorComponent
                                    initialHeight="300px"
                                    editorData={currentData?.description}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>


                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
        project_active_list: state.ProjectReducer.project_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data)),
        getProjectActive: (filter) => dispatch(getProjectActive(filter)),
        getMaxValue: (column) => dispatch(getMaxValue(column))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProjectBasedJobForm' })(ProjectBasedJobForm));
