import {
    GET_ALL_CARD_TYPE,
    CREATE_CARD_TYPE,
    UPDATE_CARD_TYPE,
    REMOVE_CARD_TYPE,
    GET_CARD_TYPE_ACTIVE
} from '../actions/types';

const INIT_STATE = {
    card_type_list: [],
    card_type_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_CARD_TYPE:
            return {
                ...state,
                card_type_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_CARD_TYPE_ACTIVE: {
            return {
                ...state,
                card_type_active_list: action.payload.data,
            }
        }
        case CREATE_CARD_TYPE:
            return {
                ...state,
                card_type_list: [action.payload, ...state.card_type_list],
                total: state.total + 1
            }

        case UPDATE_CARD_TYPE:
            let index = state.card_type_list.findIndex(response => {
                return response.id === action.payload.id
            });
            let temp = [...state.card_type_list];
            temp[index] = action.payload
            return {
                ...state,
                card_type_list: temp
            }
        case REMOVE_CARD_TYPE:
            let temp1 = state.card_type_list.filter(response => {
                return action.payload.indexOf(response.id) < 0;
            })
            return {
                ...state,
                card_type_list: temp1
            }
        default:
            return { ...state }
    }
};
