import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Descriptions, Button } from 'antd'
import { isValidDate } from '../../utils/helpers';
import { getListCustomerMedical } from '../../redux/actions/CustomerMedicalActions';
import { checkPermission } from '../../utils/permission';
import { EditOutlined } from '@ant-design/icons';
import CustomerMedicalForm from './CustomerMedicalForm';

class CustomerMedicalDetail extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    openForm() {
        this.setState({ currentCustomer: this.props.currentCustomer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.getListCustomerMedical({ customer_id: this.props.currentCustomer?.id }).then(res => {
            const data = res?.data;
            this.setState({ currentItem: data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    render() {
        var { currentItem, isOpenForm, currentCustomer } = this.state;

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                    <Tag color='#0b2b33'>8</Tag> Thông tin y tế
                </Divider>
                <Row justify="end" className='mb-1'>
                    <Button
                        disabled={!checkPermission('customer_medical', 'create')}
                        type="dashed"
                        className='color-blue'
                        size='small'
                        onClick={() => this.openForm()}
                        icon={<EditOutlined />}
                    >
                        Cập nhật
                    </Button>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Cân nặng">
                                {currentItem?.data?.weight}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Chiều cao">
                                {currentItem?.data?.height}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Huyết áp">
                                {currentItem?.data?.blood_pressure}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Ngày đo (huyết áp)">
                                {isValidDate(currentItem?.data?.blood_pressure_date)}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Đường huyết">
                                {currentItem?.data?.blood_sugar}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Nhóm máu">
                                {currentItem?.data?.blood_type}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Tiền sử bệnh án">
                                {currentItem?.data?.list_diseases}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

                <CustomerMedicalForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerMedical: (filter) => dispatch(getListCustomerMedical(filter))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerMedicalDetail);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerMedicalDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CustomerMedicalDetail);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerMedicalDetail {...props} ref={ref} />
));
