import api from '../../utils/api';
import {
    GET_ALL_PRODUCT,
    CREATE_NEW_PRODUCT,
    UPDATE_SPECIFIC_PRODUCT,
    GET_SPECIFIC_PRODUCT,
    REMOVE_PRODUCT,
    GET_ALL_PRODUCT_WITH_CONCATENATE,
    GET_STATISTICAL_PRODUCT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllProduct = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_ALL_PRODUCT_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getActiveProduct = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product/product-active', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_ALL_PRODUCT_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getSpecificProduct = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createNewProduct = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product', data).then(res => {
            dispatch({
                type: CREATE_NEW_PRODUCT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateSpecificProduct = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_SPECIFIC_PRODUCT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeProduct = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/product', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_PRODUCT,
                payload: ids
            });
            NotificationManager.success("Xoá thành công!");
            resolve(res.data.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/product/statistical/total', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: GET_STATISTICAL_PRODUCT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}