import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusCircleOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Table, Avatar, Tag, Divider, Button, Select, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { cleanObject, getFirstLetterOfWords } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';
import BaseSelect from '../../components/Elements/BaseSelect';
import DoctorAssistantStatusForm from './DoctorAssistantStatusForm'
// actions
import { getDoctorAssistantStatus } from '../../redux/actions/DoctorAssistantStatusActions';
import { getUser } from '../../redux/actions/UserActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/doctor-assistant-status',
        breadcrumbName: 'Danh sách bác sĩ, phụ tá',
    },
]

class DoctorAssistantStatusList extends Component {
    state = {
        isLoading: false,
        isOpenForm: false,
        listUser: [],
        selectedRowKeys: []
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getDoctorAssistantStatus(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getDoctorAssistantStatus(query).then(() => {
                this.setState({
                    isLoading: false
                })
            });
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query.page = 1;
        query = cleanObject(query); // remove empty fields

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));

        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order == 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });

    }

    toggleOpenForm(isOpenForm) {
        const { selectedRowKeys } = this.state;
        const listUserPromises = selectedRowKeys.map(user_id => {
            return this.props.getUser(user_id);
        });
        Promise.all(listUserPromises)
            .then(responses => {
                const listUser = responses.map(res => res);
                this.setState({ isOpenForm: isOpenForm, listUser: listUser });
            })
            .catch(error => {
                console.error("Error fetching users:", error);
            });
    }

    handleCancel() {
        this.toggleOpenForm(false);
        this.setState({ selectedRowKeys: [] })
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    render() {
        var { doctor_assistant_status, pagination, config } = this.props;
        var { isLoading, isOpenForm, listUser, selectedRowKeys } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const columns = [
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                sorter: true,
                render: (text, record) => {
                    if (record.full_name) {
                        return (
                            <>
                                {record.avatar ? (
                                    <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                ) : (
                                    <>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    </>
                                )}
                                &nbsp;<span>{record.full_name}</span>
                            </>
                        )
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Nhóm',
                dataIndex: 'role_name',
                key: 'role_name',
                width: '100px',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                ),
            },
            {
                title: 'Trạng thái',
                dataIndex: 'user_status',
                key: 'user_status',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    if (text == 'available') {
                        return (
                            <Tag color='green' size='small'>
                                Đang sẵn sàng
                            </Tag>
                        )
                    }
                    if (text == 'busy') {
                        return (
                            <Tag color='red' size='small'>
                                Đang bận
                            </Tag>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle
                    title="Danh sách bác sĩ, phụ tá"
                    routes={routes}
                />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            checkPermission('doctor_assistant_status', 'assign') ? (
                                <Button className="table-button" onClick={() => this.toggleOpenForm(true)} type='primary' icon={<PlusCircleOutlined />} disabled={!selectedRowKeys.length ? true : false}>
                                    Giao bệnh nhân
                                </Button>
                            ) : null
                        }

                        <BaseSelect
                            placeholder="Lọc theo trạng thái"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter("user_status", value)}
                            options={[
                                { label: "Trạng thái", value: "" },
                                { label: "Đang sẵn sàng", value: 'available' },
                                { label: "Đang bận", value: 'busy' },
                            ]}
                            className="table-button"
                            defaultValue={query.user_status || null}
                        />
                        <Select
                            showSearch
                            placeholder="Lọc theo nhóm"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter("role", value)}
                            options={config.role_trees}
                            optionFilterProp="children"
                            allowClear
                            className="table-button"
                            defaultValue={parseInt(query.role) || null}
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size='small'
                    dataSource={doctor_assistant_status ?? []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={{
                        ...rowSelection,
                        getCheckboxProps: (record) => ({
                            disabled: record.user_status === 'busy',
                        }),
                    }}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <DoctorAssistantStatusForm
                    visible={isOpenForm}
                    listUser={listUser}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.handleCancel()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        doctor_assistant_status: state.DoctorAssistantStatusReducer.doctor_assistant_status,
        pagination: state.DoctorAssistantStatusReducer.pagination,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorAssistantStatus: (filter) => dispatch(getDoctorAssistantStatus(filter)),
        getUser: (id, filter) => dispatch(getUser(id, filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAssistantStatusList);
