import React, { Component } from 'react';
import { Divider, Tag, Row, Col, Descriptions } from 'antd'

class CustomerInteractionDetail extends Component {

    render() {
        var { currentItem } = this.props;
        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-3'>
                    <Tag color='#0b2b33'>9</Tag> Tương tác
                </Divider>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Trạng thái">
                                {
                                    currentItem.customer_status_color ? (
                                        <Tag color={currentItem.customer_status_color}>{currentItem ? currentItem.customer_status : null}</Tag>
                                    ) : (
                                        <Tag>{currentItem ? currentItem.customer_status : null}</Tag>
                                    )
                                }

                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Loại tương tác">
                                <>{currentItem ? currentItem.fb_Interactive_type : null}</>
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Trạng thái tương tác">
                                <>{currentItem ? currentItem.fb_Interactive_status : null}</>
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Tên facebook">
                                {currentItem ? currentItem.fb_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Link facebook">
                                {currentItem ? currentItem.fb_link : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Link fanpage">
                                {currentItem ? currentItem.fb_source : null}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </>
        )
    }
}

export default (CustomerInteractionDetail);
