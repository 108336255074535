import api from '../../utils/api';
import {
    UPDATE_SPECIFIC_CUSTOMER_DATA,
    CREATE_NEW_CUSTOMER_DATA
} from './types';
import { NotificationManager } from 'react-notifications';

export const createCustomer = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-data', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_DATA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomer = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-data/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_DATA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}

export const updateCustomerProfile = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-data/update/profile/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_DATA, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        })
    })
}