import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import { ChromePicker } from 'react-color';
// actions
import { createCustomerStatus, updateCustomerStatus } from '../../redux/actions/CustomerStatusActions';
import { _newLine } from '../../utils/helpers';

class CustomerStatusForm extends Component {
    constructor() {
        super();
        this.state = {
            displayColorPicker: false,
            background: '#fff'
        };
    }
    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateCustomerStatus(this.props.currentItem.id, values);
                    this.onClose();
                } else {
                    await this.props.createCustomerStatus(values);
                    //this.onClose();
                    this.props.form.resetFields();
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.props.loadData();
    }

    handleColorPickerClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleColorPickerClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChangeComplete = (data) => {
        this.setState({ background: data.hex });
        this.props.form.setFieldsValue({
            text_color: data.hex
        });
        // console.log("data: ", data)
    }

    render() {
        var { visible, currentItem } = this.props;
        const { getFieldDecorator } = this.props.form;

        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <Modal
                visible={visible}
                title={currentItem ? "Sửa bản ghi" : "Tạo mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentItem ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Tên">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentItem ? currentItem.name : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item>
                                    <Button onClick={this.handleColorPickerClick}>Chọn màu chữ</Button>
                                    {this.state.displayColorPicker ? <div style={popover}>
                                        <div style={cover} onClick={this.handleColorPickerClose} />
                                        <ChromePicker
                                            color={this.state.background}
                                            onChange={this.handleChangeComplete}
                                        />
                                    </div> : null}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item>
                                    {getFieldDecorator('text_color', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.text_color : ''
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Kích hoạt", value: 1 },
                                        { label: "Chưa kích hoạt", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerStatus: (data) => dispatch(createCustomerStatus(data)),
        updateCustomerStatus: (id, data) => dispatch(updateCustomerStatus(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerStatusForm' })(CustomerStatusForm));
