import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Modal, Button, Input, Row, Col } from "antd";
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
import BaseSelect from '../../components/Elements/BaseSelect';
// actions
import {
    createProductWarehouse,
    updateProductWarehouse
} from "../../redux/actions/ProductWarehouseAction";
import { getBranchActive, getSpecificBranch } from '../../redux/actions/BranchManagementActions';

class ProductWarehouseForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            branch_name: null
        };
    }

    componentDidMount() {
        this.props.getBranchActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentItem) {
                        await this.props.updateProductWarehouse(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createProductWarehouse(values);
                        this.props.reloadData();
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
        });
        this.props.onCancel();
    }

    onChangeBranch(id) {
        this.props.getSpecificBranch(id).then(res => {
            this.props.form.setFieldsValue({ address: res?.address })
        })
    }

    render() {
        const { visible, currentItem, branchActiveList } = this.props;
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <Modal
                visible={visible}
                title={
                    currentItem ? "Sửa kho" : "Thêm kho mới"
                }
                width="40%"
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button
                        loading={isLoading}
                        key="submit"
                        type="primary"
                        onClick={(e) => this.submit(e)}
                    >
                        Submit
                    </Button>,
                ]}
            >
                {visible ? (
                    <>

                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} md={24}>

                                    <Form.Item label="Tên kho">
                                        {getFieldDecorator("name", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.name : "",
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Mã kho">
                                        {getFieldDecorator("code", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.code : "",
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Cơ sở">
                                        {getFieldDecorator('branch_management_id', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.branch_management_id : null
                                        })(
                                            <BaseSelect
                                                options={branchActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn cơ sở"
                                                className="w-100"
                                                onChange={(id) => this.onChangeBranch(id)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Địa chỉ">
                                        {getFieldDecorator("address", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.address : "",
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Trạng thái">
                                        {getFieldDecorator('status', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.status : 1
                                        })(
                                            <BaseRadioList
                                                options={[
                                                    { label: "Kích hoạt", value: 1 },
                                                    { label: "Chưa kích hoạt", value: 0 }
                                                ]}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? _newLine(currentItem.note) : ""
                                        })(
                                            <Input.TextArea />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ) : null
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
        branchActiveList: state.BranchManagementReducer.branchActiveList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProductWarehouse: (data) => dispatch(createProductWarehouse(data)),
        updateProductWarehouse: (id, data) => dispatch(updateProductWarehouse(id, data)),
        getBranchActive: () => dispatch(getBranchActive()),
        getSpecificBranch: (id) => dispatch(getSpecificBranch(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductWarehouseForm" })(ProductWarehouseForm));
