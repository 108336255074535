import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';

const { Content } = Layout;

class AppLayout extends Component {

    render() {
        const { location } = this.props;

        // Hide the header only if on 'customer-data' path and on mobile
        const shouldShowHeader = !(isMobile && location.pathname.includes('customer-data'));

        return (
            <Layout>
                {shouldShowHeader ? <AppHeader /> : null}
                <Layout>
                    {isMobile ? null : <AppSidebar />}
                    <Layout style={{ padding: `0 ${isMobile ? '8px' : '15px'} 15px` }}>
                        <Content className="content-wrapper">
                            {this.props.children}
                        </Content>
                        {/* <AppFooter /> */}
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.auth.authUser
});

export default connect(mapStateToProps)(withRouter(AppLayout));
