import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, DatePicker, Divider, Empty, Input, Row, Spin, TreeSelect } from 'antd';
import { Form } from '@ant-design/compatible';
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';

import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';
import { CustomerScheduleType, _newLine, customerGender, isValidDateForm, makeTree } from '../../utils/helpers';
import { createCustomerFromChat, updateCustomerFromChat } from '../../redux/actions/CustomerActions';

class CustomerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            districts: [],
            wards: []
        }
    }

    componentDidMount() {
        this.props.getBranchActive();
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();

        const { data } = this.props;
        if (data) {
            // Sử dụng Promise.all để gọi cả hai hàm cùng lúc
            Promise.all([
                this.props.getListDistricts({ province_code: data.province_code }),
                this.props.getListWards({ district_code: data.district_code })
            ]).then(([districtsRes, wardsRes]) => {
                this.setState({
                    districts: districtsRes.data,
                    wards: wardsRes.data
                });
            });
        }

    }

    async handleSaveCustomer(e) {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { data, newCustomer } = this.props;
                    const {
                        gender, full_name, phone, province_code, district_code, customer_class_id,
                        customer_status_id, fb_Interactive_status_id, fb_Interactive_type_id, note,
                        customer_source_id, branch_management_id, interested_service_id, selected_service_id,
                        appointment_schedule_name, appointment_schedule_time
                    } = values;

                    const customer = {
                        gender,
                        full_name,
                        phone,
                        province_code,
                        district_code,
                        customer_class_id,
                        customer_status_id,
                        fb_Interactive_status_id,
                        fb_Interactive_type_id,
                        note,
                        mode: "from_message",
                        fb_source: `https://www.facebook.com/${newCustomer.page_id}`,
                        fb_link: "https://facebook.com",
                        customer_source_id,
                        branch_management_id,
                        ...newCustomer && {
                            fb_psid: newCustomer.sender_id,
                            fb_name: newCustomer.sender_name,
                            sender_picture: newCustomer.sender_picture,
                            current_thread: newCustomer.thread_id
                        }
                    };

                    const services_interested = { service_id: interested_service_id };
                    const services_selected = { service_id: selected_service_id };
                    const appointment_schedule = {
                        name: appointment_schedule_name,
                        time: appointment_schedule_time ? appointment_schedule_time.format("YYYY-MM-DD HH:mm") : null,
                    };

                    values = {
                        ...values,
                        customer,
                        services_interested,
                        services_selected,
                        appointment_schedule
                    };

                    const fieldsToDelete = [
                        'interested_service_id', 'selected_service_id', 'appointment_schedule_name', 'appointment_schedule_time',
                        'full_name', 'phone', 'province_code', 'district_code', 'customer_class_id', 'customer_status_id',
                        'fb_Interactive_status_id', 'fb_Interactive_type_id', 'note', 'gender', 'branch_management_id', 'customer_source_id'
                    ];

                    fieldsToDelete.forEach(field => delete values[field]);

                    if (data) {
                        customer.id = data.id;
                        customer.code = data.code;
                        appointment_schedule.id = data.appointment_schedule?.id;
                        services_interested.id = data.services_interested?.id;
                        services_selected.id = data.services_selected?.id;

                        await this.props.updateCustomerFromChat(values).then(res => {
                            this.setState({ isLoading: false });
                        });

                    } else {
                        await this.props.createCustomerFromChat(values).then(res => {
                            this.setState({ isLoading: false });
                        });
                    }

                    //console.log(values);

                    this.props.form.resetFields();
                    this.props.callback();

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                //this.props.form.resetFields(['district_code', 'wards_code']);
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                //this.props.form.resetFields(['wards_code']);
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    render() {
        const { districts, isLoading } = this.state;
        var { data, newCustomer, customerStatusActiveList,
            service_active_list,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            branchActiveList,
            customer_source_active_list,
        } = this.props;

        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);

        const customerName = data?.full_name ?? newCustomer?.sender_name ?? null;

        return (
            <div>
                <Row gutter={16} className='pl-3 pr-3 pb-3'>
                    {checkPermission('messenger', 'update_customer') ? (
                        <>
                            <Spin tip="Loading..." spinning={isLoading}>
                                <Form layout='vertical' size='small'>

                                    <Divider orientation="left" orientationMargin="0" className='mt-0 mb-1'>
                                        Thông tin khách hàng
                                    </Divider>

                                    <Row gutter={[5, 5]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator('full_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: customerName
                                                })(<Input placeholder='Tên khách hàng' />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator("gender", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: data?.gender || null
                                                })(
                                                    <BaseSelect
                                                        options={customerGender || []}
                                                        placeholder="Giới tính"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator("phone", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: data?.phone || null
                                                })(
                                                    <Input placeholder='Số điện thoại' />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator('branch_management_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền',
                                                        },
                                                    ],
                                                    initialValue: data?.branch_management_id || null
                                                })(
                                                    <BaseSelect
                                                        options={branchActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        placeholder="Chọn cơ sở"
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item >
                                                {getFieldDecorator("province_code", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.province_code || null
                                                })(
                                                    <BaseSelect
                                                        options={list_provinces || []}
                                                        optionValue="code"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Tỉnh/Thành phố"
                                                        style={{ width: '100%' }}
                                                        onChange={(code) => this.loadDistricts(code)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item >
                                                {getFieldDecorator("district_code", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.district_code || null
                                                })(
                                                    <BaseSelect
                                                        options={districts || []}
                                                        optionValue="code"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Quận/Huyện"
                                                        style={{ width: '100%' }}
                                                        onChange={(code) => this.loadWards(code)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                        Dịch vụ
                                    </Divider>
                                    <Row gutter={[5, 5]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item>
                                                {getFieldDecorator("interested_service_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.services_interested?.service_id || null
                                                })(
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                        treeData={service}
                                                        treeNodeFilterProp='title'
                                                        allowClear
                                                        placeholder="Dịch vụ quan tâm"
                                                        treeDefaultExpandAll
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item>
                                                {getFieldDecorator("selected_service_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.services_selected?.service_id || null
                                                })(
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                        treeData={service}
                                                        treeNodeFilterProp='title'
                                                        allowClear
                                                        placeholder="Dịch vụ chốt"
                                                        treeDefaultExpandAll
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                        Lịch hẹn
                                    </Divider>
                                    <Row gutter={[5, 5]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label='Tên lịch hẹn'>
                                                {getFieldDecorator("appointment_schedule_name", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.appointment_schedule?.name || null
                                                })(
                                                    <BaseSelect
                                                        options={CustomerScheduleType || []}
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Thời gian">
                                                {getFieldDecorator('appointment_schedule_time', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: data ? isValidDateForm(data?.appointment_schedule?.time, false) : null
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                        Tương tác
                                    </Divider>
                                    <Row gutter={[5, 5]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator("customer_status_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.customer_status_id || null
                                                })(
                                                    <BaseSelect
                                                        options={customerStatusActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Trạng thái"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item>
                                                {getFieldDecorator("fb_Interactive_type_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.fb_Interactive_type_id || null
                                                })(
                                                    <BaseSelect
                                                        options={customerInteractiveTypeActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Loại tương tác"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item >
                                                {getFieldDecorator("fb_Interactive_status_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.fb_Interactive_status_id || null
                                                })(
                                                    <BaseSelect
                                                        options={customerInteractiveStatusActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Trạng thái tương tác"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item >
                                                {getFieldDecorator("customer_source_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: data?.customer_source_id || null
                                                })(
                                                    <BaseSelect
                                                        options={customer_source_active_list || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Nguồn khách hàng"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                        Ghi chú
                                    </Divider>
                                    <Row gutter={[5, 5]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item>
                                                {getFieldDecorator('note', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: _newLine(data?.note) || null
                                                })(
                                                    <Input.TextArea rows={4} placeholder='Nội dung tư vấn' />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {
                                        data ? (
                                            <>
                                                {
                                                    checkPermission('customer', 'update_from_chat') ? (
                                                        <Row gutter={16} className="mb-2" justify="end" style={{ margin: 0 }}>
                                                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.handleSaveCustomer(e)}>Cập nhật</Button>
                                                        </Row>
                                                    ) : null
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    checkPermission('customer', 'create_from_chat') ? (
                                                        <Row gutter={16} className="mb-2" justify="end" style={{ margin: 0 }}>
                                                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.handleSaveCustomer(e)}>Lưu</Button>
                                                        </Row>
                                                    ) : null
                                                }
                                            </>
                                        )
                                    }
                                </Form>
                            </Spin>
                        </>
                    ) : (
                        <div className='fix-ant-spin-nested-loading'>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Bạn không có quyền xem nội dung này" />
                        </div>
                    )}
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        branchActiveList: state.BranchManagementReducer.branchActiveList,
        service_active_list: state.ServiceReducer.service_active_list,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBranchActive: () => dispatch(getBranchActive()),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        createCustomerFromChat: (data) => dispatch(createCustomerFromChat(data)),
        updateCustomerFromChat: (data) => dispatch(updateCustomerFromChat(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerForm' })(CustomerForm));