import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Divider, Tag, Button, Row, Col, List, Modal, Image, Space, Card } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { isValidDate } from '../../utils/helpers';
import CustomerServiceAddForm from '../customer-services/CustomerServiceAddForm';
import CustomerServiceEditForm from '../customer-services/CustomerServiceEditForm';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import { getSpecificService, removeService } from '../../redux/actions/CustomerServicesActions';

const { confirm } = Modal;

class CustomerServicesList extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            isOpenAddForm: false,
            currentData: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm, currentData = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentData: currentData,
            currentCustomer: this.props.currentItem
        });
    }

    toggleOpenForm(isOpenAddForm, currentData = null) {
        this.setState({
            isOpenAddForm: isOpenAddForm,
            currentData: currentData,
            currentCustomer: this.props.currentItem
        });
    }

    onEdit(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenEditForm(true, res);
        })
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeService(id).then(() => {
                setTimeout(() => {
                    this.props.reloadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { currentData, isOpenAddForm, isOpenEditForm, currentCustomer } = this.state;
        var { currentItem } = this.props;

        return (
            <div>
                {
                    checkPermission('customer_services', 'list') ? (
                        <>
                            <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                                <Tag color='#0b2b33'>6</Tag> Dịch vụ đã chốt
                            </Divider>
                            <Row justify="end" className='mb-1'>
                                <Button
                                    disabled={!checkPermission('customer_services', 'create')}
                                    type="dashed"
                                    className='color-blue'
                                    size='small'
                                    onClick={() => this.setState({ isOpenAddForm: true })}
                                    icon={<PlusOutlined />}>
                                    Thêm mới
                                </Button>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <List
                                        className='list-in-detail-customer dv-chot'
                                        itemLayout="vertical"
                                        rowKey='id'
                                        size="small"
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        dataSource={currentItem.services_selected || []}
                                        renderItem={(item, index) => {
                                            const IconAction = [];
                                            if (checkPermission('customer_services', 'update')) {
                                                IconAction.push(<a onClick={() => this.onEdit(item.id)} type='link' key="list-vertical-edit"><EditOutlined /> Sửa</a>)
                                            }
                                            if (checkPermission('customer_services', 'remove')) {
                                                IconAction.push(<span className='delete-icon' onClick={() => this.openRemove([item.id])} type='link' key="list-vertical-delete"><DeleteOutlined /> Xóa</span>)
                                            }

                                            return (
                                                <List.Item
                                                    key={index}
                                                    actions={IconAction}
                                                    className='mb-3'
                                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                                >
                                                    <List.Item.Meta
                                                        title={<><MedicineBoxOutlined /> {item.service_name}</>}
                                                        className='mb-0'
                                                    />
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Giá gốc">
                                                                    <NumberFormat value={item.regular_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Giá bán">
                                                                    <NumberFormat value={item.sale_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Số lượng">
                                                                    <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thành tiền">
                                                                    <NumberFormat value={item?.amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ngày chốt">
                                                                    {isValidDate(item?.selected_at, false)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Người tạo">
                                                                    {item?.created_user}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ghi chú">
                                                                    {HtmlParser(item.note)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={12} className='mb-2'>
                                                            <Card size='small' className='border-dashed'>
                                                                <h6 className='h6-title'>Ảnh before</h6>
                                                                {
                                                                    Array.isArray(item.condition_before) && item.condition_before.length ? (
                                                                        <Image.PreviewGroup>
                                                                            <Space wrap>
                                                                                {
                                                                                    item.condition_before.map(item => {
                                                                                        return (
                                                                                            <Image
                                                                                                height={40}
                                                                                                src={item?.src}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Space>
                                                                        </Image.PreviewGroup>
                                                                    ) : null
                                                                }
                                                            </Card>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Card size='small' className='border-dashed'>
                                                                <h6 className='h6-title'>Ảnh after</h6>
                                                                {
                                                                    Array.isArray(item.condition_after) && item.condition_after.length ? (
                                                                        <Image.PreviewGroup>
                                                                            <Space wrap>
                                                                                {
                                                                                    item.condition_after.map(item => {
                                                                                        return (
                                                                                            <Image
                                                                                                height={40}
                                                                                                src={item?.src}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Space>
                                                                        </Image.PreviewGroup>
                                                                    ) : null
                                                                }
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item?.examination_visit && item?.examination_visit.length ? (
                                                            <>
                                                                <h6 className='h6-title'>Lần khám:</h6>
                                                                <Row gutter={16}>
                                                                    {item.examination_visit.map(item2 => {
                                                                        return (
                                                                            <Col xs={24} md={12} className='mb-2'>
                                                                                <Card size="small" className='card-service-detail'>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Thời điểm">
                                                                                                {isValidDate(item2.time)}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Bác sĩ">
                                                                                                {item2.doctor_charge_id}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Phụ tá">
                                                                                                {item2.assistant_doctor_id}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <h6 className="h6-title">Kết quả khám:</h6>
                                                                                        {HtmlParser(item.note)}
                                                                                    </Col>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </Row>
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                        item?.consultant_directly && item?.consultant_directly.length ? (
                                                            <>
                                                                <h6 className='h6-title mt-2'>Nhân viên tư vấn trực tiếp:</h6>
                                                                <Row gutter={16}>
                                                                    {
                                                                        item?.consultant_directly.map(item2 => {
                                                                            return (
                                                                                <Col xs={24} md={12} className='mb-2'>
                                                                                    <Card size="small" className='card-service-detail'>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Nhân viên">
                                                                                                    {item2.consultant_user}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                    {isValidDate(item2.consultant_date)}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <h6 className="h6-title">Kết quả tư vấn:</h6>
                                                                                            {HtmlParser(item2.note)}
                                                                                        </Col>
                                                                                    </Card>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </>) : null
                                                    }
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : null
                }
                <CustomerServiceAddForm
                    visible={isOpenAddForm}
                    currentItem={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CustomerServiceEditForm
                    visible={isOpenEditForm}
                    currentItem={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchManagementReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeService: (ids) => dispatch(removeService(ids)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerServicesList);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerServicesSelected = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CustomerServicesList);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerServicesSelected {...props} ref={ref} />
));
