import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, TreeSelect, Row, Col, InputNumber } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
// actions
import { getServiceActive, createService, updateService } from '../../redux/actions/ServicesActions';
import { _newLine, makeTree } from '../../utils/helpers';

class ServiceForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {
        this.props.getServiceActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateService(this.props.currentItem.id, values);
                    this.onClose();
                } else {
                    await this.props.createService(values);
                    //this.onClose();
                    this.props.reloadData()
                    this.props.form.resetFields();
                }
            }
        });
    }

    onClose() {
        this.props.reloadData()
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        const { } = this.state;
        var { visible, currentItem, service_active_list } = this.props;

        const { getFieldDecorator } = this.props.form;
        const serviceParentTree = makeTree(service_active_list || [], 0);
        return (
            <Modal
                visible={visible}
                title={currentItem ? "Sửa bản ghi" : "Tạo mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentItem ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên dịch vụ">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Mã dịch vụ">
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.code : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Giá dịch vụ">
                                    {getFieldDecorator('price', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.price : ""
                                    })(
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            style={{ width: '100%' }}
                                            step={100000}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Parent">
                                    {getFieldDecorator('parent_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.parent_id : 0
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={serviceParentTree}
                                            showSearch
                                            multiple={false}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn một dịch vụ"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Kích hoạt", value: 1 },
                                        { label: "Chưa kích hoạt", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_active_list: state.ServiceReducer.service_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createService: (data) => dispatch(createService(data)),
        updateService: (id, data) => dispatch(updateService(id, data)),
        getServiceActive: (filter) => dispatch(getServiceActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ServiceForm' })(ServiceForm));
