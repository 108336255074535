import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Button, Input } from "antd";
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';

// actions
import {
    createProductSupplier,
    updateProductSupplier
} from "../../redux/actions/ProductSupplierAction";

class ProductSupplierForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentItem) {
                        await this.props.updateProductSupplier(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createProductSupplier(values);
                        this.props.reloadData();
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
        });
        this.props.onCancel();
    }

    render() {
        const { visible, currentItem } = this.props;
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <Modal
                visible={visible}
                title={
                    currentItem ? "Sửa nhà cung cấp" : "Thêm mới nhà cung cấp"
                }
                onOk={(e) => this.submit(e)}
                width="40%"
                onCancel={() => this.onClose()}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button
                        loading={isLoading}
                        key="submit"
                        type="primary"
                        onClick={(e) => this.submit(e)}
                    >
                        Submit
                    </Button>,
                ]}
            >
                {visible ? (
                    <>
                        <Form layout='vertical'>
                            <Form.Item label="Tên nhà cung cấp">
                                {getFieldDecorator("name", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng điền tên nhà cung cấp!",
                                        },
                                    ],
                                    initialValue: currentItem ? currentItem.name : "",
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            <Form.Item label="Ghi chú">
                                {getFieldDecorator('note', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: currentItem ? _newLine(currentItem.note) : ""
                                })(
                                    <Input.TextArea />
                                )}
                            </Form.Item>
                        </Form>
                    </>
                ) : null}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProductSupplier: (data) => dispatch(createProductSupplier(data)),
        updateProductSupplier: (id, data) => dispatch(updateProductSupplier(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductSupplierForm" })(ProductSupplierForm));
