import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Modal, Button, Input, Row, Col } from "antd";
import BaseRadioList from '../../components/Elements/BaseRadios';
import { createCardType, updateCardType } from "../../redux/actions/CardTypeActions";


class CardTypeForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentItem) {
                        await this.props.updateCardType(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createCardType(values);
                        this.props.reloadData();
                        this.onClose();
                    }
                    console.log('values', values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
        });
        this.props.onCancel();
    }

    render() {
        const { visible, currentItem } = this.props;
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <Modal
                visible={visible}
                title={
                    currentItem ? "Cập nhật bản ghi" : "Thêm bản ghi"
                }
                width="40%"
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button
                        loading={isLoading}
                        key="submit"
                        type="primary"
                        onClick={(e) => this.submit(e)}
                    >
                        Submit
                    </Button>,
                ]}
            >
                {visible ? (
                    <>
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tên">
                                        {getFieldDecorator("name", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.name : null,
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Trạng thái">
                                        {getFieldDecorator('status', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.status : 1
                                        })(
                                            <BaseRadioList
                                                options={[
                                                    { label: "Kích hoạt", value: 1 },
                                                    { label: "Chưa kích hoạt", value: 0 }
                                                ]}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ) : null
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCardType: (data) => dispatch(createCardType(data)),
        updateCardType: (id, data) => dispatch(updateCardType(id, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "CardTypeForm" })(CardTypeForm));
