import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_CLASS,
    GET_SPECIFIC_CUSTOMER_CLASS,
    UPDATE_SPECIFIC_CUSTOMER_CLASS,
    CREATE_NEW_CUSTOMER_CLASS,
    REMOVE_CUSTOMER_CLASS,
    GET_CUSTOMER_CLASS_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerClass = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-class', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CUSTOMER_CLASS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerClass = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-class/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_CLASS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCustomerClassActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-class/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_CUSTOMER_CLASS_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerClass = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-class', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_CLASS, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateCustomerClass = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-class/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_CLASS, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerClass = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-class`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_CLASS, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
