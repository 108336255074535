import {
    GET_LIST_ROLE_GROUP,
    GET_SPECIFIC_ROLE_GROUP,
    UPDATE_SPECIFIC_ROLE_GROUP,
    CREATE_NEW_ROLE_GROUP,
    REMOVE_ROLE_GROUP,
    GET_ROLE_GROUP_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    roleGroupList: [],
    currentRoleGroupList: null,
    roleGroupActiveList: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_ROLE_GROUP: {
            return {
                ...state,
                roleGroupList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_ROLE_GROUP_ACTIVE: {
            return {
                ...state,
                roleGroupActiveList: action.payload.data,
            }
        }
        case GET_SPECIFIC_ROLE_GROUP: {
            return { ...state, currentRoleGroupList: action.payload }
        }
        case CREATE_NEW_ROLE_GROUP: {
            return {
                ...state,
                roleGroupList: [action.payload, ...state.roleGroupList],
                roleGroupActiveList: [action.payload, ...state.roleGroupActiveList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_ROLE_GROUP: {
            let index = state.roleGroupList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.roleGroupList];
            temp[index] = action.payload;
            //
            let index2 = state.roleGroupActiveList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.roleGroupActiveList];
            temp[index2] = action.payload;

            return { ...state, roleGroupList: temp, roleGroupActiveList: temp2 }
        }
        case REMOVE_ROLE_GROUP: {
            let temp = state.roleGroupList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.roleGroupActiveList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, roleGroupList: temp, roleGroupActiveList: temp2 }
        }
        default: return { ...state };
    }
}
