import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Button, DatePicker, Select, Typography, Row, Col } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { cleanObject } from '../../utils/helpers';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
// actions
import { getAllData, getData, removeData, createData, updateData } from '../../redux/actions/ShiftSchedulingActions';


const { Title } = Typography;
const { Option } = Select;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/shift-scheduling',
        breadcrumbName: 'Ca làm việc',
    },
]

const { confirm } = Modal;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {

    state = {
        editing: false
    };

    toggleEdit = () => {
        this.setState(
            prevState => ({
                editing: !prevState.editing
            }),
            () => {
                if (this.state.editing) {
                    this.select.focus();
                }
            }
        );
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, handleChange, config, text } = this.props;

        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }} noStyle>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: false,
                            message: `Required.`
                        }
                    ],
                    initialValue: record[dataIndex]
                })(
                    <Select
                        mode='multiple'
                        className='w-100'
                        placeholder="Chọn 1 hoặc nhiều nhân viên"
                        optionLabelProp="label"
                        allowClear={true}
                        showSearch={true}
                        onChange={(value) => handleChange(value)}
                        onBlur={this.save}
                        ref={node => (this.select = node)}
                    >
                        {
                            config && config?.doctors ? (
                                <>
                                    {
                                        config?.doctors.map((item) => {
                                            return (
                                                <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                            )
                                        })
                                    }
                                </>
                            ) : null
                        }
                    </Select>
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                onClick={this.toggleEdit}
            >
                {text && Array.isArray(text) && text.map(value => <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{value}</p>)}

                {/* {children} */}
            </div>
        );
    };

    render() {
        const {
            editable,
            dataIndex,
            text,
            record,
            index,
            handleSave,
            children,
            config,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                    <>
                        {children}
                    </>
                )}
            </td>
        );
    }
}


class ShiftSchedulingList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        dataSource: [],
        isOpenForm: false,
        start_date: moment().weekday(0).format('YYYY-MM-DD'),
        end_date: moment().weekday(6).format('YYYY-MM-DD')
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllData(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        if (name === 'created_at') {
            if (value) {
                query.start_date = moment(value).startOf('week').format('YYYY-MM-DD');
                query.end_date = moment(value).endOf('week').format('YYYY-MM-DD');
            } else {
                // Tuần hiện tại
                query.start_date = moment().weekday(0).format('YYYY-MM-DD');
                query.end_date = moment().weekday(6).format('YYYY-MM-DD');
            }
        }

        delete query.created_at;
        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeData(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    handleSave = row => {
        console.log(row);
        if (this.isDataChanged(row)) {
            this.props.updateData(row.id, {
                monday: row.monday,
                tuesday: row.tuesday,
                wednesday: row.wednesday,
                thursday: row.thursday,
                friday: row.friday,
                saturday: row.saturday,
                sunday: row.sunday
            }).then(res => {
                this.loadData();
            }).catch(err => {

            });
        } else {
            console.log("Dữ liệu không có thay đổi.");
        }
    };

    isDataChanged = newRow => {
        const currentRow = this.getCurrentRow(newRow.id);
        return (
            newRow.monday !== currentRow.monday ||
            newRow.tuesday !== currentRow.tuesday ||
            newRow.wednesday !== currentRow.wednesday ||
            newRow.thursday !== currentRow.thursday ||
            newRow.friday !== currentRow.friday ||
            newRow.saturday !== currentRow.saturday ||
            newRow.sunday !== currentRow.sunday
        );
    };

    getCurrentRow(currentRowId) {
        const { data_list } = this.props;
        return data_list.find(row => row.id === currentRowId);
    }

    onChangeItem(name, value, itemKey) {
        console.log("onChangeItem: ", name, value, itemKey)
    }

    onCreate(e) {
        e.preventDefault();
        this.props.form.validateFields(['week'], (err, values) => {
            if (!err) {
                try {
                    values.scheduling_date = moment(values.week).startOf('week').format('YYYY-MM-DD');
                    values.start_date = values.scheduling_date;
                    values.end_date = moment(values.week).endOf('week').format('YYYY-MM-DD');

                    confirm({
                        title: 'Cảnh báo',
                        content: 'Bạn chắc chắn muốn thực hiện thao tác này?',
                        okText: 'OK',
                        cancelText: 'Huỷ',
                        onOk: () => this.props.createData(values).then(() => {
                            this.loadData();
                            this.onCancel()
                        }),
                        onCancel() { },
                    })
                } catch (error) {

                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ isOpenForm: false })
    }

    render() {
        var { isOpenForm } = this.state;
        var { data_list, pagination, config } = this.props;
        var {
            isLoading,
            selectedRowKeys,
            start_date,
            end_date
        } = this.state;
        var query = qs.parse(this.props.location.search.slice(1));
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            }
        };

        const columns = [
            {
                title: 'Khu vực chức năng',
                dataIndex: 'area_name',
                key: 'area_name',
                render: (text, record) => {
                    return (
                        <strong>{text}</strong>
                    )
                }
            },
            {
                title: 'Thứ 2',
                dataIndex: 'monday',
                key: 'monday',
                className: new Date().getDay() === 1 ? 'current-day-column' : '',
                editable: true,
                width: "180px",
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "monday",
                    config,
                    text: record.monday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('monday', value, record.id)
                }),
            },
            {
                title: 'Thứ 3',
                dataIndex: 'tuesday',
                key: 'tuesday',
                className: new Date().getDay() === 2 ? 'current-day-column' : '',
                editable: true,
                width: "180px",
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "tuesday",
                    config,
                    text: record.tuesday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('tuesday', value, record.id)
                }),
            },
            {
                title: 'Thứ 4',
                dataIndex: 'wednesday',
                key: 'wednesday',
                className: new Date().getDay() === 3 ? 'current-day-column' : '',
                editable: true,
                width: "180px",
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "wednesday",
                    config,
                    text: record.wednesday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('wednesday', value, record.id)
                }),
            },
            {
                title: 'Thứ 5',
                dataIndex: 'thursday',
                key: 'thursday',
                width: "180px",
                className: new Date().getDay() === 4 ? 'current-day-column' : '',
                editable: true,
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "thursday",
                    config,
                    text: record.thursday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('thursday', value, record.id)
                }),
            },
            {
                title: 'Thứ 6',
                dataIndex: 'friday',
                key: 'friday',
                width: "180px",
                className: new Date().getDay() === 5 ? 'current-day-column' : '',
                editable: true,
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "friday",
                    config,
                    text: record.friday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('friday', value, record.id)
                }),
            },
            {
                title: 'Thứ 7',
                dataIndex: 'saturday',
                key: 'saturday',
                width: "180px",
                className: new Date().getDay() === 6 ? 'current-day-column' : '',
                editable: true,
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "saturday",
                    config,
                    text: record.saturday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('saturday', value, record.id)
                }),
            },
            {
                title: 'Chủ nhật',
                dataIndex: 'sunday',
                key: 'sunday',
                width: "180px",
                className: new Date().getDay() === 0 ? 'current-day-column' : '',
                editable: true,
                onCell: record => ({
                    record,
                    editable: true,
                    dataIndex: "sunday",
                    config,
                    text: record.sunday_user_name,
                    handleSave: this.handleSave,
                    handleChange: (value) => this.onChangeItem('sunday', value, record.id)
                }),
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Ca làm việc" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('shift_scheduling', 'create')}
                        isShowDeleteButton={checkPermission('shift_scheduling', 'remove')}
                        onAdd={() => this.setState({ isOpenForm: true })}
                        addText="Tạo lịch"
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        showSearch={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[]}
                    >
                        <DatePicker.WeekPicker
                            placeholder="Chọn tuần"
                            style={{ width: '200px' }}
                            className="table-button"
                            onChange={(date) => this.onChangeFilter('created_at', date)}
                            defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : start_date ? moment(start_date, 'YYYY-MM-DD') : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    bordered
                    dataSource={data_list ?? []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    components={checkPermission('shift_scheduling', 'update') ? components : null}
                    rowClassName={() => "editable-row"}
                    className='shift-scheduling-list-table'
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                    title={() => {
                        return (
                            <Title level={4} style={{ margin: 0 }}>
                                Tuần {moment(query.start_date || start_date).week()} ({moment(query.start_date || start_date).format('DD/MM/YYYY')} - {moment(query.end_date || end_date).format('DD/MM/YYYY')})
                            </Title>
                        )
                    }}
                />

                <Modal
                    title="Tạo lịch"
                    visible={isOpenForm}
                    header={false}
                    onOk={(e) => this.onCreate(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onCreate(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tuần">
                                    {getFieldDecorator("week", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DatePicker.WeekPicker
                                            placeholder="Chọn tuần"
                                            className='w-100'
                                            // disabledDate={(current) => {
                                            //     let endOfCurrentWeek = moment().endOf('week');
                                            //     return current && current < endOfCurrentWeek;
                                            // }}
                                            disabledDate={(current) => {
                                                let startOfCurrentWeek = moment().startOf('week');
                                                return current && current <= startOfCurrentWeek;
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        data_list: state.ShiftSchedulingReducer.data_list,
        pagination: state.ShiftSchedulingReducer.pagination,
        users: state.config.users,
        config: state.config,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllData: (filter) => dispatch(getAllData(filter)),
        getData: (id) => dispatch(getData(id)),
        removeData: (id) => dispatch(removeData(id)),
        updateData: (id, data) => dispatch(updateData(id, data)),
        createData: (data) => dispatch(createData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ShiftSchedulingList' })(ShiftSchedulingList));
