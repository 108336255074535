import api from '../../utils/api';
import {
    GET_LIST_PRODUCT_STATUS,
    GET_SPECIFIC_PRODUCT_STATUS,
    UPDATE_SPECIFIC_PRODUCT_STATUS,
    CREATE_NEW_PRODUCT_STATUS,
    REMOVE_PRODUCT_STATUS,
    GET_PRODUCT_STATUS_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListProductStatus = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-status', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PRODUCT_STATUS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificProductStatus = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-status/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCT_STATUS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getProductStatusActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-status/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_PRODUCT_STATUS_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createProductStatus = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-status', data).then(res => {
            dispatch({ type: CREATE_NEW_PRODUCT_STATUS, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateProductStatus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-status/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PRODUCT_STATUS, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeProductStatus = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/product-status`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PRODUCT_STATUS, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
