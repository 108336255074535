import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import { Spin, Divider, Card } from 'antd';
import { Form } from "@ant-design/compatible";
import ReactHtmlParser from 'react-html-parser';
import CommentsComponent from '../../components/CommentsComponent';
import { getGuide } from '../../redux/actions/GuideActions';
import { processHTML } from '../../utils/helpers';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/guide',
        breadcrumbName: 'Hướng dẫn sử dụng',
    },
    {
        path: '#',
        breadcrumbName: 'Chi tiết',
    },
]

class GuideDetail extends Component {
    state = {
        isLoading: true
    }

    componentDidMount() {
        this.props.getGuide(this.props.match.params.id).then(() => {
            this.setState({ isLoading: false })
        })
    }

    reloadData() {
        this.props.getGuide(this.props.match.params.id).then(() => {
            this.setState({ isLoading: false, submitting: false })
        })
        this.props.form.resetFields();
    }

    render() {
        var { currentData } = this.props;
        var { isLoading } = this.state;

        if (isLoading || !currentData) {
            return (
                <div className="text-center" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <Spin size="large" />
                </div>
            )
        }

        return (
            <div>
                <PageTitle routes={routes} title={currentData?.title} />
                {
                    currentData ? (
                        <Card className='mt-2'>
                            <div className="view ql-editor training-img chingo-content-detail">
                                {ReactHtmlParser(processHTML(currentData?.content))}
                            </div>
                            <Divider />
                            <CommentsComponent
                                reloadData={() => this.reloadData()}
                                commentsData={currentData.comments}
                                type="guide"
                                parentId={currentData?.id}
                            />
                        </Card>
                    ) : null
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        currentData: state.Guide.currentGuide,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGuide: (id) => dispatch(getGuide(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "GuideDetail" })(GuideDetail));