import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import { Avatar, Button, Divider, Table, Tag, Modal, Row, Col, Select, Input, Tooltip } from 'antd';
import {
    FacebookOutlined,
    EditOutlined,
    UserOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux';
import TableActionBar from '../../components/TableActionBar';
import { checkPermission } from '../../utils/permission';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import qs from 'qs';
import { cleanObject, _newLine, stripHtml } from '../../utils/helpers';
import axios from 'axios';
import appConfig from '../../config';
import FacebookLogin from 'react-facebook-login'; // https://www.npmjs.com/package/react-facebook-login
import { Form } from "@ant-design/compatible";
import { isMobile } from 'react-device-detect';
import BaseRadios from '../../components/Elements/BaseRadios';
import BaseSelect from '../../components/Elements/BaseSelect';

// actions
import { getListPage, insertInfo, updateStatus, getSpecificPage, updatePage } from '../../redux/actions/PageManagerActions';

const { Option } = Select;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/page-list',
        breadcrumbName: 'Danh sách trang',
    },
]

class PageManagerList extends Component {
    formRef = React.createRef();

    state = {
        isLoading: false,
        selectedRowKeys: [],
        openPopupUpdateStatus: false,
        isLoadingUpdateStatus: false,
        visible: false,
        currentData: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListPage(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListPage(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    responseFacebook = async (response) => {
        //console.log(response);
        this.setState({ isLoading: false });
        if (response.status !== 'unknown') {
            try {
                var fb_exchange_token = response.accessToken;
                var user_id = response.id;
                let token_user_url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/oauth/access_token?grant_type=fb_exchange_token&client_id=${appConfig.APP_ID}&client_secret=${appConfig.APP_SECRET}&fb_exchange_token=${fb_exchange_token}`;
                let list_page_in_accounts_url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${user_id}/accounts?access_token=${fb_exchange_token}`;

                // Lấy d/s page của user
                const accounts = await axios.get(list_page_in_accounts_url).then(res => res.data.data).catch(error => console.log(error));
                //console.log("accounts: ", accounts)

                if (accounts) {
                    // Lấy token dài hạn của user 60 days
                    const users = await axios.get(token_user_url).then(res => res.data).catch(error => console.log(error));
                    //console.log('users: ', users)

                    // Lấy token dài hạn của page 60 days
                    var long_lived_user_access_token = users.access_token;
                    const promises = accounts.map(async item => {
                        let page_id = item.id;
                        let token_page_url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${page_id}?fields=access_token,name,id,page_token,picture{url}&access_token=${long_lived_user_access_token}`;
                        const pages = await axios.get(token_page_url).then(res => res.data).catch(error => console.log(error));
                        // subscribed page để nhận các event qua webhook
                        let subscribe_url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${page_id}/subscribed_apps?subscribed_fields=feed,messages,message_deliveries,message_echoes,message_reads&access_token=${pages.access_token}`;
                        let subscribe_data = await axios.post(subscribe_url).then(res => res.data).catch(error => console.log(error));
                        console.log("subscribe_data: ", subscribe_data)
                        return pages
                    })
                    const list_page = await axios.all(promises)
                    //console.log('list_page: ', list_page)
                    const params = {
                        list_page: list_page,
                        user_name: response.name,
                        user_id: response.id,
                        user_access_token: users.access_token,
                        token_expires_in: users.expires_in,
                        user_picture: response.picture.data.url
                    }
                    //console.log(params)
                    await this.props.insertInfo(params);
                    this.reloadData();
                }
            } catch (err) {
                console.log(err.message)
            }
        }
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListPage(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    handleCancel() {
        this.setState({ selectedRowKeys: [], openPopupUpdateStatus: false, isLoadingUpdateStatus: false, visible: false })
        this.props.form.resetFields();
    }

    async onUpdateStatus(e) {
        e.preventDefault();
        this.props.form.validateFields(['status'], async (err, values) => {
            if (!err) {
                this.setState({ isLoadingUpdateStatus: true });
                try {
                    await this.props.updateStatus(this.state.selectedRowKeys, values.status).then(() => {
                        this.handleCancel();
                        setTimeout(() => {
                            Modal.destroyAll();
                        }, 800)
                        this.reloadData()
                    })
                } catch (error) {
                    this.setState({ isLoadingUpdateStatus: false });
                }
            }
        });
    }

    onEdit(id) {
        this.setState({ visible: true })
        this.props.getSpecificPage(id).then(res => {
            this.setState({ currentData: res })
            // console.log('res: ', res)
        })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.state.currentData) {
                    await this.props.updatePage(this.state.currentData.id, values);
                    this.reloadData();
                    this.handleCancel();
                }
            }
        });
    }

    render() {
        var { isLoading, visible, selectedRowKeys, openPopupUpdateStatus, isLoadingUpdateStatus, currentData } = this.state;
        var { pageList, pagination, users } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('page_manager', 'update') ? (
                                <EditOutlined style={{ fontSize: '15px', color: '#0071d8' }} onClick={() => this.onEdit(record.id)} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'page_name',
                key: 'page_name',
                render: (text, record) => {
                    return (
                        <>
                            <Avatar icon={<UserOutlined />} src={record.page_picture} /> &nbsp;
                            <a href={`https://www.facebook.com/${record.page_id}`} target="_blank">
                                {record.page_name}
                            </a>
                        </>
                    )
                }
            },
            {
                title: 'Page ID',
                dataIndex: 'page_id',
                key: 'page_id',
                width: '150px',
                sorter: true
            },
            {
                title: 'Nhân viên quản lý',
                width: '150px',
                dataIndex: 'listUser',
                key: 'listUser',
                render: (text, record) => {
                    if (record.listUser.length > 0) {
                        return (
                            <>
                                {
                                    record.listUser.map(item => {
                                        return (
                                            <Tag>{item.full_name}</Tag>
                                        )
                                    })
                                }
                            </>
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '150px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={2} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text == 1) {
                        return <Tag color='#52c41a'>Đã kích hoạt</Tag>;
                    } else if (text == 0) {
                        return <Tag color='#e74c3c'>Chưa kích hoạt</Tag>;
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Fanpages" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        addText="Thêm trang"
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            checkPermission('page_manager', 'connect_facebook') ? (
                                <>
                                    <FacebookLogin
                                        appId={appConfig.APP_ID}
                                        version="15.0"
                                        autoLoad={false}
                                        fields="name,picture,email"
                                        // scope="email,pages_show_list,pages_messaging,pages_read_engagement,pages_manage_metadata,pages_manage_posts,pages_manage_engagement,pages_read_user_content"
                                        scope="email,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,pages_manage_engagement,pages_read_user_content"
                                        cssClass="ant-btn table-button ant-btn-primary my-facebook-button-class"
                                        isDisabled={!checkPermission('page_manager', 'connect_facebook')}
                                        icon={<FacebookOutlined />}
                                        textButton={<span>Kết nối facebook</span>}
                                        onClick={() => this.setState({ isLoading: true })}
                                        callback={this.responseFacebook} />
                                </>
                            ) : null
                        }
                        <BaseSelect
                            options={users || []}
                            optionValue="user_id"
                            optionLabel="full_name"
                            showSearch
                            defaultText="Nhận viên quản lý"
                            placeholder="Nhận viên quản lý"
                            style={{ width: '200px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('staff_id', value)}
                            defaultValue={query.staff_id ? parseInt(query.staff_id) : null}
                        />
                        {
                            checkPermission('page_manager', 'update_status') ? (
                                <Button className="table-button" onClick={() => this.setState({ openPopupUpdateStatus: true })} disabled={!selectedRowKeys.length ? true : false} type='primary'>Cập nhật trạng thái</Button>
                            ) : null
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="page_id"
                    size="small"
                    tableLayout='auto'
                    dataSource={pageList}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <Modal
                    width={isMobile ? '100%' : '30%'}
                    title="Kích hoạt trang"
                    visible={openPopupUpdateStatus}
                    onOk={(e) => this.onUpdateStatus(e)}
                    okText="Kích hoạt"
                    confirmLoading={isLoadingUpdateStatus}
                    onCancel={() => this.handleCancel()}
                    maskClosable={false}
                >
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label='Trạng thái'>
                                    {getFieldDecorator("status", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseRadios
                                            options={
                                                [
                                                    { value: 1, label: "Kích hoạt" },
                                                    { value: 0, label: "Hủy kích hoạt" }
                                                ]
                                            }
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal
                    visible={visible}
                    title="Cập nhật thông tin page"
                    onOk={(e) => this.submit(e)}
                    onCancel={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '40%'}
                    footer={[
                        <Button key="back" onClick={() => this.handleCancel()}>
                            Huỷ
                        </Button>,
                        <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            Cập nhật
                        </Button>,
                    ]}
                >
                    {visible ?
                        <Form layout='vertical'>
                            <Form.Item label="Trạng thái">
                                {getFieldDecorator('status', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: currentData ? currentData.status : 1
                                })(
                                    <BaseRadios
                                        options={[
                                            { label: "Kích hoạt", value: 1 },
                                            { label: "Hủy kích hoạt", value: 0 }
                                        ]}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Nhân viên quản lý">
                                {getFieldDecorator('user_ids', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: currentData ? currentData.currentUser : ''
                                })(
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn nhân viên quản lý"
                                        optionLabelProp="label"
                                        allowClear={true}
                                        showSearch={true}
                                    >
                                        {
                                            users.map((item) => {
                                                return (
                                                    <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Ghi chú">
                                {getFieldDecorator('note', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: currentData ? _newLine(currentData.note) : ''
                                })(
                                    <Input.TextArea rows={3} />
                                )}
                            </Form.Item>
                        </Form>
                        : null}
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        pageList: state.PageManagerReducer.pageList,
        pagination: state.PageManagerReducer.pagination,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListPage: (filter) => dispatch(getListPage(filter)),
        insertInfo: (data) => dispatch(insertInfo(data)),
        updateStatus: (page_ids, status) => dispatch(updateStatus(page_ids, status)),
        getSpecificPage: (id) => dispatch(getSpecificPage(id)),
        updatePage: (id, data) => dispatch(updatePage(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "PageManagerList" })(PageManagerList));