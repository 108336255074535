import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Card, Typography, Button, Descriptions, Modal, Spin } from 'antd'

import { getScheduleByCustomer, getSpecificCustomerSchedule, removeCustomerSchedule } from '../../redux/actions/CustomerScheduleActions';
import { ClockCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { ConvertCustomerScheduleStatus, ConvertCustomerScheduleType, isValidDate } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import moment from 'moment';
import CustomerScheduleForm from './CustomerScheduleForm';

const { Text } = Typography;
const { confirm } = Modal;

class ScheduleCustomerDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null,
            currentData: null,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    openForm() {
        this.setState({ currentCustomer: this.props.currentCustomer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false, currentData: null })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.getScheduleByCustomer(this.props.currentCustomer?.id).then(res => {
            this.setState({ currentItem: res, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerSchedule(id).then(() => {
                setTimeout(() => {
                    this.loadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onEdit(id) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({
                currentCustomer: this.props.currentCustomer,
                currentData: res,
                isOpenForm: true
            })
        })
    }

    render() {
        var { currentItem, currentData, isOpenForm, currentCustomer, isLoading } = this.state;

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                    <Tag color='#0b2b33'>3</Tag> Lịch hẹn
                </Divider>
                <Row justify="end" className='mb-1'>
                    <Button
                        disabled={!checkPermission('customer_schedule', 'create')}
                        type='dashed' size='small'
                        onClick={() => this.openForm()}
                        className='color-blue'
                        icon={<PlusOutlined />}>
                        Thêm mới
                    </Button>
                </Row>
                {
                    currentItem && currentItem?.length ? (
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16} className='mb-3 row-customer-appointment-schedule'>
                                {
                                    currentItem?.map(item => {
                                        var diffDate = item.time ? moment(item.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                                        return (
                                            <Col xs={24} md={12} className='mb-3'>
                                                <Card size="small"
                                                    title={<>{<ClockCircleOutlined />} {ConvertCustomerScheduleType(item.name)}</>}
                                                    extra={
                                                        <>
                                                            {checkPermission('customer_schedule', 'update') ? (
                                                                <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            {checkPermission('customer_schedule', 'remove') ? (
                                                                <span className='delete-icon' onClick={() => this.openRemove([item.id])} type="link" key="list-vertical-delete">
                                                                    <DeleteOutlined /> Xóa
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Thời gian">
                                                                {isValidDate(item.time)} -&nbsp;
                                                                {
                                                                    diffDate ? (
                                                                        <span>
                                                                            {
                                                                                diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                item.time != null ? (
                                                                                    <Tag color="#096dd9">Hôm nay</Tag>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Ngày tạo">
                                                                {isValidDate(item.created_at)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Người tạo">
                                                                {item.created_user}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Bác sĩ thực hiện">
                                                                {item.doctors}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Trạng thái">
                                                                {ConvertCustomerScheduleStatus(item.status)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label={`Thời điểm ${item.name == 'GOILAI' ? 'gọi' : 'đến'}`}>
                                                                {isValidDate(item?.time_to)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Text strong>Ghi chú: </Text>
                                                        {HtmlParser(item.note)}
                                                    </Col>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Spin>
                    ) : <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                }

                <CustomerScheduleForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    currentItem={currentData}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getScheduleByCustomer: (customer_id) => dispatch(getScheduleByCustomer(customer_id)),
        getSpecificCustomerSchedule: (id) => dispatch(getSpecificCustomerSchedule(id)),
        removeCustomerSchedule: (id) => dispatch(removeCustomerSchedule(id))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCustomerDetail);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedScheduleCustomerDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ScheduleCustomerDetail);

export default React.forwardRef((props, ref) => (
    <ConnectedScheduleCustomerDetail {...props} ref={ref} />
));
