import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Modal, Tooltip, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import BranchManagementForm from './BranchManagementForm';
import BranchManagementDetail from './BranchManagementDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
// actions
import {
    getListBranch,
    getSpecificBranch,
    removeBranch
} from '../../redux/actions/BranchManagementActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/branch-management',
        breadcrumbName: 'Danh sách cơ sở',
    },
]

class BranchManagementList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        currentData: null,
        isOpenDetail: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListBranch(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListBranch(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificBranch(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeBranch(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeBranch(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificBranch(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    render() {
        var { branchList, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, currentData, isOpenDetail, current_id } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: '#',
                width: '75px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('branch_management', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('branch_management', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Mã cơ sở',
                dataIndex: 'code',
                key: 'code',
                width: '100px',
                sorter: true
            },
            {
                title: 'Tên cơ sở',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    if (checkPermission('branch_management', 'detail')) {
                        return (
                            <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                {text}
                            </div>
                        )
                    } else {
                        return (<>{text}</>)
                    }
                }
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: 'Tỉnh/Thành phố',
                dataIndex: 'province_name',
                key: 'province_name'
            },
            {
                title: 'Người quản lý',
                dataIndex: 'management_user',
                key: 'management_user'
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone_number',
                key: 'phone_number'
            },
            {
                title: 'Ngày thành lập',
                dataIndex: 'founding_date',
                key: 'founding_date'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách cơ sở" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('branch_management', 'create')}
                        isShowDeleteButton={checkPermission('branch_management', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={branchList || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <BranchManagementForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <BranchManagementDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        branchList: state.BranchManagementReducer.branchList,
        pagination: state.BranchManagementReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListBranch: (filter) => dispatch(getListBranch(filter)),
        getSpecificBranch: (id) => dispatch(getSpecificBranch(id)),
        removeBranch: (ids) => dispatch(removeBranch(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchManagementList);
