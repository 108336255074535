import api from '../../utils/api';
import {
    GET_LIST_PRODUCT_GROUP,
    GET_SPECIFIC_PRODUCT_GROUP,
    UPDATE_SPECIFIC_PRODUCT_GROUP,
    CREATE_NEW_PRODUCT_GROUP,
    REMOVE_PRODUCT_GROUP,
    GET_PRODUCT_GROUP_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListProductGroup = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-group', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PRODUCT_GROUP, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getProductGroupActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-group/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_PRODUCT_GROUP_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificProductGroup = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-group/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCT_GROUP, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createProductGroup = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-group', data).then(res => {
            dispatch({ type: CREATE_NEW_PRODUCT_GROUP, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateProductGroup = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-group/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PRODUCT_GROUP, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeProductGroup = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/product-group`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PRODUCT_GROUP, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
