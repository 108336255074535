import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, Spin, Descriptions, Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { UserOutlined } from '@ant-design/icons';
// actions
import { assignPatient } from '../../redux/actions/DoctorAssistantStatusActions';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';


class DoctorAssistantStatusForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customerQuery: ''
        };
    }
    componentDidMount() {
        this.props.getListCustomerActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.list_user = this.props.listUser;
                    this.props.assignPatient(values).then((res) => {
                        this.onClose();
                    }).catch(error => {
                        this.setState({ isLoading: false });
                    });
                    console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false });
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleCustomerScroll(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    render() {
        var { isLoading } = this.state;
        var {
            visible,
            listUser,
            customerActiveList
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        //console.log(listUser)

        return (
            <Drawer
                visible={visible}
                title={`Giao bệnh nhân`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16}>
                                {
                                    listUser && listUser.length ? (
                                        <>
                                            {
                                                listUser.map(user => {
                                                    return (
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label={<span><UserOutlined /> {user.role_name}</span>}>
                                                                    {user.full_name}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </>
                                    ) : null
                                }
                                <Col xs={24} md={24} className='mt-4'>
                                    <Form.Item label={<>Chọn bệnh nhân </>}>
                                        {getFieldDecorator("customer_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <BaseSelect
                                                options={customerActiveList || []}
                                                optionValue="id"
                                                optionLabel="full_name"
                                                additionalLabel="code"
                                                showSearch
                                                placeholder="Nhập tên hoặc mã bệnh nhân"
                                                onSearch={(val) => this.onSearchCustomer(val)}
                                                onScrollEnd={(e) => this.handleCustomerScroll(e)}
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label={`Ghi chú`}>
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input.TextArea rows={10} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        assignPatient: (data) => dispatch(assignPatient(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'DoctorAssistantStatusForm' })(DoctorAssistantStatusForm));
