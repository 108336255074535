import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
    state = {

    }

    async componentDidMount() {

    }

    render() {
        return (<></>)
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);