import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Drawer, Descriptions, Divider, Comment, Row, Col, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate, processHTML } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';

import CommentsComponent from '../../components/CommentsComponent';
import WorkReportForm from './WorkReportForm';
import NumberFormat from 'react-number-format';

class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenFormEdit: false,
        };
    }

    reloadData() {
        this.props.reloadData();
        this.setState({ isLoading: false, submitting: false })
        this.props.form.resetFields();
    }

    handleCancel() {
        this.setState({ isOpenFormEdit: false });
        this.props.onCancel();
    }

    render() {
        const { isOpenFormEdit } = this.state;
        var { visible, currentData } = this.props;

        const singleFields = ['end_day_revenue', 'afternoon_cleaning', 'evening_guest_area_cleaning', 'evening_service_area_cleaning'];

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Chi tiết báo cáo"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '70%'}
                    extra={
                        <div style={{ display: 'flex' }}>
                            {checkPermission('work_report', 'update') ? (
                                <Button type="primary" onClick={() => this.setState({ isOpenFormEdit: true })} icon={<EditOutlined />}>
                                    Sửa
                                </Button>
                            ) : null}
                        </div>
                    }
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Button key="back" onClick={() => this.props.onCancel()}>
                                Huỷ
                            </Button>
                        </div>
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Bào cáo ngày">
                                            {isValidDate(currentData?.report_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhân viên">
                                            {currentData?.user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {ReactHtmlParser(currentData?.note)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>

                            <Divider orientation="left" orientationMargin="0" className='mb-3'>
                                Nội dung báo cáo
                            </Divider>

                            {currentData?.AttributeValues && Object.entries(currentData?.AttributeValues).map(([entityType, attributes]) => (
                                <div key={entityType}>
                                    <div className='mb-2' style={{ fontWeight: 500 }}>
                                        {attributes[0]?.entity_name}
                                    </div>
                                    {attributes.map(attribute => (
                                        <div key={attribute.id}>
                                            {Array.isArray(attribute.value) ? (
                                                <ul style={{ listStyle: 'auto' }}>
                                                    {attribute.value.map((item, index) => (
                                                        <li style={{ marginBottom: 5 }} key={index}>{item.name} {item.customer_code && `(${item.customer_code})`} {item.code && ` - ${item.code}`}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div style={{ marginLeft: 25 }}>
                                                    {attribute.name == 'type' ? (
                                                        <div><span style={{ fontWeight: 500 }}>Hình thức:</span> <Tag style={{ border: 'none' }} color={attribute.value == 'Làm chính' ? 'green' : 'yellow'}>{attribute.value}</Tag></div>
                                                    ) : (
                                                        {
                                                            ...singleFields.includes(attribute.name) ? (
                                                                <div><span style={{ fontWeight: 500 }}>Mô tả:</span> {attribute.value}</div>
                                                            ) : (
                                                                <div><span style={{ fontWeight: 500 }}>Ghi chú:</span> {attribute.value}</div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <Divider className="no-margin mb-3 mt-3" />
                                </div>
                            ))}
                            {currentData?.content && <Comment
                                author={<span className='ant-descriptions-item-label'>Nội dung báo cáo Khác</span>
                                }
                                content={<div className='chingo-content-detail'>{ReactHtmlParser(processHTML(currentData?.content))}</div>}
                            />}
                            <CommentsComponent
                                reloadData={() => this.reloadData()}
                                commentsData={currentData.comments}
                                type="work_report"
                                parentId={currentData?.id}
                            />
                        </div>
                    ) : null}
                </Drawer>

                <WorkReportForm
                    visible={isOpenFormEdit}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenFormEdit: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));
