import {
    GET_LIST_CUSTOMER_PROFILE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    list: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER_PROFILE: {
            return {
                ...state,
                list: action.payload.data
            }
        }
        default: return { ...state };
    }
}
