import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, InputNumber, DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { _newLine, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import ListCurrentImage from '../../components/ListCurrentImage';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import { createCard, updateCard } from '../../redux/actions/CardReleaseTrackingActions';
import { getCardTypeActive } from '../../redux/actions/CardTypeActions';
import moment from 'moment';

class CardReleaseTrackingForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            images: [],
            currentImages: [],
            isResetUploadImage: true
        };
    }
    componentDidMount() {
        this.props.getCardTypeActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { currentImages, images } = this.state;

                    values.images = customDataImageUpload(images, currentImages);
                    values.release_date = values.release_date ? values.release_date.format("YYYY-MM-DD") : null;

                    if (this.props.currentItem) {
                        await this.props.updateCard(this.props.currentItem.id, values).then((res) => {
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    } else {
                        await this.props.createCard(values).then((res) => {
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    }
                    console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, isResetUploadImage: true, images: [] });
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        var { isLoading, isResetUploadImage, currentImages } = this.state;
        var {
            visible,
            currentItem,
            card_type_active_list,
            users
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentItem ? 'Cập nhật bản ghi' : `Thêm bản ghi`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? 'Cập nhật' : "Thêm mới"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Nhân viên">
                                    {getFieldDecorator('staff_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.staff_id : null
                                    })(
                                        <BaseSelect
                                            options={users ?? []}
                                            showSearch
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Chọn nhân viên"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại thẻ">
                                    {getFieldDecorator('card_type_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.card_type_id : null
                                    })(
                                        <BaseSelect
                                            options={card_type_active_list ?? []}
                                            showSearch
                                            optionValue="id"
                                            optionLabel="name"
                                            placeholder="Loại thẻ"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Số lượng thẻ được cấp">
                                    {getFieldDecorator('quantity', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.quantity : null
                                    })(<InputNumber className='w-100' />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày cấp">
                                    {getFieldDecorator('release_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: currentItem ? isValidDateForm(currentItem.release_date, false) : moment()
                                    })(
                                        <DatePicker
                                            className='w-100'
                                            format="DD/MM/YYYY"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Mã thẻ được cấp">
                                    {getFieldDecorator('card_number', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.card_number : null
                                    })(<Input.TextArea rows={3} />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : null
                                    })(
                                        <Input.TextArea rows={3} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ảnh" className='flex-upload border-dashed p-2'>
                                    <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                    {getFieldDecorator('images', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={30}
                                            onChangeData={this.onChangeImage}
                                            isResetUpload={isResetUploadImage}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        card_type_active_list: state.CardTypeReducer.card_type_active_list,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCard: (data) => dispatch(createCard(data)),
        updateCard: (id, data) => dispatch(updateCard(id, data)),
        getCardTypeActive: () => dispatch(getCardTypeActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CardReleaseTrackingForm' })(CardReleaseTrackingForm));
