import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Row, Col, Space, Tag, Typography, Alert, Image, Table, Button } from 'antd'
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate, repeatPhoneNumber } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import NumberFormat from 'react-number-format';
import { EditOutlined } from '@ant-design/icons';
import CustomerConsultantDirectForm from './CustomerConsultantDirectForm';

const { Paragraph } = Typography;

class CustomerConsultantDirectDetail extends Component {

    constructor() {
        super();
        this.state = {
            isOpenEditForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Dịch vụ",
                dataIndex: "service_name"
            },
            {
                title: "Giá",
                dataIndex: "price",
                width: "150px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Nhân viên tư vấn",
                dataIndex: "consultant_users"
            },
        ];

        var total_amount = 0;
        if (currentItem) {
            currentItem.all_service_provideds.forEach(item => {
                total_amount += item.price;
            });
        }

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết lịch tư vấn`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '55%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('customer_consultant_direct', 'update') ? (
                                    <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                        </Space>
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên khách hàng">
                                            {currentItem ? currentItem.customer_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã khách hàng">
                                            <Paragraph style={{ margin: 0 }} copyable={
                                                {
                                                    text: currentItem ? currentItem.customer_code : null,
                                                    tooltips: false
                                                }
                                            }>
                                                <Tag color='#1890ff'>{currentItem ? currentItem.customer_code : null}</Tag>
                                            </Paragraph>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số điện thoại">
                                            {currentItem ? (
                                                <HandlePhoneNumber
                                                    permission={checkPermission('customer', 'phonenumber')}
                                                    inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                    customer_id={currentItem?.customer_id}
                                                    hiddenPhoneNumber={repeatPhoneNumber(currentItem?.customer_phone)}
                                                    fullPhoneNumber={currentItem?.customer_phone}
                                                />
                                            ) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái khách hàng">
                                            <Tag color={currentItem?.customer_status_color}>{currentItem?.customer_status}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nguồn khách hàng">
                                            {currentItem ? currentItem.customer_source : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cơ sở">
                                            {currentItem ? currentItem.branch_management : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={24} className='mt-2 mb-2'>
                                    <Alert
                                        message={<span style={{ fontWeight: '600' }}>Ghi chú khách hàng</span>}
                                        description={currentItem ? ReactHtmlParser(currentItem.customer_note) : null}
                                        type="info"
                                        className='fix-alert-element'
                                    />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái tư vấn">
                                            {currentItem?.status == 1 ? (
                                                <Tag color='green'>Đã trả kết quả</Tag>
                                            ) : null}
                                            {currentItem?.status == 0 ? (
                                                <Tag color='#f5b22d'>Chưa trả kết quả</Tag>
                                            ) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời điểm tư vấn">
                                            {isValidDate(currentItem?.time_consultant, true)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nhân viên tư vấn">
                                            {
                                                Array.isArray(currentItem?.consultant_direct_staff) && currentItem?.consultant_direct_staff.length ? (
                                                    <>
                                                        {currentItem?.consultant_direct_staff.map(item => {
                                                            return (
                                                                <Tag>{item.staff_consultant_direct}</Tag>
                                                            )
                                                        })}
                                                    </>
                                                ) : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Table
                                        className='mt-3'
                                        size='small'
                                        bordered
                                        rowKey='key'
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        tableLayout="auto"
                                        columns={columns}
                                        dataSource={currentItem?.all_service_provideds || []}
                                        pagination={false}
                                        scroll={{
                                            x: 'max-content'
                                        }}
                                        title={() => {
                                            return (
                                                <strong>Dịch vụ chốt</strong>
                                            )
                                        }}
                                        footer={() => {
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600' }}>
                                                    <div style={{ marginRight: '10px' }}>Tổng giá trị:</div>
                                                    <div>
                                                        <NumberFormat value={total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </Col>
                                <Col xs={24} md={24} className='mt-3 mb-2'>
                                    <Alert
                                        message={<span style={{ fontWeight: '600' }}>Kết quả tư vấn</span>}
                                        description={
                                            <>
                                                {currentItem ? ReactHtmlParser(currentItem.result_content) : null}

                                                {Array.isArray(currentItem?.images) && currentItem?.images.length ? (
                                                    <>
                                                        <Divider style={{ margin: '8px 0' }} orientation="left" orientationMargin={0}>Ảnh</Divider>
                                                        <Image.PreviewGroup>
                                                            <Space>
                                                                {
                                                                    currentItem?.images.map(item => {
                                                                        return (
                                                                            <Image
                                                                                height={50}
                                                                                src={item.src}
                                                                                style={{ border: '1px solid #dedede' }}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </Space>
                                                        </Image.PreviewGroup>
                                                    </>
                                                ) : null}
                                            </>
                                        }
                                        type="info"
                                        className='fix-alert-element'
                                    />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người trả kết quả">
                                            {currentItem.user_update_result}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentItem.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem ? currentItem.created_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </Drawer>

                <CustomerConsultantDirectForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerConsultantDirectDetail' })(CustomerConsultantDirectDetail));
