import api from '../../utils/api';
import {
    GET_ALL_PRODUCT_WAREHOUSE,
    GET_PRODUCT_WAREHOUSE,
    CREATE_PRODUCT_WAREHOUSE,
    UPDATE_PRODUCT_WAREHOUSE,
    REMOVE_PRODUCT_WAREHOUSE,
    GET_PRODUCT_WAREHOUSE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllProductWarehouse = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/product-warehouse', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_PRODUCT_WAREHOUSE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getProductWarehouseActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-warehouse/by-active', { params: filter }).then(res => {
            dispatch({ type: GET_PRODUCT_WAREHOUSE_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getOneProductWarehouse = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-warehouse/${id}`).then(res => {
            dispatch({
                type: GET_PRODUCT_WAREHOUSE,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createProductWarehouse = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-warehouse', data).then(res => {
            dispatch({
                type: CREATE_PRODUCT_WAREHOUSE,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateProductWarehouse = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-warehouse/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_PRODUCT_WAREHOUSE,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeProductWarehouse = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/product-warehouse', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_PRODUCT_WAREHOUSE,
                payload: ids
            });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
