import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, Divider, Button } from 'antd';
import qs from 'qs';
import { cleanObject } from '../utils/helpers';

const { Option } = Select;

class FilterableMultiSelect extends Component {
    state = {
        selectedValues: []
    };

    handleSelectAll = () => {
        const { options, parameterName, optionValue } = this.props;
        const allValues = options.map(item => item[optionValue]);
        this.handleOnChange(parameterName, allValues);
        this.setState({ selectedValues: allValues });
    }

    handleDeselect = (deselectedValue) => {
        const updatedValues = this.state.selectedValues.filter(value => value !== deselectedValue);
        this.setState({ selectedValues: updatedValues });
        this.handleOnChange(this.props.parameterName, updatedValues);
    }

    handleOnChange = (name, value) => {
        var queryValue = qs.parse(this.props.location.search.slice(1));
        queryValue[name] = value;

        if (name === this.props.parameterName) {
            if (value && value.length > 0) {
                let string = value.join(',');
                queryValue[name] = string;
            } else {
                delete queryValue[name];
            }
        }

        queryValue = cleanObject(queryValue);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(queryValue)
        });
    }

    handleOnClear = () => {
        const { parameterName } = this.props;
        this.setState({ selectedValues: [] }, () => {
            this.handleOnChange(parameterName, []);
        });
    }

    static defaultProps = {
        optionValue: "value",
        optionLabel: "label"
    };

    render() {
        const { options, placeholder, parameterName, optionValue, optionLabel } = this.props;
        const { selectedValues } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));
        const queryValue = query[parameterName];

        return (
            <Select
                className="w-100"
                placeholder={placeholder}
                mode="multiple"
                allowClear
                showArrow
                showSearch
                onClear={this.handleOnClear}
                value={selectedValues.length > 0 ? selectedValues : queryValue ? queryValue.split(',').map(Number) : []}
                onDeselect={this.handleDeselect}
                onChange={(value) => {
                    this.setState({ selectedValues: value }, () => {
                        if (value.length === 0) {
                            this.handleOnClear();
                        } else {
                            this.handleOnChange(parameterName, value);
                        }
                    });
                }}
                defaultValue={queryValue ? queryValue.split(',').map(Number) : []}
                onSelect={(value) => this.setState({ selectedValues: [...selectedValues, value] })}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div style={{ padding: '2px 8px 8px 8px' }}>
                            <Button className='w-100' type="dashed" onClick={this.handleSelectAll}>
                                Chọn tất cả
                            </Button>
                        </div>
                    </>
                )}
            >
                {options.map((item, index) => (
                    <Option key={`${item[optionValue]}_${index}`} value={item[optionValue]}>
                        {item[optionLabel]}
                    </Option>
                ))}
            </Select>
        );
    }
}

export default withRouter(FilterableMultiSelect);
