import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import CustomerPotentialFamilyList from './CustomerPotentialFamilyList';
import moment from 'moment';
// actions
import { updateCustomerPotential, createCustomerPotential } from '../../redux/actions/CustomerPotentialActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { _newLine, potentialProfileStatus } from '../../utils/helpers';

class CustomerPotentialForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customer_family: [],
            currentCustomerFamily: [],
            districts: [],
            wards: []
        };
    }

    componentDidMount() {
        this.props.getListProvinces();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.customer_family) {
                this.setState({ currentCustomerFamily: nextProps?.currentItem?.customer_family })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentItem != this.props.currentItem) {
            if (this.props.currentItem) {
                this.props.getListDistricts({ province_code: this.props.currentItem.province_code }).then(res => {
                    this.setState({ districts: res.data });
                })
                this.props.getListWards({ district_code: this.props.currentItem.district_code }).then(res2 => {
                    this.setState({ wards: res2.data })
                })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { customer_family } = this.state;
                    values.customer_family = customer_family;
                    values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                    if (this.props.currentItem) {
                        await this.props.updateCustomerPotential(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        await this.props.createCustomerPotential(values);
                        this.onClose();
                    }
                    console.log(values)
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false });
    }

    getItems = data => {
        this.setState({ customer_family: data })
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    render() {
        var { isLoading, currentCustomerFamily, districts, wards } = this.state;
        var { visible, currentItem, list_provinces, customerStatusActiveList } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? `Sửa khách hàng: ${currentItem?.full_name}` : "Thêm khách hàng"}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16} className='mt-4'>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tên khách hàng">
                                    {getFieldDecorator('full_name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.full_name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Giới tính">
                                    {getFieldDecorator("gender", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.gender : null
                                    })(
                                        <BaseSelect
                                            options={[
                                                { label: "Nam", value: 'Nam' },
                                                { label: "Nữ", value: 'Nữ' },
                                                { label: "Khác", value: 'Khác' }
                                            ]}
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Số điện thoại">
                                    {getFieldDecorator("phone", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.phone : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Mã số thẻ cũ">
                                    {getFieldDecorator("card_number", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.card_number : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={8}>
                                <Form.Item label="Mã số thẻ mới">
                                    {getFieldDecorator("card_number_new", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.card_number_new : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> */}
                            <Col xs={24} md={8}>
                                <Form.Item label="Năm sinh">
                                    {getFieldDecorator('birthday', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem && moment(currentItem.birthday).isValid() ? moment(currentItem.birthday, 'YYYY') : null
                                    })(
                                        <DatePicker
                                            picker="year"
                                            style={{ width: '100%' }}
                                            placeholder="Chọn"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tỉnh/Thành phố">
                                    {getFieldDecorator("province_code", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.province_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={list_provinces || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(code) => this.loadDistricts(code)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Quận/Huyện">
                                    {getFieldDecorator("district_code", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.district_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={districts || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(code) => this.loadWards(code)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Phường/Xã">
                                    {getFieldDecorator("wards_code", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.wards_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={wards || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tổ">
                                    {getFieldDecorator("residential_group", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.residential_group : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Địa chỉ">
                                    {getFieldDecorator('address', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.address : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label='Trạng thái'>
                                    {getFieldDecorator("customer_status_id", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.customer_status_id : null,
                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                    })(
                                        <BaseSelect
                                            options={customerStatusActiveList || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label='Hồ sơ'>
                                    {getFieldDecorator("profile", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.profile : null,
                                    })(
                                        <BaseSelect
                                            options={potentialProfileStatus || []}
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Col xs={24} md={24}>
                            <Form.Item label="Ghi chú">
                                {getFieldDecorator('note', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: currentItem ? _newLine(currentItem.note) : null
                                })(
                                    <Input.TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Col>
                        <CustomerPotentialFamilyList currentCustomerFamily={currentCustomerFamily} getItems={(data) => this.getItems(data)} />
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        list_provinces: state.RegionsReducer.list_provinces,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomerPotential: (id, data) => dispatch(updateCustomerPotential(id, data)),
        createCustomerPotential: (data) => dispatch(createCustomerPotential(data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerPotentialForm' })(CustomerPotentialForm));
