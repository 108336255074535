import {
    GET_ALL_SHIFT_SCHEDULING,
    GET_SPECIFIC_SHIFT_SCHEDULING,
    REMOVE_SHIFT_SCHEDULING
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    data_list: [],
    currentData: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SHIFT_SCHEDULING: {
            return {
                ...state,
                data_list: action.payload.result.data,
                pagination: {
                    currentPage: parseInt(action.payload.result.current_page),
                    total: parseInt(action.payload.result.total),
                    perPage: parseInt(action.payload.result.per_page),
                }
            }
        }
        case GET_SPECIFIC_SHIFT_SCHEDULING: {
            return { ...state, currentData: action.payload }
        }
        case REMOVE_SHIFT_SCHEDULING: {
            let temp = state.data_list.filter(data => {
                return action.payload.indexOf(data.id) < 0;
            });
            return { ...state, data_list: temp }
        }
        default: return { ...state };
    }
}
