import api from '../../utils/api';
import {
    GET_ALL_PRODUCT_RECEIPT_IMPORT,
    CREATE_NEW_PRODUCT_RECEIPT_IMPORT,
    UPDATE_SPECIFIC_PRODUCT_RECEIPT_IMPORT,
    GET_SPECIFIC_PRODUCT_RECEIPT_IMPORT,
    REMOVE_PRODUCT_RECEIPT_IMPORT,
    PRODUCT_RECEIPT_IMPORT_EXPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllProductReceiptImport = filter => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-receipt-import', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_PRODUCT_RECEIPT_IMPORT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getSpecificProductReceiptImport = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-receipt-import/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCT_RECEIPT_IMPORT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createNewProductReceiptImport = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-receipt-import', data).then(res => {
            dispatch({
                type: CREATE_NEW_PRODUCT_RECEIPT_IMPORT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateSpecificProductReceiptImport = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-receipt-import/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_SPECIFIC_PRODUCT_RECEIPT_IMPORT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeProductReceiptImport = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/product-receipt-import', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_PRODUCT_RECEIPT_IMPORT,
                payload: ids
            });
            NotificationManager.success("Xoá thành công!");
            resolve(res.data.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const exportExcel = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/product-receipt-import/export`, filter).then(res => {
            dispatch({ type: PRODUCT_RECEIPT_IMPORT_EXPORT_EXCEL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}