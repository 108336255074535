import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, Card, Row, Col, Select, InputNumber } from "antd";
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
import PageTitle from '../../components/PageTitle';
// actions
import { rewardUpdateConfig, rewardGetConfig } from "../../redux/actions/RewardSpinActions";

import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import { getListCustomerPotentialActive } from '../../redux/actions/CustomerPotentialActions';
import BaseSelect from '../../components/Elements/BaseSelect';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/reward-config',
        breadcrumbName: 'Cấu hình',
    },
]
const { Option } = Select;

class RewardSpinConfig extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            districts: [],
            wards: [],
            customerQuery: '',
            customerType: 'potential',
            show_ip_field: 'yes'
        };

    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentItem !== prevProps.currentItem) {
            this.props.getListDistricts({ province_code: this.props.currentItem?.province_code }).then(res => {
                this.setState({ districts: res.data });
            });
            this.props.getListWards({ district_code: this.props.currentItem?.district_code }).then(res2 => {
                this.setState({ wards: res2.data });
            });
            this.setState({ customerType: prevProps.currentItem?.customer_type })
            this.setState({ show_ip_field: prevProps.currentItem?.show_button })
        }
    }

    reloadData() {
        this.props.rewardGetConfig().then(() => {
            this.props.getListProvinces();
            this.props.getListCustomerActive();
            this.props.getListCustomerPotentialActive();

            this.setState({ customerType: this.props.currentItem?.customer_type });
            this.setState({ show_ip_field: this.props.currentItem?.show_button });
        });
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    await this.props.rewardUpdateConfig(values);
                    this.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
        });
        this.props.onCancel();
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.setFieldsValue({ district_code: null, wards_code: [] });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.setFieldsValue({ wards_code: [] });
                this.setState({ wards: res.data })
            })
        }
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            if (this.state.customerType == 'potential') {
                this.props.getListCustomerPotentialActive({ keyword: val });
            } else {
                this.props.getListCustomerActive({ keyword: val });
            }
        })
    }

    handleScroll(e) {
        if (this.state.customerType == 'potential') {
            this.props.getListCustomerPotentialActive({ keyword: this.state.customerQuery, page: this.props.pagination_customer_potential_active.currentPage + 1 });
        } else {
            this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
        }
    }

    onChangeCustomerType(value) {
        this.props.form.setFieldsValue({ excluded_customer: [] });
        this.setState({ customerType: value });
    }

    changeShowButton(value) {
        this.setState({ show_ip_field: value });
    }


    render() {
        const { currentItem, list_provinces, customerActiveList, customerPotentialActiveList } = this.props;
        const { isLoading, districts, wards, customerType, show_ip_field } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageTitle routes={routes} title="Cấu hình quay thưởng" />
                <Card size="small" className="mt-3">
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Loại khách hàng">
                                    {getFieldDecorator("customer_type", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.customer_type : 'potential',
                                    })(<BaseRadioList
                                        disabled
                                        options={[
                                            { label: "Khách hàng tiềm năng", value: 'potential' },
                                            { label: "Khách hàng", value: 'customer' }
                                        ]}
                                        onChange={(value) => this.onChangeCustomerType(value)}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Tỉnh/Thành phố">
                                    {getFieldDecorator('province_code', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem?.province_code ?? null
                                    })(<BaseSelect
                                        options={list_provinces || []}
                                        optionValue="code"
                                        optionLabel="name"
                                        showSearch
                                        placeholder="Tỉnh/Thành phố"
                                        className="w-100"
                                        onChange={(value) => { this.loadDistricts(value) }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Quận/Huyện">
                                    {getFieldDecorator('district_code', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem?.district_code ?? null
                                    })(<BaseSelect
                                        options={districts || []}
                                        optionValue="code"
                                        optionLabel="name"
                                        showSearch
                                        placeholder="Quận/Huyện"
                                        className="w-100"
                                        onChange={(value) => { this.loadWards(value) }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Phường/Xã">
                                    {getFieldDecorator('wards_code', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem?.wards_code ?? []
                                    })(
                                        <Select
                                            mode='multiple'
                                            className="w-100"
                                            placeholder="Chọn 1 hoặc nhiều bản ghi"
                                            optionLabelProp="label"
                                            allowClear={true}
                                            showSearch={true}
                                        >
                                            {
                                                wards && wards.length ? (
                                                    <>
                                                        {
                                                            wards.map((item) => {
                                                                return (
                                                                    <Option key={item.code} value={item.code} label={item.name}>{item.name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                ) : null
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Hạn nhận giải thưởng (ngày)">
                                    {getFieldDecorator("date_to_expire", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.date_to_expire : 0,
                                    })(<InputNumber />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Bỏ qua những khách hàng đã quay">
                                    {getFieldDecorator("ignore_customer", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.ignore_customer : 'no',
                                    })(<BaseRadioList
                                        options={[
                                            { label: "Có", value: 'yes' },
                                            { label: "Không", value: 'no' }
                                        ]}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Hiển thị nút quay">
                                    {getFieldDecorator("show_button", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.show_button : 'no',
                                    })(<BaseRadioList
                                        options={[
                                            { label: "Có", value: 'yes' },
                                            { label: "Không", value: 'no' }
                                        ]}
                                        onChange={(value) => this.changeShowButton(value)}
                                    />)}
                                </Form.Item>
                            </Col>
                            {
                                show_ip_field == 'yes' ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Chỉ hiển thị nút quay với d/s ip">
                                            {getFieldDecorator("allow_ips", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem.allow_ips : '',
                                            })(<Input.TextArea placeholder="Mỗi ip cách nhau dâu (,)" />)}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            {
                                customerType == 'potential' ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Loại trừ khách hàng">
                                            {getFieldDecorator('excluded_customer', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentItem?.excluded_customer ?? []
                                            })(<Select
                                                className="w-100"
                                                placeholder="Nhập tên khách hàng, chọn 1 hoặc nhiều khách hàng"
                                                optionLabelProp="label"
                                                allowClear={true}
                                                mode='multiple'
                                                showSearch={true}
                                                onPopupScroll={(e) => this.handleScroll(e)}
                                                onSearch={(val) => this.onSearchCustomer(val)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    customerPotentialActiveList.map((item) => {
                                                        return (
                                                            <Option key={item.id} value={item.id} label={item.full_name}>{item.full_name} ({item.code})</Option>
                                                        )
                                                    })
                                                }
                                            </Select>)}
                                        </Form.Item>
                                    </Col>
                                ) : (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Loại trừ khách hàng">
                                            {getFieldDecorator('excluded_customer', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentItem?.excluded_customer ?? []
                                            })(<Select
                                                className="w-100"
                                                placeholder="Nhập tên khách hàng, chọn 1 hoặc nhiều khách hàng"
                                                optionLabelProp="label"
                                                allowClear={true}
                                                mode='multiple'
                                                showSearch={true}
                                                onPopupScroll={(e) => this.handleScroll(e)}
                                                onSearch={(val) => this.onSearchCustomer(val)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    customerActiveList.map((item) => {
                                                        return (
                                                            <Option key={item.id} value={item.id} label={item.full_name}>{item.full_name} ({item.code})</Option>
                                                        )
                                                    })
                                                }
                                            </Select>)}
                                        </Form.Item>
                                    </Col>
                                )
                            }

                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                        <div className="mb-4">
                            <Button key="submit" type="primary" onClick={(e) => this.submit(e)} loading={isLoading}>
                                Cập nhật
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentItem: state.RewardSpinReducer.reward_config,
        list_provinces: state.RegionsReducer.list_provinces,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        customerPotentialActiveList: state.CustomerPotentialReducer.customerActiveList,
        pagination_customer_potential_active: state.CustomerPotentialReducer.pagination_active
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        rewardUpdateConfig: (data) => dispatch(rewardUpdateConfig(data)),
        rewardGetConfig: () => dispatch(rewardGetConfig()),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        getListCustomerPotentialActive: (filter) => dispatch(getListCustomerPotentialActive(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "RewardSpinConfig" })(RewardSpinConfig));
