import {
    GET_LIST_PAGE,
    GET_SPECIFIC_PAGE,
    GET_PAGE_BY_PAGEID,
    GET_LIST_PAGE_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    pageList: [],
    currentPageItem: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    },
    currentpage: null,
    list_page_active: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_PAGE: {
            return {
                ...state,
                pageList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_LIST_PAGE_ACTIVE: {
            return {
                ...state,
                list_page_active: action.payload
            }
        }
        case GET_SPECIFIC_PAGE: {
            return { ...state, currentPageItem: action.payload }
        }
        case GET_PAGE_BY_PAGEID: {
            return { ...state, currentpage: action.payload }
        }
        default: return { ...state };
    }
}
