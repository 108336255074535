import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Drawer, Descriptions, Divider, Comment, Row, Col } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate, processHTML } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import FunctionalForm from './FunctionalForm';
import { checkPermission } from '../../utils/permission';
// actions
import { getData } from '../../redux/actions/FunctionalActions';

class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenFormEdit: false,
        };
    }

    handleCancel() {
        this.props.onCancel()
        this.props.reloadList()
    }

    render() {
        const { isOpenFormEdit } = this.state;
        var { visible, currentData } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Chi tiết công việc"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        <div style={{ display: 'flex' }}>
                            {checkPermission('functional', 'update') ? (
                                <Button type="primary" onClick={() => this.setState({ isOpenFormEdit: true })} icon={<EditOutlined />}>
                                    Sửa
                                </Button>
                            ) : null}
                        </div>
                    }
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Button key="back" onClick={() => this.props.onCancel()}>
                                Huỷ
                            </Button>
                        </div>
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Khu vực chức năng">
                                            {currentData?.area_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Comment className='comment-content-detail'
                                author={<span className='ant-descriptions-item-label'>Mô tả công việc</span>
                                }
                                content={<div className='chingo-content-detail'>
                                    {ReactHtmlParser(processHTML(currentData?.description))}
                                </div>}
                            />
                            <Divider className="no-margin" />
                            <Comment className='comment-content-detail'
                                author={<span className='ant-descriptions-item-label'>Ghi chú</span>
                                }
                                content={ReactHtmlParser(currentData?.note)}
                            />
                            <Row gutter={16}>
                                <Divider className="no-margin" />
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)} bởi: {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </Drawer>

                <FunctionalForm
                    visible={isOpenFormEdit}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenFormEdit: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getData: (id) => dispatch(getData(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));
