import api from '../../utils/api';
import {
    GET_LIST_UNIT,
    GET_SPECIFIC_UNIT,
    UPDATE_SPECIFIC_UNIT,
    CREATE_NEW_UNIT,
    REMOVE_UNIT,
    GET_UNIT_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListUnit = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/unit', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_UNIT, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificUnit = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/unit/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_UNIT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getUnitActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/unit/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_UNIT_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createUnit = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/unit', data).then(res => {
            dispatch({ type: CREATE_NEW_UNIT, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateUnit = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/unit/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_UNIT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeUnit = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/unit`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_UNIT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
