import {
    GET_LIST_FUNCTIONAL_AREA,
    UPDATE_SPECIFIC_FUNCTIONAL_AREA,
    CREATE_NEW_FUNCTIONAL_AREA,
    REMOVE_FUNCTIONAL_AREA,
    GET_FUNCTIONAL_AREA_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    list: [],
    active_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_FUNCTIONAL_AREA: {
            return {
                ...state,
                list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_FUNCTIONAL_AREA_ACTIVE: {
            return {
                ...state,
                active_list: action.payload.data,
            }
        }
        case CREATE_NEW_FUNCTIONAL_AREA: {
            return {
                ...state,
                list: [action.payload, ...state.list],
                active_list: [action.payload, ...state.active_list],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_FUNCTIONAL_AREA: {
            let index = state.list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.list];
            temp[index] = action.payload;
            //
            let index2 = state.active_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.active_list];
            temp[index2] = action.payload;

            return { ...state, list: temp, active_list: temp2 }
        }
        case REMOVE_FUNCTIONAL_AREA: {
            let temp = state.list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.active_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, list: temp, active_list: temp2 }
        }
        default: return { ...state };
    }
}
