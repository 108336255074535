import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined, CopyOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Tag, Divider, Button, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import ProjectForm from './ProjectForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';

import moment from 'moment';
// actions
import { getListProject, getSpecificProject, removeProject, cloneProject } from '../../redux/actions/ProjectActions';
import BaseSelect from '../../components/Elements/BaseSelect';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/project',
        breadcrumbName: 'Dự án',
    },
]

class ProjectList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        isLoadingClone: false
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListProject(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificProject(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeProject(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeProject(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onClone() {
        confirm({
            title: 'Xác nhận',
            content: 'Bạn chắc chắn muốn nhân bản dự án này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.cloneProject(this.state.selectedRowKeys).then(() => {
                this.loadData();
                this.setState({ selectedRowKeys: [] });
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListProject(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { project_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, isLoadingClone } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('project', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('project', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Người phụ trách',
                dataIndex: 'person_charge',
                key: 'person_charge',
                sorter: true
            },
            {
                title: 'Tình trạng',
                dataIndex: 'project_status',
                key: 'project_status',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    let status = '';
                    if (record.project_status == 1) {
                        status = <Tag color='#205072'>Đã hoàn thành</Tag>
                    } else if (record.project_status == 0) {
                        status = <Tag color='#d46c4e'>Chưa hoàn thành</Tag>
                    }
                    return (
                        <div>{status}</div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Dự án" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('project', 'create')}
                        isShowDeleteButton={checkPermission('project', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >

                        <Button className="table-button" type='primary' icon={<CopyOutlined />} disabled={!selectedRowKeys.length ? true : false} loading={isLoadingClone} onClick={() => this.onClone()}>Nhân bản</Button>
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã hoàn thành' },
                                { value: 0, label: 'Chưa hoàn thành' },
                            ]}
                            onChange={(value) => this.onChangeFilter("project_status", value)}
                            placeholder="Tình trạng"
                            defaultValue={parseInt(query.project_status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã kích hoạt' },
                                { value: 0, label: 'Chưa kích hoạt' },
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            placeholder="Trạng thái"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={project_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <ProjectForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        project_list: state.ProjectReducer.project_list,
        pagination: state.ProjectReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListProject: (filter) => dispatch(getListProject(filter)),
        getSpecificProject: (id) => dispatch(getSpecificProject(id)),
        removeProject: (ids) => dispatch(removeProject(ids)),
        cloneProject: (ids) => dispatch(cloneProject(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
