import React, { Component } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, Divider, Space } from 'antd';
import appConfig from '../../config';
import { connect } from 'react-redux';
import { downloadFromLink } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
// actions
import { importExcel } from '../../redux/actions/CustomerPotentialActions';

class ImportExcelForm extends Component {

    state = {
        isLoadingFile: false,
        file: null,

    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoadingFile: true
        })
        const formData = new FormData();
        formData.append('file', this.state.file)
        //console.log(this.state.file)
        this.props.importExcel(formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res)
            this.props.onSetMessage(res.data.msg, 'success')
            this.props.reloadData();
            this.handleCancel();
        }).catch(err => {
            this.props.onSetMessage(err.response.data.msg, 'error')
            this.handleCancel();
        })
    }

    handleCancel = () => {
        this.setState({
            isLoadingFile: false,
            file: null
        });
        this.props.onCancel()
        this.fileInput.value = "";
    }

    onChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onDownLoadFile(e) {
        e.preventDefault();
        const response = {
            file: appConfig.MEDIA_URL + '/upload/demo_danh_sach_khach_hang_tiem_nang.xlsx'
        }
        downloadFromLink(response.file, 'demo_danh_sach_khach_hang_tiem_nang.xlsx')
    }

    render() {
        const { isLoadingFile } = this.state;
        const { visible } = this.props;
        return (
            <Modal
                title="Import excel"
                width={isMobile ? '100%' : '30%'}
                visible={visible}
                onOk={(e) => this.onSubmit(e)}
                confirmLoading={isLoadingFile}
                onCancel={this.handleCancel}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={this.handleCancel}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoadingFile}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.onSubmit(e)}
                            >
                                Import
                            </Button>
                        </Space>
                    </div>
                }
            >
                <div className="mb-3">
                    <div className="mb-2">
                        <div className='mb-2'>
                            Tải file mẫu và chỉnh sửa nội dung file trước khi import.
                        </div>
                        <div style={{ color: 'red' }}>Lưu ý: Những cột chữ màu đỏ là bắt buộc điền</div>
                    </div>
                    <Button onClick={(e) => this.onDownLoadFile(e)}>
                        <DownloadOutlined /> Tải File Mẫu
                    </Button>
                </div>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Divider />
                        <form className='mt-3'>
                            <input accept='.xls, .xlsx' type="file" onChange={this.onChange} ref={ref => this.fileInput = ref} />
                        </form>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        importExcel: (data, header) => dispatch(importExcel(data, header))
    }
}

export default connect(null, mapDispatchToProps)(ImportExcelForm);