import {
    GET_LIST_CUSTOMER_CLASS,
    GET_SPECIFIC_CUSTOMER_CLASS,
    UPDATE_SPECIFIC_CUSTOMER_CLASS,
    CREATE_NEW_CUSTOMER_CLASS,
    REMOVE_CUSTOMER_CLASS,
    GET_CUSTOMER_CLASS_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    customerClassList: [],
    currentCustomerClass: null,
    customerClassActiveList: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER_CLASS: {
            return {
                ...state,
                customerClassList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_CUSTOMER_CLASS_ACTIVE: {
            return {
                ...state,
                customerClassActiveList: action.payload.data,
            }
        }
        case GET_SPECIFIC_CUSTOMER_CLASS: {
            return { ...state, currentCustomerClass: action.payload }
        }
        case CREATE_NEW_CUSTOMER_CLASS: {
            return {
                ...state,
                customerClassList: [action.payload, ...state.customerClassList],
                customerClassActiveList: [action.payload, ...state.customerClassActiveList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_CUSTOMER_CLASS: {
            let index = state.customerClassList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.customerClassList];
            temp[index] = action.payload;
            //
            let index2 = state.customerClassActiveList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.customerClassActiveList];
            temp[index2] = action.payload;

            return { ...state, customerClassList: temp, customerClassActiveList: temp2 }
        }
        case REMOVE_CUSTOMER_CLASS: {
            let temp = state.customerClassList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.customerClassActiveList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, customerClassList: temp, customerClassActiveList: temp2 }
        }
        default: return { ...state };
    }
}
