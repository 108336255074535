import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Typography, DatePicker, Tag, Button, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, repeatPhoneNumber } from '../../utils/helpers';
import moment from 'moment';

// actions
import { getListViewPhoneNumber, removeViewPhoneNumber } from '../../redux/actions/ViewPhonenumberActions';
import { ReloadOutlined } from '@ant-design/icons';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/view-phonenumber',
        breadcrumbName: 'Lịch sử xem số điện thoại',
    },
]
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

class ViewPhonenumberList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListViewPhoneNumber(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListViewPhoneNumber(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'viewed_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.viewed_at;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeViewPhoneNumber(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.componentDidMount()
        })
    }

    render() {
        var { list, pagination } = this.props;
        var { isLoading, selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'Tên khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name'
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: '130px',
                render: (text, record) => {
                    if (text) {
                        if (checkPermission('view_phonenumber', 'phonenumber')) {
                            return (
                                <Tag>{text}</Tag>
                            )
                        } else {
                            return (
                                <>
                                    {repeatPhoneNumber(text)}
                                </>
                            )
                        }
                    }
                }
            },
            {
                title: 'Người xem',
                dataIndex: 'user_viewed',
                key: 'user_viewed',
                width: '250px'
            },
            {
                title: 'Ngày xem',
                dataIndex: 'viewed_at',
                key: 'viewed_at',
                sorter: true,
                width: '160px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Loại KH',
                dataIndex: 'customer_type',
                key: 'customer_type',
                width: '120px',
                render: (text, record) => {
                    if (text && text == 'potential') {
                        return (
                            <div>KH tiềm năng</div>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Lịch sử xem số điện thoại" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('view_phonenumber', 'remove')}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <RangePicker
                            format="DD/MM/YYYY"
                            placeholder={['Từ ngày', 'Đến ngày']}
                            style={{ width: '250px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('viewed_at', value)}
                            defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.componentDidMount()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={list ?? []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.ViewPhonenumberReducer.list,
        pagination: state.ViewPhonenumberReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListViewPhoneNumber: (filter) => dispatch(getListViewPhoneNumber(filter)),
        removeViewPhoneNumber: (ids) => dispatch(removeViewPhoneNumber(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPhonenumberList);
