import {
    GET_ALL_PRODUCT,
    CREATE_NEW_PRODUCT,
    UPDATE_SPECIFIC_PRODUCT,
    GET_SPECIFIC_PRODUCT,
    REMOVE_PRODUCT,
    GET_ALL_PRODUCT_WITH_CONCATENATE,
    GET_STATISTICAL_PRODUCT
} from '../actions/types'

const INIT_STATE = {
    product_list: [],
    current_product: {},
    statistical: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT: {
            return {
                ...state,
                product_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            }
        }
        case GET_ALL_PRODUCT_WITH_CONCATENATE: {
            let listProduct = [...state.product_list, ...action.payload.data];

            return {
                ...state,
                product_list: listProduct.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case CREATE_NEW_PRODUCT: {
            return {
                ...state,
                product_list: [
                    action.payload,
                    ...state.product_list
                ],
                total: state.total + 1
            }
        }
        case GET_SPECIFIC_PRODUCT: {
            return { ...state, current_product: action.payload }
        }
        case UPDATE_SPECIFIC_PRODUCT: {
            let index = state.product_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.product_list];
            temp[index] = action.payload;
            return { ...state, product_list: temp }
        }
        case REMOVE_PRODUCT: {
            let temp = state.product_list.filter(role => {
                return action.payload.indexOf(role.id) < 0;
            });
            return { ...state, product_list: temp }
        }
        case GET_STATISTICAL_PRODUCT:
            return {
                ...state,
                statistical: action.payload
            };
        default:
            return { ...state }
    }

}
