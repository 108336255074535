import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { ReloadOutlined, TeamOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Row, Button, Select, Avatar } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import Detail from './Detail';
import JobsForm from './JobsForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';

// actions
import { getAllData, getData, removeData } from '../../redux/actions/JobsActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/jobs',
        breadcrumbName: 'Công việc',
    },
]

class JobsList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
        isOpenDetail: false,
        current_id: null,
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllData(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getData(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    onRemove() {
        this.props.removeData(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ selectedRowKeys: [] })
    }

    render() {
        var { data_list, pagination, config } = this.props;
        var {
            isLoading,
            selectedRowKeys,
            isOpenForm,
            currentData,
            isOpenDetail,
            current_id
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: 'Loại công việc',
                dataIndex: 'typejob_name',
                key: 'typejob_name',
                render: (text, record) => {
                    if (checkPermission('jobs', 'detail')) {
                        return (
                            <Tooltip placement="right" title='Xem chi tiết'>
                                <div onClick={() => this.onDetail(record.id)} style={{ wordBreak: "break-all" }} className='color-primary item-action-btn'>
                                    <Avatar size='small' icon={<TeamOutlined />} style={{ backgroundColor: '#0a4e8fbf', color: '#ffffff' }} /> {text}
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (<>{text}</>)
                    }
                }
            },
            {
                title: 'Mô tả công việc',
                dataIndex: 'description',
                key: 'description',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <Tooltip placement="right" title={ReactHtmlParser(stripHtml(text))}>
                            <div style={{ wordBreak: "break-all" }}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>{record.created_user}</>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Công việc" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('jobs', 'create')}
                        isShowDeleteButton={checkPermission('jobs', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[]}
                    >
                        <Select
                            allowClear
                            className='table-button'
                            placeholder="Loại công việc"
                            options={config.role_trees || []}
                            onChange={(value) => this.onChangeFilter('role_id', value)}
                            style={{ width: '200px' }}
                            defaultValue={parseInt(query.role_id) || null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    dataSource={data_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <JobsForm
                    visible={isOpenForm}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <Detail
                    visible={isOpenDetail}
                    currentData={currentData}
                    reloadList={() => this.loadData()}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        data_list: state.JobsReducer.data_list,
        pagination: state.JobsReducer.pagination,
        users: state.config.users,
        config: state.config,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllData: (filter) => dispatch(getAllData(filter)),
        getData: (id) => dispatch(getData(id)),
        removeData: (ids) => dispatch(removeData(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'JobsList' })(JobsList));
