import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Drawer, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import { isMobile } from 'react-device-detect';

// actions
import PaymentHistory from '../../components/PaymentHistory';
import { UserOutlined } from '@ant-design/icons';


class CustomerPaymentReceiptList extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    onClose() {
        this.props.onCancel();
    }

    render() {
        var { } = this.state;
        var {
            visible,
            currentCustomer
        } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Lịch sử thanh toán`}
                    onOk={(e) => this.submit(e)}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    placement="right"
                    width={isMobile ? '100%' : '65%'}
                    footer={false}
                >
                    {visible ?
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mb-3'>
                                    <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{currentCustomer?.full_name} - {currentCustomer?.code}</span></h6>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <PaymentHistory data={currentCustomer?.cashReceipts} area="customer-list" />
                                </Col>
                            </Row>
                        </div>
                        : null}
                </Drawer>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerPaymentReceiptList' })(CustomerPaymentReceiptList));
