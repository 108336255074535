import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip, Tag, Avatar, Button, Typography, DatePicker } from 'antd';
import { ReloadOutlined, UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerDetail from '../customer/CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, isValidDate, repeatPhoneNumber, getFirstLetterOfWords } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import CustomerConsultantDirectResultForm from './CustomerConsultantDirectResultForm'
import CustomerConsultantDirectDetail from './CustomerConsultantDirectDetail';

import { getListCustomerConsultantDirect, getSpecificCustomerConsultantDirect, removeCustomerConsultantDirect } from '../../redux/actions/CustomerConsultantDirectActions';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import DynamicTable from '../../components/DynamicTable';
import moment from 'moment';

const { RangePicker } = DatePicker;

const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-consultant-direct',
        breadcrumbName: 'Lịch tư vấn trực tiếp',
    },
]

class CustomerConsultantDirectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            openCustomerDetail: false,
            currentCustomerData: null,
            loadingDetail: false,
            openDetail: false,
            currentItem: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCustomerConsultantDirect(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    toggleOpenCustomerDetail(openCustomerDetail, data = null) {
        this.setState({ openCustomerDetail: openCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id, loadingDetail: true })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    toggleDetail(openDetail, data = null) {
        this.setState({ openDetail: openDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomerConsultantDirect(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleDetail(true, res);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerConsultantDirect(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({
            openUpdateResult: false,
            isLoading: false,
            selectedRowKeys: []
        })
    }

    onUpdate(id) {
        this.props.getSpecificCustomerConsultantDirect(id).then(res => {
            this.setState({ currentItem: res, openUpdateResult: true })
        })
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeCustomerConsultantDirect(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] });
            this.loadData();
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    render() {
        var { customerConsultantList, pagination, users, customerStatusActiveList } = this.props;
        var { isLoading,
            currentItem,
            currentCustomerData,
            openCustomerDetail,
            currentCustomerId,
            loadingDetail,
            openUpdateResult,
            selectedRowKeys,
            openDetail,
            current_id
        } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                key: "stt",
                render: (text, record, index) => index + 1
            },
            {
                title: '#',
                align: 'center',
                key: 'action',
                render: (text, record) => {
                    if (text) {
                        if (checkPermission('customer_consultant_direct', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết lịch tư vấn" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.schedule_consultant_id)}>
                                        <EyeOutlined />
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <EyeOutlined style={{ color: '#dedede' }} />
                                </>
                            )
                        }
                    }
                }
            },
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (text, record) => {
                    if (record.customer_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ khách hàng" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;<span>{record.customer_name}</span>
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.customer_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.customer_code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'customer_phone',
                width: '120px',
                key: 'customer_phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record.customer_id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Trạng thái',
                width: '150px',
                dataIndex: 'consultant_direct_status',
                key: 'consultant_direct_status',
                render: (text, record) => {
                    var bgcolor = "#f5b22d";
                    var text2 = "Chưa trả kết quả"
                    if (text == 1) {
                        bgcolor = "green";
                        text2 = "Đã trả kết quả"
                    }
                    if (checkPermission('customer_consultant_direct', 'update_result')) {
                        return (
                            <div>
                                <Tooltip title="Click để cập nhật" placement="right">
                                    <Button icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.onUpdate(record.schedule_consultant_id)} size='small'>
                                        {text2}
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <Button style={{ background: bgcolor, color: '#fff', border: 'none' }} size='small'>
                                    {text2}
                                </Button>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Thời điểm tư vấn',
                dataIndex: 'time_consultant',
                key: 'time_consultant',
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Kết quả tư vấn',
                dataIndex: 'result_content',
                key: 'result_content',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái KH',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú KH',
                dataIndex: 'customer_note',
                key: 'customer_note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_management',
                key: 'branch_management',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nhân viên tư vấn',
                dataIndex: 'consultant_direct_staff',
                key: 'consultant_direct_staff',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày giao',
                dataIndex: 'casc_created_at',
                key: 'casc_created_at',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div><Tag>{text}</Tag></div>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Lịch tư vấn trực tiếp" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('customer_consultant_direct', 'remove')}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        activeFilter={
                            query.created_date_start

                        }
                        filters={[
                            <RangePicker
                                format="DD/MM/YYYY"
                                placeholder={['Ngày tạo', 'Đến ngày']}
                                className="w-100"
                                onChange={(value) => this.onChangeFilter('created_at', value)}
                                defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                            />,
                        ]}
                    >
                        <BaseSelect
                            options={users || []}
                            optionValue="user_id"
                            optionLabel="full_name"
                            showSearch
                            placeholder="Nhận viên tư vấn"
                            style={{ width: '200px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('user_consultant_id', value)}
                            defaultValue={query.user_consultant_id ? parseInt(query.user_consultant_id) : null}
                        />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã trả kết quả' },
                                { value: 0, label: 'Chưa trả kết quả' }
                            ]}
                            placeholder="Trạng thái"
                            style={{ width: '200px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('consultant_direct_status', value)}
                            defaultValue={query.consultant_direct_status ? parseInt(query.consultant_direct_status) : null}
                        />
                        <BaseSelect
                            options={customerStatusActiveList || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            placeholder="Trạng thái khách hàng"
                            onChange={(value) => this.onChangeFilter('customer_status_id', value)}
                            style={{ width: '200px' }}
                            className="table-button"
                            defaultValue={query.customer_status_id ? parseInt(query.customer_status_id) : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>

                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="schedule_consultant_id"
                        dataSource={customerConsultantList || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="Customer_consultant_direct"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>

                <CustomerDetail
                    visible={openCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <CustomerConsultantDirectDetail
                    visible={openDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleDetail(false)}
                    reloadList={() => this.loadData()}
                    loadingDetail={loadingDetail}
                />
                <CustomerConsultantDirectResultForm
                    visible={openUpdateResult}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerConsultantList: state.CustomerConsultantDirectReducer.customerConsultantList,
        pagination: state.CustomerConsultantDirectReducer.pagination,
        users: state.config.users,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerConsultantDirect: (filter) => dispatch(getListCustomerConsultantDirect(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        getSpecificCustomerConsultantDirect: (id) => dispatch(getSpecificCustomerConsultantDirect(id)),
        removeCustomerConsultantDirect: (id) => dispatch(removeCustomerConsultantDirect(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerConsultantDirectList' })(CustomerConsultantDirectList));

