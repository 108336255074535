import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Divider, DatePicker, Button, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, isValidDate } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import ProductDetail from './ProductDetail';
import ProductForm from './ProductForm';
import BaseSelect from '../../components/Elements/BaseSelect';

import moment from 'moment';
// actions
import { getAllProduct, getSpecificProduct, removeProduct, getStatistical } from '../../redux/actions/ProductActions';
import { getProductGroupActive } from '../../redux/actions/ProductGroupActions';
import { getProductSupplierActive } from '../../redux/actions/ProductSupplierAction';
import { getProductWarehouseActive } from '../../redux/actions/ProductWarehouseAction';
import { getUnitActive } from '../../redux/actions/UnitActions'
import { getProductStatusActive } from '../../redux/actions/ProductStatusActions';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/product-list',
        breadcrumbName: 'Sản phẩm',
    },
]

class ProductList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null,
        isOpenDetail: false,
        current_id: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProduct(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStatistical(query);
        this.props.getProductGroupActive();
        this.props.getProductSupplierActive();
        this.props.getProductWarehouseActive();
        this.props.getUnitActive();
        this.props.getProductStatusActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProduct(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
            this.props.getStatistical(query);
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificProduct(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeProduct(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.reloadData()
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeProduct(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData()
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificProduct(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProduct(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { product_list, pagination, product_group_active_list, product_supplier_active_list, product_warehouse_active_list, unit_active_list,
            product_status_active_list, authUser, users, statistical } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem, current_id, isOpenDetail } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '70px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('product', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('product', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên sản phẩm',
                dataIndex: 'name',
                key: 'name',
                width: '250px',
                //sorter: true,
                render: (text, record) => {
                    if (record.name) {
                        if (checkPermission('product', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn ecom-paragraph' onClick={() => this.onDetail(record.id)}>
                                        <Paragraph style={{ margin: 0 }} copyable={
                                            {
                                                text: record ? record.name : null,
                                                tooltips: false
                                            }
                                        }>
                                            {record.name}
                                        </Paragraph>
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <Paragraph style={{ margin: 0 }} copyable={
                                        {
                                            text: record ? record.name : null,
                                            tooltips: false
                                        }
                                    }>
                                        {record.name}
                                    </Paragraph>
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            // {
            //     title: 'Mã sản phẩm',
            //     dataIndex: 'code',
            //     key: 'code',
            //     width: '150px'
            // },
            // {
            //     title: "Giá nhập",
            //     dataIndex: "regular_price",
            //     width: "120px",
            //     render: (text, record) => {
            //         return (
            //             <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
            //         )
            //     }
            // },
            // {
            //     title: "Giá bán",
            //     dataIndex: "sale_price",
            //     width: "120px",
            //     render: (text, record) => {
            //         return (
            //             <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
            //         )
            //     }
            // },
            {
                title: `SL tồn (${statistical?.total_quantity ?? 0})`,
                dataIndex: 'quantity',
                key: 'quantity',
                sorter: true
            },
            {
                title: 'Hạn sử dụng',
                dataIndex: 'expiry_date',
                key: 'expiry_date',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(record.expiry_date, false)}</div>
                    )
                }
            },
            {
                title: 'Kho',
                dataIndex: 'warehouse_name',
                key: 'warehouse_name',
                width: '150px'
            },
            {
                title: 'Nhóm sản phẩm',
                dataIndex: 'group_name',
                key: 'group_name',
                width: '250px'
            },
            {
                title: 'Đơn vị tính',
                dataIndex: 'unit',
                key: 'unit'
            },
            {
                title: 'Tình trạng',
                dataIndex: 'product_status_name',
                key: 'product_status_name'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text, true)}</div>
                    )
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={product_group_active_list || []}
                onChange={(value) => this.onChangeFilter("group_id", value)}
                optionValue="id"
                showSearch
                defaultValue={parseInt(query.group_id) || null}
                optionLabel="name"
                placeholder="Nhóm sản phẩm"
                className="w-100"
            />,
            <BaseSelect
                options={product_supplier_active_list || []}
                onChange={(value) => this.onChangeFilter("supplier_id", value)}
                optionValue="id"
                showSearch
                defaultValue={parseInt(query.supplier_id) || null}
                optionLabel="name"
                placeholder="Nhà cung cấp"
                className="w-100"
            />,
            <BaseSelect
                options={unit_active_list || []}
                onChange={(value) => this.onChangeFilter("unit_id", value)}
                optionValue="id"
                showSearch
                defaultValue={parseInt(query.unit_id) || null}
                optionLabel="name"
                placeholder="Đơn vị tính"
                className="w-100"
            />,
            <BaseSelect
                options={product_status_active_list || []}
                onChange={(value) => this.onChangeFilter("product_status_id", value)}
                optionValue="id"
                showSearch
                defaultValue={parseInt(query.product_status_id) || null}
                optionLabel="name"
                placeholder="Tình trạng"
                className="w-100"
            />,
            <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Ngày tạo', 'Đến ngày']}
                className="w-100"
                onChange={(value) => this.onChangeFilter('created_at', value)}
                defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
            />
        ];

        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người tạo"
                    showSearch={false}
                    className="w-100"
                    defaultValue={parseInt(query.created_user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />
            );
        }

        return (
            <div>
                <PageTitle routes={routes} title="Sản phẩm" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('product', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            parseInt(query.warehouse_id) ||
                            parseInt(query.created_user_id) ||
                            parseInt(query.product_status_id) ||
                            parseInt(query.group_id) ||
                            parseInt(query.supplier_id) ||
                            parseInt(query.unit_id)
                        }
                        filters={filters}
                    >
                        <BaseSelect
                            options={product_warehouse_active_list || []}
                            onChange={(value) => this.onChangeFilter("warehouse_id", value)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(query.warehouse_id) || null}
                            optionLabel="name"
                            placeholder="Kho hàng"
                            style={{ width: '180px' }}
                            className="table-button"
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={product_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />

                <ProductDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_list: state.ProductReducer.product_list,
        pagination: state.ProductReducer.pagination,
        product_group_active_list: state.ProductGroupReducer.product_group_active_list,
        product_supplier_active_list: state.ProductSupplierReducer.product_supplier_active_list,
        product_warehouse_active_list: state.ProductWarehouseReducer.product_warehouse_active_list,
        unit_active_list: state.UnitReducer.unit_active_list,
        product_status_active_list: state.ProductStatusReducer.product_status_active_list,
        authUser: state.auth.authUser,
        users: state.config.users,
        statistical: state.ProductReducer.statistical
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProduct: (filter) => dispatch(getAllProduct(filter)),
        getSpecificProduct: (id) => dispatch(getSpecificProduct(id)),
        removeProduct: (ids) => dispatch(removeProduct(ids)),
        getProductSupplierActive: () => dispatch(getProductSupplierActive()),
        getProductGroupActive: () => dispatch(getProductGroupActive()),
        getProductWarehouseActive: () => dispatch(getProductWarehouseActive()),
        getUnitActive: () => dispatch(getUnitActive()),
        getProductStatusActive: () => dispatch(getProductStatusActive()),
        getStatistical: filter => dispatch(getStatistical(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
