import React, { Component } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from '../../utils/helpers';

class UploadVideo extends Component {
    state = {
        fileList: []
    };

    static defaultProps = {
        onChangeData: () => { },
        multiple: false,
        maxCount: 1,
        uploadText: 'Chọn video',
        accept: 'video/*'
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isResetUpload) {
            this.setState({ fileList: [] });
        }
    }

    async setData(list) {
        let listData = [];
        if (list.length) {
            for (let i = 0; i < list.length; i++) {
                let data = await getBase64(list[i].originFileObj);
                listData.push({
                    data: data,
                    name: list[i].originFileObj.name
                })
            }
        }
        return listData;
    }

    handleChange = async ({ fileList }) => {
        this.setState({ fileList }, async () => {
            let data = await this.setData(this.state.fileList);
            this.props.onChangeData(data);
        });
    };

    render() {
        const { fileList } = this.state;
        const { multiple, maxCount, uploadText, accept } = this.props;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{uploadText}</div>
            </div>
        );

        return (
            <div className="clearfix">
                <Upload
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={this.handleChange}
                    accept={accept}
                    multiple={multiple}
                    maxCount={maxCount}
                >
                    {uploadButton}
                </Upload>
            </div>
        );
    }
}

export default UploadVideo;
