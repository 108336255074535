import api from '../../utils/api';
import {
    GET_ALL_PRODUCT_SUPPLIER,
    GET_PRODUCT_SUPPLIER,
    CREATE_PRODUCT_SUPPLIER,
    UPDATE_PRODUCT_SUPPLIER,
    REMOVE_PRODUCT_SUPPLIER,
    GET_PRODUCT_SUPPLIER_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllProductSupplier = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/product-supplier', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_PRODUCT_SUPPLIER,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getProductSupplierActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-supplier/by-active', { params: filter }).then(res => {
            dispatch({ type: GET_PRODUCT_SUPPLIER_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getOneProductSupplier = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-supplier/${id}`).then(res => {
            dispatch({
                type: GET_PRODUCT_SUPPLIER,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createProductSupplier = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-supplier', data).then(res => {
            dispatch({
                type: CREATE_PRODUCT_SUPPLIER,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateProductSupplier = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-supplier/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_PRODUCT_SUPPLIER,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeProductSupplier = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/product-supplier', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_PRODUCT_SUPPLIER,
                payload: ids
            });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
