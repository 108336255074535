import api from '../../utils/api';
import {
    GET_ALL_PRODUCT_RECEIPT_EXPORT,
    CREATE_NEW_PRODUCT_RECEIPT_EXPORT,
    UPDATE_SPECIFIC_PRODUCT_RECEIPT_EXPORT,
    GET_SPECIFIC_PRODUCT_RECEIPT_EXPORT,
    REMOVE_PRODUCT_RECEIPT_EXPORT,
    PRODUCT_RECEIPT_EXPORT_EXPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllProductReceiptExport = filter => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-receipt-export', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_PRODUCT_RECEIPT_EXPORT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getSpecificProductReceiptExport = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-receipt-export/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCT_RECEIPT_EXPORT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createNewProductReceiptExport = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-receipt-export', data).then(res => {
            dispatch({
                type: CREATE_NEW_PRODUCT_RECEIPT_EXPORT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateSpecificProductReceiptExport = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-receipt-export/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_SPECIFIC_PRODUCT_RECEIPT_EXPORT,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeProductReceiptExport = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/product-receipt-export', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_PRODUCT_RECEIPT_EXPORT,
                payload: ids
            });
            NotificationManager.success("Xoá thành công!");
            resolve(res.data.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const exportExcel = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/product-receipt-export/export`, filter).then(res => {
            dispatch({ type: PRODUCT_RECEIPT_EXPORT_EXPORT_EXCEL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}