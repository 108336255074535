import api from '../../utils/api';
import {
    GET_LIST_PAYMENT_VOUCHER,
    GET_SPECIFIC_PAYMENT_VOUCHER,
    UPDATE_SPECIFIC_PAYMENT_VOUCHER,
    CREATE_NEW_PAYMENT_VOUCHER,
    REMOVE_PAYMENT_VOUCHER,
    GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListPaymentVoucher = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/payment-voucher', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PAYMENT_VOUCHER, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListPaymentVoucherActive = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/payment-voucher/by-active', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_LIST_PAYMENT_VOUCHER,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_LIST_PAYMENT_VOUCHER_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificPaymentVoucher = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/payment-voucher/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PAYMENT_VOUCHER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createPaymentVoucher = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/payment-voucher', data).then(res => {
            dispatch({ type: CREATE_NEW_PAYMENT_VOUCHER, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updatePaymentVoucher = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/payment-voucher/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PAYMENT_VOUCHER, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removePaymentVoucher = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/payment-voucher`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PAYMENT_VOUCHER, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updatePaymentVoucherStatus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/payment-voucher/status/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PAYMENT_VOUCHER, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}