import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { moneyToWord, capitalize, generateDots, repeatPhoneNumber } from '../../utils/helpers';
import { format } from 'date-fns';

class PrintTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalQuantity: 0,
            totalPrice: 0,
            totalPriceColumn: 0,
            currentDate: format(new Date(), "dd 'tháng' MM 'năm' yyyy") // Format current date
        };
    }

    componentDidMount() {
        this.calculateTotal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData !== this.props.tableData) {
            this.calculateTotal();
        }
    }

    // calculateTotal = () => {
    //     let totalQuantity = 0;
    //     let totalPrice = 0;
    //     let totalPriceColumn = 0;

    //     this.props.tableData.forEach(item => {
    //         totalQuantity += item.quantity;
    //         totalPrice += item.price * item.quantity;
    //         totalPriceColumn += item.price;
    //     });

    //     this.setState({ totalQuantity, totalPrice, totalPriceColumn });
    // }

    calculateTotal = () => {
        return new Promise((resolve) => {
            let totalQuantity = 0;
            let totalPrice = 0;
            let totalPriceColumn = 0;

            this.props.tableData.forEach(item => {
                totalQuantity += item.quantity;
                totalPrice += item.price * item.quantity;
                totalPriceColumn += item.price;
            });

            this.setState({ totalQuantity, totalPrice, totalPriceColumn }, () => {
                resolve();
            });
        });
    }

    render() {
        const { customer } = this.props;

        return (
            <div class="container">
                <div class="c0001">
                    <img src="https://chingodental.vn/logo-1024x1024-a17a4f.png" alt='' />
                    <div class="c0002">
                        <h2>NHA KHOA CHINGO</h2>
                        <p>
                            <strong>Hà Nội:</strong> 560 Nguyễn Văn Cừ, P. Gia Thụy, Q. Long Biên<br />
                            Hotline: 09780 09780
                        </p>
                        <p>
                            <strong>Đà Nẵng:</strong> 22 Lê Hồng Phong, P. Phước Ninh, Q. Hải Châu<br />
                            Hotline: 0916 01 9696
                        </p>
                    </div>
                </div>
                <div class="c0003">
                    <h1>PHIẾU THU</h1>
                    <div class="c0004">
                        {/* Số: {generateDots(30)} */}
                        Số: {customer?.invoice_no ? customer?.invoice_no : generateDots(30)}
                    </div>
                </div>
                <div class="c0011">
                    <div class="c0005">
                        Tên khách hàng: <strong>{customer?.customer_name}</strong>
                    </div>
                    <div class="c0006" style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        Địa chỉ: {customer?.province_name ? customer?.province_name : generateDots(80)}
                        &nbsp;
                        SĐT: {customer?.customer_phone ? repeatPhoneNumber(customer?.customer_phone) : generateDots(60)}
                    </div>
                </div>
                <table class="tb0001">
                    <thead class="ant-table-thead">
                        <tr>
                            <th class="ant-table-cell" style={{ textAlign: "center" }}>STT</th>
                            <th class="ant-table-cell" style={{ textAlign: "left" }}>Sản phẩm/Dịch vụ</th>
                            <th class="ant-table-cell" style={{ width: '65px' }}>Số lượng</th>
                            <th class="ant-table-cell" style={{ textAlign: "left" }}>Đơn giá</th>
                            <th class="ant-table-cell" style={{ textAlign: "left" }}>Thành tiền</th>
                        </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                        {this.props.tableData.map((item, index) => (
                            <tr key={index}>
                                <td className="ant-table-cell" style={{ textAlign: "center" }}>{index + 1}</td>
                                <td className="ant-table-cell">
                                    {item.name}&nbsp;
                                    {item.price == 0 && <i style={{ fontSize: '12px' }}>(Được tặng)</i>}
                                </td>
                                <td className="ant-table-cell" style={{ textAlign: "center" }}>{item.quantity}</td>
                                <td className="ant-table-cell">{<NumberFormat value={item.price} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                                <td className="ant-table-cell">{<NumberFormat value={item.price * item.quantity} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                            </tr>
                        ))}
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Tổng cộng</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={this.state.totalPrice} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Ưu đãi</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={customer?.special_discount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Số tiền thu</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={customer?.amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Tổng đã thu</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={customer?.total_amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr className="ant-table-row" style={{ fontWeight: 'bold' }}>
                            <td className="ant-table-cell" colSpan={4}>Còn nợ</td>
                            <td className="ant-table-cell">
                                <NumberFormat value={customer?.remaining_debt} displayType={'text'} suffix="đ" thousandSeparator={true} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="c0012">
                    <p>
                        Tổng cộng bằng chữ: {customer?.amount_paid ? capitalize(moneyToWord(customer?.amount_paid)) : null}
                    </p>
                    <p style={{ whiteSpace: "nowrap", overflow: 'hidden' }}>
                        Đã nhận đủ:{generateDots(1000)}
                    </p>
                </div>
                <div class="c0009">
                    {customer?.province_name ? customer?.province_name : generateDots(20)}, ngày {this.state.currentDate}
                </div>
                <div class="c0010">
                    <div>
                        <strong>Khách hàng</strong><br />
                        (Ký, họ tên)
                    </div>
                    <div>
                        <strong>Người lập phiếu</strong><br />
                        (Ký, họ tên)
                    </div>
                    <div>
                        <strong>Giám đốc</strong><br />
                        (Ký, họ tên, đóng dấu)
                    </div>
                </div>
                <style>
                    {`
                                .container {
                                    width: 600px;
                                    margin: 0 auto;
                                    padding: 20px;
                                    /**background: #f9f4f4;*/
                                }
                        
                                .c0001 img {
                                    height: 100px;
                                    display: block;
                                    margin: 0 auto;
                                }
                        
                                .c0001 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                        
                                .c0002 {
                                    font-size: 14px;
                                }
                        
                                .c0002 h2 {
                                    font-size: 16px;
                                    margin: 0 0 10px 0;
                                }
                        
                                .c0002 p {
                                    margin: 5px 0 0 0;
                                }
                        
                                .c0003 {
                                    text-align: center;
                                    position: relative;
                                    margin: 20px 0;
                                }
                        
                                .c0003 h1 {
                                    margin: 0;
                                }
                        
                                .c0004 {
                                    display: inline-block;
                                    position: absolute;
                                    right: 0;
                                    bottom: 4px;
                                }
                        
                                .c0011 {
                                    margin: 0 0 20px 0;
                                    clear: both;
                                }
                        
                                .c0011 div {
                                    margin: 10px 0;
                                }
                        
                                .tb0001 {
                                    border: 1px solid #dedede;
                                    border-collapse: collapse;
                                    width: 100%;
                                    max-width: 100%;
                                    margin: 20px 0;
                                }
                        
                                .tb0001 th {
                                    border: 1px solid #929292;
                                    padding: 6px;
                                }
                        
                                .tb0001 td {
                                    border: 1px solid #929292;
                                    padding: 6px;
                                }
                        
                                .c0009 {
                                    text-align: right;
                                    margin: 15px 0;
                                }
                        
                                .c0010 {
                                    display: flex;
                                    justify-content: space-between;
                                    text-align: center;
                                    margin-bottom: 120px;
                                }
                        
                                .c0012 {
                                    margin: 0 0 10px 0;
                                }
                        
                                .c0012 p {
                                    margin: 8px 0;
                                }
                            `}
                </style>
            </div>
        );
    }
}

export default PrintTable;
