import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Drawer, Modal, Menu } from 'antd';
import {
    LogoutOutlined,
    ExclamationCircleOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import menu from '../menu';
import MenuGroupItem from '../components/Menu/MenuGroupItem';
import MenuItem from '../components/Menu/MenuItem';
import logo from '../assets/img/logo-white-text.png';
// actions
import { logout } from '../redux/actions/AuthActions';
const { confirm } = Modal;

class AppDrawer extends Component {
    onLogout() {
        confirm({
            title: 'Xác nhận đăng xuất',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn chắc chắn muốn đăng xuất chứ?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                this.props.logout().then(() => {
                    window.location.href = '/login'
                })
            }
        })
    }

    onClickMenu(item, key, keyPath) {
        if (key == 'logout') this.onLogout();
        // else if (key == 'profile') this.props.history.push(key);
        else this.props.history.push(key);
        this.props.onClose();
    }

    render() {
        var { pathname } = this.props.location;
        var { visible, onClose, config } = this.props;

        return (
            <Drawer
                placement="left"
                closable={false}
                width='85%'
                className='ecom-menu'
                onClose={() => onClose()}
                visible={visible}
                headerStyle={{ padding: '0 10px 0 10px', background: '#0b2b33' }}
                bodyStyle={{ padding: 2 }}
                title={<>
                    <Link to="/">
                        <img className='drawer-logo-mobile' src={logo} alt="Logo" />
                    </Link>
                </>}
            >
                <Menu
                    mode="inline"
                    selectedKeys={[pathname]}
                    style={{ height: '100%', borderRight: 0 }}
                    onClick={({ item, key, keyPath }) => this.onClickMenu(item, key, keyPath)}
                >
                    {
                        menu.map((item, key) => {
                            if (item.children) {
                                return <MenuGroupItem item={item} key={key} />
                            } else {
                                return <MenuItem item={item} key={item.path} />
                            }
                        })
                    }
                    {/* <Menu.Item key="profile" icon={<InfoCircleOutlined />}>
                        Thông tin tài khoản
                    </Menu.Item> */}
                    <Menu.Item key="logout" icon={<LogoutOutlined className='sidebar-icon' />}>
                        Đăng xuất
                    </Menu.Item>
                </Menu>
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDrawer));