import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, DatePicker, Switch, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, replaceHeadingsWithDivs, stripHtml } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import PostsForm from './PostsForm';
import PostsDetail from './PostsDetail';

// actions
import { getListPosts, getOnePosts, removePosts, updatePosts } from '../../redux/actions/PostsAction';
import HtmlParser from 'react-html-parser';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/posts',
        breadcrumbName: 'Danh sách bài viết',
    }
]

class PostsList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            isOpenDetail: false,
            current_id: null,
            currentData: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListPosts({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListPosts({ ...query }).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removePosts(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.reloadData()
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListPosts({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenAddForm: false
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getOnePosts(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenAddForm,
            isOpenDetail,
            currentData,
            current_id
        } = this.state;
        const { posts, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '40px'
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                width: '300px',
                render: (text, record) => {
                    return (
                        <div onClick={() => this.onDetail(record.id)} className='primary-color item-action-btn'>
                            <Tooltip placement='right' title={HtmlParser(replaceHeadingsWithDivs(record.title))}>
                                <TextTruncate text={stripHtml(record.title)} line={1} truncateText="..." />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Keyword',
                dataIndex: 'keyword',
                key: 'keyword',
                width: '200px',
            },
            {
                title: 'Domain used',
                dataIndex: 'domain_used',
                key: 'domain_used',
                width: '200px',
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            <Tooltip placement='right' title={HtmlParser(replaceHeadingsWithDivs(record.note))}>
                                <TextTruncate text={stripHtml(record.note)} line={1} truncateText="..." />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Tình trạng',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => (
                    <div>
                        <Switch
                            defaultChecked={record.status}
                            checkedChildren="Đã hoàn thiện"
                            unCheckedChildren="Chưa hoàn thiện"
                            onChange={(value) => this.props.updatePosts(record.id, { status: value })}
                        />
                    </div>
                )
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>{isValidDate(text)}</>
                    )
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={[
                    { value: 1, label: 'Đã hoàn thiện' },
                    { value: 0, label: 'Chưa hoàn thiện' },
                ]}
                onChange={(value) => this.onChangeFilter("status", value)}
                placeholder="Tình trạng"
                defaultValue={query.status ? parseInt(query.status) : null}
                className="w-100"
            />,
            <DatePicker
                format="DD/MM/YYYY"
                className="w-100"
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                className="w-100"
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách bài viết" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('posts', 'create')}
                        isShowDeleteButton={checkPermission('posts', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.setState({ isOpenAddForm: true })}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={query.status || parseInt(query.status)}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={posts || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <PostsForm
                    visible={isOpenAddForm}
                    onCancel={() => this.handleCancel()}
                    reloadData={() => this.reloadData()}
                />

                <PostsDetail
                    visible={isOpenDetail}
                    currentData={currentData}
                    reloadData={() => { this.onDetail(current_id); this.reloadData() }}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        posts: state.PostsReducer.posts,
        pagination: state.PostsReducer.pagination,
        authUser: state.auth.authUser,
        users: state.config.users,
        admins: state.config.admins
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListPosts: filter => dispatch(getListPosts(filter)),
        removePosts: ids => dispatch(removePosts(ids)),
        updatePosts: (id, data) => dispatch(updatePosts(id, data)),
        getOnePosts: (id) => dispatch(getOnePosts(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsList);
