import api from '../../utils/api';
import {
    GET_LIST_PROVINCES,
    GET_LIST_DISTRICTS,
    GET_LIST_WARDS,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListProvinces = () => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/regions/provinces').then(res => {
            dispatch({ type: GET_LIST_PROVINCES, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListDistricts = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/regions/provinces/districts', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_DISTRICTS, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListWards = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/regions/provinces/districts/wards', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_WARDS, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}