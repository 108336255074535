import {
    GET_LIST_CUSTOMER_POTENTIAL,
    UPDATE_SPECIFIC_CUSTOMER_POTENTIAL,
    CREATE_NEW_CUSTOMER_POTENTIAL,
    REMOVE_CUSTOMER_POTENTIAL,
    GET_LIST_CUSTOMER_POTENTIA_ACTIVE,
    GET_LIST_NAME_CUSTOMER_POTENTIAL
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    customerList: [],
    customerActiveList: [],
    list_name: [],
    currentCustomer: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    },
    pagination_active: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER_POTENTIAL: {
            return {
                ...state,
                customerList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_LIST_NAME_CUSTOMER_POTENTIAL: {
            return {
                ...state,
                list_name: action.payload.data
            }
        }

        case GET_LIST_CUSTOMER_POTENTIA_ACTIVE: {
            let listCustomers = [...state.customerActiveList, ...action.payload.data];
            return {
                ...state,
                customerActiveList: listCustomers.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination_active: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case CREATE_NEW_CUSTOMER_POTENTIAL: {
            return {
                ...state,
                customerList: [action.payload, ...state.customerList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_CUSTOMER_POTENTIAL: {
            let index = state.customerList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.customerList];
            temp[index] = action.payload;
            return { ...state, customerList: temp }
        }
        case REMOVE_CUSTOMER_POTENTIAL: {
            let temp = state.customerList.filter(item => {
                //console.log(action.payload)
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, customerList: temp }
        }
        default: return { ...state };
    }
}
