import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, InputNumber, Drawer, Space, Divider, Table, Popconfirm, DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import { _newLine, ProductReceiptImportType, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseSelectCustom from '../../components/Elements/BaseSelect/custom';
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ListCurrentImage from '../../components/ListCurrentImage';
import { NotificationManager } from 'react-notifications'
import UploadImage from '../../components/UploadImage';
// actions
import { createNewProductReceiptImport, updateSpecificProductReceiptImport } from '../../redux/actions/ProductReceiptImportActions';
import { getProductSupplierActive } from '../../redux/actions/ProductSupplierAction';
import { getProductWarehouseActive } from '../../redux/actions/ProductWarehouseAction';
import { getAllProduct, getSpecificProduct } from '../../redux/actions/ProductActions';
import { getProductGroupActive } from '../../redux/actions/ProductGroupActions';
import { getUnitActive } from '../../redux/actions/UnitActions'
import { getProductStatusActive } from '../../redux/actions/ProductStatusActions';

class ProductReceiptImportForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            items: [],
            count: 0,
            productQuery: '',
            images: [],
            currentImages: [],
            isResetUploadImage: true
        };
    }
    componentDidMount() {
        this.props.getProductSupplierActive();
        this.props.getProductWarehouseActive();
        this.props.getAllProduct();
        this.props.getProductGroupActive();
        this.props.getUnitActive();
        this.props.getProductStatusActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.items && Array.isArray(nextProps?.currentItem?.items)) {
                this.renderNewItem(nextProps?.currentItem?.items)
            } else {
                this.setState({ items: [] })
            }
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    renderNewItem(items = []) {
        //console.log('items: ', items)
        let new_items = items.map((item, index) => {
            return {
                id: item.id,
                item_id: item.item_id,
                stt: index + 1,
                name: item.name,
                regular_price: item.regular_price,
                quantity: item.quantity,
                amount: item.regular_price * item.quantity,
                group_id: item.group_id,
                expiry_date: item.expiry_date,
                note: item.note,
                unit_id: item.unit_id,
                product_status_id: item.product_status_id,
                mode: item.mode
            }
        })
        this.setState({
            items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        });
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.id != id;
        });
        this.renderNewItem(items)
    }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.renderNewItem(items)
    }

    onChangeProduct(id) {
        this.setState({ isloading: true })
        this.props.getSpecificProduct(id).then((item) => {
            let index = this.state.count + 1;
            let new_items = {
                id: item.id,
                item_id: item.item_id,
                stt: index,
                name: item.name,
                regular_price: item.regular_price,
                quantity: item.quantity,
                amount: item.regular_price * item.quantity,
                group_id: item.group_id,
                expiry_date: null,
                note: item.note,
                unit_id: item.unit_id,
                product_status_id: item.product_status_id,
                mode: null
            }
            this.setState({
                items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                count: index,
                isloading: false
            })
        })
    }

    onAdd() {
        let index = this.state.count + 1;
        let currentTime = new Date().getTime();
        let new_items = {
            id: index + currentTime,
            stt: index,
            name: null,
            group_id: null,
            regular_price: null,
            quantity: 1,
            amount: 0,
            unit_id: null,
            product_status_id: null,
            expiry_date: null,
            note: null,
            mode: 'add_new'
        }
        this.setState({
            items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
            count: index,
            isloading: false
        })
    }

    onSearchProduct(val) {
        this.setState({ productQuery: val }, () => {
            this.props.getAllProduct({ keyword: val });
        })
    }

    handleScroll(e) {
        this.props.getAllProduct({ keyword: this.state.productQuery, page: this.props.pagination.currentPage + 1 }, true);
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { items, currentImages, images } = this.state;
                    values.images = customDataImageUpload(images, currentImages);
                    values.import_date = values.import_date ? values.import_date.format("YYYY-MM-DD") : null;
                    values.items = items;
                    var total_amount = 0;
                    items.forEach(item => {
                        total_amount += item.amount;
                    });
                    values.total_amount = total_amount;
                    //console.log('values: ', values)
                    if (this.props.currentItem) {
                        this.props.updateSpecificProductReceiptImport(this.props.currentItem.id, values).then((res) => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(err => {
                            this.setState({ isLoading: false });
                        })
                    } else {
                        await this.props.createNewProductReceiptImport(values).then(res => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(err => {
                            this.setState({ isLoading: false });
                        })
                    }

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, items: [], images: [], isResetUploadImage: true })
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        var { isLoading, items, isResetUploadImage, currentImages } = this.state;
        var { visible, currentItem,
            product_supplier_active_list,
            product_warehouse_active_list,
            product_list, product_group_active_list,
            unit_active_list,
            product_status_active_list
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        //console.log("currentItem: ", currentItem)

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center'
            },
            {
                title: "Tên sản phẩm",
                dataIndex: "name",
                width: "180px",
                render: (text, record) => {
                    if (record.mode == 'add_new') {
                        return (
                            <Input.TextArea
                                value={text}
                                name="name"
                                className='w-100'
                                style={{ height: 16, marginBottom: 0 }}
                                onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.id)}
                            />
                        )
                    } else {
                        return (<>{text}</>)
                    }
                }
            },
            {
                title: "Nhóm sản phẩm",
                dataIndex: "group_id",
                width: "130px",
                render: (text, record) => {
                    return (
                        <BaseSelect
                            options={product_group_active_list || []}
                            onChange={(value) => this.onChangeItem('group_id', value, record.id)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(record.group_id) || null}
                            optionLabel="name"
                            placeholder="Chọn nhóm sản phẩm"
                            className='w-100'
                        />
                    )
                }
            },
            {
                title: "Giá nhập",
                dataIndex: "regular_price",
                key: 'regular_price',
                width: "100px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="regular_price"
                            min={0}
                            step={1000}
                            className='w-100'
                            onChange={(value) => this.onChangeItem('regular_price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                key: 'quantity',
                width: "80px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="quantity"
                            min={1}
                            className='w-100'
                            //onChange={(value) => this.onChangeItem('quantity', value, record.id)}
                            onChange={(value) => {
                                // Kiểm tra giá trị và thay thế nếu cần thiết
                                const validValue = value == null || value <= 0 ? 1 : value;
                                this.onChangeItem('quantity', validValue, record.id);
                            }}
                        />
                    )
                }
            },
            {
                title: "Đơn vị tính",
                dataIndex: "unit_id",
                width: "100px",
                render: (text, record) => {
                    return (
                        <BaseSelect
                            options={unit_active_list || []}
                            onChange={(value) => this.onChangeItem('unit_id', value, record.id)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(record.unit_id) || null}
                            optionLabel="name"
                            placeholder="Chọn"
                            className='w-100'
                        />
                    )
                }
            },
            {
                title: "Tình trạng",
                dataIndex: "product_status_id",
                width: "100px",
                render: (text, record) => {
                    return (
                        <BaseSelect
                            options={product_status_active_list || []}
                            onChange={(value) => this.onChangeItem('product_status_id', value, record.id)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(record.product_status_id) || null}
                            optionLabel="name"
                            placeholder="Chọn"
                            className='w-100'
                        />
                    )
                }
            },
            {
                title: "Hạn sử dụng",
                dataIndex: "expiry_date",
                width: "130px",
                render: (text, record) => {
                    return (
                        <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="Chọn ngày"
                            value={text ? moment(text, 'YYYY-MM-DD') : text}
                            name="expiry_date"
                            onChange={(value) => this.onChangeItem('expiry_date', value, record.id)}
                            className='w-100'
                        />
                    )
                }
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                width: "200px",
                render: (text, record) => {
                    return (
                        <Input.TextArea
                            value={text}
                            name="note"
                            style={{ height: 16, marginBottom: 0 }}
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.id)}
                        />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa sản phẩm này?"
                            onConfirm={() => this.onDelete(record.id)}
                        >
                            <DeleteTwoTone twoToneColor="#fe0000" />
                        </Popconfirm>
                    )
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.amount;
        });

        return (
            <Drawer
                visible={visible}
                title={currentItem ? "Sửa phiếu" : "Tạo phiếu nhập kho"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '75%'}
                footer={
                    <Row justify="end">
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                {currentItem ? "Cập nhật" : "Tạo phiếu"}
                            </Button>
                        </Space>
                    </Row>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Divider orientation="left" orientationMargin="0">
                                    Thông tin sản phẩm
                                </Divider>
                                <Row gutter={16}>
                                    <Col xs={24} md={18}>
                                        <BaseSelectCustom
                                            options={product_list}
                                            optionValue="id"
                                            optionLabel="name"
                                            additionalLabel1="quantity"
                                            additionalLabel2="expiry_date_format"
                                            additionalLabel3="warehouse_name"
                                            showSearch
                                            allowClear={false}
                                            defaultValue="Chọn sản phẩm có sẵn trong kho"
                                            placeholder="Chọn sản phẩm có sẵn trong kho"
                                            onSearch={(val) => this.onSearchProduct(val)}
                                            onScrollEnd={(e) => this.handleScroll(e)}
                                            onChange={(id) => this.onChangeProduct(id)}
                                            className="w-100 mb-2"
                                        />
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Button onClick={() => this.onAdd()} type='primary' icon={<PlusOutlined />}>Thêm sản phẩm mới</Button>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Table
                                            className='mt-2'
                                            size='small'
                                            rowKey='key'
                                            tableLayout="auto"
                                            columns={columns}
                                            dataSource={items || []}
                                            pagination={false}
                                            loading={isLoading}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                            scroll={{
                                                x: 'max-content'
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row justify="end" gutter={16} className='mt-4'>
                                    <Col xs={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <h5>Tổng tiền: <NumberFormat value={total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} /></h5>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={24}>
                                <Divider orientation="left" orientationMargin="0">
                                    Thông tin phiếu
                                </Divider>
                                <div className='box-info'>
                                    <Row gutter={16}>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Tên phiếu">
                                                {getFieldDecorator('name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.name : ""
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Loại phiếu">
                                                {getFieldDecorator('type', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.type : null
                                                })(
                                                    <BaseSelect
                                                        options={ProductReceiptImportType}
                                                        placeholder="Loại phiếu"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Nhập vào kho">
                                                {getFieldDecorator('warehouse_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.warehouse_id : null
                                                })(<BaseSelect
                                                    options={product_warehouse_active_list || []}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    showSearch
                                                    placeholder="Chọn kho sản phẩm"
                                                    style={{ width: '100%' }}
                                                />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Nhà cung cấp">
                                                {getFieldDecorator('supplier_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.supplier_id : null
                                                })(<BaseSelect
                                                    options={product_supplier_active_list || []}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    showSearch
                                                    placeholder="Nhà cung cấp"
                                                    style={{ width: '100%' }}
                                                />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Ngày nhập kho">
                                                {getFieldDecorator('import_date', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? isValidDateForm(currentItem.import_date, true) : moment()
                                                })(
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        placeholder="Chọn ngày"
                                                        className='w-100'
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Ảnh phiếu" className='flex-upload'>
                                                <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                                {getFieldDecorator('images', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <UploadImage
                                                        multiple={true}
                                                        maxCount={30}
                                                        onChangeData={this.onChangeImage}
                                                        isResetUpload={isResetUploadImage}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? _newLine(currentItem.note) : ""
                                        })(
                                            <Input.TextArea />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_supplier_active_list: state.ProductSupplierReducer.product_supplier_active_list,
        product_warehouse_active_list: state.ProductWarehouseReducer.product_warehouse_active_list,
        product_list: state.ProductReducer.product_list,
        pagination: state.ProductReducer.pagination,
        product_group_active_list: state.ProductGroupReducer.product_group_active_list,
        unit_active_list: state.UnitReducer.unit_active_list,
        product_status_active_list: state.ProductStatusReducer.product_status_active_list,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewProductReceiptImport: (data) => dispatch(createNewProductReceiptImport(data)),
        updateSpecificProductReceiptImport: (id, data) => dispatch(updateSpecificProductReceiptImport(id, data)),
        getProductSupplierActive: () => dispatch(getProductSupplierActive()),
        getProductWarehouseActive: () => dispatch(getProductWarehouseActive()),
        getAllProduct: (filter, concatenate) => dispatch(getAllProduct(filter, concatenate)),
        getSpecificProduct: (id) => dispatch(getSpecificProduct(id)),
        getProductGroupActive: () => dispatch(getProductGroupActive()),
        getUnitActive: () => dispatch(getUnitActive()),
        getProductStatusActive: () => dispatch(getProductStatusActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductReceiptImportForm' })(ProductReceiptImportForm));
