import api from '../../utils/api';
import {
    GET_LIST_HISTORY,
    GET_SPECIFIC_HISTORY,
    REMOVE_HISTORY,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getList = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/history', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_HISTORY, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecific = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/history/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_HISTORY, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const remove = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/history`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_HISTORY, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
