import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DeleteOutlined,
    ReloadOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Table, Modal, Tooltip, Avatar, Button, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import { checkPermission } from '../../utils/permission';
import { getFirstLetterOfWords, cleanObject } from '../../utils/helpers';
import moment from 'moment';
// actions
import { getListRewardCustomerHas, removeRewardCustomerHas } from '../../redux/actions/RewardSpinActions';

const { confirm } = Modal;
const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/reward-customer-has',
        breadcrumbName: 'Khách hàng đã quay',
    },
]

class RewardSpinCustomerHasList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: []
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListRewardCustomerHas(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListRewardCustomerHas(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeRewardCustomerHas(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.reloadData()
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá khách hàng này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeRewardCustomerHas([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData()
            }),
            onCancel() { },
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListRewardCustomerHas(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ selectedRowKeys: [] })
    }

    render() {
        var { reward_customer_has_list, pagination } = this.props;
        var { isLoading, selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('reward_spin', 'remove_customer_has') ? (
                                <Tooltip title="Xóa" placement="top">
                                    <DeleteOutlined className='item-action-btn color-red' onClick={() => this.openAlert(record.id, query.tab)} />
                                </Tooltip>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Paragraph style={{ margin: 0 }} copyable={
                            {
                                text: record ? record.code : null,
                                tooltips: false
                            }
                        }>
                            {text}
                        </Paragraph>
                    )
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                render: (text, record) => {
                    if (record.full_name) {
                        return (
                            <>
                                {
                                    record.avatar ? (
                                        <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    )
                                }
                                &nbsp;{record.full_name}
                            </>
                        )
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã thẻ',
                dataIndex: 'card_number_new',
                key: 'card_number_new',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number_new : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Mã thẻ cũ',
                dataIndex: 'card_number',
                key: 'card_number',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng đã quay" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('reward_spin', 'remove_customer_has')}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={reward_customer_has_list || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        reward_customer_has_list: state.RewardSpinReducer.customer_has_list.list,
        pagination: state.RewardSpinReducer.customer_has_list.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListRewardCustomerHas: (filter) => dispatch(getListRewardCustomerHas(filter)),
        removeRewardCustomerHas: (ids) => dispatch(removeRewardCustomerHas(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RewardSpinCustomerHasList' })(RewardSpinCustomerHasList));

