import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, DatePicker, Row, Col, Drawer, Divider, Radio, InputNumber, Tag } from 'antd';
import moment from 'moment';
import { _newLine } from '../../utils/helpers';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';
import { createData, updateData } from '../../redux/actions/WorkReportActions';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseSelectCustom from '../../components/Elements/BaseSelect/custom';
import { getActiveProduct } from '../../redux/actions/ProductActions';
import { getListCashReceiptActive } from '../../redux/actions/CashReceiptActions';
import { getListPaymentVoucherActive } from '../../redux/actions/PaymentVoucherActions';

class WorkReportForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            productQuery: '',
            customerQuery: '',
            cashReceiptQuery: '',
            paymentVoucheQuery: '',
        };
    }

    componentDidMount() {
        this.props.getListCustomerActive();
        this.props.getActiveProduct();
        this.props.getListCashReceiptActive();
        this.props.getListPaymentVoucherActive();
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            console.log("values: ", values)
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateData(this.props.currentData.id, values).then(() => {
                        this.handCancel()
                        this.props.reloadData()
                    }).catch(err => {
                        console.log(err)
                        this.setState({ isloading: false })
                    });
                } else {
                    await this.props.createData(values).then(() => {
                        this.handCancel()
                        this.props.reloadData()
                    }).catch(err => {
                        console.log(err)
                        this.setState({ isloading: false })
                    });
                }
                this.setState({ isloading: false })
            }
        });
    }

    handCancel() {
        this.setState({ isloading: false, showDate: true, result: 'done' })
        this.props.form.resetFields();
        this.props.onCancel();
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleCustomerScroll(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    onSearchProduct(val) {
        this.setState({ productQuery: val }, () => {
            this.props.getActiveProduct({ keyword: val });
        })
    }

    handleScrollProduct(e) {
        this.props.getActiveProduct({ keyword: this.state.productQuery, page: this.props.pagination_product.currentPage + 1 }, true);
    }

    onSearchCashReceipt(val) {
        this.setState({ cashReceiptQuery: val }, () => {
            this.props.getListCashReceiptActive({ keyword: val });
        })
    }

    handleScrollCashReceipt(e) {
        this.props.getListCashReceiptActive({ keyword: this.state.cashReceiptQuery, page: this.props.pagination_cash_receipt.currentPage + 1 }, true);
    }

    onSearchPaymentVoucher(val) {
        this.setState({ paymentVoucheQuery: val }, () => {
            this.props.getListPaymentVoucherActive({ keyword: val });
        })
    }

    handleScrollPaymentVoucher(e) {
        this.props.getListPaymentVoucherActive({ keyword: this.state.paymentVoucheQuery, page: this.props.pagination_payment_voucher.currentPage + 1 }, true);
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, customerActiveList, doctors, product_list, cash_receipt_list, payment_voucher_list } = this.props;
        const { getFieldDecorator } = this.props.form;

        const renderNoteField = (noteFieldName, initialValue) => (
            <Form.Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                {getFieldDecorator(noteFieldName, {
                    rules: [
                        { required: false, message: 'Vui lòng điền!' },
                    ],
                    initialValue: initialValue || ''
                })(<Input.TextArea rows={1} placeholder='Ghi chú' style={{ height: 16, marginBottom: 0 }} />)}
            </Form.Item>
        )

        const renderTypeField = (typeFieldName, initialValue) => (
            <Form.Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                {getFieldDecorator(typeFieldName, {
                    rules: [
                        { required: false, message: 'Vui lòng chọn!' },
                    ],
                    initialValue: initialValue || null
                })(
                    <Radio.Group style={{ paddingTop: 4 }}>
                        <Radio value={1}>Làm chính</Radio>
                        <Radio value={2}>Hỗ trợ</Radio>
                    </Radio.Group>
                )}
            </Form.Item>
        )

        const renderFormElement = (fieldName, noteFieldName, typeFieldName, label, initialValue) => (
            <Form.Item label={label}>
                <Row gutter={16} className='row-group-input'>
                    <Col xs={24} md={10}>
                        {getFieldDecorator(fieldName, {
                            rules: [
                                { required: false, message: 'Vui lòng chọn!' },
                            ],
                            initialValue: initialValue?.fieldName || []
                        })(
                            <BaseSelect
                                options={customerActiveList || []}
                                optionValue="id"
                                optionLabel="full_name"
                                additionalLabel="phone"
                                additionalLabel2="code"
                                isCustomerList={true}
                                showSearch
                                placeholder="Chọn 1 hoặc nhiều khách hàng. Mã, tên, 3 số cuối SĐT"
                                onSearch={(val) => this.onSearchCustomer(val)}
                                onScrollEnd={(e) => this.handleCustomerScroll(e)}
                                className="w-100"
                                mode='multiple'
                            />
                        )}
                    </Col>
                    <Col xs={24} md={8}>
                        {noteFieldName && renderNoteField(noteFieldName, initialValue?.noteFieldName)}
                    </Col>
                    <Col xs={24} md={6}>
                        {typeFieldName && renderTypeField(typeFieldName, initialValue?.typeFieldName)}
                    </Col>
                </Row>
            </Form.Item>
        );

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa báo cáo" : "Thêm mới báo cáo"}
                onOk={(e) => this.handleSubmit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '65%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.handleSubmit(e)}>
                            {currentData ? "Cập nhật" : "Thêm"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={10}>
                                <Form.Item label="Ngày báo cáo">
                                    {getFieldDecorator('report_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? moment(currentData.report_date).isValid() ? moment(currentData.report_date) : null : moment()
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_add_update_customer_ids',
                                    'customer_add_update_note',
                                    'customer_add_update_type',
                                    <><Tag color='#0b2b33'>1</Tag>Tạo mới, cập nhật dữ liệu khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_add_update_customer_ids,
                                        noteFieldName: currentData?.customer_add_update_note,
                                        typeFieldName: currentData?.customer_add_update_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_schedule_customer_ids',
                                    'customer_schedule_note',
                                    'customer_schedule_type',
                                    <><Tag color='#0b2b33'>2</Tag>Hẹn lịch khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_schedule_customer_ids,
                                        noteFieldName: currentData?.customer_schedule_note,
                                        typeFieldName: currentData?.customer_schedule_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_call_schedule_customer_ids',
                                    'customer_call_schedule_note',
                                    'customer_call_schedule_type',
                                    <><Tag color='#0b2b33'>3</Tag>Gọi nhắc lịch khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_call_schedule_customer_ids,
                                        noteFieldName: currentData?.customer_call_schedule_note,
                                        typeFieldName: currentData?.customer_call_schedule_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_consultation_online_customer_ids',
                                    'customer_consultation_online_note',
                                    'customer_consultation_online_type',
                                    <><Tag color='#0b2b33'>4</Tag>Tư vấn online</>,
                                    {
                                        fieldName: currentData?.customer_consultation_online_customer_ids,
                                        noteFieldName: currentData?.customer_consultation_online_note,
                                        typeFieldName: currentData?.customer_consultation_online_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_welcoming_customer_ids',
                                    'customer_welcoming_note',
                                    'customer_welcoming_type',
                                    <><Tag color='#0b2b33'>5</Tag>Đón khách trực tiếp</>,
                                    {
                                        fieldName: currentData?.customer_welcoming_customer_ids,
                                        noteFieldName: currentData?.customer_welcoming_note,
                                        typeFieldName: currentData?.customer_welcoming_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_consultation_customer_ids',
                                    'customer_consultation_note',
                                    'customer_consultation_type',
                                    <><Tag color='#0b2b33'>6</Tag>Tư vấn trực tiếp</>,
                                    {
                                        fieldName: currentData?.customer_consultation_customer_ids,
                                        noteFieldName: currentData?.customer_consultation_note,
                                        typeFieldName: currentData?.customer_consultation_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_telesale_customer_ids',
                                    'customer_telesale_note',
                                    'customer_telesale_type',
                                    <><Tag color='#0b2b33'>7</Tag>Telesale khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_telesale_customer_ids,
                                        noteFieldName: currentData?.customer_telesale_note,
                                        typeFieldName: currentData?.customer_telesale_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_taking_xray_customer_ids',
                                    'customer_taking_xray_note',
                                    'customer_taking_xray_type',
                                    <><Tag color='#0b2b33'>8</Tag>Chụp phim</>,
                                    {
                                        fieldName: currentData?.customer_taking_xray_customer_ids,
                                        noteFieldName: currentData?.customer_taking_xray_note,
                                        typeFieldName: currentData?.customer_taking_xray_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_taking_picture_customer_ids',
                                    'customer_taking_picture_note',
                                    'customer_taking_picture_type',
                                    <><Tag color='#0b2b33'>9</Tag>Chụp ảnh khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_taking_picture_customer_ids,
                                        noteFieldName: currentData?.customer_taking_picture_note,
                                        typeFieldName: currentData?.customer_taking_picture_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'customer_feedback_customer_ids',
                                    'customer_feedback_note',
                                    'customer_feedback_type',
                                    <><Tag color='#0b2b33'>10</Tag>Lấy feedback khách hàng</>,
                                    {
                                        fieldName: currentData?.customer_feedback_customer_ids,
                                        noteFieldName: currentData?.customer_feedback_note,
                                        typeFieldName: currentData?.customer_feedback_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>11</Tag> Nhập lịch bác sĩ</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('doctor_schedule_doctor_ids', {
                                                rules: [
                                                    { required: false, message: 'Vui lòng chọn!' },
                                                ],
                                                initialValue: currentData?.doctor_schedule_doctor_ids || []
                                            })(
                                                <BaseSelect
                                                    options={doctors ?? []}
                                                    optionValue="user_id"
                                                    optionLabel="full_name"
                                                    placeholder="Chọn 1 hoặc nhiều bác sĩ"
                                                    showSearch={true}
                                                    mode="multiple"
                                                    className="w-100"
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('doctor_schedule_note', currentData?.doctor_schedule_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('doctor_schedule_type', currentData?.doctor_schedule_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'supporting_shift_customer_ids',
                                    'supporting_shift_note',
                                    'supporting_shift_type',
                                    <><Tag color='#0b2b33'>12</Tag>Phụ ca</>,
                                    {
                                        fieldName: currentData?.supporting_shift_customer_ids,
                                        noteFieldName: currentData?.supporting_shift_note,
                                        typeFieldName: currentData?.supporting_shift_type
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>13</Tag> Thống kê vật tư kho</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('warehouse_statistics_product_ids', {
                                                rules: [
                                                    { required: false, message: 'Vui lòng chọn!' },
                                                ],
                                                initialValue: currentData?.warehouse_statistics_product_ids || []
                                            })(
                                                <BaseSelectCustom
                                                    options={product_list}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    additionalLabel1="quantity"
                                                    additionalLabel2="expiry_date_format"
                                                    additionalLabel3="warehouse_name"
                                                    showSearch
                                                    mode="multiple"
                                                    allowClear={false}
                                                    placeholder="Chọn vật tư có sẵn trong kho"
                                                    onSearch={(val) => this.onSearchProduct(val)}
                                                    onScrollEnd={(e) => this.handleScrollProduct(e)}
                                                    className="w-100 mb-2"
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('warehouse_statistics_note', currentData?.warehouse_statistics_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('warehouse_statistics_type', currentData?.warehouse_statistics_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>14</Tag> Báo nhập vật tư</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('import_report_product_ids', {
                                                rules: [
                                                    { required: false, message: 'Vui lòng chọn!' },
                                                ],
                                                initialValue: currentData?.import_report_product_ids || []
                                            })(
                                                <BaseSelectCustom
                                                    options={product_list}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    additionalLabel1="quantity"
                                                    additionalLabel2="expiry_date_format"
                                                    additionalLabel3="warehouse_name"
                                                    showSearch
                                                    mode="multiple"
                                                    allowClear={false}
                                                    placeholder="Chọn vật tư có sẵn trong kho"
                                                    onSearch={(val) => this.onSearchProduct(val)}
                                                    onScrollEnd={(e) => this.handleScrollProduct(e)}
                                                    className="w-100 mb-2"
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('import_report_note', currentData?.import_report_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('import_report_type', currentData?.import_report_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>15</Tag> Nhập phiếu thu</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('entry_receipt_receipt_ids', {
                                                rules: [
                                                    { required: false, message: 'Vui lòng chọn!' },
                                                ],
                                                initialValue: currentData?.entry_receipt_receipt_ids || []
                                            })(
                                                <BaseSelect
                                                    options={cash_receipt_list ?? []}
                                                    optionValue="id"
                                                    optionLabel="customer_name"
                                                    additionalLabel="code"
                                                    placeholder="Chọn 1 hoặc nhiều phiếu thu, Nhập tên KH hoặc mã phiếu thu"
                                                    showSearch={true}
                                                    mode="multiple"
                                                    className="w-100"
                                                    onSearch={(val) => this.onSearchCashReceipt(val)}
                                                    onScrollEnd={(e) => this.handleScrollCashReceipt(e)}
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('entry_receipt_note', currentData?.entry_receipt_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('entry_receipt_type', currentData?.entry_receipt_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>16</Tag> Nhập phiếu chi</>}>
                                    <Row gutter={16} className='row-group-input'>

                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('entry_payment_voucher_voucher_ids', {
                                                rules: [
                                                    { required: false, message: 'Vui lòng chọn!' },
                                                ],
                                                initialValue: currentData?.entry_payment_voucher_voucher_ids || []
                                            })(
                                                <BaseSelect
                                                    options={payment_voucher_list ?? []}
                                                    optionValue="id"
                                                    optionLabel="recipient"
                                                    additionalLabel="code"
                                                    placeholder="Chọn 1 hoặc nhiều phiếu chi. Nhập tên KH hoặc mã phiếu chi"
                                                    showSearch={true}
                                                    mode="multiple"
                                                    className="w-100"
                                                    onSearch={(val) => this.onSearchPaymentVoucher(val)}
                                                    onScrollEnd={(e) => this.handleScrollPaymentVoucher(e)}
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('entry_payment_voucher_note', currentData?.entry_payment_voucher_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('entry_payment_voucher_type', currentData?.entry_payment_voucher_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'doctor_shift_customer_ids',
                                    'doctor_shift_note',
                                    'doctor_shift_type',
                                    <><Tag color='#0b2b33'>17</Tag>Thực hiện ca (Bác sĩ)</>,
                                    {
                                        fieldName: currentData?.doctor_shift_customer_ids,
                                        noteFieldName: currentData?.doctor_shift_note,
                                        typeFieldName: currentData?.doctor_shift_type,
                                    }
                                )}
                            </Col>
                            <Col xs={24} md={24}>
                                {renderFormElement(
                                    'doctor_consultation_shift_customer_ids',
                                    'doctor_consultation_shift_note',
                                    'doctor_consultation_shift_type',
                                    <><Tag color='#0b2b33'>18</Tag>Hội chẩn (Bác sĩ)</>,
                                    {
                                        fieldName: currentData?.doctor_consultation_shift_customer_ids,
                                        noteFieldName: currentData?.doctor_consultation_shift_note,
                                        typeFieldName: currentData?.doctor_consultation_shift_type,
                                    }
                                )}
                            </Col>

                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>19</Tag> Doanh thu cuối ngày</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={10}>
                                            {getFieldDecorator('end_day_revenue', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData?.end_day_revenue || null
                                            })(
                                                <InputNumber
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    className="w-100"
                                                    step={100000}
                                                    placeholder='Đơn vị vnđ'
                                                />
                                            )}
                                        </Col>
                                        <Col xs={24} md={8}>
                                            {renderNoteField('end_day_revenue_note', currentData?.end_day_revenue_note)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('end_day_revenue_type', currentData?.end_day_revenue_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>20</Tag> Vệ sinh dụng cụ</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={18}>
                                            {getFieldDecorator('afternoon_cleaning', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData?.afternoon_cleaning || null
                                            })(<Input.TextArea rows={1} style={{ height: 16, marginBottom: 0 }} placeholder='Mô tả chỉ tiết' />)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('afternoon_cleaning_type', currentData?.afternoon_cleaning_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>21</Tag> Vệ sinh khu vực tiếp khách</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={18}>
                                            {getFieldDecorator('evening_guest_area_cleaning', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData?.evening_guest_area_cleaning || null
                                            })(<Input.TextArea rows={1} style={{ height: 16, marginBottom: 0 }} placeholder='Mô tả chi tiết' />)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('evening_guest_area_cleaning_type', currentData?.evening_guest_area_cleaning_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>22</Tag> Vệ sinh khu vực làm dịch vụ</>}>
                                    <Row gutter={16} className='row-group-input'>
                                        <Col xs={24} md={18}>
                                            {getFieldDecorator('evening_service_area_cleaning', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData?.evening_service_area_cleaning || null
                                            })(<Input.TextArea rows={1} style={{ height: 16, marginBottom: 0 }} placeholder='Mô tả chi tiết' />)}
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {renderTypeField('evening_service_area_cleaning_type', currentData?.evening_service_area_cleaning_type)}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>23</Tag> Nội dung báo cáo khác</>}>
                                    {getFieldDecorator('other_report_contents', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.content : null
                                    })(
                                        <CKEditorComponent
                                            initialHeight="300px"
                                            editorData={currentData?.content}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label={<><Tag color='#0b2b33'>24</Tag> Ghi chú</>}>
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? _newLine(currentData.note) : null
                                    })(
                                        <Input.TextArea rows={2} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        doctors: state.config.doctors,
        product_list: state.ProductReducer.product_list,
        pagination_product: state.ProductReducer.pagination,
        cash_receipt_list: state.CashReceiptReducer.cash_receipt_list,
        pagination_cash_receipt: state.CashReceiptReducer.pagination,
        payment_voucher_list: state.PaymentVoucherReducer.payment_voucher_list,
        pagination_payment_voucher: state.PaymentVoucherReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data)),
        getListCustomerActive: (params) => dispatch(getListCustomerActive(params)),
        getActiveProduct: (params, concatenate) => dispatch(getActiveProduct(params, concatenate)),
        getListCashReceiptActive: (params, concatenate) => dispatch(getListCashReceiptActive(params, concatenate)),
        getListPaymentVoucherActive: (params, concatenate) => dispatch(getListPaymentVoucherActive(params, concatenate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'WorkReportForm' })(WorkReportForm));
