import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AreaChartOutlined, ClockCircleOutlined, CustomerServiceOutlined, FileSearchOutlined, MedicineBoxOutlined, MenuOutlined, OneToOneOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import PageTitle from '../../components/PageTitle';
import { isMobile } from 'react-device-detect';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import { cleanObject } from '../../utils/helpers';
import moment from 'moment';

import Statistic from './Statistic';
import CustomerInfo from './CustomerInfo';
import AppDrawer from '../../layouts/AppDrawer';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-data',
        breadcrumbName: 'Dữ liệu khách hàng',
    },
]
const { TabPane } = Tabs;

class CustomerData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            visibleDrawer: false
        }
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    onChangeFilter(name, value) {
        let query = qs.parse(this.props.location.search.slice(1));

        const handleDateRange = (startKey, endKey, dateValue) => {
            if (dateValue && dateValue.length > 0) {
                query[startKey] = moment(dateValue[0]).format('YYYY-MM-DD');
                query[endKey] = moment(dateValue[1]).format('YYYY-MM-DD');
            } else {
                delete query[startKey];
                delete query[endKey];
            }
        };

        if (name === 'created_at') {
            handleDateRange('created_date_start', 'created_date_end', value);
        } else if (name === 'appointment_schedule') {
            handleDateRange('appointment_schedule_start', 'appointment_schedule_end', value);
        } else if (name === 'usage_date') {
            handleDateRange('usage_date_start', 'usage_date_end', value);
        } else {
            query[name] = value;
        }

        delete query.created_at;
        delete query.usage_date;
        delete query.appointment_schedule;

        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onTabClick = (key) => {
        // Cập nhật state và URL khi tab thay đổi
        this.setState({ activeTab: key });
        this.onChangeFilter('tab', key);
    };

    toggleDrawer(visibleDrawer) {
        this.setState({ visibleDrawer })
    }


    render() {
        const { visibleDrawer } = this.state;
        const { location } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        const shouldShowItemMenu = (isMobile && location.pathname.includes('customer-data'));

        return (
            <div>
                {isMobile ? null : (
                    <>
                        <PageTitle routes={routes} title="Dữ liệu khách hàng" />
                        <div style={{ marginBottom: 15 }} />
                    </>
                )}
                <Tabs
                    onChange={(value) => this.onChangeFilter('tab', value)}
                    defaultActiveKey={query.tab}
                    type="card"
                    size='large'
                    className='tabs-custom'
                    tabBarStyle={{
                        background: "#0b2b33",
                        ...(isMobile ? { marginLeft: '-8px', marginRight: '-8px' } : {})
                    }}
                >
                    <TabPane tab={<span ><AreaChartOutlined />Thống kê</span>} key="statistic" forceRender>
                        <Statistic />
                    </TabPane>
                    <TabPane tab={<span><FileSearchOutlined />Tra cứu khách hàng</span>} key="lookup" forceRender>
                        <CustomerInfo />
                    </TabPane>
                    <TabPane tab={<span><ClockCircleOutlined />Lịch hẹn trong ngày</span>} key="schedule" forceRender>
                        Lịch hẹn trong ngày
                    </TabPane>
                    <TabPane tab={<span><CustomerServiceOutlined />Telesale</span>} key="telesale" forceRender>
                        Telesale
                    </TabPane>
                    <TabPane tab={<span><OneToOneOutlined />Tư vấn trực tiếp</span>} key="consultant-direct" forceRender>
                        Tư vấn trực tiếp
                    </TabPane>
                    <TabPane tab={<span><MedicineBoxOutlined />Phụ tá</span>} key="assistant" forceRender>
                        Phụ tá
                    </TabPane>
                    <TabPane tab={<span style={{ marginRight: 50 }}><MedicineBoxOutlined />Bác sĩ</span>} key="doctor" forceRender>
                        Bác sĩ
                    </TabPane>
                </Tabs>

                {shouldShowItemMenu ? (
                    <>
                        <div className='menu-icon-mobile' onClick={() => this.toggleDrawer(true)}>
                            <MenuOutlined />
                        </div>
                        <AppDrawer visible={visibleDrawer} onClose={() => this.toggleDrawer(false)} />
                    </>
                ) : null}

            </div >
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerData' })(CustomerData));

