import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { downloadFromLink } from '../utils/helpers';

class ListCurrentFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFiles: props.currentFiles || [],
        };
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    deleteFiles = (name) => {
        this.setState(prevState => ({
            currentFiles: prevState.currentFiles.filter(item => item.name !== name)
        }), () => {
            this.props.getData(this.state.currentFiles); // Gọi callback để trả về currentFiles mới
        });
    }

    render() {
        const { currentFiles } = this.state;

        return (
            <>
                {currentFiles.map(item => (
                    <div key={item.name}>
                        <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteFiles(item.name)}
                        />
                    </div>
                ))}
            </>
        );
    }

}

export default ListCurrentFiles;