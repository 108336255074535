import {
    GET_LIST_CASH_RECEIPT,
    REMOVE_CASH_RECEIPT,
    GET_LIST_CASH_RECEIPT_WITH_CONCATENATE,
    GET_STATISTICAL_CASH_RECEIPT
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    cash_receipt_list: [],
    statistical: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CASH_RECEIPT: {
            return {
                ...state,
                cash_receipt_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_LIST_CASH_RECEIPT_WITH_CONCATENATE: {
            let listCashReceipts = [...state.cash_receipt_list, ...action.payload.data];

            return {
                ...state,
                cash_receipt_list: listCashReceipts.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case REMOVE_CASH_RECEIPT: {
            let temp = state.cash_receipt_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, cash_receipt_list: temp }
        }

        case GET_STATISTICAL_CASH_RECEIPT:
            return {
                ...state,
                statistical: action.payload
            };
        default: return { ...state };
    }
}
