import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Avatar, Tag, Divider, Button, Select } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { cleanObject, stripHtml, getFirstLetterOfWords, isValidDate } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
// actions
import { getAllUsers, getUser, removeUsers } from '../../redux/actions/UserActions';
import UserForm from './UserForm';
import UserDetail from './UserDetail';
import BaseSelect from '../../components/Elements/BaseSelect';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/users',
        breadcrumbName: 'Nhân viên',
    },
]

class UserList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenUserForm: false,
        currentUser: null,
        isOpenDetail: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllUsers(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllUsers(query).then(() => {
                this.setState({
                    isLoading: false
                })
            });
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query.page = 1;
        query = cleanObject(query); // remove empty fields

        this.props.history.push({
            pathname: '/users',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));

        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order == 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/users',
            search: qs.stringify(query)
        });

    }

    toggleOpenForm(isOpenUserForm, user = null) {
        this.setState({ isOpenUserForm: isOpenUserForm, currentUser: user });
    }

    onEdit(id) {
        this.props.getUser(id).then(user => {
            this.toggleOpenForm(true, user);
        })
    }

    onRemove() {
        this.props.removeUsers(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });

            Modal.destroyAll();
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getUser(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentUser: data });
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllUsers(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { users, pagination, config } = this.props;
        var { isLoading, selectedRowKeys, isOpenUserForm, currentUser, isOpenDetail, current_id } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        // query string
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: true,
                width: '50px'
            },
            {
                title: '#',
                align: 'center',
                width: '50px',
                render: (text, record) => (
                    <div>
                        <EditOutlined onClick={() => this.onEdit(record.id)} className='color-primary' style={{ fontSize: '15px' }} />
                    </div>
                )
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                sorter: true,
                render: (text, record) => {
                    if (record.full_name) {
                        return (
                            <>
                                {record.avatar ? (
                                    <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                ) : (
                                    <>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    </>
                                )}
                                &nbsp;<span className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>{record.full_name}</span>
                            </>
                        )
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Tên đăng nhập',
                dataIndex: 'user_name',
                key: 'user_name',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                ),
                sorter: true
            },
            {
                title: 'Email',
                dataIndex: 'user_email',
                key: 'user_email'
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone_number',
                key: 'phone_number'
            },
            {
                title: 'Nhóm',
                dataIndex: 'role_name',
                key: 'role_name',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                ),
            },
            {
                title: 'Cơ sở làm việc chính',
                dataIndex: 'primary_workplace',
                key: 'primary_workplace',
            },
            {
                title: 'Cơ sở làm việc tạm thời',
                dataIndex: 'temporary_workplace',
                key: 'temporary_workplace',
            },
            {
                title: 'Thời gian làm việc tạm thời',
                render: (text, record) => {
                    if (record.temporary_work_duration_start) {
                        return (
                            <>
                                {isValidDate(record.temporary_work_duration_start, false)} -&nbsp;
                                {isValidDate(record.temporary_work_duration_end, false)}
                            </>
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip overlayInnerStyle={{ width: '300px' }} title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },

            {
                title: 'Trạng thái',
                dataIndex: 'user_active',
                key: 'user_active',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle
                    title="Nhân viên"
                    routes={routes}
                />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('user', 'create')}
                        isShowDeleteButton={checkPermission('user', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        activeFilter={query.role || query.user_active}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {/* <BaseSelect
                            style={{ width: '150px' }}
                            onChange={(value) => this.onChangeFilter("role", value)}
                            options={config.roles}
                            placeholder="Vai trò"
                            optionValue="id"
                            optionLabel="name"
                            className="table-button"
                            defaultValue={query.role ? parseInt(query.role) : null}
                        /> */}
                        <Select
                            allowClear
                            className='table-button'
                            placeholder="Nhóm"
                            options={config.role_trees || []}
                            onChange={(value) => this.onChangeFilter('role', value)}
                            style={{ width: '200px' }}
                            defaultValue={parseInt(query.role) || null}
                        />
                        <BaseSelect
                            placeholder="Trạng thái"
                            style={{ width: '150px' }}
                            onChange={(value) => this.onChangeFilter("user_active", value)}
                            options={[
                                { label: "Trạng thái", value: "" },
                                { label: "Active", value: '1' },
                                { label: "Inactive", value: '0' },
                            ]}
                            className="table-button"
                            defaultValue={query.user_active || null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>

                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    //bordered={true}
                    size='small'
                    dataSource={users}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <UserForm
                    visible={isOpenUserForm}
                    user={currentUser}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <UserDetail
                    visible={isOpenDetail}
                    currentItem={currentUser}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        users: state.user.users,
        pagination: state.user.pagination,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllUsers: (filter) => dispatch(getAllUsers(filter)),
        getUser: (id) => dispatch(getUser(id)),
        removeUsers: (ids) => dispatch(removeUsers(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
