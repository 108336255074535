import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Divider, Tag, Tooltip, Table, Row } from 'antd'
import NumberFormat from 'react-number-format';
import ProductReceiptExportDetail from '../product-receipt-export/ProductReceiptExportDetail';
import { checkPermission } from '../../utils/permission';

// actions
import { getSpecificProductReceiptExport } from '../../redux/actions/ProductReceiptExportActions';

class CustomerOrderDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenDetail: false,
            currentData: null,
            loadingDetail: false,
            current_id: null
        };
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.props.reloadCustomerDetail()
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificProductReceiptExport(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }


    render() {
        var { isLoading, isOpenDetail, currentData, current_id, loadingDetail } = this.state;
        var { currentItem } = this.props;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Tên sản phẩm",
                dataIndex: "name",
                width: "300px"
            },
            {
                title: "Tên phiếu xuất kho",
                dataIndex: "receipt_export_name",
                render: (text, record) => {
                    if (checkPermission('product_receipt_export', 'detail')) {
                        return (
                            <Tooltip title="Click để xem chi tiết phiếu" placement="right">
                                <span className='item-action-btn alink' onClick={() => this.onDetail(record.receipt_export_id)}>{text}</span>
                            </Tooltip>
                        )
                    } else {
                        return (<>{text}</>)
                    }
                }
            },
            {
                title: 'Số lượng',
                dataIndex: "quantity",
                width: "80px"
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "150px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            }
        ];

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                    <Tag color='#0b2b33'>13</Tag> Vật tư sử dụng
                </Divider>
                {
                    currentItem && currentItem?.receiptExport.length ? (
                        <>
                            <Table
                                className='mt-2'
                                size='small'
                                rowKey='key'
                                tableLayout="auto"
                                columns={columns}
                                dataSource={currentItem?.receiptExport || []}
                                pagination={false}
                                loading={isLoading}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />

                            <ProductReceiptExportDetail
                                visible={isOpenDetail}
                                currentItem={currentData}
                                loadingDetail={loadingDetail}
                                reloadData={() => this.onDetail(current_id)}
                                onCancel={() => this.toggleOpenDetail(false)}
                            />
                        </>
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }

            </>
        )
    }
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificProductReceiptExport: (id) => dispatch(getSpecificProductReceiptExport(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerOrderDetail' })(CustomerOrderDetail));
