import {
    GET_LIST_PROVINCES,
    GET_LIST_DISTRICTS,
    GET_LIST_WARDS,
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    list_provinces: [],
    list_districts: [],
    list_wards: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_PROVINCES: {
            return {
                ...state,
                list_provinces: action.payload.data,
            }
        }
        case GET_LIST_DISTRICTS: {
            return {
                ...state,
                list_districts: action.payload.data,
            }
        }
        case GET_LIST_WARDS: {
            return {
                ...state,
                list_wards: action.payload.data,
            }
        }
        default: return { ...state };
    }
}
