import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_TELESALE,
    GET_SPECIFIC_CUSTOMER_TELESALE,
    UPDATE_SPECIFIC_CUSTOMER_TELESALE,
    REMOVE_CUSTOMER_TELESALE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerTelesale = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-telesale', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_TELESALE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerTelesale = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-telesale/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_TELESALE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateResultCustomerTelesale = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-telesale/update/result/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_TELESALE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCustomerTelesale = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-telesale/update/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_TELESALE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerTelesale = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-telesale`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_TELESALE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}