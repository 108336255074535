import api from '../../utils/api';
import {
    GET_LIST_VIEW_PHONENUMBER,
    CREATE_NEW_VIEW_PHONENUMBER,
    REMOVE_VIEW_PHONENUMBER
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListViewPhoneNumber = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/view-phonenumber', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_VIEW_PHONENUMBER, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createViewPhoneNumber = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/view-phonenumber', data).then(res => {
            dispatch({ type: CREATE_NEW_VIEW_PHONENUMBER, payload: res.data.data });
            //NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeViewPhoneNumber = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/view-phonenumber`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_VIEW_PHONENUMBER, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
