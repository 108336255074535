import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Divider, Avatar, Tooltip, Table, Typography } from 'antd'
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import { getFirstLetterOfWords } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import CustomerDetail from '../customer/CustomerDetail';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
const { Paragraph } = Typography;
class VoucherCustomerUsedList extends Component {
    constructor() {
        super();
        this.state = {
            isOpenCustomerDetail: false,
            currenCustomertData: null,
            loadingDetail: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currenCustomertData: data });
    }

    onCustomerDetail(id) {
        this.setState({ currentCustomerId: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    render() {
        var { isOpenCustomerDetail, currenCustomertData, loadingDetail, currentCustomerId } = this.state;
        var { visible, currentItem } = this.props;

        const columns = [
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '50%',
                render: (text, record) => {
                    var class_name = ''
                    var tooltip_title = 'Xem hồ sơ khách hàng';
                    if (record.tasks == 'delete') {
                        class_name = 'is_delete_item';
                        tooltip_title = "Đã xóa"
                    }
                    if (record.full_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title={tooltip_title} placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">{record.full_name ? getFirstLetterOfWords(record.full_name) : ''}</Avatar>
                                        &nbsp;<span className={class_name}>{record.full_name}</span>
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <Avatar
                                        style={{
                                            backgroundColor: '#31cdf5',
                                            verticalAlign: 'middle'
                                        }}
                                        size="small">{record.full_name ? getFirstLetterOfWords(record.full_name) : ''}</Avatar>
                                    &nbsp;<span className={class_name}>{record.full_name}</span>
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '25%',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.customer_code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Ngày khách sử dụng',
                dataIndex: 'used_date',
                key: 'used_date',
                width: '25%',
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            }
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentItem && currentItem.name ? currentItem.name : "Khách hàng sử dụng"}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                >
                    {visible ? (
                        <div>
                            <Divider orientation="left" orientationMargin="0" className='mt-3'>
                                Khách hàng sử dụng ({currentItem?.customers.length})
                            </Divider>
                            <Table
                                rowKey="id"
                                size="small"
                                tableLayout='auto'
                                dataSource={currentItem?.customers || []}
                                columns={columns}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />
                        </div>
                    ) : null}

                </Drawer>
                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currenCustomertData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'VoucherCustomerUsedList' })(VoucherCustomerUsedList));
