import React, { Component } from "react";
import { connect } from 'react-redux';
import { checkPermission } from "../../utils/permission";
import { BellOutlined, ContainerOutlined, DeleteOutlined, DownOutlined, FolderAddOutlined, HistoryOutlined, MenuOutlined, PhoneOutlined, ProfileOutlined, QrcodeOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Space } from "antd";
import { NotificationManager } from "react-notifications";
import CustomerProfileForm from "../customer-profile/CustomerProfileForm";
import ServicesInterestedForm from '../customer-services/ServicesInterestedForm';
import CustomerServiceAddForm from '../customer-services/CustomerServiceAddForm';
import CustomerScheduleForm from '../customer-schedule/CustomerScheduleForm';
import VoucherApplyCustomerForm from '../voucher/VoucherApplyCustomerForm';
import CustomerPaymentReceiptList from './CustomerPaymentReceiptList';
import { getSpecificCustomer, removeCustomer, getListPaymentReceiptCustomer } from '../../redux/actions/CustomerActions';
//import Stringee from "../../components/Stringee";

const { confirm } = Modal;

class DropdownActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentItem: null,
            currentData: null,
            callToNumber: null,
            callToAlias: null,
            isOpenAddServiceInterestedForm: false,
            isOpenAddServiceSelectedForm: false,
            isOpenScheduleForm: false,
            isOpenAddVoucherForm: false,
            isOpenProfileForm: false
        }
    }

    makeStringeeCall(callToNumber, callToAlias) {
        //console.log(callToNumber, callToAlias);
        NotificationManager.error("Tính năng đang hoàn thiện");
        //this.setState({ callToNumber: callToNumber, callToAlias: callToAlias, openPopover: true })
    }

    createQRCode(customer_id) {
        NotificationManager.error("Tính năng đang hoàn thiện");
        console.log(customer_id)
    }

    viewPaymentHistory(customer_id) {
        this.setState({ current_id: customer_id });
        this.props.getListPaymentReceiptCustomer(customer_id).then(res => {
            this.props.getSpecificCustomer(customer_id).then(customer => {
                this.setState({ currentData: customer })
            })
            this.setState({ isOpenPaymentReceipt: true, currentItem: res })
        })
    }

    openServiceForm(customer_id, option) {
        this.props.getSpecificCustomer(customer_id).then(res => {
            if (option == 'quantam') {
                this.setState({ isOpenAddServiceInterestedForm: true, currentData: res });
            } else {
                this.setState({ isOpenAddServiceSelectedForm: true, currentData: res });
            }
        })
    }

    openScheduleForm(customer_id) {
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ isOpenScheduleForm: true, currentData: res });
        })
    }

    openVoucherForm(customer_id) {
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ isOpenAddVoucherForm: true, currentData: res });
        })
    }

    openUpdateProfile(id) {
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ isOpenProfileForm: true, currentData: res })
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá khách hàng này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomer([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.props.reloadData();
            }),
            onCancel() { },
        })
    }

    render() {

        const {
            isOpenProfileForm,
            currentData,
            isOpenAddServiceInterestedForm,
            isOpenAddServiceSelectedForm,
            isOpenScheduleForm,
            isOpenAddVoucherForm,
            isOpenPaymentReceipt,
            currentItem,
            current_id,
            callToNumber,
            callToAlias,
            openPopover
        } = this.state;

        const renderItems = () => {
            const itemsAction = [];
            const { record } = this.props;

            if (checkPermission('customer', 'voicecall')) {
                itemsAction.push(
                    {
                        key: '1',
                        label: (
                            <div onClick={() => this.makeStringeeCall(record.phone, record.full_name)}>Gọi điện thoại</div>
                        ),
                        icon: <PhoneOutlined />
                    }
                )
            }
            if (checkPermission('customer_schedule', 'create')) {
                itemsAction.push(
                    {
                        key: '3',
                        label: (
                            <div onClick={() => this.openScheduleForm(record.id)}>Lịch hẹn</div>
                        ),
                        icon: <BellOutlined />
                    },
                )
            }
            if (checkPermission('customer_services', 'create')) {
                itemsAction.push(
                    {
                        key: '4',
                        label: (
                            <div onClick={() => this.openServiceForm(record.id, 'quantam')}>Dịch vụ quan tâm</div>
                        ),
                        icon: <FolderAddOutlined />
                    },
                    {
                        key: '5',
                        label: (
                            <div onClick={() => this.openServiceForm(record.id, 'chot')}>Dịch vụ chốt</div>
                        ),
                        icon: <ContainerOutlined />
                    },
                )
            }
            if (checkPermission('customer_profile', 'create')) {
                itemsAction.push(
                    {
                        key: '10',
                        label: (
                            <div onClick={() => this.openUpdateProfile(record.id)}>Ảnh hồ sơ</div>
                        ),
                        icon: <ProfileOutlined />
                    }
                )
            }
            if (checkPermission('voucher', 'apply_customer')) {
                itemsAction.push(
                    {
                        key: '6',
                        label: (
                            <div onClick={() => this.openVoucherForm(record.id)}>Voucher</div>
                        ),
                        icon: <ShoppingOutlined />
                    },
                )
            }
            if (checkPermission('customer', 'payment_history')) {
                itemsAction.push(
                    {
                        key: '7',
                        label: (
                            <div onClick={() => this.viewPaymentHistory(record.id)}>Lịch sử thanh toán</div>
                        ),
                        icon: <HistoryOutlined />
                    },
                )
            }
            if (checkPermission('customer', 'qrcode')) {
                itemsAction.push(
                    {
                        key: '8',
                        label: (
                            <div onClick={() => this.createQRCode(record.id)}>Mã QRCode</div>
                        ),
                        icon: <QrcodeOutlined />
                    },
                )
            }
            if (checkPermission('customer', 'remove')) {
                itemsAction.push(
                    {
                        key: '9',
                        label: (
                            <div onClick={() => this.openAlert(record.id)}>Xóa khách hàng</div>
                        ),
                        icon: <DeleteOutlined />,
                        danger: true
                    }
                )
            }

            Object.keys(itemsAction).sort();
            //console.log(Object.keys(itemsAction).sort());
            return itemsAction;
        }

        const items = renderItems();

        return (
            <>
                <Space size="middle">
                    {items && items.length > 0 ? (
                        <Dropdown trigger="click" menu={{ items }}>
                            <div className='item-action-btn'>
                                <MenuOutlined /> <DownOutlined />
                            </div>
                        </Dropdown>
                    ) : (
                        <MenuOutlined style={{ color: '#dedede' }} />
                    )}
                </Space>

                <CustomerProfileForm
                    visible={isOpenProfileForm}
                    currentCustomer={currentData}
                    reloadData={() => false}
                    onCancel={() => this.setState({ isOpenProfileForm: false })}
                />
                <ServicesInterestedForm
                    visible={isOpenAddServiceInterestedForm}
                    currentCustomer={currentData}
                    reloadData={() => false}
                    onCancel={() => this.setState({ isOpenAddServiceInterestedForm: false })}
                />
                <CustomerServiceAddForm
                    visible={isOpenAddServiceSelectedForm}
                    currentCustomer={currentData}
                    reloadData={() => false}
                    onCancel={() => this.setState({ isOpenAddServiceSelectedForm: false })}
                />
                <CustomerScheduleForm
                    visible={isOpenScheduleForm}
                    currentCustomer={currentData}
                    reloadData={() => false}
                    onCancel={() => this.setState({ isOpenScheduleForm: false })}
                />
                <VoucherApplyCustomerForm
                    visible={isOpenAddVoucherForm}
                    currentCustomer={currentData}
                    reloadData={() => false}
                    onCancel={() => this.setState({ isOpenAddVoucherForm: false })}
                />
                <CustomerPaymentReceiptList
                    visible={isOpenPaymentReceipt}
                    currentCustomer={currentData}
                    currentListItem={currentItem}
                    reLoadViewPaymentHistory={() => this.viewPaymentHistory(current_id)}
                    onCancel={() => this.setState({ isOpenPaymentReceipt: false })}
                />
                {/* <Stringee
                    callToNumber={callToNumber}
                    callToAlias={callToAlias}
                    isModalOpen={openPopover}
                    handleCancel={() => this.setState({ openPopover: false })}
                /> */}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        removeCustomer: (ids) => dispatch(removeCustomer(ids)),
        getListPaymentReceiptCustomer: (customer_id) => dispatch(getListPaymentReceiptCustomer(customer_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownActions);