import {
    GET_LIST_BRANCH,
    GET_SPECIFIC_BRANCH,
    UPDATE_SPECIFIC_BRANCH,
    CREATE_NEW_BRANCH,
    REMOVE_BRANCH,
    GET_BRANCH_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    branchList: [],
    currentbranch: null,
    branchActiveList: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_BRANCH: {
            return {
                ...state,
                branchList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_BRANCH_ACTIVE: {
            return {
                ...state,
                branchActiveList: action.payload.data,
            }
        }
        case GET_SPECIFIC_BRANCH: {
            return { ...state, currentbranch: action.payload }
        }
        case CREATE_NEW_BRANCH: {
            return {
                ...state,
                branchList: [action.payload, ...state.branchList],
                branchActiveList: [action.payload, ...state.branchActiveList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_BRANCH: {
            let index = state.branchList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.branchList];
            temp[index] = action.payload;
            //
            let index2 = state.branchActiveList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.branchActiveList];
            temp[index2] = action.payload;

            return { ...state, branchList: temp, branchActiveList: temp2 }
        }
        case REMOVE_BRANCH: {
            let temp = state.branchList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.branchActiveList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, branchList: temp, branchActiveList: temp2 }
        }
        default: return { ...state };
    }
}
