import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Image, Space, Typography, Avatar, Card } from 'antd'
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import UserForm from './UserForm';
import { downloadFromLink, isValidDate } from '../../utils/helpers';
// actions

const { Text } = Typography;

class UserDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentItem: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }
    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;

        //console.log("currentItem: ", currentItem)

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentItem && currentItem.full_name ? currentItem.full_name : "Chi tiết nhân viên"}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '60%'}
                    extra={
                        checkPermission('user', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mb-20 pt-20' style={{ background: '#18394014', border: '1px dashed #dedede', borderRadius: '5px' }}>
                                    <Row gutter={16} justify='center'>
                                        <Col xs={24} md={24} style={{ textAlign: 'center', marginBottom: '-50px' }}>
                                            {currentItem?.avatar.length ? (
                                                currentItem?.avatar.map(item => {
                                                    return <Avatar size={150} src={item.src} />;
                                                })
                                            ) : (
                                                <Avatar size={150} icon={<UserOutlined />} />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={24} className='mt-5'>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Họ và tên">
                                                    {currentItem ? currentItem.full_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Vai trò">
                                                    {currentItem ? currentItem.role_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Username">
                                                    {currentItem ? currentItem.user_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Trạng thái">
                                                    {
                                                        currentItem && currentItem.user_active == 1 ? <>`<CheckCircleTwoTone twoToneColor="#52c41a" /> Active</> : null
                                                    }
                                                    {
                                                        currentItem && currentItem.user_active == 0 ? <><CloseCircleTwoTone twoToneColor="#e74c3c" /> Inactive</> : null
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="E-mail">
                                                    {currentItem ? currentItem.user_email : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="ID telegram">
                                                    {currentItem?.telegram_id}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Số điện thoại">
                                                    {currentItem ? currentItem.phone_number : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ngày tạo">
                                                    {currentItem ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm') : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Cơ sở làm việc chính">
                                                    {currentItem ? currentItem.primary_workplace : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Cơ sở làm việc tạm thời">
                                                    {currentItem ? currentItem.temporary_workplace : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Thời gian làm việc tạm thời">
                                                    {isValidDate(currentItem.temporary_work_duration_start, false)} -&nbsp;
                                                    {isValidDate(currentItem.temporary_work_duration_end, false)}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>

                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ghi chú">
                                                    {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider orientation="left" orientationMargin="0">
                                Giấy tờ liên quan
                            </Divider>
                            <Row gutter={16}>
                                <Col xs={24} md={12} className='mb-2'>
                                    <Card size="small" className='border-dashed'>
                                        <div className='mb-1 mt-1'>
                                            <Text strong>Bằng tốt nghiệp</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.diploma.map(item => {
                                                        return (
                                                            <Image
                                                                height={50}
                                                                src={item.src}
                                                                style={{ border: '1px solid #dedede' }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12} className='mb-2'>
                                    <Card size="small" className='border-dashed'>
                                        <div className='mb-1 mt-1'>
                                            <Text strong>CCCD/CMT</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.identity_card.map(item => {
                                                        return (
                                                            <Image
                                                                height={50}
                                                                src={item.src}
                                                                style={{ border: '1px solid #dedede' }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12} className='mb-2'>
                                    <Card size="small" className='border-dashed'>
                                        <div className='mb-1 mt-1'>
                                            <Text strong>Chứng chỉ hành nghề</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.certificate.map(item => {
                                                        return (
                                                            <Image
                                                                height={50}
                                                                src={item.src}
                                                                style={{ border: '1px solid #dedede' }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12} className='mb-2'>
                                    <Card size="small" className='border-dashed'>
                                        <div className='mb-1 mt-1'>
                                            <Text strong>Giấy tờ khác</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.orther_document.map(item => {
                                                        return (
                                                            <Image
                                                                width={50}
                                                                height={50}
                                                                src={item.src}
                                                                style={{ border: '1px solid #dedede' }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>

                <UserForm
                    visible={isOpenEditForm}
                    user={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UserDetail' })(UserDetail));
