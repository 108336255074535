import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_SOURCE,
    GET_SPECIFIC_CUSTOMER_SOURCE,
    UPDATE_SPECIFIC_CUSTOMER_SOURCE,
    CREATE_NEW_CUSTOMER_SOURCE,
    REMOVE_CUSTOMER_SOURCE,
    GET_CUSTOMER_SOURCE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerSource = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-source', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CUSTOMER_SOURCE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerSource = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-source/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_SOURCE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCustomerSourceActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-source/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_CUSTOMER_SOURCE_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerSource = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-source', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_SOURCE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateCustomerSource = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-source/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_SOURCE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerSource = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-source`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_SOURCE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
