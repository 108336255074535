import React from 'react';
import { Row, Card, Col } from 'antd';
import StatisticNumber from '../../components/StatisticNumber';

const Statistic = (props) => {

    const { data } = props;

    return (
        <div>
            <Row gutter={16} className="mb-2 mt-2" justify="space-between">
                <Card className="customer-data-statistic">
                    <StatisticNumber
                        title="Cuộc gọi đã thực hiện"
                        value={0}
                        precision={0}
                    />
                </Card>
                <Card className="customer-data-statistic">
                    <StatisticNumber
                        title="Nhắc lịch khách"
                        value={0}
                        precision={0}
                    />
                </Card>
                <Card className="customer-data-statistic">
                    <StatisticNumber
                        title="Gọi chăm sóc khách"
                        value={0}
                        precision={0}
                        unit="đ"
                    />
                </Card>
                <Card className="customer-data-statistic">
                    <StatisticNumber
                        title="KPI"
                        value={0}
                        precision={0}
                        unit="đ"
                    />
                </Card>
            </Row>
        </div>
    );
}

export default Statistic;