import api from '../../utils/api';
import {
    GET_LIST_SERVICE,
    GET_SPECIFIC_SERVICE,
    UPDATE_SPECIFIC_SERVICE,
    CREATE_NEW_SERVICE,
    REMOVE_SERVICE,
    GET_SERVICE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListService = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/services', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_SERVICE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificService = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/services/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_SERVICE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getServiceActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/services/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_SERVICE_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createService = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/services', data).then(res => {
            dispatch({ type: CREATE_NEW_SERVICE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateService = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/services/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_SERVICE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeService = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/services`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_SERVICE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
