import {
    GET_LIST_CASH_BOOK,
    GET_STATISTICAL_CASH_BOOK
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    cash_book_list: [],
    statistical: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CASH_BOOK: {
            return {
                ...state,
                cash_book_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_STATISTICAL_CASH_BOOK:
            return {
                ...state,
                statistical: action.payload
            };
        default: return { ...state };
    }
}
