import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, Drawer, Divider } from 'antd';
import { _newLine } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';

// actions
import { createData, updateData } from '../../redux/actions/FunctionalActions';
import { getFunctionalAreaActive } from '../../redux/actions/FunctionalAreaActions';

class FunctionalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false
        };
    }

    componentDidMount() {
        this.props.getFunctionalAreaActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isloading: true })
                    const params = {
                        functional_area_id: values.functional_area_id,
                        description: values.description,
                        note: values.note,
                    }
                    if (this.props.currentData) {
                        await this.props.updateData(this.props.currentData.id, params);
                        this.handCancel()
                        this.props.reloadData()
                    } else {
                        await this.props.createData(params);
                        this.handCancel()
                        this.props.reloadData()
                    }
                    console.log(params)
                    this.setState({ isloading: false })
                } catch (error) {
                    this.setState({ isloading: false });
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false })
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, active_list } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa công việc" : "Thêm công việc"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Khu vực chức năng">
                                    {getFieldDecorator('functional_area_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.functional_area_id : null
                                    })(
                                        <BaseSelect
                                            options={active_list || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            placeholder="Chọn tầng"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Mô tả công việc">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng nhập!",
                                    },
                                ],
                                initialValue: currentData ? currentData.description : null
                            })(
                                <CKEditorComponent
                                    initialHeight="300px"
                                    editorData={currentData?.description}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>


                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
        active_list: state.FunctionalAreaReducer.active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data)),
        getFunctionalAreaActive: (filter) => dispatch(getFunctionalAreaActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'FunctionalForm' })(FunctionalForm));
