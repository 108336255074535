import { DeleteOutlined, EditOutlined, FileProtectOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Spin, Tag, Descriptions, Typography } from 'antd';
import React, { useState } from 'react';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { isValidDate } from '../../utils/helpers';
import NumberFormat from 'react-number-format';

const { Text } = Typography;

const Payment = (props) => {

    const { currentItem } = props;
    const cashReceipts = currentItem?.cashReceipts || [];

    const { isOpenAddForm, setOpenAddForm } = useState(false);
    const { isLoading, setIsLoading } = useState(false);

    const handleAddForm = () => {

    }

    const onEdit = (id) => {

    }

    const openRemove = (ids) => {

    }

    console.log('currentItem', currentItem);

    return (
        <div>
            <Row justify="end" gutter={16}>
                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                    <Card size="small"
                        title={<><Tag color='#0b2b33'>4</Tag> Phiếu thanh toán</>}
                        bordered={false}
                    >
                        <Button
                            disabled={!checkPermission('cash_receipt', 'create')}
                            type="primary"
                            className='w-100'
                            onClick={() => handleAddForm()}
                            icon={<PlusCircleOutlined />}>
                            Tạo phiếu thanh toán
                        </Button>
                    </Card>
                </Col>
            </Row>
            {
                cashReceipts && cashReceipts?.length ? (
                    <Row gutter={16}>
                        {
                            cashReceipts?.map((item, index) => {
                                return (
                                    <Col key={index} xs={24} md={12} className='box-item-mobile'>
                                        <Card size="small"
                                            title={<>{<FileProtectOutlined />} {item.code}</>}
                                            bordered={false}
                                            extra={
                                                <>
                                                    {checkPermission('cash_receipt', 'update') ? (
                                                        <a onClick={() => onEdit(item.id)} type="link" key="list-vertical-edit">
                                                            <EditOutlined /> Sửa
                                                        </a>
                                                    ) : null}
                                                    <Divider type='vertical' />
                                                    {checkPermission('cash_receipt', 'remove') ? (
                                                        <span className='delete-icon' onClick={() => openRemove([item.id])} type="link" key="list-vertical-delete">
                                                            <DeleteOutlined /> Xóa
                                                        </span>
                                                    ) : null}
                                                </>
                                            }
                                        >
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày thanh toán">
                                                        {isValidDate(item.payment_at, false)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tổng">
                                                        <NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ưu đãi">
                                                        <NumberFormat value={item.special_discount} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Doanh số">
                                                        <NumberFormat value={item.adjusted_sales} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Số tiền thanh toán">
                                                        <NumberFormat value={item.amount_paid} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tổng đã thanh toán">
                                                        <NumberFormat value={item.total_amount_paid} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Công nợ">
                                                        <NumberFormat value={item.remaining_debt} displayType={'text'} thousandSeparator={true} />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className='no-margin' />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Text strong>Ghi chú: </Text>
                                                {HtmlParser(item.note)}
                                            </Col>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                ) : <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
            }
        </div>
    );
}

export default Payment;