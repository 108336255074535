import api from '../../utils/api';
import {
    GET_LIST_CARD_PENDING_CUSTOMER,
    CARD_PENDING_CUSTOMER_ASSIGN_TO_STAFF,
    CARD_PENDING_CUSTOMER_EXPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCardPendingCustomer = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/card-pending-customers', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CARD_PENDING_CUSTOMER,
                payload: res.data.data
            });

            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const assignToStaff = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/card-pending-customers/assign_to_staff', data).then(res => {
            dispatch({ type: CARD_PENDING_CUSTOMER_ASSIGN_TO_STAFF, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const exportExcel = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/card-pending-customers/export`, filter).then(res => {
            dispatch({ type: CARD_PENDING_CUSTOMER_EXPORT_EXCEL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}
