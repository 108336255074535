import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, InputNumber, Spin, Popconfirm, Divider, Table, DatePicker } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { PaymentMethod, _newLine, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import ListCurrentImage from '../../components/ListCurrentImage';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
// actions
import { createPaymentVoucher, updatePaymentVoucher } from '../../redux/actions/PaymentVoucherActions';
import NumberFormat from 'react-number-format';
import BaseRadioList from '../../components/Elements/BaseRadios';
import moment from 'moment';

class PaymentVoucherForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            items: [{
                id: 0,
                name: '',
                price: '',
                quantity: 1,
                amount: 0
            }],
            count: 1,
            images: [],
            currentImages: [],
            isResetUploadImage: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.items && Array.isArray(nextProps?.currentItem?.items)) {
                this.renderNewItem(nextProps?.currentItem?.items)
            } else {
                this.setState({ items: [] })
            }
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { items, currentImages, images } = this.state;
                    values.items = items;
                    values.images = customDataImageUpload(images, currentImages);
                    values.payment_at = values.payment_at ? values.payment_at.format("YYYY-MM-DD") : null;
                    if (this.props.currentItem) {
                        await this.props.updatePaymentVoucher(this.props.currentItem.id, values).then((res) => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    } else {
                        await this.props.createPaymentVoucher(values).then((res) => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    }
                    //console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, items: [], isResetUploadImage: true, images: [] });
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                amount: item.quantity * item.price
            }
        })
        this.setState({
            items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        }, () => {
            this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
        });
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.id != id;
        });
        this.renderNewItem(items)
    }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.renderNewItem(items)
    }

    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.items.forEach(item => {
            total_amount += item.price * item.quantity;
        });
        this.props.form.setFieldsValue({
            total_amount: total_amount
        });
    };

    onAdd() {
        let index = this.state.count + 1;
        let item = {
            id: index,
            name: '',
            price: '',
            quantity: 1,
            amount: 0
        }
        this.setState({
            items: [
                ...this.state.items,
                item
            ],
            count: index
        })
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        var { isLoading, items, isResetUploadImage, currentImages } = this.state;
        var { visible, currentItem } = this.props;
        const { getFieldDecorator } = this.props.form;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Tên",
                dataIndex: "name",
                render: (text, record) => {
                    return (
                        <Input
                            value={text}
                            name="name"
                            className='w-100'
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: '80px',
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="quantity"
                            min={1}
                            className='w-100'
                            //onChange={(value) => this.onChangeItem('quantity', value, record.id)}
                            onChange={(value) => {
                                // Kiểm tra giá trị và thay thế nếu cần thiết
                                const validValue = value == null || value <= 0 ? 1 : value;
                                this.onChangeItem('quantity', validValue, record.id);
                            }}
                        />
                    )
                }
            },
            {
                title: "Đơn giá",
                dataIndex: "price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="price"
                            min={0}
                            className='w-100'
                            onChange={(value) => this.onChangeItem('price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa bản ghi này?"
                            onConfirm={() => this.onDelete(record.id)}
                        >
                            <DeleteOutlined className='item-action-btn-remove' />
                        </Popconfirm>
                    )
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.price;
        });

        return (
            <Drawer
                visible={visible}
                title={currentItem ? 'Sửa phiếu thu' : `Tạo phiếu thu`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '55%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? 'Cập nhật' : "Tạo phiếu"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Người nhận tiền">
                                        {getFieldDecorator('recipient', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.recipient : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Divider orientation="left" orientationMargin="0">
                                        Nội dung chi
                                    </Divider>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Button type="default" size='small' onClick={() => this.onAdd()} icon={<PlusOutlined />}>Thêm dòng</Button>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Table
                                        className='mt-2 mb-4'
                                        size='small'
                                        rowKey='key'
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        tableLayout="auto"
                                        columns={columns}
                                        dataSource={items || []}
                                        pagination={false}
                                        scroll={{
                                            x: 'max-content'
                                        }}
                                        footer={() => {
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600' }}>
                                                    <div style={{ marginRight: '10px' }}>Tổng tiền:</div>
                                                    <div>
                                                        {getFieldDecorator('total_amount', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng điền!',
                                                                },
                                                            ],
                                                            initialValue: currentItem ? currentItem.total_amount : total_amount
                                                        })(
                                                            <InputNumber
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                className="w-100"
                                                                step={100000}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Hình thức chi">
                                        {getFieldDecorator('payment_method', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.payment_method : null
                                        })(
                                            <BaseRadioList
                                                options={PaymentMethod ?? []}
                                                showSearch
                                                placeholder="Chọn hình thức thanh toán"
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ngày chi">
                                        {getFieldDecorator('payment_at', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem ? isValidDateForm(currentItem.payment_at, false) : moment()
                                        })(
                                            <DatePicker
                                                className='w-100'
                                                format="DD/MM/YYYY"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? _newLine(currentItem.note) : null
                                        })(
                                            <Input.TextArea rows={3} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ảnh phiếu" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                        {getFieldDecorator('images', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={30}
                                                onChangeData={this.onChangeImage}
                                                isResetUpload={isResetUploadImage}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createPaymentVoucher: (data) => dispatch(createPaymentVoucher(data)),
        updatePaymentVoucher: (id, data) => dispatch(updatePaymentVoucher(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PaymentVoucherForm' })(PaymentVoucherForm));
