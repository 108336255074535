import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Row, Col, Tag } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import moment from 'moment';
// actions
import { stripHtml, ConvertCustomerScheduleType, isValidDate, ConvertCustomerScheduleStatus } from '../../utils/helpers';

class CustomerScheduleDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }

    onClose() {
        this.props.onCancel();
        this.setState({
            isLoading: false
        });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentItem } = this.props;

        const columns = [
            {
                title: 'Kiểu lịch hẹn',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return (
                        <strong>
                            {ConvertCustomerScheduleType(record.name)}
                        </strong>
                    )
                }
            },
            {
                title: 'Thời gian',
                dataIndex: 'time',
                key: 'time',
                width: '140px',
                render: (text, record) => {
                    return (
                        <div>
                            {isValidDate(text)} &nbsp;
                        </div>
                    )
                }
            },
            {
                title: '#',
                width: '100px',
                render: (text, record) => {
                    var diffDate = record.time ? moment(record.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                    return (
                        <div>
                            {
                                diffDate ? (
                                    <span>
                                        {
                                            diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                        }
                                    </span>
                                ) : (
                                    <>
                                        {
                                            record.time != null ? (
                                                <Tag color="#096dd9">Hôm nay</Tag>
                                            ) : null
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{ConvertCustomerScheduleStatus(text)}</div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <>{stripHtml(text)}</>
                    )
                }
            }
        ];

        return (
            <Modal
                visible={visible}
                title={`Lịch hẹn khách hàng: ${currentItem?.full_name} - ${currentItem?.code}`}
                onCancel={() => this.onClose()}
                maskClosable={true}
                width={isMobile ? '100%' : '55%'}
                footer={false}
            >
                {visible ?
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Table
                                size='small'
                                rowKey='key'
                                tableLayout="auto"
                                columns={columns}
                                dataSource={currentItem ? currentItem.appointment_schedule : []}
                                pagination={false}
                                loading={isLoading}
                                scroll={isMobile ? { x: 'max-content' } : undefined}
                                locale={{ emptyText: "Chưa có dữ liệu" }}
                            />
                        </Col>
                    </Row>
                    : null}
            </Modal>
        )
    }
}

export default connect(null, null)((CustomerScheduleDetail));
