import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Col, DatePicker, Modal, Row } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getCardTypeActive } from '../../redux/actions/CardTypeActions';
import moment from 'moment';
import { assignToStaff } from '../../redux/actions/CardPendingCustomerActions';

class AssignToStaffForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.props.getCardTypeActive();
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    values.customer_ids = this.props.customer_ids;
                    values.release_date = values.release_date ? values.release_date.format("YYYY-MM-DD HH:mm") : null
                    await this.props.assignToStaff(values).then(res => {
                        this.onCancel()
                    }).catch(err => {
                        this.setState({ isLoading: false });
                    });
                } catch (error) {
                    this.setState({ isLoading: false });
                }
                console.log(values)
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ isLoading: false })
        this.props.onCancel();
    }

    render() {
        const { isLoading } = this.state;
        const { visible, users, card_type_active_list } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Modal
                    title="Giao cho nhân viên"
                    visible={visible}
                    header={false}
                    onOk={(e) => this.handleSubmit(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nhân viên">
                                    {getFieldDecorator("staff_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={users ?? []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Chọn nhân viên"
                                            defaultText="Chọn nhân viên"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại thẻ">
                                    {getFieldDecorator('card_type_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={card_type_active_list ?? []}
                                            showSearch
                                            optionValue="id"
                                            optionLabel="name"
                                            placeholder="Loại thẻ"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày cấp">
                                    {getFieldDecorator('release_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn!',
                                            },
                                        ],
                                        initialValue: moment()
                                    })(
                                        <DatePicker
                                            className='w-100'
                                            format="DD/MM/YYYY"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        card_type_active_list: state.CardTypeReducer.card_type_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCardTypeActive: () => dispatch(getCardTypeActive()),
        assignToStaff: (data) => dispatch(assignToStaff(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'AssignToStaffForm' })(AssignToStaffForm));