import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Descriptions, Button, Alert, Divider, Comment, Drawer, Row, Col, Switch } from 'antd';
import { Form } from "@ant-design/compatible";
import ReactHtmlParser from 'react-html-parser';
// actions
import { getOnePosts, updatePosts } from '../../redux/actions/PostsAction';
import { checkPermission } from '../../utils/permission';
import { isMobile } from 'react-device-detect';
import { isValidDate } from '../../utils/helpers';
import PostsForm from './PostsForm';

class PostsDetail extends Component {
    state = {
        isLoading: true,
        isOpenEditForm: false,
        currentPosts: null,
    }

    toggleOpenEditForm(isOpenEditForm, posts = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentPosts: posts
        });
    }

    onEdit(id) {
        this.props.getOnePosts(id).then((res) => {
            this.toggleOpenEditForm(true, res)
        })
    }

    render() {
        var { currentData, visible } = this.props;
        var {
            isOpenEditForm,
            currentPosts,
        } = this.state;

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={`#${currentData?.id}`}
                    width={isMobile ? '100%' : '65%'}
                    onClose={() => this.props.onCancel()}
                    maskClosable={false}
                    footer={false}
                    extra={
                        <>
                            {
                                checkPermission('posts', 'update') ? <><Button type="primary" className='fix-buttom-mobile' onClick={() => this.onEdit(currentData?.id)} icon={<EditOutlined />}>Sửa</Button></> : null
                            }
                        </>
                    }
                >
                    {visible && currentData ? (
                        <>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mt-2'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Tên bài viết</span>}
                                        content={<>{currentData?.title}</>}
                                    />
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mt-2'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Keyword</span>}
                                        content={<>{currentData?.keyword}</>}
                                    />
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mt-3'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Edited content</span>}
                                        content={<>{ReactHtmlParser(currentData?.edited_content)}</>}
                                    />
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24} className='mt-3'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Origin content</span>}
                                        content={<>{ReactHtmlParser(currentData?.origin_content)}</>}
                                    />
                                </Col>
                                <Col xs={24} md={24} className='wrap-alert'>
                                    <Alert
                                        message={<strong>Ghi chú</strong>}
                                        description={ReactHtmlParser(currentData?.note)}
                                        type="info"
                                        className='fix-alert-element'
                                    />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small" className='mt-3'>
                                        <Descriptions.Item label="Tình trạng">
                                            <Switch
                                                defaultChecked={currentData.status}
                                                checkedChildren="Đã hoàn thiện"
                                                unCheckedChildren="Chưa hoàn thiện"
                                                onChange={(value) => this.props.updatePosts(currentData.id, { status: value })}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày cập nhật">
                                            {isValidDate(currentData?.updated_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người cập nhật">
                                            {currentData.updated_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                        </>
                    ) : null
                    }
                </Drawer>
                <PostsForm
                    visible={isOpenEditForm}
                    currentData={currentPosts}
                    onCancel={() => this.toggleOpenEditForm(false)}
                    reloadData={() => this.props.reloadData()}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOnePosts: id => dispatch(getOnePosts(id)),
        updatePosts: (id, data) => dispatch(updatePosts(id, data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "PostsDetail" })(PostsDetail));