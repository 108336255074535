import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { customerGender, CustomerCardStatus } from '../../utils/helpers';
import { Button, Col, Row, Space, Input, Spin, Tag, Card, DatePicker, Drawer } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Form } from '@ant-design/compatible';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadioList from '../../components/Elements/BaseRadios';
import CustomerFamilyList from './CustomerFamilyList';
import { isMobile } from 'react-device-detect';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';
import { getCustomerClassActive } from '../../redux/actions/CustomerClassActions';
import { getCustomerInteractiveStatusActive } from '../../redux/actions/CustomerInteractiveStatusActions';
import { getCustomerInteractiveTypeActive } from '../../redux/actions/CustomerInteractiveTypeActions';
import { getCustomerStatusActive } from '../../redux/actions/CustomerStatusActions';
import { createCustomer } from '../../redux/actions/CustomerDataActions';

const CustomerForm = (props) => {
    const { getFieldDecorator } = props.form || {};
    const { setCustomer, customer } = props;

    const [branchActiveList, setBranchActiveList] = useState([]);
    const [customerClassActiveList, setCustomerClassActiveList] = useState([]);
    const [customer_source_active_list, setCustomerSourceActiveList] = useState([]);
    const [customerStatusActiveList, setCustomerStatusActiveList] = useState([]);
    const [customerInteractiveTypeActiveList, setCustomerInteractiveTypeActiveList] = useState([]);
    const [customerInteractiveStatusActiveList, setCustomerInteractiveStatusActiveList] = useState([]);
    const [list_provinces, setListProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingSave(true);
            const fetchers = [
                { action: props.getListProvinces, setter: setListProvinces, name: 'list_provinces' },
                { action: props.getCustomerSourceActive, setter: setCustomerSourceActiveList, name: 'customer_source_active_list' },
                { action: props.getBranchActive, setter: setBranchActiveList, name: 'branchActiveList' },
                { action: props.getCustomerClassActive, setter: setCustomerClassActiveList, name: 'customerClassActiveList' },
                { action: props.getCustomerInteractiveTypeActive, setter: setCustomerInteractiveTypeActiveList, name: 'customerInteractiveTypeActiveList' },
                { action: props.getCustomerInteractiveStatusActive, setter: setCustomerInteractiveStatusActiveList, name: 'customerInteractiveStatusActiveList' },
                { action: props.getCustomerStatusActive, setter: setCustomerStatusActiveList, name: 'customerStatusActiveList' }
            ];

            try {
                // Dùng Promise.all để chạy các hàm API song song
                const responses = await Promise.all(fetchers.map(fetcher => fetcher.action()));
                responses.forEach((res, index) => {
                    const { setter, name } = fetchers[index];
                    setter(res?.data); // Cập nhật state tương ứng
                    // console.log(`${name}`, res);
                });
            } catch (error) {
                console.error("Failed to fetch data", error);
            } finally {
                setLoadingSave(false);
            }
        };

        fetchData();
    }, []);

    const loadDistricts = (province_code) => {
        if (province_code) {
            props.getListDistricts({ province_code }).then(res => {
                props.form.resetFields(['district_code', 'wards_code']);
                setDistricts(res.data);
                setWards([]); // Reset wards
            });
        }
    }

    const loadWards = (district_code) => {
        if (district_code) {
            props.getListWards({ district_code }).then(res => {
                props.form.resetFields(['wards_code']);
                setWards(res.data);
            });
        }
    }

    const handleGetItems = useCallback((items) => {
        setFamilyData(items);
    }, []);

    const handleSaveCustomer = async (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setLoadingSave(true);
                    values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                    values.customer_family = familyData;
                    values.mode = "from_manual_form"

                    console.log(values)
                    await props.createCustomer(values).then(res => {
                        console.log("res", res);
                        if (res) {
                            setCustomer(res);
                            props.history.push('/customer-data?tab=lookup&keyword=' + res.id);
                            setLoadingSave(false);
                        }
                    });
                    props.form.resetFields();
                } catch (error) {
                    setLoadingSave(false);
                }
            } else {
                window.scrollTo({ top: 250, behavior: 'smooth' });
            }
        });
    }

    const onResetForm = () => {
        props.form.resetFields();
    }

    const onClose = () => {
        props.form.resetFields();
        setCustomer(null);
        setVisible(false);
    }

    const isOpenForm = () => {
        setVisible(true);
    }

    return (
        <>
            <Row gutter={16}>
                <Col xs={24} md={24}>
                    <Button
                        type="primary"
                        onClick={() => isOpenForm()}
                        className="w-100 mt-4"
                    >
                        Thêm khách hàng mới
                    </Button>
                </Col>
            </Row>

            <Drawer
                visible={visible}
                title={`${customer ? "Sửa khách hàng" : "Tạo khách hàng"}`}
                onClose={() => onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => onResetForm()}>
                                Reset from
                            </Button>
                            <Button
                                loading={loadingSave}
                                key="submit"
                                type="primary"
                                icon={<CheckOutlined />}
                                onClick={(e) => handleSaveCustomer(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Spin tip="Loading..." spinning={loadingSave}>
                    <div style={{ margin: "15px 0px 0 0" }} >
                        <Row gutter={16} justify="center" style={{ margin: 0 }}>
                            <Col xs={24} md={24}>
                                <Form layout='vertical'>
                                    <Card title={<><Tag color='#0b2b33'>1</Tag> Thông tin khách hàng</>} size='small'>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Row gutter={16}>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Tên khách hàng">
                                                            {getFieldDecorator('full_name', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Vui lòng điền!',
                                                                    },
                                                                ],
                                                                initialValue: ""
                                                            })(<Input />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Cơ sở">
                                                            {getFieldDecorator('branch_management_id', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Vui lòng điền',
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <BaseSelect
                                                                    options={branchActiveList || []}
                                                                    optionValue="id"
                                                                    optionLabel="name"
                                                                    placeholder="Chọn một bản ghi"
                                                                    className="w-100"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Giới tính">
                                                            {getFieldDecorator("gender", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng điền!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <BaseSelect
                                                                    options={customerGender || []}
                                                                    placeholder="Chọn một bản ghi"
                                                                    style={{ width: '100%' }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Số điện thoại">
                                                            {getFieldDecorator("phone", {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "Vui lòng điền!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <Input placeholder='0978015988' />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Nguồn">
                                                            {getFieldDecorator("customer_source_id", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng điền!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <BaseSelect
                                                                    options={customer_source_active_list || []}
                                                                    optionValue="id"
                                                                    optionLabel="name"
                                                                    showSearch
                                                                    placeholder="Chọn một bản ghi"
                                                                    style={{ width: '100%' }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item label="Năm sinh">
                                                            {getFieldDecorator('birthday', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: 'Vui lòng điền!',
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <DatePicker
                                                                    picker="year"
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Chọn"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Form.Item label="Trạng thái thẻ">
                                                            {getFieldDecorator('card_status', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: 'none'
                                                            })(
                                                                <BaseRadioList
                                                                    options={CustomerCardStatus}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Row gutter={16} className='mt-3'>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Ghi chú">
                                                {getFieldDecorator('note', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: ""
                                                })(
                                                    <Input.TextArea rows={4} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Card title={<><Tag color='#0b2b33'>2</Tag> Tương tác</>} size='small' className='mt-4'>
                                        <Row gutter={16}>
                                            <Col xs={24} md={8}>
                                                <Form.Item label='Trạng thái'>
                                                    {getFieldDecorator("customer_status_id", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={customerStatusActiveList || []}
                                                            optionValue="id"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label='Loại tương tác'>
                                                    {getFieldDecorator("fb_Interactive_type_id", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={customerInteractiveTypeActiveList || []}
                                                            optionValue="id"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label='Trạng thái tương tác'>
                                                    {getFieldDecorator("fb_Interactive_status_id", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={customerInteractiveStatusActiveList || []}
                                                            optionValue="id"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Tên facebook">
                                                    {getFieldDecorator('fb_name', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(<Input />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Link facebook">
                                                    {getFieldDecorator("fb_link", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(
                                                        <Input />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Link fanpage">
                                                    {getFieldDecorator("fb_source", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(
                                                        <Input />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card title={<><Tag color='#0b2b33'>3</Tag> Thông tin nhân khẩu</>} size='small' className='mt-4'>
                                        <Row gutter={16}>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Tỉnh/Thành phố">
                                                    {getFieldDecorator("province_code", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={list_provinces || []}
                                                            optionValue="code"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                            onChange={(code) => loadDistricts(code)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Quận/Huyện">
                                                    {getFieldDecorator("district_code", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={districts || []}
                                                            optionValue="code"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                            onChange={(code) => loadWards(code)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Phường/Xã">
                                                    {getFieldDecorator("wards_code", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null,
                                                        normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                    })(
                                                        <BaseSelect
                                                            options={wards || []}
                                                            optionValue="code"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Địa chỉ">
                                                    {getFieldDecorator('address', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: ""
                                                    })(
                                                        <Input />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <CustomerFamilyList getItems={handleGetItems} />
                                            </Col>
                                        </Row> */}
                                    </Card>
                                </Form>
                            </Col>
                            {/* <div className="footer-action-bar">
                            <Space size="large">
                                <Button key="back" onClick={() => onResetForm()}>
                                    Reset from
                                </Button>
                                <Button
                                    icon={<CheckOutlined />}
                                    loading={loadingSave}
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => handleSaveCustomer(e)}
                                >
                                    Lưu
                                </Button>
                            </Space>
                        </div> */}
                        </Row>
                    </div>
                </Spin>
            </Drawer>
        </>
    );
}


function mapDispatchToProps(dispatch) {
    return {
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive()),
        getCustomerClassActive: () => dispatch(getCustomerClassActive()),
        getCustomerInteractiveStatusActive: () => dispatch(getCustomerInteractiveStatusActive()),
        getCustomerInteractiveTypeActive: () => dispatch(getCustomerInteractiveTypeActive()),
        getCustomerStatusActive: () => dispatch(getCustomerStatusActive()),
        createCustomer: (data) => dispatch(createCustomer(data))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Form.create({ name: 'CustomerForm' })(CustomerForm)));