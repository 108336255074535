import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, InputNumber, Drawer, Space, DatePicker } from 'antd';
import UploadImage from '../../components/UploadImage';
import { isMobile } from 'react-device-detect';
import { _newLine, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications'
import ListCurrentImage from '../../components/ListCurrentImage';
import BaseSelect from '../../components/Elements/BaseSelect';
// actions
import { createNewProduct, updateSpecificProduct } from '../../redux/actions/ProductActions';
import { getProductGroupActive } from '../../redux/actions/ProductGroupActions';
import { getProductSupplierActive } from '../../redux/actions/ProductSupplierAction';
import { getProductWarehouseActive } from '../../redux/actions/ProductWarehouseAction';
import { getUnitActive } from '../../redux/actions/UnitActions';
import { getProductStatusActive } from '../../redux/actions/ProductStatusActions';

class ProductForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            images: [],
            currentImages: [],
            isResetUploadImage: true
        };
    }
    componentDidMount() {
        this.props.getProductGroupActive();
        this.props.getProductSupplierActive();
        this.props.getProductWarehouseActive();
        this.props.getUnitActive();
        this.props.getProductStatusActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { currentImages, images } = this.state;
                    values.images = customDataImageUpload(images, currentImages);
                    values.expiry_date = values.expiry_date ? values.expiry_date.format("YYYY-MM-DD") : null;
                    if (this.props.currentItem) {
                        await this.props.updateSpecificProduct(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createNewProduct(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
            images: [],
            isResetUploadImage: true
        })
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        var { isResetUploadImage, currentImages, isLoading } = this.state;
        var { visible, currentItem, product_group_active_list, product_status_active_list, unit_active_list, product_supplier_active_list, product_warehouse_active_list } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log("currentImages: ", currentImages)
        return (
            <Drawer
                visible={visible}
                title={currentItem ? "Sửa sản phẩm" : "Thêm sản phẩm mới"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <Row justify="end">
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                {currentItem ? "Cập nhật" : "Thêm mới"}
                            </Button>
                        </Space>
                    </Row>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tên sản phẩm">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={8}>
                                <Form.Item label="Mã sản phẩm">
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.code : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col> */}
                            <Col xs={24} md={8}>
                                <Form.Item label="Kho sản phẩm">
                                    {getFieldDecorator('warehouse_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.warehouse_id : null
                                    })(<BaseSelect
                                        options={product_warehouse_active_list || []}
                                        optionValue="id"
                                        optionLabel="name"
                                        showSearch
                                        placeholder="Chọn kho sản phẩm"
                                        style={{ width: '100%' }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Nhóm sản phẩm">
                                    {getFieldDecorator('group_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.group_id : null
                                    })(<BaseSelect
                                        options={product_group_active_list || []}
                                        optionValue="id"
                                        optionLabel="name"
                                        showSearch
                                        placeholder="Nhóm sản phẩm"
                                        style={{ width: '100%' }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Nhà cung cấp">
                                    {getFieldDecorator('supplier_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.supplier_id : null
                                    })(<BaseSelect
                                        options={product_supplier_active_list || []}
                                        optionValue="id"
                                        showSearch
                                        optionLabel="name"
                                        placeholder="Nhà cung cấp"
                                        style={{ width: '100%' }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Giá nhập">
                                    {getFieldDecorator('regular_price', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.regular_price : null
                                    })(
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            className='w-100'
                                            step={1000}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Giá bán">
                                    {getFieldDecorator('sale_price', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.sale_price : null
                                    })(
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            className='w-100'
                                            step={1000}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Số lượng">
                                    {getFieldDecorator('quantity', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.quantity : null
                                    })(<InputNumber className='w-100' />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Đơn vị tính">
                                    {getFieldDecorator('unit_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.unit_id : null
                                    })(
                                        <BaseSelect
                                            options={unit_active_list || []}
                                            optionValue="id"
                                            showSearch
                                            optionLabel="name"
                                            placeholder="Chọn"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tình trạng">
                                    {getFieldDecorator('product_status_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.product_status_id : null
                                    })(
                                        <BaseSelect
                                            options={product_status_active_list || []}
                                            optionValue="id"
                                            showSearch
                                            optionLabel="name"
                                            placeholder="Chọn"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Hạn sử dụng">
                                    {getFieldDecorator('expiry_date', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? isValidDateForm(currentItem.expiry_date, false) : null
                                    })(
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            placeholder="Chọn ngày"
                                            className='w-100'
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : null
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                        <Form.Item label="Ảnh sản phẩm" className='flex-upload'>
                            <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                            {getFieldDecorator('images', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={30}
                                    onChangeData={this.onChangeImage}
                                    isResetUpload={isResetUploadImage}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_group_active_list: state.ProductGroupReducer.product_group_active_list,
        product_supplier_active_list: state.ProductSupplierReducer.product_supplier_active_list,
        product_warehouse_active_list: state.ProductWarehouseReducer.product_warehouse_active_list,
        unit_active_list: state.UnitReducer.unit_active_list,
        product_status_active_list: state.ProductStatusReducer.product_status_active_list,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewProduct: (data) => dispatch(createNewProduct(data)),
        updateSpecificProduct: (id, data) => dispatch(updateSpecificProduct(id, data)),
        getProductSupplierActive: () => dispatch(getProductSupplierActive()),
        getProductGroupActive: () => dispatch(getProductGroupActive()),
        getProductWarehouseActive: () => dispatch(getProductWarehouseActive()),
        getUnitActive: () => dispatch(getUnitActive()),
        getProductStatusActive: () => dispatch(getProductStatusActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductForm' })(ProductForm));
