import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ExportOutlined, FileDoneOutlined, HistoryOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Table, Tooltip, Tag, DatePicker, Row, Typography, Avatar, Tabs, Button, Card } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, repeatPhoneNumber, isValidDate, downloadFromLink } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
import appConfig from '../../config';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';
import { getListCardPendingCustomer, exportExcel } from '../../redux/actions/CardPendingCustomerActions';
import CustomerDetail from '../customer/CustomerDetail';
import AssignToStaffForm from './AssignToStaffForm';

const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/card-pending-customers',
        breadcrumbName: 'Khách hàng chờ làm thẻ',
    },
]
const { TabPane } = Tabs;

class CardPendingCustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            openCustomerDetail: false,
            currentItem: null,
            loadingDetail: false,
            current_id: null,
            visibleAssignedToStaff: false,
            isLoadingExport: false
        }
    }

    componentDidMount() {
        this.loadData();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCardPendingCustomer(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCardPendingCustomer(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onCancel() {
        this.props.form.resetFields();
        this.setState({ selectedRowKeys: [], visibleAssignedToStaff: false })
    }

    toggleCustomerDetail(openCustomerDetail, data = null) {
        this.setState({ openCustomerDetail: openCustomerDetail, currentItem: data });
    }

    onCustomerDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleCustomerDetail(true, res);
        })
    }

    handleExport() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportExcel(query).then((res) => {
            setTimeout(() => {
                const response = {
                    file: `${appConfig.MEDIA_URL}/${res.path}`,
                };
                downloadFromLink(response.file, "_card_pending_customers.xlsx");
            }, 100);
            this.setState({ isLoadingExport: false });
        }).catch(err => {
            this.setState({ isLoadingExport: false });
        });

    }

    render() {
        var { customerList, pagination, branchActiveList, users } = this.props;
        var { isLoading, selectedRowKeys, openCustomerDetail, currentItem, loadingDetail, isLoadingExport, current_id, visibleAssignedToStaff } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '250px',
                render: (text, record) => {
                    if (record.full_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;{record.full_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                width: '120px',
                key: 'phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_management',
                key: 'branch_management',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'card_pending_created_at',
                key: 'card_pending_created_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, false)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        const tables = <Table
            rowKey="id"
            size="small"
            tableLayout='auto'
            dataSource={customerList ?? []}
            columns={columns}
            loading={isLoading}
            rowSelection={query.tab !== 'pending' ? rowSelection : null}
            pagination={{
                pageSize: pagination.perPage,
                current: pagination.currentPage,
                total: pagination.total,
                showTotal: total => `Tổng ${total} bản ghi`,
                defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
            }}
            onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
            scroll={{
                x: 'max-content'
            }}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng chờ làm thẻ" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}

                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        activeFilter={
                            query.created_date_start ||
                            query.branch_management_id ||
                            query.created_user_id
                        }
                        filters={
                            [
                                <BaseSelect
                                    options={branchActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Chọn cơ sở"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('branch_management_id', value)}
                                    defaultValue={query.branch_management_id ? parseInt(query.branch_management_id) : null}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />
                            ]
                        }
                    >
                        {
                            query.tab === 'pending' && checkPermission('card_pending_customers', 'export_excel') && (
                                <Button
                                    icon={<ExportOutlined />}
                                    className="table-button"
                                    type="primary"
                                    loading={isLoadingExport}
                                    onClick={() => this.handleExport()}>
                                    Xuất file
                                </Button>
                            )
                        }
                        {
                            query.tab === 'issued_cards' && checkPermission('card_pending_customers', 'transfer_to_staff') && (
                                <Button
                                    icon={<UserOutlined />}
                                    className="table-button"
                                    type='primary'
                                    disabled={!selectedRowKeys.length}
                                    onClick={() => this.setState({ visibleAssignedToStaff: true })}
                                >
                                    Giao cho nhân viên
                                </Button>
                            )
                        }


                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <Card size='small' bodyStyle={{ padding: 0 }}>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}><HistoryOutlined />Đang chờ làm thẻ</span>} key="pending">
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}><FileDoneOutlined />Đã phát hành thẻ</span>} key="issued_cards">
                            {tables}
                        </TabPane>
                    </Tabs>
                </Card>

                <CustomerDetail
                    visible={openCustomerDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onCustomerDetail(current_id)}
                    onCancel={() => this.toggleCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />

                <AssignToStaffForm
                    visible={visibleAssignedToStaff}
                    customer_ids={selectedRowKeys}
                    onCancel={() => this.onCancel()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.CardPendingCustomerReducer.customerList,
        pagination: state.CardPendingCustomerReducer.pagination,
        branchActiveList: state.BranchManagementReducer.branchActiveList,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        getListCardPendingCustomer: (filter) => dispatch(getListCardPendingCustomer(filter)),
        getBranchActive: () => dispatch(getBranchActive()),
        exportExcel: (filter) => dispatch(exportExcel(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CardPendingCustomerList' })(CardPendingCustomerList));

