import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, DatePicker, Card, Tag } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
// actions
import { createCustomer } from '../../redux/actions/CustomerActions';
import { _newLine, customerGender } from '../../utils/helpers';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';
import CustomerPotentialFamilyList from './CustomerPotentialFamilyList';


class CreateCustomerProfileForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            districts: [],
            wards: [],
            customer_family: [],
            currentCustomerFamily: [],
        };
    }
    componentDidMount() {
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.customer_family) {
                this.setState({ currentCustomerFamily: nextProps?.currentItem?.customer_family })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentItem != this.props.currentItem) {
            if (this.props.currentItem) {
                this.props.getListDistricts({ province_code: this.props.currentItem.province_code }).then(res => {
                    this.setState({ districts: res.data });
                })
                this.props.getListWards({ district_code: this.props.currentItem.district_code }).then(res2 => {
                    this.setState({ wards: res2.data })
                })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        try {
            const values = await new Promise((resolve, reject) => {
                this.props.form.validateFields((err, values) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(values);
                    }
                });
            });

            this.setState({ isLoading: true });

            if (this.props.currentItem) {
                const { customer_family } = this.state;

                values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                values.customer_family = customer_family;
                values.mode = "from_potential";
                //values.customer_status_id = 1; // liên hệ mới
                values.customer_potential_id = this.props.currentItem?.id;

                console.log(values);
                await this.props.createCustomer(values);
                this.onClose();
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({ isLoading: false });
        }
    }


    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false });
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    getItems = data => {
        this.setState({ customer_family: data })
    }

    render() {
        var {
            districts,
            wards,
            isLoading,
            currentCustomerFamily,
        } = this.state;
        var {
            visible,
            currentItem,
            list_provinces,
            customer_source_active_list,
            branchActiveList,
            customerStatusActiveList
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        var branch_management_id = null;
        var gender = 'khac';

        var genderMale = ['nam', 'Nam'];
        var genderFemale = ['nữ', 'nu', 'Nữ', 'Nu'];

        if (currentItem) {
            if (currentItem.province_code !== null && currentItem.province_code !== "") {
                // eslint-disable-next-line default-case
                switch (currentItem.province_code.trim()) {
                    case '01':
                        branch_management_id = 2; // Hà Nội
                        break;
                    case '48':
                        branch_management_id = 1; // Đà Nẵng
                        break;
                }
            }
            if (currentItem.gender !== null && currentItem.gender !== "") {
                if (genderMale.includes(currentItem.gender.trim())) {
                    gender = "nam";
                } else if (genderFemale.includes(currentItem.gender.trim())) {
                    gender = "nu";
                }
            }
        }


        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? currentItem?.full_name : ""} - ${currentItem?.code}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Tạo hồ sơ
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Card title={<><Tag color='#0b2b33'>1</Tag> Thông tin khách hàng</>} size='small'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Tên khách hàng">
                                                {getFieldDecorator('full_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.full_name : null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Cơ sở">
                                                {getFieldDecorator('branch_management_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền',
                                                        },
                                                    ],
                                                    initialValue: branch_management_id
                                                })(
                                                    <BaseSelect
                                                        options={branchActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        defaultText="Chọn cơ sở"
                                                        placeholder="Chọn cơ sở"
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Giới tính">
                                                {getFieldDecorator("gender", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: gender
                                                })(
                                                    <BaseSelect
                                                        options={customerGender || []}
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Số điện thoại">
                                                {getFieldDecorator("phone", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.phone : null
                                                })(
                                                    <Input placeholder='0978015988' />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nguồn">
                                                {getFieldDecorator("customer_source_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: 48,
                                                    normalize: value => value !== undefined ? value : null
                                                })(
                                                    <BaseSelect
                                                        options={customer_source_active_list || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Năm sinh">
                                                {getFieldDecorator('birthday', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem && moment(currentItem.birthday).isValid() ? moment(currentItem.birthday, 'YYYY') : ""
                                                })(
                                                    <DatePicker
                                                        picker="year"
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mã số thẻ cũ">
                                                {getFieldDecorator("card_number", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.card_number : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label='Trạng thái'>
                                                {getFieldDecorator("customer_status_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={customerStatusActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Kết quả tele">
                                    {getFieldDecorator('result_content', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input.TextArea rows={8} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Card title={<><Tag color='#0b2b33'>2</Tag> Thông tin nhân khẩu</>} size='small' className='mt-4'>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tỉnh/Thành phố">
                                        {getFieldDecorator("province_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.province_code : null,
                                            normalize: value => value !== undefined ? value : null
                                        })(
                                            <BaseSelect
                                                options={list_provinces || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadDistricts(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Quận/Huyện">
                                        {getFieldDecorator("district_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.district_code : null,
                                            normalize: value => value !== undefined ? value : null
                                        })(
                                            <BaseSelect
                                                options={districts || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadWards(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Phường/Xa">
                                        {getFieldDecorator("wards_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.wards_code : null,
                                            normalize: value => value !== undefined ? value : null
                                        })(
                                            <BaseSelect
                                                options={wards || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Địa chỉ">
                                        {getFieldDecorator('address', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.address : ""
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <CustomerPotentialFamilyList currentCustomerFamily={currentCustomerFamily} getItems={(data) => this.getItems(data)} />
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        list_provinces: state.RegionsReducer.list_provinces,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        branchActiveList: state.BranchManagementReducer.branchActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomer: (data) => dispatch(createCustomer(data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CreateCustomerProfileForm' })(CreateCustomerProfileForm));
