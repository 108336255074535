import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, DatePicker, Select } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getBloodTypeActive } from '../../redux/actions/BloodTypeActions';
import { getDiseasesActive } from '../../redux/actions/ListDiseasesActions';
import { isValidDateForm } from '../../utils/helpers';
import { getListCustomerMedical, createCustomerMedical } from '../../redux/actions/CustomerMedicalActions';

const { Option } = Select;

class CustomerMedicalForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: null
        };
    }

    componentDidMount() {
        this.props.getBloodTypeActive();
        this.props.getDiseasesActive();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentCustomer !== prevProps.currentCustomer) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getListCustomerMedical({ customer_id: this.props.currentCustomer?.id }).then(res => {
            //console.log(res);
            this.setState({ currentItem: res?.data })
        }).catch(err => {

        });
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentCustomer) {

                        values.blood_pressure_date = values.blood_pressure_date ? values.blood_pressure_date.format("YYYY-MM-DD") : null;
                        values.customer_id = this.props.currentCustomer?.id;

                        //console.log(values)
                        await this.props.createCustomerMedical(values).then(res => {
                            this.onClose();
                        }).catch(err => {
                            this.setState({ isLoading: false });
                        });

                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.reloadData();
        this.props.form.resetFields();
        this.setState({ isLoading: false });
        this.loadData();
    }

    render() {
        var { isLoading, currentItem } = this.state;
        var {
            visible,
            currentCustomer,
            blood_type_active_list,
            diseases_active_list,
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`${currentCustomer ? currentCustomer?.full_name : ""} - ${currentCustomer?.code}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Cân nặng">
                                    {getFieldDecorator('weight', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.data?.weight : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Chiều cao">
                                    {getFieldDecorator('height', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.data?.height : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Huyết áp">
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            {getFieldDecorator('blood_pressure', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem?.data?.blood_pressure : null
                                            })(<Input />)}
                                        </Col>
                                        <Col xs={24} md={12}>
                                            {getFieldDecorator('blood_pressure_date', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem && currentItem?.data?.blood_pressure_date ? isValidDateForm(currentItem?.data?.blood_pressure_date, false) : null
                                            })(
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    style={{ width: '100%' }}
                                                    placeholder="Ngày đo"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Đường huyết">
                                    {getFieldDecorator('blood_sugar', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.data?.blood_sugar : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tiền sử bệnh án">
                                    {getFieldDecorator("diseases_id", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.data?.diseases_id ? currentItem?.data?.diseases_id : [] : []
                                    })(
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Chọn 1 hoặc nhiều bản ghi"
                                            optionLabelProp="label"
                                            allowClear={true}
                                            showSearch={true}
                                        >
                                            {
                                                diseases_active_list.map((item) => {
                                                    return (
                                                        <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nhóm máu">
                                    {getFieldDecorator("blood_type_id", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.data?.blood_type_id : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={blood_type_active_list || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        blood_type_active_list: state.BloodTypeReducer.blood_type_active_list,
        diseases_active_list: state.ListDiseasesReducer.diseases_active_list,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBloodTypeActive: () => dispatch(getBloodTypeActive()),
        getDiseasesActive: () => dispatch(getDiseasesActive()),
        getListCustomerMedical: (filter) => dispatch(getListCustomerMedical(filter)),
        createCustomerMedical: (data) => dispatch(createCustomerMedical(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerMedicalForm' })(CustomerMedicalForm));
