import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from 'antd';
const { Title } = Typography;
class PrivilegeError extends Component {
    state = {

    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className="text-center mt-4">
                <Title>Permission access is denied</Title>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivilegeError);
