import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import MainApp from './container/MainApp';
import { NotificationContainer } from 'react-notifications';

// css
import 'antd/dist/antd.less';
import 'antd-mobile/dist/antd-mobile.less';
import '@ant-design/compatible/assets/index.css';
import './assets/css/custom.css';
import './assets/css/bootstrap.min.css';
import './assets/css/stringee.css';
import 'react-quill/dist/quill.snow.css';
import 'react-notifications/lib/notifications.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '@fortawesome/fontawesome-free/css/all.css'; // Bị lỗi ko build đc app

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'

// locale
import moment from 'moment';
import 'moment/locale/vi'  // without this line it didn't work
import vi_VN from 'antd/lib/locale-provider/vi_VN';
moment.locale('vi')

library.add(fas, faFontAwesome)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConfigProvider locale={vi_VN}>
          <BrowserRouter basename='/'>
            <MainApp />
            <NotificationContainer />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    )
  }
}
export default App;