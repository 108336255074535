import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, DatePicker, Space, Col, Row, Input } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import ListCurrentImage from '../../components/ListCurrentImage';
import UploadImage from '../../components/UploadImage';
import UploadVideo from '../../components/UploadVideo';
import { NotificationManager } from 'react-notifications';

// actions
import { updateSpecificSpinHistories } from '../../redux/actions/RewardSpinActions';
import { isValidDateForm, customDataImageUpload, _newLine } from '../../utils/helpers';

class RewardSpinForm extends Component {
    constructor() {
        super();
        this.state = {
            require_time: false,
            isLoading: false,
            isResetUpload: true,
            currentImages: [],
            images: [],
            videos: []
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.status) {
                if (!nextProps?.currentItem?.status) {
                    this.setState({ require_time: false })
                    this.props.form.setFieldsValue({ received_date: null })
                } else {
                    this.setState({ require_time: true })
                }
            }
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.images;
            //delete values.videos
            if (!err) {
                try {
                    this.setState({ isLoading: true });
                    const { images, videos, currentImages } = this.state;
                    values.images = customDataImageUpload(images, currentImages);
                    //values.videos = videos;
                    values.received_date = values.received_date ? values.received_date.format("YYYY-MM-DD HH:mm") : null;
                    //console.log(values)
                    if (this.props.currentItem) {
                        await this.props.updateSpecificSpinHistories(this.props.currentItem.id, values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, isResetUpload: true });
        this.props.reloadData();
    }

    changeStatus(value) {
        if (!value) {
            this.setState({ require_time: false })
            this.props.form.setFieldsValue({ received_date: null })
        } else {
            this.setState({ require_time: true })
        }
    }

    onChangeUploadImages = (data) => {
        this.setState({ isResetUpload: false, images: data });
        NotificationManager.success("Images complete.");
    }

    onChangeUploadVideos = (data) => {
        //console.log(data)
        this.setState({ isResetUpload: false, videos: data });
        NotificationManager.success("Video complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        const { require_time, isLoading, isResetUpload, currentImages } = this.state;
        var { visible, currentItem } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Cập nhật trạng thái"
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 0
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Đã nhận thưởng", value: 1 },
                                                { label: "Chưa nhận thưởng", value: 0 }
                                            ]}
                                            onChange={(value) => this.changeStatus(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_time ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Thời gian nhận">
                                            {getFieldDecorator('received_date', {
                                                rules: [
                                                    {
                                                        required: require_time,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem ? isValidDateForm(currentItem.received_date, false) : null
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : null
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ảnh" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                    {getFieldDecorator('images', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeUploadImages}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Videos">
                                    {getFieldDecorator('videos', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.videos : null
                                    })(
                                        <Input.TextArea rows={10} placeholder={'Onedrive:\n<iframe src="https://onedrive.live.com/embed?resid=7B72D0C4E742B963%2118094&authkey=!AFD8t_m-41p8KTM" width="320" height="320" frameborder="0" scrolling="no" allowfullscreen></iframe>\n\nYoutube:\n<iframe src="https://www.youtube.com/embed/tu0qn7kVg7w?si=G7Gknr4lBk3fSezG" width="320" height="320" frameborder="0" scrolling="no" allowfullscreen></iframe>'} />
                                    )}
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={24}>
                                <Form.Item label="Video" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    {getFieldDecorator('videos', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadVideo
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeUploadVideos}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateSpecificSpinHistories: (id, data) => dispatch(updateSpecificSpinHistories(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RewardSpinForm' })(RewardSpinForm));
