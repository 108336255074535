import api from '../../utils/api';
import {
    GET_ALL_VOUCHER,
    CREATE_NEW_VOUCHER,
    UPDATE_SPECIFIC_VOUCHER,
    GET_SPECIFIC_VOUCHER,
    REMOVE_VOUCHER,
    GET_ALL_VOUCHER_WITH_CONCATENATE,
    GET_ALL_VOUCHER_BY_ACTIVE,

    VOUCHER_APPLY_CUSTOMER,
    VOUCHER_CUSTOMER_REMOVE,
    VOUCHER_CUSTOMER_UPDATE,
    VOUCHER_CUSTOMER_GET_USED,
    VOUCHER_CUSTOMER_GET_USED_BY_CUSTOMER
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllVoucher = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/voucher', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_ALL_VOUCHER,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_ALL_VOUCHER_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getAllVoucherByActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/voucher/by-active', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_VOUCHER_BY_ACTIVE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getSpecificVoucher = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/voucher/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_VOUCHER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createNewVoucher = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/voucher', data).then(res => {
            dispatch({
                type: CREATE_NEW_VOUCHER,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateSpecificVoucher = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/voucher/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_SPECIFIC_VOUCHER,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeVoucher = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/voucher', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_VOUCHER,
                payload: ids
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

// Voucher customer
export const voucherApplyCustomer = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/voucher/customer/apply-customer', data).then(res => {
            dispatch({ type: VOUCHER_APPLY_CUSTOMER, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const voucherCustomerUpdate = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/voucher/customer/update/${id}`, data).then(res => {
            dispatch({ type: VOUCHER_CUSTOMER_UPDATE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const voucherCustomerRemove = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/voucher/customer/delete`, { data: { ids: ids } }).then(res => {
            dispatch({ type: VOUCHER_CUSTOMER_REMOVE, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const voucherCustomerGetUsed = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/voucher/customer/${id}`).then(res => {
            dispatch({ type: VOUCHER_CUSTOMER_GET_USED, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const voucherCustomerGetUsedByCustomer = (customer_id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/voucher/customer/by-customer/${customer_id}`).then(res => {
            dispatch({ type: VOUCHER_CUSTOMER_GET_USED_BY_CUSTOMER, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
