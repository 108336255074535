import api from '../../utils/api';
import {
    GET_DOCTOR_ASSISTANT_STATUS,
    DOCTOR_ASSISTANT_STATUS_ASSIGN
} from './types';
import { NotificationManager } from 'react-notifications';

export const getDoctorAssistantStatus = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/doctor-assistant-status', { params: filter }).then(res => {
            dispatch({ type: GET_DOCTOR_ASSISTANT_STATUS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const assignPatient = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/doctor-assistant-status/assign`, data).then(res => {
            dispatch({ type: DOCTOR_ASSISTANT_STATUS_ASSIGN, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}