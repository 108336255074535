import api from '../../utils/api';
import {
    GET_LIST_PAGE,
    GET_SPECIFIC_PAGE,
    INSERT_INFO_PAGE,
    GET_PAGE_BY_PAGEID,
    UPDATE_STATTUS_PAGE,
    GET_LIST_PAGE_ACTIVE,
    UPDATE_SPECIFIC_PAGE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListPage = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/page-manager', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PAGE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListPageActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/page-manager/page/active', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PAGE_ACTIVE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificPage = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/page-manager/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PAGE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getPageByPageId = (page_id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/page-manager/page/${page_id}`).then(res => {
            dispatch({ type: GET_PAGE_BY_PAGEID, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateStatus = (page_ids, status) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/page-manager/update-status', { page_ids: page_ids, status: status }).then(res => {
            dispatch({
                type: UPDATE_STATTUS_PAGE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updatePage = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/page-manager/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PAGE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const insertInfo = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/page-manager', data).then(res => {
            dispatch({ type: INSERT_INFO_PAGE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}