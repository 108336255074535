import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_CONSULTANT,
    GET_SPECIFIC_CUSTOMER_CONSULTANT,
    UPDATE_SPECIFIC_CUSTOMER_CONSULTANT,
    REMOVE_CUSTOMER_CONSULTANT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerConsultantDirect = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-consultant-direct', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_CONSULTANT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerConsultantDirect = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-consultant-direct/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_CONSULTANT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCustomerConsultantDirect = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-consultant-direct/update/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_CONSULTANT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateResultCustomerConsultantDirect = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-consultant-direct/update-result/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_CONSULTANT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerConsultantDirect = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-consultant-direct`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_CONSULTANT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}