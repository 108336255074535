import React from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class PrintButton extends React.Component {

    handlePrint = () => {
        const content = document.getElementById('custom-print-content').innerHTML;
        const printWindow = window.open('', '', 'width=900,height=800');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    render() {
        return (
            <>
                <Button icon={<PrinterOutlined />} onClick={this.handlePrint}>Print</Button>
                <span id="custom-print-content" style={{ display: 'none' }}>
                    <span>
                        {this.props.children}
                    </span>
                </span>
            </>
        );
    }
}

export default PrintButton;
