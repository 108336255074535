import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CommentOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Comment, Button, List, Avatar } from 'antd';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import HtmlParser from 'react-html-parser';
import TextArea from 'antd/lib/input/TextArea';

import { createComment, removeComment } from '../redux/actions/CommentsAction';
import { getFirstLetterOfWords } from '../utils/helpers';

const { confirm } = Modal;

class CommentsComponent extends Component {

    constructor() {
        super();
        this.state = {
            isloading: false
        };
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(['content'], async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                await this.props.createComment({ type: this.props.type, content: values.content, parent_id: this.props.parentId }).then(res => {
                    this.props.reloadData();
                    this.setState({ isloading: false });
                    this.props.form.resetFields();
                })
            }
        });
    }

    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.props.reloadData();
            }),
            onCancel() { },
        })
    }

    render() {
        const { isloading } = this.state;
        const { commentsData, authUser } = this.props;
        const { getFieldDecorator } = this.props.form;

        const comments = [];
        if (commentsData) {
            commentsData.map(item => {
                const disabledDeleteComment = authUser.role_code === 'ADMIN' || authUser.id === item.sender ? false : true;
                comments.push(
                    {
                        actions: [<Button disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <Avatar style={{ backgroundColor: '#31cdf5', verticalAlign: 'middle' }} size="small"> {item?.created_user ? getFirstLetterOfWords(item?.created_user) : ''}</Avatar>,
                        content: <p>{HtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                className='app-comment-list'
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        return (
            <>
                <h6 className='mt-4'><CommentOutlined /> THẢO LUẬN</h6>
                {comments.length > 0 && <CommentList comments={comments} />}
                <Comment
                    avatar={<Avatar style={{ backgroundColor: '#0b2b33', verticalAlign: 'middle' }} size="small"> {authUser?.full_name ? getFirstLetterOfWords(authUser?.full_name) : ''}</Avatar>}
                    content={
                        <>
                            <Form.Item>
                                {getFieldDecorator('content', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Vui lòng điền!',
                                        },
                                    ],
                                    initialValue: ""
                                })(<TextArea rows={4} />)}
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" loading={isloading} onClick={(e) => this.submitComment(e)} type="primary">
                                    Đăng
                                </Button>
                            </Form.Item>
                        </>
                    }
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createComment: (data) => dispatch(createComment(data)),
        removeComment: (ids) => dispatch(removeComment(ids)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CommentsComponent' })(CommentsComponent));

