import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, DatePicker, Drawer, Divider } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import UploadImage from '../../components/UploadImage';
import { _newLine, customDataImageUpload, isValidDateForm, customDataFilesUpload } from '../../utils/helpers';
import DraggerFile from './DraggerFile';
import { NotificationManager } from 'react-notifications'
import ListCurrentImage from '../../components/ListCurrentImage';
import ListCurrentFiles from '../../components/ListCurrentFiles';

import { createBranch, updateBranch } from '../../redux/actions/BranchManagementActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';

class BranchManagementForm extends Component {
    constructor() {
        super();
        this.state = {
            districts: [],
            wards: [],
            documents: [],
            currentDocuments: [],
            selectDocument: false,
            images: [],
            currentImages: [],
            isResetUploadImage: true,
            loadingForm: false,
            current_province_code: null,
            current_district_code: null
        };
    }

    componentDidMount() {
        this.props.getListProvinces();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.documents && Array.isArray(nextProps?.currentItem?.documents)) {
                this.setState({ currentDocuments: nextProps?.currentItem?.documents })
            } else {
                this.setState({ currentDocuments: [] })
            }
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
            this.props.getListDistricts({ province_code: nextProps?.currentItem?.province_code }).then(res => {
                this.props.form.resetFields(['district_code', 'wards_code']);
                this.setState({ districts: res.data })
            })

            this.props.getListWards({ district_code: nextProps?.currentItem?.district_code }).then(res => {
                this.props.form.resetFields(['wards_code']);
                this.setState({ wards: res.data })
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.documents;
            delete values.images;
            values.founding_date = values.founding_date ? values.founding_date.format("YYYY-MM-DD") : null;

            if (!err) {
                try {
                    this.setState({ loadingForm: true })
                    const { currentDocuments, documents, currentImages, images } = this.state;
                    //console.log('values: ', values)
                    values.images = customDataImageUpload(images, currentImages);
                    values.documents = customDataFilesUpload(currentDocuments, documents);
                    if (this.props.currentItem) {
                        await this.props.updateBranch(this.props.currentItem.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createBranch(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ loadingForm: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            documents: [],
            selectDocument: !this.state.selectDocument,
            images: [],
            loadingForm: false,
            isResetUploadImage: true
        })
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                //this.props.form.resetFields(['district_code', 'wards_code']);
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                //this.props.form.resetFields(['wards_code']);
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    onChangeDocument = (documents) => {
        this.setState({
            ...this.state,
            documents: documents,
        });
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getFiles(data) {
        this.setState({ currentDocuments: data })
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    render() {
        var { districts, wards, isResetUploadImage, currentDocuments, currentImages, loadingForm } = this.state;
        var { visible, currentItem, list_provinces, users } = this.props;
        const { getFieldDecorator } = this.props.form;
        // console.log("currentImages: ", currentImages)

        return (
            <Drawer
                visible={visible}
                title={currentItem ? "Sửa cơ sở" : "Thêm mới cơ sở"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button loading={loadingForm} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentItem ? "Cập nhật" : "Thêm mới"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tên cơ sở">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Mã cơ sở">
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.code : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Ngày thành lập">
                                    {getFieldDecorator('founding_date', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? isValidDateForm(currentItem.founding_date, false) : null
                                    })(<DatePicker
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn ngày"
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Tỉnh/Thành phố">
                                    {getFieldDecorator("province_code", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.province_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={list_provinces || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Tỉnh/Thành phố"
                                            style={{ width: '100%' }}
                                            onChange={(code) => this.loadDistricts(code)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Quận/Huyện">
                                    {getFieldDecorator("district_code", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.district_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={districts || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Quận/Huyện"
                                            style={{ width: '100%' }}
                                            onChange={(code) => this.loadWards(code)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Phường/Xã">
                                    {getFieldDecorator("wards_code", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.wards_code : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={wards || []}
                                            optionValue="code"
                                            optionLabel="name"
                                            showSearch
                                            placeholder="Phường/Xã"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Địa chỉ sơ sở">
                                    {getFieldDecorator('address', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.address : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Số nhà">
                                    {getFieldDecorator('house_number', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.house_number : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Người quản lý">
                                    {getFieldDecorator('management_user_id', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.management_user_id : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(<BaseSelect
                                        options={users || []}
                                        optionValue="user_id"
                                        optionLabel="full_name"
                                        showSearch
                                        placeholder="Chọn người quản lý"
                                        style={{ width: '100%' }}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Số điện thoại">
                                    {getFieldDecorator('phone_number', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.phone_number : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Trạng thái cơ sở">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Active", value: 1 },
                                                { label: "Inactive", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : null
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tài liệu">
                                    {getFieldDecorator('documents', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DraggerFile onChangeData={this.onChangeDocument} select={this.state.selectDocument} />
                                    )}
                                    <ListCurrentFiles currentFiles={currentDocuments} getData={(data) => this.getFiles(data)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ảnh cơ sở" className='flex-upload'>
                                    <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                    {getFieldDecorator('images', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={30}
                                            onChangeData={this.onChangeImage}
                                            isResetUpload={isResetUploadImage}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        list_provinces: state.RegionsReducer.list_provinces,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createBranch: (data) => dispatch(createBranch(data)),
        updateBranch: (id, data) => dispatch(updateBranch(id, data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'BranchManagementForm' })(BranchManagementForm));
