import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Tooltip, Tag, DatePicker, Avatar, Button, Popover } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import PaymentVoucherForm from './PaymentVoucherForm';
import CustomerDetail from '../customer/CustomerDetail';
import PaymentVoucherDetail from './PaymentVoucherDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, PaymentVoucherStatus, isValidDate, ConvertPaymentVoucherStatusInList, ConvertPaymentMethod } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import BaseSelect from '../../components/Elements/BaseSelect';

import moment from 'moment';
// actions
import { getListPaymentVoucher, getSpecificPaymentVoucher, removePaymentVoucher } from '../../redux/actions/PaymentVoucherActions';
import { EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import PaymentVoucherStatusForm from './PaymentVoucherStatusForm';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/payment-voucher',
        breadcrumbName: 'Phiếu chi',
    },
]

class PaymentVoucherList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false,
            isOpenCustomerDetail: false,
            currentCustomerData: null,
            isOpenStatusForm: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListPaymentVoucher(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListPaymentVoucher(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        if (name === 'payment_at') {
            if (value && value.length > 0) {
                query.payment_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.payment_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.payment_date_start;
                delete query.payment_date_end;
            }
        }
        delete query.created_at;
        delete query.payment_at;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificPaymentVoucher(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removePaymentVoucher(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removePaymentVoucher([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificPaymentVoucher(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListPaymentVoucher(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    openUpdateStatusForm(id) {
        this.props.getSpecificPaymentVoucher(id).then(res => {
            this.setState({ isOpenStatusForm: true, currentItem: res });
        })
    }

    render() {
        var { payment_voucher_list, pagination,
            users,
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenForm,
            currentItem, isOpenDetail,
            current_id,
            currentCustomerData,
            isOpenCustomerDetail,
            currentCustomerId,
            isOpenStatusForm
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            //     width: '50px',
            //     sorter: true
            // },
            {
                title: 'Mã phiếu',
                dataIndex: 'code',
                key: 'code',
                width: '80px'
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => {
                    if (text) {
                        if (checkPermission('payment_voucher', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết phiếu" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        <EyeOutlined />
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <EyeOutlined style={{ color: '#dedede' }} />
                                </>
                            )
                        }
                    }
                }
            },
            {
                title: 'Người nhận tiền',
                dataIndex: 'recipient',
                key: 'recipient',
                render: (text, record) => {
                    return (
                        <>
                            <Avatar
                                style={{
                                    backgroundColor: '#31cdf5',
                                    verticalAlign: 'middle'
                                }}
                                size="small">{record.recipient ? getFirstLetterOfWords(record.recipient) : ''}</Avatar>
                            &nbsp;{record.recipient}
                        </>
                    )
                }
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'total_amount',
                key: 'total_amount',
                sorter: true,
                width: '140px',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Nội dung chi',
                dataIndex: 'items',
                key: 'items',
                width: '200px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map((item, index) => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {index + 1}. {item.name}
                                </div>
                            )
                        })
                        let first_item = text[0]?.name;
                        if (checkPermission('payment_voucher', 'detail')) {
                            return (
                                <Popover placement="right" content={content}>
                                    <div className='item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        <TextTruncate line={1} truncateText="…" text={stripHtml(first_item)} />
                                    </div>
                                </Popover>
                            )
                        } else {
                            return (<TextTruncate line={1} truncateText="…" text={stripHtml(first_item)} />)
                        }

                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '120px',
                render: (text, record) => {
                    if (checkPermission('payment_voucher', 'update_status')) {
                        return (
                            <Tooltip title="Click để cập nhật" placement="right">
                                <div onClick={() => this.openUpdateStatusForm(record.id)} className='item-action-btn'>
                                    {ConvertPaymentVoucherStatusInList(text)}
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <div>
                                {ConvertPaymentVoucherStatusInList(text)}
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Hình thức',
                dataIndex: 'payment_method',
                key: 'payment_method',
                width: '100px',
                render: (text, record) => {
                    return (
                        <>{ConvertPaymentMethod(text)}</>
                    )
                }
            },
            {
                title: 'Ngày chi',
                dataIndex: 'payment_at',
                key: 'payment_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, false)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text)}</>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '200px',
                sorter: true
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Phiếu chi" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('payment_voucher', 'create')}
                        isShowDeleteButton={checkPermission('payment_voucher', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã phiếu, người nhận tiền"
                        activeFilter={
                            query.created_date_start ||
                            query.created_user_id ||
                            query.payment_method ||
                            query.status
                        }
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày chi', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('payment_at', value)}
                                    defaultValue={[query.payment_date_start ? moment(query.payment_date_start, 'YYYY-MM-DD') : null, query.payment_date_end ? moment(query.payment_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />,
                                <BaseSelect
                                    options={PaymentVoucherStatus || []}
                                    onChange={(value) => this.onChangeFilter("status", value)}
                                    defaultValue={query.status || null}
                                    placeholder="Trạng thái"
                                    className="w-100"
                                />
                            ]
                        }
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={payment_voucher_list || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <PaymentVoucherForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <PaymentVoucherDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => { this.onDetail(current_id); this.reloadData() }}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={false}
                />
                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={false}
                />
                <PaymentVoucherStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenStatusForm: false })}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        payment_voucher_list: state.PaymentVoucherReducer.payment_voucher_list,
        pagination: state.PaymentVoucherReducer.pagination,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListPaymentVoucher: (filter) => dispatch(getListPaymentVoucher(filter)),
        getSpecificPaymentVoucher: (id) => dispatch(getSpecificPaymentVoucher(id)),
        removePaymentVoucher: (ids) => dispatch(removePaymentVoucher(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PaymentVoucherList' })(PaymentVoucherList));

