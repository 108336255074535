import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Tag, DatePicker, Row, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import HistoryDetail from './HistoryDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';

import { getList, remove, getSpecific } from '../../redux/actions/HistoryActions';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/history',
        breadcrumbName: 'Lịch sử thay đổi',
    },
]

class HistoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            currentData: null,
            isOpenDetail: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getList(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.remove(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.loadData();
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá khách hàng này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.remove([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.loadData();
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.props.getSpecific(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getList(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({
            isOpenDetail: false
        })
    }


    render() {
        var { history_list, pagination, users, config } = this.props;
        var { isLoading, selectedRowKeys, isOpenDetail, currentData } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'Bản ghi bị thay đổi',
                dataIndex: 'record_name',
                key: 'record_name',
                render: (text, record) => {
                    if (checkPermission('history', 'detail')) {
                        return (
                            <Tooltip title="Xem chi tiết" placement="right">
                                <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                    &nbsp;{record.record_name} - {record.record_code} (Id: {record.record_id})
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <span>
                                &nbsp;{record.record_name} - {record.record_code} (Id: {record.record_id})
                            </span>
                        )
                    }
                }
            },
            {
                title: 'Diễn giải',
                dataIndex: 'note',
                key: 'note',
                width: '300px'
            },
            {
                title: 'Module',
                dataIndex: 'module_name',
                key: 'module_name',
                width: '150px',
                render: (text, record) => {
                    return (
                        <Tag>
                            {record.module_name}
                        </Tag>
                    )
                }
            },
            {
                title: 'Hành động',
                dataIndex: 'action',
                key: 'action',
                width: '100px',
                render: (text, record) => {
                    return (
                        <Tag style={{ textTransform: 'capitalize' }} color={text == 'delete' ? 'red' : '#108ee9'}>{text}</Tag>
                    )
                }
            },
            {
                title: 'Nhân viên thay đổi',
                dataIndex: 'updated_user',
                key: 'updated_user',
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Thời gian',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, true)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Lịch sử thay đổi" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('history', 'remove')}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        activeFilter={
                            query.created_date_start ||
                            query.module_code ||
                            query.action ||
                            query.updated_user_id
                        }
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Nhân viên"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('updated_user_id', value)}
                                    defaultValue={query.updated_user_id ? parseInt(query.updated_user_id) : null}
                                />
                            ]
                        }
                    >
                        <BaseSelect
                            options={config.resource ?? []}
                            optionValue="module"
                            optionLabel="name"
                            placeholder="Module"
                            className="table-button"
                            showSearch
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('module_code', value)}
                            defaultValue={query.module_code ? query.module_code : null}
                        />
                        <BaseSelect
                            options={[
                                { label: 'Update', value: 'update' },
                                { label: 'Delete', value: 'delete' }
                            ]}
                            showSearch
                            placeholder="Hành động"
                            onChange={(value) => this.onChangeFilter('action', value)}
                            className="table-button"
                            style={{ width: '200px' }}
                            defaultValue={query.action ? query.action : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={history_list ?? []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <HistoryDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.loadData}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        history_list: state.HistoryReducer.history_list,
        pagination: state.HistoryReducer.pagination,
        users: state.config.users,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getList: (filter) => dispatch(getList(filter)),
        getSpecific: (id) => dispatch(getSpecific(id)),
        remove: (ids) => dispatch(remove(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'HistoryList' })(HistoryList));

