import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Divider, Row } from 'antd';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';

// actions
import { createGuide, updateGuide } from '../../redux/actions/GuideActions';

class GuideForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false
        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateGuide(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    await this.props.createGuide(values);
                    this.handCancel()
                    this.props.reloadData()
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false })
    }

    handleEditorChange = (data) => {
        //console.log(data)
        this.setState({ editorData: data });
    };

    render() {
        const { isloading } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '80%'}
                footer={
                    <Row justify="end">
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </Row>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Tiêu đề">
                            {getFieldDecorator('title', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentData ? currentData.title : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Nội dung">
                            {getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentData ? currentData.content : null
                            })(
                                <CKEditorComponent
                                    onChange={this.handleEditorChange}
                                    initialHeight="300px"
                                    editorData={currentData?.content}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createGuide: (data) => dispatch(createGuide(data)),
        updateGuide: (id, data) => dispatch(updateGuide(id, data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'GuideForm' })(GuideForm));
