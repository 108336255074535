import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Divider, Tag, Row, Col, Card, Descriptions, Modal, Button, DatePicker, Input } from 'antd'
import { PhoneOutlined, EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate, isValidDateForm, _newLine, renderTelesaleDeadline, renderTelesaleTime, CustomerScheduleType } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';
import { isMobile } from 'react-device-detect';
import { getSpecificCustomerTelesale, updateResultCustomerTelesale, removeCustomerTelesale } from '../../redux/actions/CustomerTelesaleActions';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadioList from '../../components/Elements/BaseRadios';

const { confirm } = Modal;

class CustomerTelesaleDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenEditForm: false,
            currentData: null,
            require_field: null,
            require_time: true,
            scheduleOption: null
        };
    }

    onEdit(id) {
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.setState({ currentData: res, isOpenEditForm: true, require_field: res.status })
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerTelesale([id]).then(() => {
                this.props.reloadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    async handleUpdateResult(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { currentData } = this.state;
                    if (currentData) {
                        values.schedule_records = {
                            name: values.name,
                            time: values.time ? values.time.format("YYYY-MM-DD HH:mm") : null,
                            note: values.note
                        };
                        values.retele_records = {
                            deadline: values.deadline ? values.deadline.format("YYYY-MM-DD HH:mm") : null
                        }
                        delete values.name;
                        delete values.time;
                        delete values.note;
                        delete values.deadline;

                        values.time_telesale = values.time_telesale ? values.time_telesale.format("YYYY-MM-DD HH:mm") : null;
                        await this.props.updateResultCustomerTelesale(currentData.id, values);
                        this.onCancel()
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
                //console.log(values)
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.reloadData();
        this.setState({
            isLoading: false,
            isOpenEditForm: false,
            currentData: null,
            require_field: null,
            require_time: true,
            scheduleOption: null
        })
    }

    ongChangeStatus(value) {
        this.setState({ require_field: value, scheduleOption: null })
    }

    ongChangeSchedule(value) {
        this.setState({ scheduleOption: value })
    }

    changeName(value) {
        if (value == 'CHUADATDUOCLICH') {
            this.setState({ require_time: false })
            this.props.form.setFieldsValue({ time: null })
        } else {
            this.setState({ require_time: true })
        }
    }

    render() {
        var { isLoading, isOpenEditForm, currentData, require_field, scheduleOption, require_time } = this.state;
        var { currentItem } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                    <Tag color='#0b2b33'>2</Tag> Telesale
                </Divider>
                {
                    currentItem && currentItem?.telesale_staff.length ? (
                        <Row gutter={16} className='mb-3 row-customer-telesale'>
                            {
                                currentItem?.telesale_staff.map(item => {
                                    return (
                                        <Col xs={24} md={12} className='mb-3'>
                                            <Card size="small"
                                                title={<>{<PhoneOutlined />} {item.user_telesale}</>}
                                                extra={
                                                    <>
                                                        {checkPermission('customer_telesale', 'update_result') && item.allow_action ? (
                                                            <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                <EditOutlined /> Sửa
                                                            </a>
                                                        ) : null}
                                                        <Divider type='vertical' />
                                                        {checkPermission('customer_telesale', 'remove') && item.allow_action ? (
                                                            <span className='delete-icon' onClick={() => this.openAlert(item.id)} type="link" key="list-vertical-delete">
                                                                <DeleteOutlined /> Xóa
                                                            </span>
                                                        ) : null}
                                                    </>
                                                }
                                            >
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Deadline">
                                                            {renderTelesaleDeadline(item?.deadline, item?.status, 'detail')}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Trạng thái tư vấn">
                                                            {item?.status == 1 ? (
                                                                <Tag color='green'>Đã trả kết quả</Tag>
                                                            ) : null}
                                                            {item?.status == 0 ? (
                                                                <Tag color='#f5b22d'>Chưa trả kết quả</Tag>
                                                            ) : null}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Thời điểm tư vấn">
                                                            {renderTelesaleTime(item?.deadline, item?.time_telesale, 'detail')}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Người trả kết quả">
                                                            {item?.user_update_result}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ngày tạo">
                                                            {isValidDate(item.created_at)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Người tạo">
                                                            {item.created_user}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24} className='mt-2 mb-2'>
                                                    <h6 className="h6-title">Kết quả tư vấn:</h6>
                                                    {ReactHtmlParser(item.result_content)}
                                                </Col>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }

                <Modal
                    title="Cập nhật kết quả tư vấn"
                    visible={isOpenEditForm}
                    header={false}
                    onOk={(e) => this.handleUpdateResult(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.handleUpdateResult(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{`${currentItem?.full_name} - ${currentItem?.code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : null
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { value: 1, label: 'Trả kết quả' },
                                                { value: 0, label: 'Chưa trả kết quả' }
                                            ]}
                                            className="w-100"
                                            onChange={(value) => this.ongChangeStatus(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_field ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Thời điểm tư vấn">
                                                {getFieldDecorator('time_telesale', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentData ? isValidDateForm(currentData.time_telesale, false) : null
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Kết quả tư vấn">
                                                {getFieldDecorator('result_content', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentData ? _newLine(currentData.result_content) : null
                                                })(
                                                    <Input.TextArea rows={8} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Lập lịch">
                                                {getFieldDecorator('scheduleOption', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseRadioList
                                                        options={[
                                                            { value: null, label: 'Không áp dụng' },
                                                            { value: 'schedule', label: 'Lịch hẹn' },
                                                            { value: 'retele', label: 'Lịch telesale lại' }
                                                        ]}
                                                        className="w-100"
                                                        onChange={(value) => this.ongChangeSchedule(value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {
                                            scheduleOption == 'schedule' ? (
                                                <>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label='Tên lịch hẹn'>
                                                            {getFieldDecorator("name", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <BaseSelect
                                                                    options={CustomerScheduleType}
                                                                    showSearch
                                                                    placeholder="Chọn một bản ghi"
                                                                    style={{ width: '100%' }}
                                                                    onChange={(value) => this.changeName(value)}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        require_time ? (
                                                            <Col xs={24} md={12}>
                                                                <Form.Item label="Thời gian">
                                                                    {getFieldDecorator('time', {
                                                                        rules: [
                                                                            {
                                                                                required: require_time,
                                                                                message: 'Vui lòng điền!',
                                                                            },
                                                                        ],
                                                                        initialValue: null
                                                                    })(
                                                                        <DatePicker
                                                                            style={{ width: '100%' }}
                                                                            showTime={{ format: 'HH:mm' }}
                                                                            format="DD/MM/YYYY HH:mm"
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        ) : null
                                                    }
                                                    <Col xs={24} md={24}>
                                                        <Form.Item label="Ghi chú">
                                                            {getFieldDecorator('note', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "Vui lòng điền!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <Input.TextArea rows={4} />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null
                                        }
                                        {
                                            scheduleOption == 'retele' ? (
                                                <>
                                                    <Col xs={24} md={24}>
                                                        <Form.Item label="Thời điểm telesale lại">
                                                            {getFieldDecorator("deadline", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <DatePicker
                                                                    style={{ width: '100%' }}
                                                                    showTime={{ format: 'HH:mm' }}
                                                                    format="DD/MM/YYYY HH:mm"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null
                                        }

                                    </>
                                ) : null
                            }
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificCustomerTelesale: (id) => dispatch(getSpecificCustomerTelesale(id)),
        removeCustomerTelesale: (ids) => dispatch(removeCustomerTelesale(ids)),
        updateResultCustomerTelesale: (id, data) => dispatch(updateResultCustomerTelesale(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerTelesaleDetail' })(CustomerTelesaleDetail));
