import React from 'react';
import axios from 'axios';
import appConfig from '../../config';
import { NotificationManager } from 'react-notifications';
import { DeleteOutlined, LikeOutlined, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { checkPermission } from '../../utils/permission';

class MessageAction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    // Hàm xóa bình luận
    deleteComment(comment_id) {

        if (!checkPermission('messenger', 'delete_comment')) {
            NotificationManager.error("Bạn không có quyền thực hiện thao tác này");
            return;
        }

        var { current_page } = this.props;
        var access_token_page = current_page.page_access_token;

        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}?method=delete&access_token=${access_token_page}`;

        this.setState({ isLoading: true });
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                console.log("Delete comment successfully: ", response.data);
                if (response.data.success) {
                    NotificationManager.success("Xóa bình luận thành công...");
                    this.setState({ isLoading: false });
                }
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({ isLoading: false });
                NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    // Hàm ẩn/hiện bình luận
    toogleHideComment(verb, comment_id) {

        if (!checkPermission('messenger', 'hide_comment')) {
            NotificationManager.error("Bạn không có quyền thực hiện thao tác này");
            return;
        }

        var { current_page } = this.props;
        var access_token_page = current_page.page_access_token;
        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}?access_token=${access_token_page}&is_hidden=${verb}`;
        var verbText = "Hiện bình luận thành công....";
        if (verb) {
            verbText = "Ẩn bình luận thành công...."
        }
        this.setState({ isLoading: true });
        axios.post(url).then((response) => {
            if (response.status == 200) {
                console.log(`Update hide/unhide comment successfully: `, response.data);
                if (response.data.success) {
                    NotificationManager.success(verbText);
                    this.setState({ isLoading: false });
                }
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({ isLoading: false });
                NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    // Ham like bình luận
    likeComment(comment_id) {
        var { current_page } = this.props;
        var access_token_page = current_page.page_access_token;
        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}/likes?access_token=${access_token_page}`;

        this.setState({ isLoading: true });
        axios.post(url).then((response) => {
            if (response.status == 200) {
                console.log(`Like comment successfully: `, response.data);
                if (response.data.success) {
                    NotificationManager.success("Like comment successfully...");
                    this.setState({ isLoading: false });
                }
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({ isLoading: false });
                NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    render() {

        const { isLoading } = this.state;
        const { item } = this.props;

        return (
            <>
                {item && item.type == 'comment' && (
                    <div className='icon-action-msg'>
                        {
                            item?.is_sender == 'page' ? (
                                <div style={{ textAlign: 'right' }}>
                                    {isLoading && <LoadingOutlined />}
                                    {
                                        item?.is_delete == 1 ? (
                                            <span style={{ color: 'red', fontSize: '12px' }}>Đã xóa</span>
                                        ) : (
                                            <Tooltip placement="top" title="Xóa">
                                                <DeleteOutlined onClick={() => this.deleteComment(item?.message_id)} className='icon delete' id={item?.message_id} />
                                            </Tooltip>
                                        )
                                    }
                                </div>
                            ) : (
                                <div className='wrap-icon'>
                                    {
                                        item?.is_delete == 1 ? (
                                            <span style={{ color: 'red', fontSize: '12px' }}>Đã xóa &nbsp;</span>
                                        ) : (
                                            <Tooltip placement="top" title="Xóa">
                                                <DeleteOutlined onClick={() => this.deleteComment(item?.message_id)} className='icon delete' id={item?.message_id} />
                                            </Tooltip>
                                        )
                                    }
                                    <Tooltip placement="top" title="Like">
                                        <LikeOutlined onClick={() => this.likeComment(item?.message_id)} className={`${item?.reaction_type ? 'icon like active' : 'icon like'}`} />
                                    </Tooltip>
                                    {
                                        item?.is_hidden == 1 ? (
                                            <Tooltip placement="top" title="Đang ẩn, click để hiện">
                                                <EyeInvisibleOutlined onClick={() => this.toogleHideComment(false, item?.message_id)} className='icon eye active' />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip placement="top" title="Ẩn">
                                                <EyeInvisibleOutlined onClick={() => this.toogleHideComment(true, item?.message_id)} className='icon eye' />
                                            </Tooltip>
                                        )
                                    }
                                    {isLoading && <LoadingOutlined />}

                                </div>
                            )
                        }
                    </div>
                )}
            </>
        );
    }
}

export default MessageAction;