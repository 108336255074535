import api from '../../utils/api';
import {
    GET_LIST_REWARD_HISTORIES,
    GET_LIST_REWARD_CUSTOMER_HAS,
    GET_SPECIFIC_REWARD_HISTORIES,
    RANDOM_CUSTOMERS,
    REMOVE_REWARD_HISTORIES,
    REMOVE_REWARD_CUSTOMER_HAS,
    REWARD_CONFIG,
    REWARD_GET_CONFIG,
    SPIN_REWARD,
    UPDATE_SPECIFIC_SPIN_HISTORIES
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListRewardHistories = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/reward-spin', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_REWARD_HISTORIES,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListRewardCustomerHas = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/reward-spin/customer-has-list', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_REWARD_CUSTOMER_HAS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificRewardHistories = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/reward-spin/detail/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_REWARD_HISTORIES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const randomCustomers = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/reward-spin/random-customers', data).then(res => {
            dispatch({ type: RANDOM_CUSTOMERS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const rewardUpdateConfig = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/reward-spin/config`, data).then(res => {
            dispatch({ type: REWARD_CONFIG, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const rewardGetConfig = () => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/reward-spin/get-config`).then(res => {
            dispatch({ type: REWARD_GET_CONFIG, payload: res.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeRewardHistories = (ids, tab) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/reward-spin`, { data: { ids: ids, tab: tab } }).then(res => {
            dispatch({ type: REMOVE_REWARD_HISTORIES, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeRewardCustomerHas = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/reward-spin/remove/customer-has`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_REWARD_CUSTOMER_HAS, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const spinReward = () => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/reward-spin/random-customers`).then(res => {
            dispatch({ type: SPIN_REWARD, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const updateSpecificSpinHistories = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/reward-spin/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_SPIN_HISTORIES, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}