import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_POTENTIAL,
    GET_SPECIFIC_CUSTOMER_POTENTIAL,
    UPDATE_SPECIFIC_CUSTOMER_POTENTIAL,
    CREATE_NEW_CUSTOMER_POTENTIAL,
    REMOVE_CUSTOMER_POTENTIAL,
    GET_LIST_CUSTOMER_POTENTIA_ACTIVE,
    GET_LIST_NAME_CUSTOMER_POTENTIAL,
    IMPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerPotential = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-potential', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_POTENTIAL,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerPotential = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-potential/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_POTENTIAL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerPotential = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-potential', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_POTENTIAL, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCustomerPotential = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-potential/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_POTENTIAL, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerPotential = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-potential`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_POTENTIAL, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const importExcel = (data, header) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-potential/import', data, header).then((res) => {
            dispatch({
                type: IMPORT_EXCEL,
                payload: res,
            });
            resolve(res);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getListCustomerPotentialActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-potential/by-active', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_POTENTIA_ACTIVE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListName = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-potential/list-name/get', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_NAME_CUSTOMER_POTENTIAL,
                payload: res.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}