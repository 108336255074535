import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { TreeSelect, Divider, Button } from 'antd';
import qs from 'qs';
import { cleanObject, makeTree } from '../utils/helpers';

class FilterableMultiTreeSelect extends Component {
    state = {
        selectedValues: []
    };

    handleSelectAll = () => {
        const { options, parameterName } = this.props;
        const allValues = options.map(item => item.value);
        this.handleOnChange(parameterName, allValues);
        this.setState({ selectedValues: allValues });
    }

    handleOnChange = (name, value) => {
        var queryValue = qs.parse(this.props.location.search.slice(1));
        queryValue[name] = value;

        if (name === this.props.parameterName) {
            if (value && value.length > 0) {
                let string = value.join(',');
                queryValue[name] = string;
            } else {
                delete queryValue[name];
            }
        }

        queryValue = cleanObject(queryValue);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(queryValue)
        });
    }

    handleOnClear = () => {
        const { parameterName } = this.props;
        this.setState({ selectedValues: [] }, () => {
            this.handleOnChange(parameterName, []);
        });
    }

    render() {
        const { options, placeholder, parameterName } = this.props;
        const { selectedValues } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));
        const queryValue = query[parameterName];

        const treeData = makeTree(options || [], 0);

        return (
            <TreeSelect
                showSearch
                className="w-100"
                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                treeData={treeData}
                treeNodeFilterProp='title'
                allowClear
                placeholder={placeholder}
                treeDefaultExpandAll
                multiple
                defaultValue={queryValue ? queryValue.split(',').map(Number) : []}
                value={selectedValues.length > 0 ? selectedValues : queryValue ? queryValue.split(',').map(Number) : []}
                onChange={(value) => {
                    this.setState({ selectedValues: value }, () => {
                        if (value.length === 0) {
                            this.handleOnClear();
                        } else {
                            this.handleOnChange(parameterName, value);
                        }
                    });
                }}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div style={{ padding: '2px 8px 8px 8px' }}>
                            <Button className='w-100' type="dashed" onClick={this.handleSelectAll}>
                                Chọn tất cả
                            </Button>
                        </div>
                    </>
                )}
            />
        );
    }
}

export default withRouter(FilterableMultiTreeSelect);
