import {
    GET_LIST_CUSTOMER_INTERACTIVE_TYPE,
    GET_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE,
    UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE,
    CREATE_NEW_CUSTOMER_INTERACTIVE_TYPE,
    REMOVE_CUSTOMER_INTERACTIVE_TYPE,
    GET_CUSTOMER_INTERACTIVE_TYPE_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    customerInteractiveTypeList: [],
    currentCustomerInteractiveType: null,
    customerInteractiveTypeActiveList: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CUSTOMER_INTERACTIVE_TYPE: {
            return {
                ...state,
                customerInteractiveTypeList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_CUSTOMER_INTERACTIVE_TYPE_ACTIVE: {
            return {
                ...state,
                customerInteractiveTypeActiveList: action.payload.data,
            }
        }
        case GET_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE: {
            return { ...state, currentCustomerInteractiveType: action.payload }
        }
        case CREATE_NEW_CUSTOMER_INTERACTIVE_TYPE: {
            return {
                ...state,
                customerInteractiveTypeList: [action.payload, ...state.customerInteractiveTypeList],
                customerInteractiveTypeActiveList: [action.payload, ...state.customerInteractiveTypeActiveList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_CUSTOMER_INTERACTIVE_TYPE: {
            let index = state.customerInteractiveTypeList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.customerInteractiveTypeList];
            temp[index] = action.payload;
            //
            let index2 = state.customerInteractiveTypeActiveList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.customerInteractiveTypeActiveList];
            temp[index2] = action.payload;

            return { ...state, customerInteractiveTypeList: temp, customerInteractiveTypeActiveList: temp2 }
        }
        case REMOVE_CUSTOMER_INTERACTIVE_TYPE: {
            let temp = state.customerInteractiveTypeList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.customerInteractiveTypeActiveList.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, customerInteractiveTypeList: temp, customerInteractiveTypeActiveList: temp2 }
        }
        default: return { ...state };
    }
}
