import {
    GET_LIST_REWARD_HISTORIES,
    GET_LIST_REWARD_CUSTOMER_HAS,
    REWARD_GET_CONFIG
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    reward_histories_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    },
    customer_has_list: {
        list: [],
        pagination: {
            currentPage: 1,
            total: 0, // total records
            perPage: 20
        },
    },
    reward_config: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_REWARD_HISTORIES: {
            return {
                ...state,
                reward_histories_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_LIST_REWARD_CUSTOMER_HAS: {
            return {
                ...state,
                customer_has_list: {
                    list: action.payload.data,
                    pagination: {
                        currentPage: parseInt(action.payload.current_page),
                        total: parseInt(action.payload.total),
                        perPage: parseInt(action.payload.per_page),
                    },
                }
            }
        }
        case REWARD_GET_CONFIG: {
            return {
                ...state,
                reward_config: action.payload.data
            }
        }
        default: return { ...state };
    }
}
