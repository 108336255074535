import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Tag, DatePicker, Typography, Avatar, Button, Row, Popover } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, isValidDate, ConvertPaymentMethod, repeatPhoneNumber, PaymentMethod, PaymentType, ConvertPaymentType, ConvertCashReceiptStatus } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
import { getListCashBook } from '../../redux/actions/CashBookActions';
import { ReloadOutlined } from '@ant-design/icons';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import CustomerDetail from '../customer/CustomerDetail';
import DynamicTable from '../../components/DynamicTable';

const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/cash-book',
        breadcrumbName: 'Sổ quỹ',
    },
]

class CashBookList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenCustomerDetail: false,
            currentCustomerData: null,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCashBook(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCashBook(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    render() {
        var { cash_book_list, pagination } = this.props;
        var { isLoading, selectedRowKeys,
            currentCustomerData,
            isOpenCustomerDetail,
            currentCustomerId,
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'Mã phiếu',
                dataIndex: 'code',
                key: 'code',
                width: '80px'
            },
            {
                title: 'Ngày TT',
                dataIndex: 'payment_at',
                key: 'payment_at',
                width: '100px',
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, false)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (text, record) => {
                    if (record.customer_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ khách hàng" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">{record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}</Avatar>
                                        &nbsp;{record.customer_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <Avatar
                                        style={{
                                            backgroundColor: '#31cdf5',
                                            verticalAlign: 'middle'
                                        }}
                                        size="small">{record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}</Avatar>
                                    &nbsp;{record.customer_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '140px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.customer_code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'customer_phone',
                width: '120px',
                key: 'customer_phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Dịch vụ sử dụng',
                dataIndex: 'items',
                key: 'items',
                width: '200px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map((item, index) => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {index + 1}. {item.name}
                                </div>
                            )
                        })
                        let first_item = text[0]?.name;
                        return (
                            <Popover placement="right" content={content}>
                                <div className='item-action-btn'>
                                    <TextTruncate line={1} truncateText="…" text={stripHtml(first_item)} />
                                </div>
                            </Popover>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Doanh số',
                dataIndex: 'customer_revenue',
                key: 'customer_revenue',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'TT gần nhất',
                dataIndex: 'amount_paid',
                key: 'amount_paid',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Tổng đã TT',
                dataIndex: 'total_amount_paid',
                key: 'total_amount_paid',
                render: (text) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                ),
            },
            {
                title: 'Công nợ',
                dataIndex: 'total_debt',
                key: 'total_debt',
                width: '200px',
                render: (text, record) => {
                    var remaining_debt = record.customer_revenue - record.total_amount_paid;
                    return (
                        <NumberFormat value={remaining_debt} displayType={'text'} thousandSeparator={true} />
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Sổ quỹ" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên khách hàng"
                        activeFilter={
                            query.created_date_start
                        }
                        filters={[]}
                    >
                        <DatePicker.MonthPicker
                            allowClear={true}
                            style={{ width: '160px' }}
                            className="table-button"
                            format="MM/YYYY"
                            placeholder="Chọn tháng"
                            onChange={(value) => this.onChangeFilter('month', value ? value.format("YYYY-MM") : null)}
                            defaultValue={query.month ? moment(query.month, 'YYYY-MM') : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>

                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={cash_book_list || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={false}
                        moduleKey="cash_book_list"
                        className='has-table-summary'
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>

                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={false}
                />

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cash_book_list: state.CashBookReducer.cash_book_list,
        pagination: state.CashBookReducer.pagination,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCashBook: (filter) => dispatch(getListCashBook(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CashBookList' })(CashBookList));

