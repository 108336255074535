import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Modal, Space, Row, Col, Image, Tooltip } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
// actions
import { updateCustomerMedia } from '../../redux/actions/CustomerActions';

const { confirm } = Modal;

class CustomerMediaEditForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentMedia: []
        };
    }
    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.customer_media && Array.isArray(nextProps?.currentItem?.customer_media)) {
                this.setState({ currentMedia: nextProps?.currentItem?.customer_media })
            }
        }
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
    }

    onDeleteImage(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá ảnh này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.updateCustomerMedia(id).then(() => {
                const currentMedia = [...this.state.currentMedia];
                this.setState({ currentMedia: currentMedia.filter(item => item.id !== id) });
            }),
            onCancel() { },
        })
    }

    render() {
        var { currentMedia } = this.state;
        var { visible } = this.props;

        return (
            <Drawer
                visible={visible}
                title="Sửa ảnh tình trạng hiện tại"
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Đóng
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <>
                        <Image.PreviewGroup>
                            <Row gutter={20} style={{ height: 'max-content' }} key={1}>
                                {
                                    currentMedia.map(item => {
                                        return (
                                            <Col xs={24} md={8} className="mb-3" >
                                                <div className="list-img-thumbnail2" style={{ marginBottom: '6px', width: '100%' }}>
                                                    <Tooltip placement="topLeft" title="Click để xóa">
                                                        <DeleteOutlined
                                                            className="btn-delete-img2"
                                                            onClick={() => this.onDeleteImage(item?.id)}
                                                        />
                                                    </Tooltip>
                                                    <Image
                                                        height="100%"
                                                        src={item?.source}
                                                        preview={false}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Image.PreviewGroup>
                    </>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomerMedia: (id) => dispatch(updateCustomerMedia(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerMediaEditForm' })(CustomerMediaEditForm));
