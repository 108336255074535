import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { moneyToWord, capitalize, generateDots } from '../../utils/helpers';
import { format } from 'date-fns';

class PrintTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalQuantity: 0,
            totalPrice: 0,
            totalPriceColumn: 0,
            currentDate: format(new Date(), "dd 'tháng' MM 'năm' yyyy") // Format current date
        };
    }

    componentDidMount() {
        this.calculateTotal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData !== this.props.tableData) {
            this.calculateTotal();
        }
    }

    calculateTotal = () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        let totalPriceColumn = 0;

        this.props.tableData.forEach(item => {
            totalQuantity += item.quantity;
            totalPrice += item.price * item.quantity;
            totalPriceColumn += item.price;
        });

        this.setState({ totalQuantity, totalPrice, totalPriceColumn });
    }

    render() {
        const { customer } = this.props;

        return (
            <div class="container">
                <div className="c0001">
                    <div className="column white-space-nowrap">
                        <p>Đơn  vị: {generateDots(100)}</p>
                        <p>Địa chỉ: {generateDots(100)}</p>
                    </div>
                    <div
                        className="column text-center white-space-nowrap"
                        style={{ margin: "0 20px" }}
                    >
                        <h1>PHIẾU CHI</h1>
                        <p className="t000">Số:{generateDots(20)}</p>
                        <p style={{ whiteSpace: 'break-spaces' }}>Ngày {this.state.currentDate}</p>
                        <p>Quyển số:{generateDots(10)} </p>
                    </div>
                    <div className="column text-center white-space-nowrap">
                        <p style={{ whiteSpace: 'normal' }}>
                            Mấu số: 02-TT
                            <br />
                            (Ban hành theo QĐ số 15/2006/QĐ-BTC
                            <br />
                            ngày 20/03/2006 của Bộ trưởng BTC)
                        </p>
                        <p>NỢ:{generateDots(50)}</p>
                        <p>CÓ:{generateDots(50)}</p>
                    </div>
                </div>
                <div className="c0002 font-size">
                    <p className="white-space-nowrap">
                        Họ tên người nhận tiền: <strong>{customer?.recipient}</strong>
                    </p>
                    <p className="white-space-nowrap">Địa chỉ: {generateDots(500)}</p>
                    <p>Lý do chi: {customer?.content ?? generateDots(100)}</p>
                    {/* <table class="tb0001">
                        <thead class="ant-table-thead">
                            <tr>
                                <th class="ant-table-cell" style={{ textAlign: "center", width: '65px' }}>STT</th>
                                <th class="ant-table-cell" style={{ textAlign: "left" }}>Tên</th>
                                <th class="ant-table-cell" style={{ width: '65px' }}>Số lượng</th>
                                <th class="ant-table-cell" style={{ textAlign: "left" }}>Đơn giá</th>
                                <th class="ant-table-cell" style={{ textAlign: "left" }}>Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                            {this.props.tableData.map((item, index) => (
                                <tr key={index}>
                                    <td className="ant-table-cell" style={{ textAlign: "center" }}>{index + 1}</td>
                                    <td className="ant-table-cell">{item.name}</td>
                                    <td className="ant-table-cell" style={{ textAlign: "center" }}>{item.quantity}</td>
                                    <td className="ant-table-cell">{<NumberFormat value={item.price} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                                    <td className="ant-table-cell">{<NumberFormat value={item.price * item.quantity} displayType={'text'} suffix="đ" thousandSeparator={true} />}</td>
                                </tr>
                            ))}
                            <tr className="ant-table-row">
                                <td className="ant-table-cell">&nbsp;</td>
                                <td className="ant-table-cell"><strong>TỔNG CỘNG</strong></td>
                                <td className="ant-table-cell" style={{ textAlign: "center" }}>{this.state.totalQuantity}</td>
                                <td className="ant-table-cell">
                                    <NumberFormat value={this.state.totalPriceColumn} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                </td>
                                <td className="ant-table-cell">
                                    <NumberFormat value={this.state.totalPrice} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
                <div className="c0003 font-size white-space-nowrap">
                    <p>
                        Số tiền: <NumberFormat value={customer.total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                        &nbsp;(Viết bằng chữ) {capitalize(moneyToWord(customer?.total_amount))}</p>
                    <p>
                        Kèm theo:{generateDots(70)} Chứng từ gốc.
                    </p>
                </div>
                <div className="c0004 font-size">{generateDots(10)}, ngày {this.state.currentDate}</div>
                <div className="c0005">
                    <div className="column">
                        <strong>Người lập phiếu</strong>
                        <br />
                        (Ký, họ tên)
                    </div>
                    <div className="column">
                        <strong>Người nhận tiền</strong>
                        <br />
                        (Ký, họ tên)
                    </div>
                    <div className="column">
                        <strong>Thủ quỹ</strong>
                        <br />
                        (Ký, họ tên)
                    </div>
                    <div className="column">
                        <strong>Kế toán trưởng</strong>
                        <br />
                        (Ký, họ tên)
                    </div>
                    <div className="column">
                        <strong>Giám đốc</strong>
                        <br />
                        (Ký, họ tên, đóng dấu)
                    </div>
                </div>
                <div className="c0006 font-size white-space-nowrap">
                    <p>Đã nhận đủ số tiền (viết bằng chữ):{generateDots(1000)}</p>
                    <p>+ Tỷ giá ngoại tệ (vàng, bạc, đá quý):{generateDots(1000)} </p>
                    <p>+ Số tiền quy đổi:{generateDots(1000)}</p>
                </div>
                <style>
                    {`
                        .column {
                            flex: 1;
                        }
                
                        .white-space-nowrap {
                            white-space: nowrap;
                            overflow: hidden;
                        }
                
                        .text-center {
                            text-align: center;
                        }
                
                        .font-size {
                            font-size: 18px;
                        }
                
                        .container {
                            width: auto;
                            margin: 0 auto;
                            padding: 20px;
                            /**background: #f9f4f4;*/
                        }
                
                        .c0001 {
                            display: flex;
                            justify-content: space-between;
                            align-items: stretch;
                        }
                
                        .c0001 .t000 {
                            font-size: 20px;
                            font-weight: normal;
                            margin: 12px 0;
                        }
                
                        .c0001 h1 {
                            margin: 0;
                        }
                
                        .c0001 p {
                            margin: 5px 0;
                        }
                
                        .c0002 p,
                        .c0003 p {
                            margin: 8px 0;
                            line-height: 25px;
                        }
                
                        .c0004 {
                            margin: 25px 0 10px 0;
                            text-align: right;
                        }
                
                        .c0005 {
                            display: flex;
                            justify-content: space-between;
                            text-align: center;
                            margin-bottom: 120px;
                        }
                
                        .c0006 {
                            font-style: italic;
                        }
                
                        .c0006 p {
                            margin: 5px 0;
                        }
                        .tb0001 {
                            border: 1px solid #dedede;
                            border-collapse: collapse;
                            width: 100%;
                            max-width: 100%;
                            margin: 20px 0;
                        }
                
                        .tb0001 th {
                            border: 1px solid #929292;
                            padding: 6px;
                        }
                
                        .tb0001 td {
                            border: 1px solid #929292;
                            padding: 6px;
                        }
                    `}
                </style>
            </div>
        );
    }
}

export default PrintTable;
