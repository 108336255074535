import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_PROFILE,
    CREATE_NEW_CUSTOMER_PROFILE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerProfile = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-profile', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_PROFILE,
                payload: res.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerProfile = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-profile', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_PROFILE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}