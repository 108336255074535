import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Image, Space } from 'antd'
import { EditOutlined, DownloadOutlined, FileMarkdownOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import BranchManagementForm from './BranchManagementForm';
import { downloadFromLink, isValidDate } from '../../utils/helpers';
// actions

class BranchManagementDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentItem: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }
    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentItem && currentItem.name ? currentItem.name : "Chi tiết sơ sở"}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        checkPermission('customer', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên cơ sở">
                                            {currentItem ? currentItem.name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã cơ sở">
                                            {currentItem ? currentItem.code : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày thành lập">
                                            {isValidDate(currentItem.founding_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người quản lý">
                                            {currentItem ? currentItem.management_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số điện thoại">
                                            {currentItem ? currentItem.phone_number : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tỉnh/Thành phố">
                                            {currentItem ? currentItem.province_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Quận/Huyện">
                                            {currentItem ? currentItem.district_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Xã/Phường">
                                            {currentItem ? currentItem.wards_name : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Địa chỉ">
                                            {currentItem ? currentItem.address : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số nhà">
                                            {currentItem ? currentItem.house_number : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ảnh cơ sở">
                                            <Image.PreviewGroup>
                                                <Space wrap>
                                                    {
                                                        currentItem?.images.map(item => {
                                                            return (
                                                                <Image
                                                                    width={50}
                                                                    height={50}
                                                                    src={item.src}
                                                                    style={{ border: '1px solid #dedede' }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tài liệu">
                                            {
                                                currentItem?.documents && currentItem?.documents.length ? (
                                                    <Space wrap>
                                                        <ul>
                                                            {
                                                                currentItem?.documents.map(item => {
                                                                    return (
                                                                        <li><FileMarkdownOutlined /> {item.name} &nbsp; <DownloadOutlined onClick={() => this.onDownload(item.data)} /></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Space>
                                                ) : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái">
                                            {
                                                currentItem && currentItem.status == 1 ? <>`<CheckCircleTwoTone twoToneColor="#52c41a" /> đang hoạt động</> : null
                                            }
                                            {
                                                currentItem && currentItem.status == 0 ? <><CloseCircleTwoTone twoToneColor="#e74c3c" /> Không hoạt động</> : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {currentItem ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm') : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem ? currentItem.created_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>
                <BranchManagementForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'BranchManagementDetail' })(BranchManagementDetail));
