import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Drawer, Descriptions, Divider, Switch, Tag, Comment, Row, Col, Space } from 'antd'
import { DownloadOutlined, EditOutlined, FileMarkdownOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { downloadFromLink, isValidDate, processHTML } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import ProjectBasedJobForm from './ProjectBasedJobForm';
import { checkPermission } from '../../utils/permission';
import UpdateResultForm from './UpdateResultForm';
// actions
import { getData, updateStatus } from '../../redux/actions/ProjectBasedJobActions';
import CommentsComponent from '../../components/CommentsComponent';

class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenUpdateResult: false,
            isOpenFormEdit: false,
        };
    }

    handleCancel() {
        this.props.onCancel()
        this.props.reloadList()
    }

    onUpdateStatus(value) {
        this.props.updateStatus(this.props.currentData?.id, { status: value })
        this.props.reloadList();
        this.props.reloadData();
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    handleEditorChange = (data) => {
        this.setState({ editorData: data });
    };

    render() {
        const { isOpenUpdateResult, isOpenFormEdit } = this.state;
        var { visible, currentData } = this.props;

        if (currentData) {
            var diffDate = currentData?.deadline ? moment(currentData?.deadline).diff(moment().startOf('day'), 'days') : 0;
            var diffDateFinishDay = currentData?.finish_day ? moment(currentData?.deadline).diff(moment(currentData?.finish_day), 'days') : 0;
        }

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Chi tiết công việc"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '65%'}
                    extra={
                        <div style={{ display: 'flex' }}>
                            {checkPermission('project_based_job', 'update') ? (
                                <Button type="primary" onClick={() => this.setState({ isOpenFormEdit: true })} icon={<EditOutlined />}>
                                    Sửa
                                </Button>
                            ) : null}
                            <Divider type='vertical' />
                            {checkPermission('project_based_job', 'update_result') ? (
                                <Button onClick={() => this.setState({ isOpenUpdateResult: true })} type="primary" icon={<EditOutlined />}>
                                    Cập nhật kết quả
                                </Button>
                            ) : null}
                        </div>
                    }
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Button key="back" onClick={() => this.props.onCancel()}>
                                Huỷ
                            </Button>
                        </div>
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="ID">
                                            <Tag>{currentData?.id}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên công việc">
                                            {currentData?.name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Dự án">
                                            {currentData?.project_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tình trạng">
                                            <Switch
                                                disabled={!checkPermission('project_based_job', 'update_status')}
                                                defaultChecked={currentData?.status}
                                                checkedChildren="Đã hoàn thành"
                                                unCheckedChildren="Chưa hoàn thành"
                                                onChange={(value) => this.onUpdateStatus(value)}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày bắt đầu">
                                            {isValidDate(currentData?.start_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                {
                                    currentData?.deadline ? (
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Deadline">
                                                    {
                                                        currentData?.status == 1 ? (
                                                            <>
                                                                {isValidDate(currentData?.deadline, false)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isValidDate(currentData?.deadline, false)} &nbsp;
                                                                {
                                                                    diffDate ? (
                                                                        <>
                                                                            {
                                                                                diffDate > 0 ? <Tag color="#52c41a">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDate} ngày</Tag>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <Tag color="#f50">Hết hạn hôm nay</Tag>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    ) : null
                                }
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người thực hiện">
                                            <Tag>{currentData?.user}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày hoàn thành">
                                            {
                                                currentData?.finish_day ? (
                                                    <>
                                                        {isValidDate(currentData?.finish_day, false)}
                                                        <Divider type='vertical' />
                                                        {
                                                            diffDateFinishDay ? (
                                                                <span>
                                                                    {
                                                                        diffDateFinishDay >= 0 ? <Tag color="#52c41a">Trước hạn {diffDateFinishDay} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDateFinishDay} ngày</Tag>
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <Tag color="#52c41a">Đúng hạn</Tag>
                                                            )
                                                        }
                                                    </>
                                                ) : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)} bởi: {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Descriptions size="small">
                                <Descriptions.Item label="Tệp đính kèm">
                                    {
                                        currentData?.attached_files && currentData?.attached_files.length ? (
                                            <Space wrap>
                                                <ul>
                                                    {
                                                        currentData?.attached_files.map(item => {
                                                            return (
                                                                <li><FileMarkdownOutlined /> {item.name} &nbsp; <DownloadOutlined style={{ color: '#108ee9' }} onClick={() => this.onDownload(item.data)} /></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Space>
                                        ) : null
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider className="no-margin" />
                            <Comment className='comment-content-detail'
                                author={<span className='ant-descriptions-item-label'>Mô tả công việc</span>
                                }
                                content={<div className='chingo-content-detail'>
                                    {ReactHtmlParser(processHTML(currentData?.description))}
                                </div>}
                            />
                            <Divider className="no-margin" />
                            <Comment className='comment-content-detail'
                                author={<span className='ant-descriptions-item-label'>Ghi chú</span>
                                }
                                content={ReactHtmlParser(currentData?.note)}
                            />
                            <div style={{ background: '#e6ece4', padding: '10px', borderRadius: '5px' }} className='mt-3'>
                                <Comment className='comment-content-detail'
                                    author={<span className='ant-descriptions-item-label'>Kết quả công việc</span>
                                    }
                                    content={ReactHtmlParser(currentData?.result_content)}
                                />
                                <Divider className="no-margin" />
                                <Descriptions size="small">
                                    <Descriptions.Item label="Tệp đính kèm">
                                        {
                                            currentData?.result_attached_files && currentData?.result_attached_files.length ? (
                                                <Space wrap>
                                                    <ul>
                                                        {
                                                            currentData?.result_attached_files.map(item => {
                                                                return (
                                                                    <li><FileMarkdownOutlined /> {item.name} &nbsp; <DownloadOutlined style={{ color: '#108ee9' }} onClick={() => this.onDownload(item.data)} /></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </Space>
                                            ) : null
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                            <CommentsComponent
                                reloadData={() => this.props.reloadData()}
                                commentsData={currentData.comments}
                                type="project_based_job"
                                parentId={currentData?.id}
                            />
                        </div>
                    ) : null}
                </Drawer>

                <ProjectBasedJobForm
                    visible={isOpenFormEdit}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenFormEdit: false })}
                />
                <UpdateResultForm
                    visible={isOpenUpdateResult}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenUpdateResult: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getData: (id) => dispatch(getData(id)),
        updateStatus: (id, data) => dispatch(updateStatus(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));
