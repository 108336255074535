import api from '../../utils/api';
import {
    GET_LIST_CASH_RECEIPT,
    GET_SPECIFIC_CASH_RECEIPT,
    UPDATE_SPECIFIC_CASH_RECEIPT,
    CREATE_NEW_CASH_RECEIPT,
    REMOVE_CASH_RECEIPT,
    GET_LIST_CASH_RECEIPT_WITH_CONCATENATE,
    GET_STATISTICAL_CASH_RECEIPT,
    IMPORT_EXCEL_RECEIPT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCashReceipt = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/cash-receipt', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CASH_RECEIPT, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListCashReceiptActive = (filter, concatenate = false) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/cash-receipt/by-active', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: GET_LIST_CASH_RECEIPT,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: GET_LIST_CASH_RECEIPT_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCashReceipt = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/cash-receipt/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CASH_RECEIPT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCashReceipt = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/cash-receipt', data).then(res => {
            dispatch({ type: CREATE_NEW_CASH_RECEIPT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateCashReceipt = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/cash-receipt/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CASH_RECEIPT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCashReceipt = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/cash-receipt`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CASH_RECEIPT, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateCashReceiptStatus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/cash-receipt/status/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CASH_RECEIPT, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/cash-receipt/statistical/total', { params: filter }).then(res => {
            dispatch({
                type: GET_STATISTICAL_CASH_RECEIPT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        });
    });
}

export const cashReceiptImportExcel = (data, header) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/cash-receipt/import', data, header).then((res) => {
            dispatch({
                type: IMPORT_EXCEL_RECEIPT,
                payload: res,
            });
            resolve(res);
        }).catch(err => {
            NotificationManager.error(err.response?.data?.msg);
            reject(err);
        });
    });
}