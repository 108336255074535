import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_MEDICAL,
    CREATE_NEW_CUSTOMER_MEDICAL,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerMedical = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-medical', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_CUSTOMER_MEDICAL,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerMedical = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-medical', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_MEDICAL, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}