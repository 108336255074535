import api from '../../utils/api';
import {
    GET_LIST_CARD_RELEASE_TRACKING,
    GET_SPECIFIC_CARD_RELEASE_TRACKING,
    UPDATE_SPECIFIC_CARD_RELEASE_TRACKING,
    CREATE_NEW_CARD_RELEASE_TRACKING,
    REMOVE_CARD_RELEASE_TRACKING
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCard = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/card-release-tracking', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CARD_RELEASE_TRACKING, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCard = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/card-release-tracking/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CARD_RELEASE_TRACKING, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCard = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/card-release-tracking', data).then(res => {
            dispatch({ type: CREATE_NEW_CARD_RELEASE_TRACKING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const updateCard = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/card-release-tracking/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CARD_RELEASE_TRACKING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCard = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/card-release-tracking`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CARD_RELEASE_TRACKING, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}