import { Button, Card, Col, Row, Tag } from "antd";
import React from "react";
import { checkPermission } from "../../utils/permission";
import { PlusCircleOutlined } from "@ant-design/icons";

const ProfileImages = () => {

    const handleAddForm = () => {

    }

    const onEdit = (id) => {

    }


    return (
        <div>
            <Row justify="end" gutter={16}>
                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                    <Card size="small"
                        title={<><Tag color='#0b2b33'>5</Tag> Ảnh hồ sơ</>}
                        bordered={false}
                    >
                        <Button
                            disabled={!checkPermission('customer_profile', 'create')}
                            type="primary"
                            className='w-100'
                            onClick={() => handleAddForm()}
                            icon={<PlusCircleOutlined />}>
                            Thêm ảnh hồ sơ
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ProfileImages;