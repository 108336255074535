import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import ProductSupplierForm from './ProductSupplierForm';
import qs from 'qs';
import moment from 'moment';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import TextTruncate from 'react-text-truncate';

// actions
import { getAllProductSupplier, removeProductSupplier, getOneProductSupplier } from '../../redux/actions/ProductSupplierAction';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/product-supplier',
        breadcrumbName: 'Nhà cung cấp',
    }
]

class ProductSupplierList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentItem: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllProductSupplier(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProductSupplier(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({
            isOpenForm: isOpenForm,
            currentItem: currentItem
        });
    }

    onEdit(id) {
        this.props.getOneProductSupplier(id).then((res) => {
            this.toggleOpenForm(true, res)
        })
    }

    onRemove() {
        this.props.removeProductSupplier(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
        })
    }

    reloadData() {
        this.setState({
            isLoading: true
        });
        this.props.getAllProductSupplier().then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenForm,
            currentItem
        } = this.state;
        const { product_supplier, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '5%'
            },
            {
                title: 'Tên nhà cung cấp',
                dataIndex: 'name',
                key: 'name',
                width: '30%'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '25%',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '12%',
                render: (text, record) => {
                    return (<div>
                        {
                            <div>{moment(text).format('HH:mm DD/MM/YYYY')}</div>
                        }
                    </div>
                    )
                }
            },
            {
                title: 'Người tạo',
                width: '15%',
                dataIndex: 'created_username',
                key: 'created_username'
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '8%',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];
        if (checkPermission('product_supplier', 'update')) {
            columns.push({
                title: 'Actions',
                align: 'center',
                width: '5%',
                render: (text, record) => (
                    <EditOutlined onClick={() => this.onEdit(record.id)} />
                )
            })
        }

        return (
            <div>
                <PageTitle routes={routes} title="Nhà cung cấp" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('product_supplier', 'create')}
                        isShowDeleteButton={checkPermission('product_supplier', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        showSearch={true}
                        showFilter={false}
                        activeFilter={query.status}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey='id'
                    size='small'
                    tableLayout="auto"
                    columns={columns}
                    rowSelection={rowSelection}
                    loading={isLoading}
                    dataSource={product_supplier}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filter, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductSupplierForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenForm(false)}
                    reloadData={() => this.reloadData()}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        product_supplier: state.ProductSupplierReducer.product_supplier,
        pagination: state.ProductSupplierReducer.pagination,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllProductSupplier: filter => dispatch(getAllProductSupplier(filter)),
        getOneProductSupplier: id => dispatch(getOneProductSupplier(id)),
        removeProductSupplier: ids => dispatch(removeProductSupplier(ids))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductSupplierList);
