import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Drawer, Divider, Select } from 'antd';
import { _newLine } from '../../utils/helpers';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';

// actions
import { createData, updateData } from '../../redux/actions/JobsActions';

class JobsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false
        };
    }

    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isloading: true })
                    const params = {
                        role_id: values.role_id,
                        description: values.description,
                        note: values.note,
                    }
                    if (this.props.currentData) {
                        await this.props.updateData(this.props.currentData.id, params);
                        this.handCancel()
                        this.props.reloadData()
                    } else {
                        await this.props.createData(params);
                        this.handCancel()
                        this.props.reloadData()
                    }
                    //console.log(params)
                    this.setState({ isloading: false })
                } catch (error) {
                    this.setState({ isloading: false });
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false })
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, config } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa công việc" : "Thêm công việc"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Loại công việc">
                            {getFieldDecorator('role_id', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng chọn!',
                                    },
                                ],
                                initialValue: currentData ? currentData.role_id : null
                            })(
                                <Select
                                    className='w-100'
                                    placeholder="Chọn 1 bản ghi"
                                    options={config.role_trees || []}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Mô tả công việc">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng nhập!",
                                    },
                                ],
                                initialValue: currentData ? currentData.description : null
                            })(
                                <CKEditorComponent
                                    initialHeight="300px"
                                    editorData={currentData?.description}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>


                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'JobsForm' })(JobsForm));
