
import React, { Component } from 'react';
import { Row, Col, Card, Avatar, Divider, List, Skeleton, Tooltip, Tag, Input, Tabs, Upload, Spin, Drawer, Empty, Button } from 'antd';
import { ArrowLeftOutlined, CommentOutlined, EyeInvisibleOutlined, EyeOutlined, FilterOutlined, LoadingOutlined, MailOutlined, MessageOutlined, ProfileOutlined, UserAddOutlined, UserOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import { getBase64, stripHtml, toNewLine, handleAttachmentMessage, formatRelativeDate, getFirstLetterOfWords, isValidDate, cleanObject, handleSnippet } from '../../utils/helpers';
import appConfig from '../../config';
import socketio from "socket.io-client";
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';
import { messages_example } from './data_example';
import HtmlParser from 'react-html-parser';
import CustomerInfo from './CustomerInfo';
import CustomerForm from './CustomerForm';
import qs from 'qs';
import axios from 'axios';
import { getCustomerByFacebookName } from '../../redux/actions/CustomerActions';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import messageSentSound from "../../assets/audio/message_sent.wav";
import messageReceivedSound from "../../assets/audio/message_received1.mp3";
import MessageAction from './MessageAction';
import { checkPermission } from '../../utils/permission';

const filterTags = [
    {
        value: 'messages',
        name: <><MailOutlined /> Tin nhắn</>
    },
    {
        value: 'comment',
        name: <><MessageOutlined /> Bình luận</>
    }
]

const { CheckableTag } = Tag;
const { Search } = Input;
const { TabPane } = Tabs;

class Messenger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            socket: socketio.connect(appConfig.SERVER_NODEJS_URL),
            loading: false,
            conversations: [],
            conversation_id: null,
            current_page_id: null,
            current_page: null,
            messages_list: [],
            current_sender_id: null,
            isLoading: false,
            loading_send_msg: false,
            page: 1,
            hasMore: true,
            msg_page: 1,
            msgHasMore: true,
            searchTerm: '',
            type: '',
            page_ids: '',
            isOpenPageFilter: false,
            selectedFilterPage: [],
            page_filter_list: [],
            page_list: [],
            conversation_info: null,
            customerInfo: null,
            newCustomer: null
        }

        this.textInput = React.createRef();
        this.messagesContentRef = React.createRef();
        this.playerSentSoundRef = React.createRef();
        this.playerReceivedSoundRef = React.createRef();
    }

    async componentDidMount() {

        const { socket } = this.state;

        socket.on('connect', () => {
            this.setState({ isConnected: true });
        });

        socket.on('disconnect', () => {
            this.handleConnectionStatus(false);
        });

        // Nhận tin nhắn mới từ server
        socket.on('new_message', (res) => {

            const { new_message, new_conversation } = res.result;
            const { conversation_id, page_list } = this.state;

            // Kiểm tra xem page_id của tin nhắn mới có trong page_list không
            // Nghĩa là người dùng có quyền quản lý page đó không
            if (!page_list.some(page => page.page_id === new_message.page_id)) {
                console.log("page_id không có trong page_list: ", new_message.page_id);
                return; // Thoát ra nếu page_id không có trong page_list
            }

            // Nếu tin nhắn mới thuộc page không được kích hoạt thì không hiển thị
            if (new_message.page_status === 0) {
                return;
            }

            try {
                if (new_message.is_sender === 'customer') {
                    this.playerReceivedSound();
                    console.log('new_message:', 'new_message');
                }
            } catch (err) {
                console.log("error: ", err)
            }

            if (new_message.conversation_id === conversation_id) {
                // Nếu tin nhắn mới thuộc về hội thoại đang xem thì hiển thị nó

                console.log("Nếu tin nhắn mới thuộc về hội thoại đang xem thì hiển thị nó: ", new_message);

                this.setState((prevState) => {
                    // Tìm hội thoại tương ứng với conversation_id của tin nhắn mới
                    const updatedConversations = prevState.conversations.filter(convo => convo.id !== conversation_id);

                    const updatedConversation = {
                        ...prevState.conversations.find(convo => convo.id === conversation_id),
                        snippet: new_message.message, // Cập nhật snippet với nội dung tin nhắn mới
                        is_sender: new_message.is_sender,
                        attachment_type: new_message.attachment_type,
                        message_type: new_message.message_type,
                        latest_created_time: new_message.created_time, // Cập nhật thời gian tin nhắn mới nhất
                        has_new_message: false // Đánh dấu hội thoại này không có tin nhắn mới
                    };

                    return {
                        messages_list: new_message.is_sender === 'customer' ? [...prevState.messages_list, new_message] : prevState.messages_list,
                        conversations: [updatedConversation, ...updatedConversations]
                    };
                });

            } else {
                this.setState((prevState) => {

                    // Nếu người gửi tin nhắn mới là khách hàng thì đánh dấu hội thoại có tin nhắn mới
                    const has_new_message = new_message.is_sender == 'customer';

                    const conversationIndex = prevState.conversations.findIndex(convo => convo.id === new_message.conversation_id);
                    if (conversationIndex !== -1) { // Nếu hội thoại đã tồn tại thì cập nhật lại snippet và thời gian

                        console.log("Nếu hội thoại đã tồn tại thì cập nhật lại snippet và thời gian: ", new_message)

                        const updatedConversation = {
                            ...prevState.conversations[conversationIndex],
                            id: new_message.conversation_id,
                            snippet: new_message.message,
                            is_sender: new_message.is_sender,
                            attachment_type: new_message.attachment_type,
                            message_type: new_message.message_type,
                            latest_created_time: new_message.created_time,
                            has_new_message: has_new_message // Đánh dấu hội thoại này có tin nhắn mới
                        };

                        // Loại bỏ hội thoại cũ và thêm hội thoại đã cập nhật vào đầu danh sách
                        const updatedConversations = [
                            updatedConversation,
                            ...prevState.conversations.filter((_, index) => index !== conversationIndex)
                        ];

                        return { conversations: updatedConversations };

                    } else { // Nếu hội thoại chưa tồn tại thì thêm mới vào danh sách
                        console.log("Nếu hội thoại chưa tồn tại thì thêm mới vào danh sách: ", new_message);

                        // Chỉ thêm hội thoại mới vào đầu danh sách nếu tin nhắn mới là từ khách hàng
                        if (new_message.is_sender === 'customer') {
                            return {
                                conversations: [
                                    {
                                        ...new_conversation,
                                        id: new_message.conversation_id,
                                        snippet: new_message.message,
                                        latest_created_time: new_message.created_time,
                                        has_new_message: has_new_message,
                                        is_sender: new_message.is_sender,
                                        attachment_type: new_message.attachment_type,
                                        message_type: new_message.message_type
                                    },
                                    ...prevState.conversations
                                ]
                            };
                        }
                    }
                });
            }

            console.log('new_message:', new_message);
            console.log('new_conversation:', new_conversation);
            console.log('conversations:', this.state.conversations);
        });

        // Gọi hàm lần đầu khi component mount
        this.loadConversations();
        this.loadPageManager();
    }

    componentWillUnmount() {
        // Hủy đăng ký các event khi component unmount để tránh memory leak
        this.state.socket.off('connect');
        this.state.socket.off('disconnect');
    }

    handleConnectionStatus = (isConnected) => {
        this.setState({ isConnected });
    }

    // Lấy danh sách các trang mà user có quyền quản lý từ server
    loadPageManager = () => {
        const { socket, page_ids } = this.state;

        socket.emit('load_pageManager', { page_ids, user_id: this.props.authUser.id });

        socket.off('pageFilter'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('pageFilter', (page_filter_list) => {
            this.setState({ page_filter_list })
        })

        socket.off('pageManager'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('pageManager', (page_list) => {
            this.setState({ page_list })
        })

        // Xử lý trường hợp lỗi
        socket.off('load_pageManager_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('load_pageManager_error', (error) => {
            console.error('Load pageManager error:', error);
        });
    }

    // Lấy danh sách hội thoại từ server
    loadConversations = () => {
        const { socket, loading, page, searchTerm, type, page_ids } = this.state;

        if (loading) {
            return;
        }

        this.setState({ loading: true });

        // Gửi yêu cầu lấy thêm dữ liệu tới server
        socket.emit('load_conversations', { page, searchTerm, type, page_ids, user_id: this.props.authUser.id });

        // Lắng nghe phản hồi từ server
        socket.off('conversations'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('conversations', (newConversations) => {
            this.setState((prevState) => ({
                conversations: prevState.page === 1 ? newConversations : [...prevState.conversations, ...newConversations],
                loading: false,
                page: prevState.page + 1, // Tăng page nếu load thêm dữ liệu
                hasMore: newConversations.length > 9 // Mặc định mỗi lần tải là 10 bản ghi, Kiểm tra nếu dữ liệu trả về nhỏ hơn 10 thì đặt hasMore là false
            }));
        });

        // Xử lý trường hợp lỗi
        socket.off('load_conversations_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('load_conversations_error', (error) => {
            console.error('Load conversations error:', error);
            this.setState({ loading: false });
        });
    };

    // Lấy danh sách tin nhắn từ server
    loadMessages = () => {
        const { socket, isLoading, msg_page, conversation_id } = this.state;

        if (isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        // Gửi yêu cầu lấy thêm dữ liệu tới server
        socket.emit('select_conversations', { msg_page, conversation_id });

        socket.off('messages'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('messages', (new_messages) => {
            this.setState((prevState) => ({
                messages_list: [...new_messages, ...prevState.messages_list],
                isLoading: false,
                msg_page: prevState.msg_page + 1, // Tăng page nếu load thêm dữ liệu
                msgHasMore: new_messages.length > 9 // Mặc định mỗi lần tải là 10 bản ghi, Kiểm tra nếu dữ liệu trả về nhỏ hơn 10 thì đặt hasMore là false
            }), () => {
                // Cuộn xuống cuối 30px cùng khi có tin nhắn mới
                if (this.messagesContentRef.current) {
                    this.messagesContentRef.current.scrollBy({
                        top: 200,
                        //behavior: 'smooth'
                    });
                }
            });
        });

        // Xử lý trường hợp lỗi
        socket.off('select_conversations_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('select_conversations_error', (error) => {
            console.error('select conversations error:', error);
            this.setState({ isLoading: false });
        });
    }

    getPageInfo = () => {
        const { socket, current_page_id } = this.state;

        // Gửi yêu cầu lấy thông tin trang hiện tại tới server
        socket.emit('get_current_page', { current_page_id });

        // Lấy thông tin trang hiện tại từ server
        socket.off('current_page'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('current_page', (current_page) => {
            this.setState({ current_page });
        });

        // Xử lý trường hợp lỗi
        socket.off('current_page_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('current_page_error', (error) => {
            console.error('current page error:', error);
        });
    }

    // Lấy thông tin hội thoại từ server
    loadConversationInfo = () => {
        const { socket, conversation_id } = this.state;

        // Gửi yêu cầu lấy thêm dữ liệu tới server
        socket.emit('load_conversation_info', { conversation_id });

        socket.off('conversation_info'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('conversation_info', (new_conversation_info) => {
            this.setState((prevState) => ({
                conversation_info: new_conversation_info,
            }));

            // Lấy thông tin khách hàng đã lưu trong database
            this.getCustomerInfo(new_conversation_info?.sender_name);
        });

        // Xử lý trường hợp lỗi
        socket.off('load_conversation_info_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
        socket.on('load_conversation_info_error', (error) => {
            console.error('load conversation info error:', error);
        });
    }

    // Hàm lấy thông tin khách hàng đã lưu trong database
    getCustomerInfo = (fb_name) => {
        this.props.getCustomerByFacebookName({ fb_name }).then(response => {
            this.setState({ customerInfo: response, newCustomer: this.state.conversation_info });
        }).catch(error => {
            console.error('getCustomerByFacebookName error:', error);
        });
    }

    // Hàm lấy tin nhắn theo conversation_id
    handSelectConversation = (conversation_id, current_page_id, current_sender_id) => {
        if (!checkPermission('messenger', 'message')) {
            NotificationManager.error("Bạn không có quyền truy cập chức năng này");
            return;
        }
        this.setState({ conversation_id, messages_list: [], msg_page: 1, current_page_id, current_sender_id }, () => {
            this.loadMessages();
            this.loadConversationInfo();
            this.updateHasNewMessage(conversation_id);
            this.getPageInfo();
            this.updateQueryParams('conversation_id', conversation_id);
        });
    }

    // Hàm cập nhật trạng thái trường has_new_message của conversation
    updateHasNewMessage = (conversation_id) => {
        this.setState((prevState) => {
            const updatedConversations = prevState.conversations.map(convo => {
                if (convo.id === conversation_id) {
                    return {
                        ...convo,
                        has_new_message: false
                    };
                }
                return convo;
            });

            return {
                conversations: updatedConversations
            };
        });
    }

    // Hàm tìm kiếm theo tên khách hàng, số điện thoại
    handleSearch = (value) => {
        this.setState({ searchTerm: value.trim(), page: 1, conversations: [] }, () => {
            this.loadConversations();
        });
    };

    // Hàm lọc conversation theo loại tin nhắn , bình luận
    handleTypeChange = (type, checked) => {
        if (checked) {
            this.setState({ type, page: 1, conversations: [] }, () => {
                this.loadConversations();
            });
        } else {
            this.setState({ type: '', page: 1, conversations: [] }, () => {
                this.loadConversations();
            });
        }
    };

    // Hàm lọc conversation theo page
    handlePageChange(value, checked) {
        const { selectedFilterPage } = this.state;
        var temp = selectedFilterPage;
        if (checked) temp.push(value);
        else temp = temp.filter(item => item != value);

        this.setState({
            selectedFilterPage: temp,
            page_ids: temp,
            page: 1,
            conversations: []
        }, () => {
            this.loadConversations();
            this.loadPageManager();
        });
    }

    onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.myFormRef.requestSubmit();
            localStorage.removeItem('messageText')
        }
    }

    setLocalStorage() {
        var value = stripHtml(this.textInput.current.value).trim();
        if (value != '' && value.trim().length) {
            if (localStorage) {
                localStorage.setItem('messageText', value)
            }
        } else {
            localStorage.removeItem('messageText')
        }
    }

    handleInput(e) {
        if (this.textInput.current) {
            this.textInput.current.style.height = "auto";
            this.textInput.current.style.height = `${e.target.scrollHeight - 16}px`;
        }
    };

    handleOpenChatMobile = () => {
        this.setState({ isOpenChatMobile: true })
    }

    // Tải lại dữ liệu sau khi cập nhật thông tin khách hàng
    handleCustomerForm = () => {
        this.getCustomerInfo(this.state.conversation_info?.sender_name);
    };

    updateQueryParams(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        };
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    playerSendSound() {
        this.playerSentSoundRef.current.play();
    }

    playerReceivedSound() {
        this.playerReceivedSoundRef.current.play();
    }

    // Hàm gửi tin nhắn
    handleSendMessage = (e) => {
        e.preventDefault();

        if (!checkPermission('messenger', 'send_message')) {
            NotificationManager.error("Bạn không có quyền truy cập chức năng này");
            return;
        }

        this.scrollToBottom();

        var value = stripHtml(this.textInput.current.value).trim();
        value = value.replace(/\&|_/gi, 'và'); // Xóa dấu &
        value = value.replace(/\#|_/gi, '$'); // Xóa dấu #

        var { socket, messages_list, current_page, current_page_id, current_sender_id, conversation_info } = this.state;

        if (value != '' && value.trim().length) {
            this.textInput.current.value = '';
            this.textInput.current.focus();
            this.textInput.current.style.height = "auto";
            localStorage.removeItem('messageText');
            this.setState({ loading_send_msg: true })

            var message = value;

            const type = conversation_info?.type;

            const new_message = {
                message: value,
                sender_name: current_page.page_name,
                sender_id: current_page_id,
                sender_picture: current_page?.picture,
                created_time: moment(),
                message_type: 'text',
                page_id: current_page_id,
                type: type,
                is_sender: 'page'
            }

            // Lấy danh sách tin nhắn của khách hàng
            // Điều kiện lọc: tin nhắn của khách hàng và loại tin nhắn là bình luận
            // Để tìm ra tin nhắn cuối cùng của khách hàng
            const filteredData = messages_list.filter(item => item.is_sender === 'customer' && item.type === 'comment');

            // Tìm ra tin nhắn cuối cùng của khách hàng múc đích là để lấy comment_id
            const latestItem = filteredData.reduce((max, item) => item.id > max.id ? item : max, filteredData[0]);

            const comment_id = latestItem?.message_id;

            // Gửi tin nhắn tới server
            socket.emit('send_message', { message, current_page_id, current_sender_id, type, comment_id });

            // Nhận tin nhắn mới từ server
            socket.off('message_sent'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
            socket.on('message_sent', (result) => {
                console.log('message_sent:', result);
                this.playerSendSound();
                this.setState({ loading_send_msg: false });
            });

            // Xử lý trường hợp lỗi
            socket.off('send_message_error'); // Hủy lắng nghe trước khi thiết lập mới để tránh lặp lại
            socket.on('send_message_error', (error) => {
                console.error('send message error:', error);
                this.setState({ loading_send_msg: false });
                NotificationManager.error(error.error);
            });

            this.setState((prevState) => ({
                messages_list: [...prevState.messages_list, new_message]
            }));
        }
    }

    // Hàm gửi file đính kèm
    sendMessageAttachment = async (e, fileType) => {

        if (!checkPermission('messenger', 'send_message')) {
            NotificationManager.error("Bạn không có quyền truy cập chức năng này");
            return;
        }

        this.scrollToBottom();

        const data = new FormData();
        var { current_page, current_page_id, conversation_info } = this.state;

        this.setState({ loading_send_msg: true })

        if (fileType == 'image') {
            var preview = await getBase64(e.file);
            const new_message = {
                message: preview,
                sender_name: current_page.page_name,
                sender_id: current_page_id,
                sender_picture: current_page?.picture,
                created_time: moment(),
                message_type: 'attachments',
                attachment_type: 'image',
                page_id: current_page_id,
                type: "messages"
            }

            this.setState((prevState) => ({
                messages_list: [...prevState.messages_list, new_message]
            }));
        }

        var attachment = {
            attachment: {
                type: fileType,
                payload: {
                    is_reusable: true
                }
            }
        }
        var recipient = {
            id: conversation_info.sender_id
        }
        data.append('recipient', JSON.stringify(recipient));
        data.append('message', JSON.stringify(attachment));
        data.append('filedata', e.file);

        var access_token_page = current_page.page_access_token;
        var url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/me/messages?access_token=${access_token_page}`;

        const config = {
            headers: { "content-type": "multipart/form-data" }
        };
        axios({
            method: "post",
            url: url,
            data,
            config
        }).then(response => {
            if (response.status == 200) {
                this.setState({ loading_send_msg: false })
                console.log("Send file data successfully: ", response.data);
                this.playerSendSound();
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({ loading_send_msg: false });
                NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    scrollToBottom() {
        if (this.messagesContentRef.current) {
            this.messagesContentRef.current.scrollBy({
                top: this.messagesContentRef.current.scrollHeight,
                //behavior: 'smooth'
            });
        }
    }

    render() {
        const { conversations, loading_send_msg,
            isOpenChatMobile, hasMore, isConnected,
            type, isOpenPageFilter, selectedFilterPage, page_filter_list,
            page_list, messages_list, msgHasMore, conversation_info, customerInfo, newCustomer } = this.state;

        const showMessages = messages_list.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        // Hàm tìm bài viết đầu tiên trong danh sách tin nhắn
        const findFirstPost = (messages) => {
            for (let message of messages) {
                if (message.type === 'comment' && message.post) {
                    return {
                        post: message.post,
                        permalink_url: message.permalink_url
                    };
                }
            }
            return null;
        };

        const firstPost = findFirstPost(messages_list);

        console.log('messages_list:', messages_list);

        return (
            <Card size='small' className='card-messenger'>
                <Row gutter={16}>
                    <Col xs={24} md={6}>
                        <Search
                            placeholder="Tên khách hàng, số điện thoại, tin nhắn..."
                            onSearch={this.handleSearch}
                            allowClear
                        />
                        <Row className="mt-2 mb-2" align="middle">
                            {
                                filterTags.map(tag => (
                                    <CheckableTag
                                        key={tag.value}
                                        checked={type.indexOf(tag.value) > -1}
                                        onChange={checked => this.handleTypeChange(tag.value, checked)}
                                        style={{ fontWeight: '500' }}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))
                            }
                            {
                                page_filter_list && page_filter_list.length ? (
                                    <>
                                        <Avatar.Group size={25} maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                            {
                                                page_filter_list.map(item => {
                                                    return (
                                                        <Tooltip title={item.page_name} placement="top">
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: '#183940',
                                                                    verticalAlign: 'middle'
                                                                }}
                                                            >
                                                                {item?.page_name ? getFirstLetterOfWords(item.page_name) : ''}
                                                            </Avatar>
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Avatar.Group>
                                        <Tooltip title="Lọc theo Trang" placement="top">
                                            <FilterOutlined onClick={() => this.setState({ isOpenPageFilter: true })} className='filter-icon-conversation' />
                                        </Tooltip>
                                    </>
                                ) : null
                            }
                        </Row>
                        <div
                            id="scrollableDiv"
                            className='side-bar-conversation'
                            style={{
                                height: '75vh',
                                overflow: 'auto',
                                padding: '0px 8px 0 0'
                            }}
                        >
                            {isConnected ? null : (
                                <div style={{ color: 'red', textAlign: 'center', margin: '10px' }}>
                                    Không thể kết nối đến server. Vui lòng kiểm tra lại kết nối.
                                </div>
                            )}
                            <InfiniteScroll
                                dataLength={conversations.length}
                                next={this.loadConversations}
                                hasMore={hasMore}
                                loader={
                                    <Skeleton
                                        avatar
                                        paragraph={{
                                            rows: 1,
                                        }}
                                        active
                                    />
                                }
                                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={conversations}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={index}
                                            onClick={() => this.handSelectConversation(item.id, item.page_id, item.sender_id)}
                                            className={item?.id == query?.conversation_id ? 'active' : null}
                                        >
                                            <List.Item.Meta
                                                avatar={<>
                                                    {item?.sender_picture ? (
                                                        <Avatar icon={<UserOutlined />} className='avata' size={40} src={item?.sender_picture} />
                                                    ) : (
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: '#31cdf5',
                                                                verticalAlign: 'middle'
                                                            }}
                                                            size={40}>
                                                            {item?.sender_name ? getFirstLetterOfWords(item.sender_name) : ''}
                                                        </Avatar>
                                                    )}
                                                </>}
                                                title={<span className={item?.has_new_message ? 'has-new-message-name' : ''}>{item?.sender_name}</span>}
                                                description={
                                                    <span className={item?.has_new_message ? 'has-new-message-snippet' : ''}>
                                                        {item.is_sender == 'page' ? <i className="fas fa-reply ng-scope icon-fa-reply"></i> : <i class="fa-solid fa-share icon-fa-share"></i>}
                                                        &nbsp;
                                                        {item.snippet ?
                                                            item.message_type === 'attachments' ? handleSnippet(item.is_sender, item.attachment_type) : stripHtml(item.snippet)
                                                            : null
                                                        }
                                                    </span>
                                                }
                                            />
                                            <div className='info'>
                                                <div className='date-time'>{formatRelativeDate(item?.latest_created_time)}</div>
                                                <div className='conversation-icon'>
                                                    {
                                                        item?.type == 'comment' ? (
                                                            <Tooltip title="Bình luận"><CommentOutlined className={item?.has_new_message ? 'has-new-message-icon' : ''} /></Tooltip>
                                                        ) : (
                                                            <Tooltip title="Tin nhắn"><MessageOutlined className={item?.has_new_message ? 'has-new-message-icon' : ''} /></Tooltip>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Col >
                    {showMessages ? (
                        <>
                            <Col xs={24} md={12} className='wrap-conversation-chat-section'>
                                <Card size='small' className='conversation-chat-section'>
                                    <div className='conversation-header'>
                                        <div className='conversation-header-title'>
                                            {conversation_info?.sender_picture ? (
                                                <Avatar icon={<UserOutlined />} size={50} src={conversation_info?.sender_picture} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size={50}
                                                >
                                                    {conversation_info?.sender_name ? getFirstLetterOfWords(conversation_info?.sender_name) : ''}
                                                </Avatar>
                                            )}
                                            <div className='conversation-title'>
                                                <div className='name'>{conversation_info?.sender_name}</div>
                                                <div className='status'>
                                                    {conversation_info?.type == 'messages' ?
                                                        conversation_info?.is_read == 1 && conversation_info?.is_read_time ? (
                                                            <><EyeOutlined /> Đã xem lúc&nbsp;
                                                                {isValidDate(conversation_info?.is_read_time, true)}
                                                            </>
                                                        ) : (
                                                            <><EyeInvisibleOutlined /> Chưa xem</>
                                                        )
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='conversation-header-page'>
                                            <div>
                                                <Tooltip placement="top" title="Truy cập trang">
                                                    <a href={`https://www.facebook.com/${conversation_info?.page_id}`} target="_blank">{conversation_info?.page_name}</a>
                                                </Tooltip>
                                            </div>
                                            <div>{conversation_info?.page_id}</div>
                                        </div>
                                    </div>
                                    <div className='conversation-body'>
                                        <div className='conversation-content' id="conversation-content" ref={this.messagesContentRef}>
                                            <InfiniteScroll
                                                dataLength={messages_list.length}
                                                next={this.loadMessages}
                                                hasMore={msgHasMore}
                                                inverse={true} // Đặt thanh cuộn luôn ở phía dưới
                                                style={{ display: 'flex', flexDirection: 'column-reverse', marginBottom: '10px', paddingBottom: '30px' }}
                                                loader={<p style={{ textAlign: "center" }}><Spin size='small' /> Loading...</p>}
                                                //endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                                                scrollableTarget="conversation-content"
                                            >
                                                <ul id='messages-ul'>
                                                    {firstPost && (
                                                        <li style={{
                                                            background: '#e9f5ff',
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            marginTop: '10px',
                                                            width: 'calc(100% - 10px)',
                                                        }}>
                                                            {HtmlParser(toNewLine(firstPost.post))}
                                                            <div style={{ textAlign: 'right' }}>
                                                                <a href={firstPost.permalink_url} target="_blank">Xem bài viết</a>
                                                            </div>
                                                        </li>
                                                    )}
                                                    {
                                                        messages_list.map(item => { // sent, replies
                                                            return (
                                                                <>
                                                                    <li className={`${item?.type} ${item?.page_id === item?.sender_id ? 'replies' : 'sent'}`}>
                                                                        {item?.sender_picture ? (
                                                                            <Avatar icon={<UserOutlined />} className='avata' size={22} src={item?.sender_picture} />
                                                                        ) : (
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor: '#31cdf5',
                                                                                    verticalAlign: 'middle'
                                                                                }}
                                                                                size={22}
                                                                                className='avata'
                                                                            >
                                                                                {item?.sender_name ? getFirstLetterOfWords(item?.sender_name) : ''}
                                                                            </Avatar>
                                                                        )}
                                                                        <div className={`msg-content ${item?.message_type}`}>
                                                                            {
                                                                                item?.message_type == 'text' ? (
                                                                                    <div className='text-msg'>{HtmlParser(toNewLine(item?.message))}</div>
                                                                                ) : (
                                                                                    <div className='attachment-msg'>{handleAttachmentMessage(item?.attachment_type, item?.message)}</div>
                                                                                )
                                                                            }
                                                                            <div class="msg-info">
                                                                                <div class="msg-time">
                                                                                    {formatRelativeDate(item?.created_time)}
                                                                                </div>
                                                                            </div>
                                                                            <MessageAction item={item} current_page={this.state.current_page} />
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                    {checkPermission('messenger', 'send_message') && (
                                        <div className='conversation-footer'>
                                            <div className="message-input">
                                                {loading_send_msg && <LoadingOutlined className='loading-messages' />}
                                                <form onSubmit={(e) => this.handleSendMessage(e)} ref={el => this.myFormRef = el}>
                                                    <textarea
                                                        ref={this.textInput}
                                                        onKeyDown={this.onEnterPress}
                                                        onKeyUp={() => this.setLocalStorage()}
                                                        placeholder="Nhấn Enter để gửi, Shift + Enter để xuống dòng. Mỗi tin không quá 2000 ký tự..."
                                                        rows={1}
                                                        type="text"
                                                        onInput={(e) => this.handleInput(e)}
                                                    >
                                                        {localStorage.getItem('messageText')}
                                                    </textarea>
                                                </form>
                                            </div>
                                            <div className='list-attachment-icon-wrap'>
                                                <Tooltip placement="top" title="Gửi ảnh, dung lượng không quá 2MB">
                                                    <Upload
                                                        showUploadList={false}
                                                        accept="image/*"
                                                        customRequest={(e) => this.sendMessageAttachment(e, 'image')}
                                                    >
                                                        <i class="fas fa-image icon picture" aria-hidden="true"></i>
                                                    </Upload>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Gửi tài liệu, dung lượng không quá 2MB">
                                                    <Upload
                                                        showUploadList={false}
                                                        accept=".xlsx, .xls, .doc, .docx, .txt, .pdf"
                                                        customRequest={(e) => this.sendMessageAttachment(e, 'file')}
                                                    >
                                                        <i className="fa fa-paperclip icon attachment" aria-hidden="true" />
                                                    </Upload>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Gửi file âm thanh, dung lượng không quá 2MB">
                                                    <Upload
                                                        showUploadList={false}
                                                        accept="audio/*"
                                                        customRequest={(e) => this.sendMessageAttachment(e, 'audio')}
                                                    >
                                                        <i class="fa fa-file-audio icon" aria-hidden="true"></i>
                                                    </Upload>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Gửi video, dung lượng không quá 2MB">
                                                    <Upload
                                                        showUploadList={false}
                                                        accept="video/*"
                                                        customRequest={(e) => this.sendMessageAttachment(e, 'video')}
                                                    >
                                                        <VideoCameraAddOutlined className="icon" />
                                                    </Upload>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Gửi tin nhắn">
                                                    <button type='submit' className="submit" onClick={(e) => this.handleSendMessage(e)}>
                                                        <i className="fa fa-paper-plane icon" aria-hidden="true" />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                            <Col xs={24} md={6} className='conversation-info'>
                                <Card size='small' bodyStyle={{ padding: 0 }}>
                                    <div className='wrap-conversation-info'>
                                        <Tabs type="card" size="small">
                                            <TabPane tab={<span style={{ fontWeight: 600 }}><ProfileOutlined />Thông tin</span>} key="info">
                                                <CustomerInfo data={customerInfo} />
                                            </TabPane>
                                            <TabPane tab={<span style={{ fontWeight: 600 }}><UserAddOutlined />Cập nhật</span>} key="update">
                                                <CustomerForm data={customerInfo} newCustomer={newCustomer} callback={this.handleCustomerForm} />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </Card>
                            </Col>
                        </>
                    ) : (
                        <Col xs={24} md={18}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Chọn một hội thoại để xem nội dung" />
                        </Col>
                    )}
                </Row>

                <audio ref={this.playerSentSoundRef} src={messageSentSound} />
                <audio ref={this.playerReceivedSoundRef} src={messageReceivedSound} />

                <Drawer
                    visible={isOpenPageFilter}
                    onClose={() => this.setState({ isOpenPageFilter: false })}
                    maskClosable={false}
                    width={isMobile ? '100%' : '40%'}
                    footer={null}
                >
                    <>
                        {
                            page_list && page_list.length ? (
                                <>
                                    {
                                        page_list.map(page => (
                                            <div className='wrap-list-page-filter'>
                                                <CheckableTag
                                                    key={page.page_id}
                                                    checked={selectedFilterPage.some(item => item.indexOf(page.page_id) > -1)}
                                                    onChange={checked => this.handlePageChange(page.page_id, checked)}
                                                    style={{ fontWeight: '500' }}
                                                >
                                                    <div className='list-page-filter'>
                                                        <div className='list-page-filter-avatar'>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: '#183940',
                                                                    verticalAlign: 'middle'
                                                                }}
                                                                size={40}>
                                                                {page?.page_name ? getFirstLetterOfWords(page.page_name) : ''}
                                                            </Avatar>
                                                        </div>
                                                        <div className='list-page-filter-page-name'>
                                                            <div>{page.page_name}</div>
                                                            <div className='pageid'>{page.page_id}</div>
                                                        </div>
                                                    </div>
                                                </CheckableTag>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : null
                        }
                    </>
                </Drawer>

                <Drawer
                    visible={isOpenChatMobile}
                    onClose={() => this.setState({ isOpenChatMobile: false })}
                    maskClosable={false}
                    width='100%'
                    closeIcon={<ArrowLeftOutlined />}
                    footer={null}
                    title={
                        <div className='conversation-header'>
                            <div className='conversation-header-title'>
                                <Avatar size={50} icon={<UserOutlined />} />
                                <div className='conversation-title'>
                                    <div className='name'>Nguyễn Văn A</div>
                                    <div className='status'><EyeOutlined /> Đã xem lúc 22/06/2024 10:18</div>
                                </div>
                            </div>
                        </div>
                    }
                >
                    <>
                        <div className='conversation-content'>
                            <ul id='messages-ul'>
                                {
                                    messages_example.map(item => { // sent, replies
                                        return (
                                            <li className={item.is_sender == 'page' ? 'replies' : 'sent'}>
                                                {item?.sender_picture ? (
                                                    <Avatar icon={<UserOutlined />} className='avata' size={22} src={item?.sender_picture} />
                                                ) : (
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: '#31cdf5',
                                                            verticalAlign: 'middle'
                                                        }}
                                                        size={22}
                                                    >
                                                        {item?.sender_name ? getFirstLetterOfWords(item?.sender_name) : ''}
                                                    </Avatar>
                                                )}
                                                <div>
                                                    {
                                                        item.message_type == 'text' ? (
                                                            <div className='text-msg'>{HtmlParser(toNewLine(item.message))}</div>
                                                        ) : (
                                                            <div className='attachment-msg'>{handleAttachmentMessage(item.attachment_type, item.message)}</div>
                                                        )
                                                    }
                                                    <div class="hover-info">
                                                        <div class="hover-time">
                                                            {formatRelativeDate(item.created_time)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                <li id='messages'></li>
                            </ul>
                        </div>
                        <div className='conversation-footer'>
                            <div className="message-input">
                                <form onSubmit={(e) => this.submitMessage(e)} ref={el => this.myFormRef = el}>
                                    <textarea
                                        ref={this.textInput}
                                        //onKeyDown={this.onEnterPress}
                                        //onKeyUp={() => this.setLocalStorage()}
                                        placeholder="Nhấn Enter để gửi, Shift + Enter để xuống dòng. Mỗi tin không quá 2000 ký tự..."
                                        rows={1}
                                        type="text"
                                        onInput={(e) => this.handleInput(e)}
                                    >
                                        {localStorage.getItem('messageText')}
                                    </textarea>
                                </form>
                            </div>
                            <div className='list-attachment-icon-wrap'>
                                <Tooltip placement="top" title="Gửi ảnh, dung lượng không quá 2MB">
                                    <Upload
                                        showUploadList={false}
                                        accept="image/*"
                                        customRequest={(e) => this.sendMessageAttachment(e, 'image')}
                                    >
                                        <i class="fas fa-image icon picture" aria-hidden="true"></i>
                                    </Upload>
                                </Tooltip>
                                <Tooltip placement="top" title="Gửi tài liệu, dung lượng không quá 2MB">
                                    <Upload
                                        showUploadList={false}
                                        accept=".xlsx, .xls, .doc, .docx, .txt, .pdf"
                                        customRequest={(e) => this.sendMessageAttachment(e, 'file')}
                                    >
                                        <i className="fa fa-paperclip icon attachment" aria-hidden="true" />
                                    </Upload>
                                </Tooltip>
                                <Tooltip placement="top" title="Gửi file âm thanh, dung lượng không quá 2MB">
                                    <Upload
                                        showUploadList={false}
                                        accept="audio/*"
                                        customRequest={(e) => this.sendMessageAttachment(e, 'audio')}
                                    >
                                        <i class="fa fa-file-audio icon" aria-hidden="true"></i>
                                    </Upload>
                                </Tooltip>
                                <Tooltip placement="top" title="Gửi video, dung lượng không quá 2MB">
                                    <Upload
                                        showUploadList={false}
                                        accept="video/*"
                                        customRequest={(e) => this.sendMessageAttachment(e, 'video')}
                                    >
                                        <VideoCameraAddOutlined className="icon" />
                                    </Upload>
                                </Tooltip>
                                <Tooltip placement="top" title="Gửi tin nhắn">
                                    <button type='submit' className="submit" onClick={(e) => this.submitMessage(e)}>
                                        <i className="fa fa-paper-plane icon" aria-hidden="true" />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                </Drawer>
            </Card >
        )
    }
}


function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerByFacebookName: (name) => dispatch(getCustomerByFacebookName(name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
