import {
    GET_ALL_PRODUCT_SUPPLIER,
    GET_PRODUCT_SUPPLIER,
    CREATE_PRODUCT_SUPPLIER,
    UPDATE_PRODUCT_SUPPLIER,
    REMOVE_PRODUCT_SUPPLIER,
    GET_PRODUCT_SUPPLIER_ACTIVE
} from '../actions/types';

const INIT_STATE = {
    product_supplier: [],
    currentProductSuppler: null,
    product_supplier_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_SUPPLIER:
            return {
                ...state,
                product_supplier: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_PRODUCT_SUPPLIER_ACTIVE: {
            return {
                ...state,
                product_supplier_active_list: action.payload.data,
            }
        }
        case GET_PRODUCT_SUPPLIER:
            return {
                ...state,
                currentProductSuppler: action.payload
            };
        case CREATE_PRODUCT_SUPPLIER:
            return {
                ...state,
                product_supplier: [action.payload, ...state.product_supplier],
                total: state.total + 1
            }

        case UPDATE_PRODUCT_SUPPLIER:
            let index = state.product_supplier.findIndex(response => {
                return response.id === action.payload.id
            });
            let temp = [...state.product_supplier];
            temp[index] = action.payload
            return {
                ...state,
                product_supplier: temp
            }
        case REMOVE_PRODUCT_SUPPLIER:
            let temp1 = state.product_supplier.filter(response => {
                return action.payload.indexOf(response.id) < 0;
            })
            return {
                ...state,
                product_supplier: temp1
            }
        default:
            return { ...state }
    }
};
