import api from '../../utils/api';
import {
    LOOKUP_GET_LIST_CUSTOMER,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListLookupCustomer = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/lookup-customer', { params: filter }).then(res => {
            dispatch({
                type: LOOKUP_GET_LIST_CUSTOMER,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
