import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Popconfirm, DatePicker, Card } from "antd";
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { customerGender } from '../../utils/helpers';
import moment from 'moment';
import BaseSelect from '../../components/Elements/BaseSelect';

class CustomerFamilyList extends Component {
    state = {
        items: [{
            key: 0,
            full_name: "",
            gender: "",
            birthday: "",
            relationship: "",
            phone: "",
            address: ""
        }],
        count: 0 // use for item key, not items length
    }

    componentDidMount() {
        if (this.props.currentCustomerFamily) {
            var items = this.props.currentCustomerFamily.map((item, index) => {
                return {
                    key: index + 1,
                    full_name: item.full_name,
                    gender: item.gender,
                    birthday: item.birthday ? moment(item.birthday, 'YYYY-MM-DD') : "",
                    relationship: item.relationship,
                    phone: item.phone,
                    address: item.address
                }
            });
            this.setState({ items: items, count: items.length }, () => {
                //console.log("this.state.items: ", this.state.items)
                this.getItems(this.state.items);
            });
        }
    }

    onAdd() {
        let index = this.state.count + 1;
        let item = {
            key: index,
            full_name: "",
            gender: "",
            birthday: "",
            relationship: "",
            phone: "",
            address: ""
        }

        this.setState({
            items: [
                ...this.state.items,
                item
            ],
            count: index
        })
    }

    onDelete(key) {
        let items = this.state.items.filter(item => {
            return item.key != key;
        });

        this.setState({
            items: items
        })
    }

    onChangeItem(name, value, itemKey) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.key == itemKey);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.setState({ items: items });
    }

    getItems() {
        this.props.getItems(this.state.items);
    }

    render() {
        var { items } = this.state;
        //console.log("item: ", items)
        var columns = [
            {
                title: "Họ và tên",
                dataIndex: "full_name",
                width: "20%",
                render: (text, record) => {
                    return (
                        <Input
                            value={text}
                            name="full_name"
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.key)}
                        />
                    )
                }
            },
            {
                title: "Giới tính",
                dataIndex: "gender",
                width: "14%",
                render: (text, record) => {
                    return (
                        <BaseSelect
                            options={customerGender || []}
                            placeholder="Chọn"
                            defaultValue={text ? text : null}
                            style={{ width: '100%' }}
                            onChange={(e) => this.onChangeItem('gender', e, record.key)}
                        />
                    )
                }
            },
            {
                title: "Năm sinh",
                dataIndex: "birthday",
                width: "14%",
                render: (text, record) => {
                    return (
                        <DatePicker
                            value={text}
                            name="birthday"
                            picker="year"
                            placeholder='Chọn'
                            style={{ width: '100%' }}
                            onChange={(value) => this.onChangeItem("birthday", value, record.key)}
                        />
                    )
                }
            },
            {
                title: "Quan hệ",
                dataIndex: "relationship",
                width: "14%",
                render: (text, record) => {
                    return (
                        <Input
                            value={text}
                            name="relationship"
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.key)}
                        />
                    )
                }
            },
            {
                title: "Số điện thoại",
                dataIndex: "phone",
                width: "14%",
                render: (text, record) => {
                    return (
                        <Input
                            value={text}
                            name="phone"
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.key)}
                        />
                    )
                }
            },
            {
                title: "Địa chỉ",
                dataIndex: "address",
                width: "24%",
                render: (text, record) => {
                    return (
                        <Input
                            value={text}
                            name="address"
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.key)}
                        />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa dòng này?"
                            onConfirm={() => this.onDelete(record.key)}
                        >
                            <DeleteTwoTone twoToneColor="#fe0000" />
                        </Popconfirm>
                    )
                }
            }
        ];

        return (
            <div className='no-border-buttom-table' onMouseOut={() => this.getItems()}>
                <Button type="default" size='small' onClick={() => this.onAdd()} icon={<PlusOutlined />}>Thêm thành viên trong gia đình</Button>
                <Table
                    className='mt-2'
                    size='small'
                    rowKey='key'
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    locale={{ emptyText: "Chưa có dữ liệu" }}
                    pagination={false}
                />
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(null, mapDispatchToProps)(CustomerFamilyList);