import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Drawer, Divider } from 'antd';
import { _newLine, customDataFilesUpload } from '../../utils/helpers';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';
import ListCurrentFiles from '../../components/ListCurrentFiles';

// actions
import { updateResult } from '../../redux/actions/ProjectBasedJobActions';

class UpdateResultForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            sort_order: 0,
            attached_files: [],
            currentFiles: [],
            selectFile: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentFiles: nextProps?.currentData?.result_attached_files,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isloading: true })
                    const { currentFiles, attached_files } = this.state;
                    if (this.props.currentData) {
                        values.result_attached_files = customDataFilesUpload(attached_files, currentFiles);
                        await this.props.updateResult(this.props.currentData.id, values);
                        this.props.reloadData()
                        this.handCancel()
                    }
                    //console.log(values)
                    this.setState({ isloading: false })
                } catch (error) {
                    this.setState({ isloading: false });
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({
            isloading: false,
            attached_files: [],
            selectFile: !this.state.selectFile
        })
    }

    onChangeUpload = (data) => {
        this.setState({
            ...this.state,
            attached_files: data,
        });
    }

    getFiles(data) {
        this.setState({ currentFiles: data })
    }

    render() {
        const { isloading, currentFiles } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Cập nhật kết quả công việc"
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form layout="vertical">
                            <Form.Item label="Nội dung">
                                {getFieldDecorator('result_content', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Field is required.'
                                        }
                                    ],
                                    initialValue: currentData ? _newLine(currentData?.result_content) : null
                                })(
                                    <CKEditorComponent
                                        initialHeight="300px"
                                        editorData={currentData?.result_content}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                        <Form.Item label="Tệp đính kèm">
                            {getFieldDecorator('result_attached_files', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ''
                            })(
                                <DraggerFile onChangeData={this.onChangeUpload} select={this.state.selectFile} />
                            )}
                            <ListCurrentFiles currentFiles={currentFiles} getData={(data) => this.getFiles(data)} />
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('result_note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.result_note) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateResult: (id, data) => dispatch(updateResult(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UpdateResultForm' })(UpdateResultForm));
