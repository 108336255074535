import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { withRouter } from 'react-router-dom';
import { cleanObject, repeatPhoneNumber, ConvertcustomerGender, ConvertCustomerCardStatus, isValidDate } from '../../utils/helpers';
import { Button, Col, Row, Space, Spin, Divider, Descriptions, Typography, Tag, Image, Alert, Collapse, Card } from "antd";
import ReactHtmlParser from 'react-html-parser';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import NumberFormat from 'react-number-format';
import { Form } from '@ant-design/compatible';
import CustomerForm from "./CustomerForm";
import { isMobile } from "react-device-detect";
import BaseSelect from '../../components/Elements/BaseSelect';
import { getListCustomerActive, getSpecificCustomer } from '../../redux/actions/CustomerActions';
import AppointmentHistory from "./AppointmentHistory";
import CustomerServices from "./CustomerServices";
import Payment from "./Payment";
import ProfileImages from "./ProfileImages";

const { Paragraph } = Typography;
const { Panel } = Collapse;

const CustomerInfo = (props) => {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [hasCustomer, setHasCustomer] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerQuery, setCustomerQuery] = useState('');
    const query = qs.parse(props.location.search.slice(1));

    const handleScroll = (ref) => {
        if (ref.current) {
            const spaceTop = isMobile ? 6 : 160;
            const offsetTop = ref === section1Ref ? ref.current.getBoundingClientRect().top + window.scrollY - 200 : ref.current.offsetTop + spaceTop;
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (query.keyword) {
            props.getListCustomerActive({ keyword: query.keyword });
            onChangeCustomer(query.keyword);
        }
    }, []);

    useEffect(() => {
        if (customer) {
            console.log('customer', customer);
            setHasCustomer(true);
            //window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            setHasCustomer(false);
        }
    }, [customer]);

    const onChangeFilter = (name, value) => {
        let query = qs.parse(props.location.search.slice(1));
        query[name] = value;
        query = cleanObject(query);
        props.history.push({
            pathname: props.location.pathname,
            search: qs.stringify(query)
        });
    };

    const onSearchCustomer = (val) => {
        setCustomerQuery(val);
        props.getListCustomerActive({ keyword: val });
    };

    const handleCustomerScroll = (e) => {
        props.getListCustomerActive({ keyword: customerQuery, page: props.pagination_active.currentPage + 1 });
    }

    const onChangeCustomer = (id) => {
        setIsLoading(true);
        props.getSpecificCustomer(id).then((item) => {
            onChangeFilter('keyword', id);
            setCustomer(item);
            setIsLoading(false);
        }).catch(err => {
            setCustomer(null);
            setIsLoading(false);
        });
    }

    const customerActiveList = props.customerActiveList;

    return (
        <div>
            <Row gutter={16} className='search-customer'>
                <Col xs={24} md={24} className="box-item-mobile mt-0 mb-0">
                    <Card
                        size="small"
                        bordered={false}
                    >
                        <BaseSelect
                            options={customerActiveList || []}
                            optionValue="id"
                            optionLabel="full_name"
                            additionalLabel="phone"
                            additionalLabel2="code"
                            isCustomerList={true}
                            showSearch
                            defaultValue={parseInt(query.keyword) || undefined}
                            placeholder="Nhập mã, tên, 3 số cuối sđt của khách hàng"
                            onSearch={(val) => onSearchCustomer(val)}
                            onScrollEnd={(e) => handleCustomerScroll(e)}
                            onChange={(value) => onChangeCustomer(value)}
                            className="w-100"
                            size="large"
                            onClear={() => { onChangeFilter('keyword', '') }}
                        />
                    </Card>
                </Col>
            </Row>

            {hasCustomer ? (
                <div className="customer-info-data">
                    <Row gutter={16} className="customer-action-bar">
                        <Col xs={24} md={24} className='box-item-mobile'>
                            <Card
                                size="small"
                                bordered={false}
                            >
                                <Space size={[5, 5]} wrap center='center' style={{ justifyContent: 'center' }}>
                                    <Button onClick={() => handleScroll(section1Ref)} type="primary">Thông tin khách hàng</Button>
                                    <Button onClick={() => handleScroll(section2Ref)} type='primary'>Lịch sử cuộc hẹn</Button>
                                    <Button onClick={() => handleScroll(section3Ref)} type="primary">Dịch vụ chốt</Button>
                                    <Button onClick={() => handleScroll(section4Ref)} type="primary">Thanh toán</Button>
                                    <Button onClick={() => handleScroll(section5Ref)} type="primary">Cập nhật ảnh</Button>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <div ref={section1Ref}></div>
                        <Row justify="end" gutter={16}>
                            <Col xs={24} md={24} className='box-item-mobile'>
                                <Card size="small"
                                    title={<><Tag color='#0b2b33'>1</Tag>  Thông tin khách hàng</>}
                                    bordered={false}
                                >
                                    <Collapse
                                        expandIconPosition="end"
                                        ghost
                                        defaultActiveKey={['1']}
                                    >
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Tên khách hàng </span> {customer?.full_name}
                                        </>} key="1">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã khách hàng">
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: customer?.code,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <Tag color='#1890ff'>{customer?.code}</Tag>
                                                    </Paragraph>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Số điện thoại">
                                                    {customer ? (
                                                        <HandlePhoneNumber
                                                            permission={checkPermission('customer', 'phonenumber')}
                                                            inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                            customer_id={customer.id}
                                                            hiddenPhoneNumber={repeatPhoneNumber(customer?.phone)}
                                                            fullPhoneNumber={customer?.phone}
                                                        />
                                                    ) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Cơ sở">
                                                    {customer?.branch_management}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Panel>
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Doanh số </span>
                                            <span className="ant-descriptions-item-content">
                                                <Tag color='green'>
                                                    <NumberFormat value={customer?.revenue} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                </Tag>
                                            </span>

                                        </>} key="2">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ưu đãi">
                                                    <Tag color='blue'>
                                                        <NumberFormat value={customer?.total_discount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Đã thanh toán">
                                                    <Tag color='cyan'>
                                                        <NumberFormat value={customer?.total_amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Còn nợ">
                                                    <Tag color='red'>
                                                        <NumberFormat value={customer?.customer_debt} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            {isMobile ? <Divider className="no-margin" /> : null}
                                        </Panel>
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Hạng khách hàng </span>
                                            <span className="ant-descriptions-item-content">
                                                <Tag color="#f39c12">{customer?.customer_class}</Tag>
                                            </span>
                                        </>} key="3">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Nguồn">
                                                    {customer?.customer_source}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số thẻ cũ">
                                                    {
                                                        customer?.card_number ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: customer?.card_number,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {customer?.card_number}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số thẻ mới">
                                                    {
                                                        customer?.card_number_new ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: customer?.card_number_new,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {customer?.card_number_new}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Giới tính">
                                                    {customer ? ConvertcustomerGender(customer.gender) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Năm sinh">
                                                    {customer?.birthday}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Trạng thái thẻ">
                                                    {customer ? ConvertCustomerCardStatus(customer?.card_status) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ngày tạo">
                                                    {isValidDate(customer?.created_at)} bởi: {customer?.created_user}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Panel>
                                    </Collapse>
                                    <Row gutter={16} className='mt-3'>
                                        <Col xs={24} md={24}>
                                            <Alert
                                                message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                                description={
                                                    <>
                                                        {customer ? ReactHtmlParser(customer.note) : null}

                                                        {Array.isArray(customer?.banner_ads) && customer?.banner_ads.length ? (
                                                            <>
                                                                <Divider orientation="left" orientationMargin={0}>Banner quảng cáo</Divider>
                                                                <Image.PreviewGroup>
                                                                    <Space>
                                                                        {
                                                                            customer?.banner_ads.map((item, index) => {
                                                                                return (
                                                                                    <Image
                                                                                        key={index}
                                                                                        height={50}
                                                                                        width={50}
                                                                                        src={item.src}
                                                                                        style={{
                                                                                            border: '1px solid #dedede',
                                                                                            objectFit: 'cover',
                                                                                            objectPosition: 'center'
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </>
                                                        ) : null}
                                                    </>
                                                }
                                                type="info"
                                                className='fix-alert-element'
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <div ref={section2Ref}></div>
                        <AppointmentHistory currentCustomer={customer} />
                        <div ref={section3Ref}></div>
                        <CustomerServices
                            currentItem={customer}
                            reloadData={() => onChangeCustomer(query.keyword)}
                        />
                        <div ref={section4Ref}></div>
                        <Payment currentItem={customer} />
                        <div ref={section5Ref}></div>
                        <ProfileImages currentItem={customer} />
                    </Spin>
                </div>
            ) : (
                <CustomerForm setCustomer={setCustomer} />
            )}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerInfo' })(CustomerInfo)));