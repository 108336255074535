import {
    GET_LIST_SERVICE,
    GET_SPECIFIC_SERVICE,
    UPDATE_SPECIFIC_SERVICE,
    CREATE_NEW_SERVICE,
    REMOVE_SERVICE,
    GET_SERVICE_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    service_list: [],
    currentService: null,
    service_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_SERVICE: {
            return {
                ...state,
                service_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_SERVICE_ACTIVE: {
            return {
                ...state,
                service_active_list: action.payload.data,
            }
        }
        case GET_SPECIFIC_SERVICE: {
            return { ...state, currentService: action.payload }
        }
        // case CREATE_NEW_SERVICE: {
        //     return {
        //         ...state,
        //         service_list: [action.payload, ...state.service_list],
        //         service_active_list: [action.payload, ...state.service_active_list],
        //         total: state.total + 1
        //     }
        // }
        // case UPDATE_SPECIFIC_SERVICE: {
        //     let index = state.service_list.findIndex((item) => {
        //         return item.id == action.payload.id;
        //     });
        //     let temp = [...state.service_list];
        //     temp[index] = action.payload;
        //     //
        //     let index2 = state.service_active_list.findIndex((item) => {
        //         return item.id == action.payload.id;
        //     });
        //     let temp2 = [...state.service_active_list];
        //     temp[index2] = action.payload;

        //     return { ...state, service_list: temp, service_active_list: temp2 }
        // }
        // case REMOVE_SERVICE: {
        //     let temp = state.service_list.filter(item => {
        //         return action.payload.indexOf(item.id) < 0;
        //     });

        //     let temp2 = state.service_active_list.filter(item => {
        //         return action.payload.indexOf(item.id) < 0;
        //     });
        //     return { ...state, service_list: temp, service_active_list: temp2 }
        // }
        default: return { ...state };
    }
}
